<template>
  <div class="column pa-4">
    <h1>Данные разработки</h1>
    <v-row
      v-if="history_dates.length"
      class="mb-6"
      no-gutters
    >
      <v-col
        v-for="(date, i) in history_dates"
        :key="i"
        sm="5"
        md="6"
      >
        <h5>{{ names[i] }}</h5>
        <v-sheet class="pa-2 ma-2">
          <apexchart
            type="bar"
            :options="getOptions(i)"
            :series="getSeries(i)"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <v-container
      v-else
      class="text-center mx-auto"
    >
      <v-progress-circular
        size="48"
        indeterminate
      />
    </v-container>
  </div>
</template>

<script>
import HistoryService from "@/service/history-service";
import moment from "moment";

export default {
  props: {
    labelsdata: {
      type: [Array, Object],
      required: true
    },
    chartdata: {
      type: [Array, Object],
      required: true
    }
  },
  data () {
    return {
      history_params: [],
      history_dates: [],
      history_values: [],
      names: [],
    };
  },
  mounted() {
    HistoryService.getParamsNames().then(({data}) => {
      this.history_params = data.names;
      this.history_params.map(param => {
        HistoryService.getHistory(param).then(({data}) => {
          this.names.push(data.description);
          this.history_dates.push(data.dates);
          this.history_values.push(data.values);
        });
      });
    });
  },
  methods: {
    getDateLabel(unixStamp) {
      const label = moment(unixStamp / 1000000).calendar();
      return label;
    },
    getSeries(i) {
      const series = [
        {
          data: this.history_values[i]
        }
      ];
      return series;
    },
    getOptions(i) {
      const categories = this.history_dates[i].map(d => this.getDateLabel(d));
      return {
        yaxis: {
          labels: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(2);
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories
        }
      };
    }
  }
};
</script>
