import httpClient from "@/http";

class LinkingApi {
  getLinkings(wellId) {
    return httpClient.get(`/api/v1/geology/well/${wellId}/core-delta/`);
  }

  removeGroup(wellId) {
    return httpClient.delete(`/api/v1/petrophysics/core-delta-group/${wellId}/delete_core_delta/`);
  }

    
  saveLinkings(user = null, data) {
    // {
    //     "top": null,
    //     "bot": null,
    //     "value": null,
    //     "well": null
    //     "id": null
    // }
        
    return httpClient.post(`/api/v1/petrophysics/core-delta-group/${data.id}/create_or_update/`, {
      // user: user,
      ...data
    });


  }
}

export default new LinkingApi();