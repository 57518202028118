<template>
  <div id="app">
    <transition name="main">
      <vue-extend-layouts />
    </transition>
    <notifications group="notification_container" />
  </div>
</template>

<script>
import VueExtendLayouts from "vue-extend-layout";

export default {
  name: "App",
  components: { VueExtendLayouts },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.v-application {
  font-family: 'Montserrat', sans-serif !important;
}

.main-enter-active {
  transition: all .5s;
}

.main-enter {
  opacity: 0;
}
</style>