<template>
  <v-container class="pt-12 mb-2">
    <mq-layout mq="mobile">
      <mobile-menu>
        <v-col class="creators-mobile">
          <v-row
            v-for="person in myJson.persons"
            :key="person.name"
            class="profile-item-mobile pa-0 mb-5"
            cols="auto"
          >
            <v-row class="pa-0 ma-0">
              <v-col
                class="pa-0"
                cols="auto"
              >
                <img
                  :src="require(`../assets/creators/${person.img}`)"
                  :alt="$t(person.name)"
                  style="height: 100%"
                >
              </v-col>
              <v-col
                cols="auto"
                class="profile-item-mobile__descriptions align-self-end"
              >
                <strong>{{ $t(person.name) }}</strong> <br>
                {{ $t(person.position) }} <br>
                {{ $t(person.scope) }}
              </v-col>
              <v-col class="pa-0 align-self-end text-right">
                <v-icon>
                  mdi-account
                </v-icon>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </mobile-menu>
    </mq-layout>
    <mq-layout mq="tablet+">
      <desktop-menu>
        <v-col class="creators">
          <v-row
            v-for="person in myJson.persons"
            :key="person.name"
            class="profile-item pa-0 mb-5"
            cols="auto"
          >
            <v-row class="pa-0 ma-0">
              <v-col
                class="pa-0"
                cols="auto"
              >
                <img
                  :src="require(`../assets/creators/${person.img}`)"
                  :alt="$t(person.name)"
                  style="height: 100%"
                >
              </v-col>
              <v-col
                cols="auto"
                class="profile-item__descriptions align-self-end"
              >
                <strong>{{ $t(person.name) }}</strong> <br>
                {{ $t(person.position) }} <br>
                {{ $t(person.scope) }}
              </v-col>
              <v-col class="pa-0 align-self-end text-right">
                <v-icon>
                  mdi-account
                </v-icon>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </desktop-menu>
    </mq-layout>
  </v-container>
</template>

<script>
import json from "./persons.json";

export default {
  name: "CreatorsView",
  data (){
    return {
      myJson: json
    };
  }
};
</script>

<style scoped lang="scss">
.creators {
  padding-top: 70px;

  &-mobile {
    width: 100vw;
    padding-top: 70px;
  }
}

.profile-item {
  border-bottom: 2px #676767 solid;
  width: 50vw;

  &__descriptions {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

</style>