<template>
  <v-container class="main-container">
    <v-col>
      <h1>{{ $t("glossary.glossary") }}</h1> <br>
      <v-row
        class="my-4 clickable"
        no-gutters
        @click="isShowPetrophysicsTerms = !isShowPetrophysicsTerms"
      >
        <h2>{{ $t("glossary.first_module") }}</h2>
        <v-spacer />
        <v-btn icon>
          <v-icon>{{ isShowPetrophysicsTerms ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
        </v-btn>
      </v-row>
      <v-expand-transition>
        <div v-show="isShowPetrophysicsTerms">
          <v-card
            v-for="(item, index) in petrophysicsTerms"
            :key="index"
            class="pa-4 my-2"
          >
            <p><strong>{{ item.name }}</strong></p>
            <p>{{ item.description }}</p>
          </v-card>
        </div>
      </v-expand-transition>
      <v-row
        class="my-4 clickable"
        no-gutters
        @click="isShowGeologyTerms = !isShowGeologyTerms"
      >
        <h2>{{ $t("glossary.second_module") }}</h2>
        <v-spacer />
        <v-btn icon>
          <v-icon>{{ isShowGeologyTerms ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
        </v-btn>
      </v-row>
      <v-expand-transition>
        <div v-show="isShowGeologyTerms">
          <v-card
            v-for="(item, index) in geologyTerms"
            :key="index"
            class="pa-4 my-2"
          >
            <p><strong>{{ item.name }}</strong></p>
            <p>{{ item.description }}</p>
          </v-card>
        </div>
      </v-expand-transition>
    </v-col>
  </v-container>
</template>

<script>
import ContentService from "@/service/content-service";

export default {
  name: "GlossaryView",
  data(){
    return {
      glossary: [],
      isShowPetrophysicsTerms: false,
      isShowGeologyTerms: false
    };
  },
  computed: {
    petrophysicsTerms() {
      return this.glossary.filter(term => term.module === "2");
    },
    geologyTerms() {
      return this.glossary.filter(term => term.module === "1");
    }
  },
  mounted() {
    ContentService.getGlossary().then(({data}) => {
      this.glossary = data;
    });
  },
};

</script>

<style scoped lang="scss">

.main-container {
  margin-top: 80px;
  margin-left: 0;
}


table, th, td {
  border:1px solid black;
}

.clickable {
  cursor: pointer;
}
</style>