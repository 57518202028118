<template>
  <v-container>
    <mq-layout mq="mobile">
      <mobile-content>
        <v-row class="main-container text-h4 font-weight-bold justify-center">
          {{ $t("episodes.episodes") }}
        </v-row>
        <v-row class="justify-center">
          <v-col cols="auto gif-wrapper">
            <img
              src="@/assets/episodes/Petrophysics.png"
              alt=""
            >
          </v-col>
        </v-row>
        <div class="module__title">
          {{ $t("episodes.petrophysics.title") }}
        </div>
        <v-row class="module__description justify-center  pa-2">
          {{ $t("episodes.petrophysics.description") }}
        </v-row>
        <v-row class="justify-center">
          <button
            class="module__btn"
            disabled
            @click="$router.push({name:'petrophysics'})"
          >
            {{ $t("episodes.status.go_to_desktop") }}
          </button>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="auto gif-wrapper">
            <img
              src="@/assets/episodes/Geology.png"
              alt=""
            >
          </v-col>
        </v-row>
        <div class="module__title">
          {{ $t("episodes.geology.title") }}
        </div>
        <v-row class="module__description justify-center  pa-2">
          {{ $t("episodes.geology.description") }}
        </v-row>
        <v-row class="justify-center">
          <button
            class="module__btn"
            disabled
            @click="$router.push({name:'geology'})"
          >
            {{ $t("episodes.status.go_to_desktop") }}
          </button>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="auto gif-wrapper">
            <img
              src="@/assets/episodes/ReservoirDevelopment.png"
              alt=""
            >
          </v-col>
        </v-row>
        <div class="module__title">
          {{ $t("episodes.history.title") }}
        </div>
        <v-row class="module__description justify-center  pa-2">
          {{ $t("episodes.history.description") }}
        </v-row>
        <v-row class="justify-center">
          <button
            class="module__btn"
            disabled
            @click="$router.push({name:'history'})"
          >
            {{ $t("episodes.status.go_to_desktop") }}
          </button>
        </v-row>


        <v-row class="justify-center">
          <v-col cols="auto gif-wrapper">
            <img
              src="@/assets/episodes/ReservoirSimulation.png"
              alt=""
            >
          </v-col>
        </v-row>
        <div class="module__title">
          {{ $t("episodes.reservoir.title") }}
        </div>
        <v-row class="module__description justify-center  pa-2">
          {{ $t("episodes.reservoir.description") }}
        </v-row>
        <v-row class="justify-center">
          <button
            class="module__btn"
            disabled
            @click="$router.push({name:'history'})"
          >
            {{ $t("episodes.status.go_to_desktop") }}
          </button>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="auto gif-wrapper">
            <img
              src="@/assets/episodes/Drilling.png"
              alt=""
            >
          </v-col>
        </v-row>
        <div class="module__title">
          {{ $t("episodes.strategies.title") }}
        </div>
        <v-row class="module__description justify-center  pa-2">
          {{ $t("episodes.strategies.description") }}
        </v-row>
        <v-row class="justify-center">
          <button
            class="module__btn"
            disabled
            @click="$router.push({name:'history'})"
          >
            {{ $t("episodes.status.go_to_desktop") }}
          </button>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="auto gif-wrapper">
            <img
              src="@/assets/episodes/Economics.png"
              alt=""
            >
          </v-col>
        </v-row>
        <div class="module__title">
          {{ $t("episodes.economics.title") }}
        </div>
        <v-row class="module__description justify-center  pa-2">
          {{ $t("episodes.economics.description") }}
        </v-row>
        <v-row class="justify-center">
          <button
            class="module__btn"
            disabled
            @click="$router.push({name:'history'})"
          >
            {{ $t("episodes.status.go_to_desktop") }}
          </button>
        </v-row>
      </mobile-content>
    </mq-layout>
    

    <mq-layout mq="tablet+">
      <desktop-content>
        <v-row class="text-left text-h4 pl-16 font-weight-bold">
          <v-col>
            {{ $t("episodes.episodes") }}
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col
            cols="3"
            class="module ma-5"
          >
            <v-row class="justify-center">
              <v-col cols="auto gif-wrapper">
                <img
                  src="@/assets/episodes/Petrophysics.png"
                  alt=""
                >
              </v-col>
            </v-row>
            <div class="module__title">
              {{ $t("episodes.petrophysics.title") }}
            </div>
            <v-row class="module__description justify-center">
              {{ $t("episodes.petrophysics.description") }}
            </v-row>
            <v-row class="justify-center">
              <button
                class="module__btn"
                @click="$router.push({name:'petrophysics'})"
              >
                {{ $t("episodes.status.go_to") }}
              </button>
            </v-row>
          </v-col>
          <v-col
            cols="3"
            class="module ma-5"
          >
            <v-row class="justify-center">
              <v-col cols="auto gif-wrapper">
                <img
                  src="@/assets/episodes/Geology.png"
                  alt=""
                >
              </v-col>
            </v-row>
            <div class="module__title">
              {{ $t("episodes.geology.title") }}
            </div>
            <v-row class="module__description justify-center">
              {{ $t("episodes.geology.description") }}
            </v-row>
            <v-row class="justify-center">
              <button
                class="module__btn"
                @click="$router.push({name:'geology'})"
              >
                {{ $t("episodes.status.go_to") }}
              </button>
            </v-row>
          </v-col>

          <v-col
            cols="3"
            class="module ma-5"
          >
            <v-row class="justify-center">
              <v-col cols="auto gif-wrapper">
                <img
                  src="@/assets/episodes/ReservoirDevelopment.png"
                  alt=""
                >
              </v-col>
            </v-row>
            <div class="module__title">
              {{ $t("episodes.history.title") }}
            </div>
            <v-row class="module__description justify-center">
              {{ $t("episodes.history.description") }}
            </v-row>
            <v-row class="justify-center">
              <button
                class="module__btn-disabled"
                disabled
                @click="$router.push({name:'history'})"
              >
                {{ $t("episodes.status.unavailable") }}
              </button>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col
            cols="3"
            class="module ma-5"
          >
            <v-row class="justify-center">
              <v-col cols="auto gif-wrapper">
                <img
                  src="@/assets/episodes/ReservoirSimulation.png"
                  alt=""
                >
              </v-col>
            </v-row>
            <div class="module__title">
              {{ $t("episodes.reservoir.title") }}
            </div>
            <v-row class="module__description justify-center">
              {{ $t("episodes.reservoir.description") }}
            </v-row>
            <v-row class="justify-center">
              <button class="module__btn-disabled">
                {{ $t("episodes.status.unavailable") }}
              </button>
            </v-row>
          </v-col>
          <v-col
            cols="3"
            class="module ma-5"
          >
            <v-row class="justify-center">
              <v-col cols="auto gif-wrapper">
                <img
                  src="@/assets/episodes/Drilling.png"
                  alt=""
                >
              </v-col>
            </v-row>
            <div class="module__title">
              {{ $t("episodes.strategies.title") }}
            </div>
            <v-row class="module__description justify-center">
              {{ $t("episodes.strategies.description") }}
            </v-row>
            <v-row class="justify-center">
              <button
                class="module__btn-disabled"
              >
                {{ $t("episodes.status.unavailable") }}
              </button>
            </v-row>
          </v-col>
          <v-col
            cols="3"
            class="module ma-5"
          >
            <v-row class="justify-center">
              <v-col cols="auto gif-wrapper">
                <img
                  src="@/assets/episodes/Economics.png"
                  alt=""
                >
              </v-col>
            </v-row>
            <div class="module__title">
              {{ $t("episodes.economics.title") }}
            </div>
            <v-row class="module__description justify-center">
              {{ $t("episodes.economics.description") }}
            </v-row>
            <v-row class="justify-center">
              <button class="module__btn-disabled">
                {{ $t("episodes.status.unavailable") }}
              </button>
            </v-row>
          </v-col>
        </v-row>
      </desktop-content>
    </mq-layout>
  </v-container>
</template>

<script>

export default {
  name: "EpisodesPageView",
  methods: {
  }
};
</script>

<style scoped lang="scss">

.main-container {
  padding-top: 90px;
}

.module {
  &__title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__description {
    font-size: 1.2rem;
    margin-bottom: .2rem;
    min-height: 100px;
    text-align: justify;
  }

  &__btn {
    color: #18A0FB;
    border: 1px solid #18A0FB;
    border-radius: 6px;
    padding: .5rem 2rem;
    transition: .2s;


    &:hover {
      transform: scale(1.1);
    }

    &-disabled {
      color: #B9B9B980;
      border: 1px solid #B9B9B980;
      border-radius: 6px;
      padding: .5rem 2rem;
    }
  }
}

.gif-wrapper {
  width: 250px;
  height: 200px;

  & > img {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  margin-bottom: 1rem;
}

</style>