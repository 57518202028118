<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-row>
      <v-col
        cols="auto"
        class="map"
      >
        <Plotly
          ref="plot"
          :data="wellData"
          :layout="layout"
          :display-mode-bar="true"
          :scroll-zoom="true"
          @click="navigateToGisPage"
        />
      </v-col>
      <v-snackbar
        v-model="isSuccess"
        :timeout="2000"
        right
        color="green"
        class="text--black"
        elevation="24"
      >
        {{ $t("geology.surface-is-calculated") }}
      </v-snackbar>
      <v-snackbar
        v-model="isError"
        :timeout="2000"
        right
        color="red accent-2"
        elevation="24"
      >
        Ошибка при расчете поверхности
      </v-snackbar>
      <v-overlay v-model="isLoading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-col>
        <v-container class="justify-center">
          <v-row>
            <v-col>
              <button
                class="well-info run-button"
                @click="run"
              >
                {{ $t("geology.recalculate") }}{{ selected_layer }}
              </button>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="surface-selector">
              <v-select
                v-model="selectedSurface"
                :items="surfaces"
                :label="$t('common.surface')"
                return-object
                outlined
                @change="getMapData"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="surface-selector">
              <v-select
                v-model="selected_layer"
                :items="layers"
                :label="$t('common.layer')"
                return-object
                outlined
                @change="getMapData"
              />
            </v-col>
          </v-row>
          <v-row class="wells-info__title justify-center">
            <v-col>
              {{ $t("geology.task") }} <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    slot="activator"
                    dark
                    color="#009797"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>Для установки отбивки нужно перейти к просмотру данных по скважине и проставить отметку кровли и подошвы Башкирского горизонта</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            v-for="well in interactiveWells"
            :key="well.id"
            col="12"
          >
            <div
              class="well-info"
              :class="{
                bounded: well.zones[0].top != null && well.zones[0].bottom != null
              }"
              @click="navigateToGisPageFromList(well.id)"
            >
              {{ $t("common.well") }} {{ well.name }}
            </div>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";
import surfaceService from "@/service/surface-service";
import {Plotly} from "vue-plotly";

export default {
  name: "Map",
  components: {Plotly},
  data() {
    return {
      isError: false,
      isLoading: false,
      isSuccess: false,
      layers: [],
      task_id: "",
      selected_layer: "",
      surfaces: [this.$t("geology.map-top"), this.$t("geology.map-bot")],
      selectedSurface: this.$t("geology.map-top"),
      wellData: [],
      layout: {
        showlegend: true,
        legend: {
          orientation: "v", y: 1, x: 0, 
          font: {
            size : 18}
        },
        hovermode: "closest",
        width: window.innerWidth * 0.6,
        height: window.innerHeight - 100,
        xaxis: {showticklabels:false,
        },
        yaxis: {
          scaleanchor: "x",
          showticklabels:false,
        },
      },
      interactiveWells: []
    };
  },
  async beforeMount() {
    await this.getLayerNames();
    await this.getMapData();
  },
  
  methods: {
    async getLayerNames(){
      await surfaceService.getAllSurfaces().then(async ({data}) => {
        
        for (var i = 0; i < data.length; i++) {
          switch (data[i].zone_name) {
          case "Башкирский":
            this.layers.push(this.$t("common.bashkir"));
            break;
          case "Верейский":
            this.layers.push(this.$t("common.verey"));
            break;
          case "Серпуховский":
            this.layers.push(this.$t("common.serpukhov"));
            break;
          default:
            this.layers.push(data[i].zone_name);}
          
        }
        this.selected_layer = this.layers[0];});
      return this.layers[0];
    },
    
    async getMapData(){
      await wellService.getAllWells().then(async ({data}) => {
        let x = [];
        let y = [];
        let text = [];
        let customData = [];
        let hovertemplate = [];
        let color = [];
        this.wellData =[];
        const interactiveWells = data.filter(well => well.is_interactive);
        const notInteractiveWells = data.filter(well => !well.is_interactive);

        this.interactiveWells = interactiveWells;
        const layer_to_push = "";
        switch (this.selected_layer) {
        case this.$t("common.bashkir"):
          this.layer_to_push = "Башкирский";
          break;
        case this.$t("common.verey") :
          this.layer_to_push = "Верейский";
          break;
        case this.$t("common.serpukhov") :
          this.layer_to_push = "Серпуховский"; 
          break;
        default:
          this.layer_to_push = this.selected_layer;
        }

        data = await surfaceService.getSurface(this.layer_to_push).then(({data}) => {
          if (this.selectedSurface==this.$t("geology.map-top")) {return data.top;} else {return data.bot;}
        
        });

        this.wellData.push({
          ...data,
          type: "contour",
          colorscale: "Earth",
          autocontour: true,
          contours: {
            coloring: "heatmap",
            showlabels: true,
            labelfont: {
              family: "Raleway",
              size: 9,
              color: "white",
            },
          },
          reversescale: true,
          colorbar:{
            title: this.$t("common.depth"),
            titleside: "left",
            titlefont: {
              size: 14,
              family: "Arial, sans-serif"
            },
          },
        });

        notInteractiveWells.forEach(well => { 
          x.push(well.x);
          y.push(well.y);
          text.push(well.name);
          customData.push(well);
          hovertemplate.push(

            "Скв.: %{text}<br>"
          );

        
        });
        this.wellData.push({
          x: x,
          y: y,
          text: text,
          textposition: "bottom",
          customdata: customData,
          type: "scatter",
          mode: "markers+text",
          name:this.$t("common.not-interactive-well"),
          marker: {
            color: "lightgreen",
            size: 20,
            line: {
              color: "black",
              width: 1
            },
          },
          hovertemplate: hovertemplate,
        });

        x = [];
        y = [];
        text = [];
        customData = [];
        hovertemplate = [];
        interactiveWells.forEach(well => {
          x.push(well.x);
          y.push(well.y);
          text.push(well.name);
          customData.push(well);
          hovertemplate.push(

            "Скв: %{text}<br>" +
              `Год бурения: ${well.drilling_year ?? "-"}<br>` +
              `Отбивки: ${well.zones[0].top ? "выставлены" : "не выставлены"}`
          );
        
          if (well.zones[0].top == null || well.zones[0].bottom == null) {
            color.push("red");
          } else {
            color.push("green");
          }
        });

        this.wellData.push({
          x: x,
          y: y,
          customdata: customData,
          text: text,
          type: "scatter",
          mode: "markers+text",
          textposition: "bottom",
          name: this.$t("common.interactive-well"),
          marker: {
            color: color,
            size: 20,
            line: {
              color: "black",
              width: 1
            }
          },
          hovertemplate: hovertemplate,
        });
      });
    },
    run() {
      this.isLoading = true;
      surfaceService.runSurfaceModule(this.layer_to_push).then(data => {
        this.task_id = data.data.task_id;
      }).catch((err) => {
        this.isError = true;
      }).finally(() => {
        this.getStatus();
      });
    },
    getStatus(){
      surfaceService.getTaskStatus(this.task_id).then(data => {
        const taskStatus = data.data.task_status;
        if (taskStatus === "SUCCESS" ) {
          surfaceService.getResults(this.task_id, this.layer_to_push)
            .then(data => {
              this.getMapData();
              this.isLoading=false;
              this.isSuccess = true;
            });
          return false;} else {
          if ( taskStatus === "FAILURE") {
            this.isLoading=false;
            this.isError = true;
            return false;
          }
        }
        setTimeout(() => {
          this.getStatus();
        }, 1000);
      } )
        .catch((err) => {
          this.isError = true;
        });
    },
    async navigateToGisPage(event) {
      const id = event.points[0].customdata.id;
      if (!event.event.shiftKey) {
        return;
      }
      const isNotComputed = !event.points[0].customdata.is_interactive;
      if (isNotComputed) {
        return;
      }
      await this.$router.push({
        name: "geology-gis",
        params: {
          id: id
        }
      });
    },
    async navigateToGisPageFromList(id) {
      await this.$router.push({
        name: "geology-gis",
        params: {
          id: id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">

.main-container {
  margin-top: 80px;
  margin-left: 0;
}

.wells-info__title {
  font-size: 1.4rem;
  font-weight: 700;
  user-select: none;
  padding: 0rem;
}

.well-info {
  width: 100%;
  padding: 0.6rem;
  margin-bottom: .3rem;
  font-weight: 500;
  user-select: none;

  border: 1px solid black;
  border-bottom: 4px solid rgba(255, 0, 0, 0.5);
  border-radius: 6px;

  transition: .2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}
.surface-selector {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.run-button {
  font-weight: bold;
  color: #18A0FB;
  border: 2px solid #18A0FB;
  border-radius: 6px;
  padding: .5rem 2rem;
  transition: .2s;

  &:hover {
    transform: scale(1.1);
  }
}
.bounded {
  border-bottom: 4px solid rgba(0, 255, 0, 0.5);
}

.map {
  border: 1 px;
  border-style: dashed;
}
</style>