<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-row>
      <v-col
        cols="auto"
        class="map"
      >
        <Plotly
          ref="plot"
          :data="wellData"
          :layout="layout"
          :display-mode-bar="true"
          :scroll-zoom="true"
          @click="navigateToGisPage"
        />
      </v-col>
      <v-col>
        <v-row>
          <v-col class="surface-selector">
            <v-select
              v-model="selectedSurface"
              :items="surfaces"
              :label="$t('common.surface')"
              return-object
              outlined
              @change="getMapData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="surface-selector">
            <v-select
              v-model="selected_layer"
              :items="layers"
              :label="$t('common.layer')"
              return-object
              outlined
              @change="getMapData"
            />
          </v-col>
        </v-row>
      
        <v-col>
          <v-container class="justify-center">
            <v-row class="wells-info__title justify-center">
              <v-col>
                {{ $t("petrophysics.owc.wells") }}  <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      slot="activator"
                      dark
                      color="#009797"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>Для установки ВНК нужно перейти к просмотру данных по скважине и проставить отметку кровли воды и подошвы нефти для соответствующего горизонта </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <p v-if="isAllWellsNotComputed">
              {{ $t("petrophysics.owc.no-wells") }} 
            </p>
            <template v-else>
              <v-row
                v-for="well in interactiveWells"
                :key="well.id"
                col="12"
              >
                <div
                  class="well-info"
                  :class="{
                    uncomputed: well.calculated_wellogs.length < 4 , started: well.calculated_wellogs.length <=3 && well.calculated_wellogs.length
                  }"
                  @click="well.calculated_wellogs.length != 4 ? false : navigateToGisPageFromList(well.id)"
                >
                  {{ $t("common.well") }}  {{ well.name }}
                </div>
              </v-row>
            </template>
          </v-container>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";
import surfaceService from "@/service/surface-service";
import {Plotly} from "vue-plotly";

export default {
  name: "PetroMap",
  components: {Plotly},
  data() {
    return {
      isError: false,
      isLoading: false,
      isSuccess: false,
      layers: [],
      task_id: "",
      selected_layer: "",
      surfaces: [this.$t("geology.map-top"), this.$t("geology.map-bot")],
      selectedSurface: this.$t("geology.map-top"),
      wellData: [],
      layout: {
        showlegend: false,
        hovermode: "closest",
        width: window.innerWidth * 0.6,
        height: window.innerHeight - 100,
        xaxis: {showticklabels:false,
        },
        yaxis: {
          scaleanchor: "x",
          showticklabels:false,
        },
      },
      interactiveWells: [],
      wells: [],
      wellLogsAdapter: {
        poro: this.$t("common.poro"),
        satur_water: this.$t("common.satur_water"),
        satur_oil: this.$t("common.satur_oil"),
        perm: this.$t("common.perm"),
      },
    };
  },
  computed: {
    isAllWellsNotComputed() {
      return this.wells.every((e) => !e.calculated_wellogs.length);
    },
  },
  async beforeMount() {
    await this.getLayerNames();
    await this.getMapData();
  },
  
  methods: {
    async getLayerNames(){
      await surfaceService.getAllSurfaces().then(async ({data}) => {
        
        for (var i = 0; i < data.length; i++) {
          switch (data[i].zone_name) {
          case "Башкирский":
            this.layers.push(this.$t("common.bashkir"));
            break;
          case "Верейский":
            this.layers.push(this.$t("common.verey"));
            break;
          case "Серпуховский":
            this.layers.push(this.$t("common.serpukhov"));
            break;
          default:
            this.layers.push(data[i].zone_name);}
          
        }
        this.selected_layer = this.layers[0];});
      return this.layers[0];
    },
    
    async getMapData(){
      await wellService.getAllWells().then(async ({data}) => {
        let x = [];
        let y = [];
        let text = [];
        let customData = [];
        let hovertemplate = [];
        let color = [];
        this.wellData =[];
        this.wells = data;
        const interactiveWells = data.filter(well => well.has_cores);
        const notInteractiveWells = data.filter(well => !well.has_cores);

        this.interactiveWells = interactiveWells;

        const layer_to_push = "";
        switch (this.selected_layer) {
        case this.$t("common.bashkir"):
          this.layer_to_push = "Башкирский";
          break;
        case this.$t("common.verey") :
          this.layer_to_push = "Верейский";
          break;
        case this.$t("common.serpukhov") :
          this.layer_to_push = "Серпуховский"; 
          break;
        default:
          this.layer_to_push = this.selected_layer;
        }

        data = await surfaceService.getSurface(this.layer_to_push).then(({data}) => {
          if (this.selectedSurface==this.$t("geology.map-top")) {return data.top;} else {return data.bot;}
        
        });

        this.wellData.push({
          ...data,
          type: "contour",
          colorscale: "Earth",
          autocontour: true,
          contours: {
            coloring: "heatmap",
            showlabels: true,
            labelfont: {
              family: "Raleway",
              size: 9,
              color: "white",
            },
          },
          reversescale: true,
          colorbar:{
            title: this.$t("common.depth"),
            titleside: "left",
            titlefont: {
              size: 14,
              family: "Arial, sans-serif"
            },
          },
        });

        notInteractiveWells.forEach(well => { 
          x.push(well.x);
          y.push(well.y);
          text.push(well.name);
          customData.push(well);

          const calculatedWellLogs = well.calculated_wellogs.map((e) => this.wellLogsAdapter[e]).join(", ");
          hovertemplate.push(

            this.$t("common.well")+": %{text}<br>"
          );
        
        });
        this.wellData.push({
          x: x,
          y: y,
          text: text,
          customdata: customData,
          type: "scatter",
          name:"",
          mode: "markers+text",
          textposition: "right",
          marker: {
            color: "lightgreen",
            size: 20,
            line: {
              color: "black",
              width: 1
            }
          },
          hovertemplate: hovertemplate,
          "hoverlabel": {
            "bgcolor": "lightgreen",
            "bordercolor": "black",
            "font": {
              "family": "Arial, sans-serif",
              "size": 15,
              "color": "black"
            }
          },
        });

        x = [];
        y = [];
        text = [];
        customData = [];
        hovertemplate = [];
        interactiveWells.forEach(well => {
          x.push(well.x);
          y.push(well.y);
          text.push(well.name);
          customData.push(well);

          const calculatedWellLogs = well.calculated_wellogs.map((e) => this.wellLogsAdapter[e]).join(", ");
          hovertemplate.push(

            this.$t("common.well")+": %{text}<br>" +
              `${this.$t("petrophysics.owc.drilling-year")} ${well.drilling_year ?? "-"}<br>` +
              `${this.$t("petrophysics.owc.calculated-well-logs")} ${calculatedWellLogs.length ? calculatedWellLogs : this.$t("common.no")}`
          );
          if (!well.calculated_wellogs.length) {
            color.push("grey");
          } else if (well.calculated_wellogs.length && well.calculated_wellogs.length < 4) {
            color.push("orange");
          } else if (well.calculated_wellogs.length == 4) {
            color.push("green");
          }
        });

        this.wellData.push({
          x: x,
          y: y,
          customdata: customData,
          text: text,
          name:"",
          type: "scatter",
          mode: "markers+text",
          textposition: "right",
          marker: {
            color: color,
            size: 20,
            line: {
              color: "black",
              width: 1
            }
          },
          hovertemplate: hovertemplate,
          "hoverlabel": {
            "bgcolor": color,
            "bordercolor": "black",
            "font": {
              "family": "Arial, sans-serif",
              "size": 15,
              "color": "white"
            }
          },
        });
      });
    },
    async navigateToGisPage(event) {
      // console.log(event);
      const id = event.points[0].customdata.id;
      if (!event.event.shiftKey) {
        return;
      }
      const isNotComputed = event.points[0].customdata.calculated_wellogs.length != 4;
      if (isNotComputed) {
        return;
      }
      await this.$router.push({
        name: "petrophysics-owc",
        params: {
          id: id
        }
      });
    },
    async navigateToGisPageFromList(id) {
      await this.$router.push({
        name: "petrophysics-owc",
        params: {
          id: id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">

.main-container {
  padding-top: 110px;
}

.wells-info__title {
  font-size: 1.4rem;
  font-weight: 700;
  user-select: none;
  padding: 0rem;
}

.well-info {
  width: 100%;
  padding: 0.6rem;
  margin-bottom: .3rem;
  font-weight: 500;
  user-select: none;

  border: 1px solid black;
  border-bottom: 4px solid rgba(0, 255, 47, 0.5);
  border-radius: 6px;

  transition: .2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}
.surface-selector {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.run-button {
  font-weight: bold;
  color: #18A0FB;
  border: 2px solid #18A0FB;
  border-radius: 6px;
  padding: .5rem 2rem;
  transition: .2s;

  &:hover {
    transform: scale(1.1);
  }
}
.bounded {
  border-bottom: 4px solid rgba(0, 255, 0, 0.5);
}

.uncomputed {
  border-bottom: 4px solid grey;
  &:hover {
    cursor: not-allowed;
  }
}
.started {
    border-bottom: 4px solid orange;
  }

.map {
  border: 1 px;
  border-style: dashed;
}
</style>