<template>
  <v-container class="geology-main pa-10">
    <v-row class="geology-main__title justify-start align-center">
      <v-col
        cols="auto"
        class="mr-16"
      >
        {{ $t("episodes.geology.title") }}
      </v-col>
      <v-col cols="auto">
        <img
          class="geo-gif"
          src="@/assets/episodes/Geology.png"
          alt=""
        >
      </v-col>
      <v-col>
        <button
          class="geology-main__run-wrapper__button ml-16"
          @click="startModule"
        >
          {{ startModuleButtonText }}
        </button>
      </v-col>
    </v-row>
    <v-row class="geology-main__description">
      {{ $t("geology.first-description") }}
    </v-row>
    <v-row class="geology-main__tasks__title">
      {{ $t("common.task-title") }}
    </v-row>
    <v-row class="geology-main__tasks__list">
      <ul>
        <li>{{ $t("geology.task-1") }} </li>
        <!-- <li>Определить Литологию</li> -->
        <li>{{ $t("geology.task-2") }}</li>
        <li>{{ $t("geology.task-3") }}</li>
        <li>{{ $t("geology.task-4") }}</li>
      </ul>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GeologyMainPageView",
  computed: {
    startModuleButtonText() {
      const isModuleStarted = localStorage.getItem("is_geo_module_started") || false;
      if (isModuleStarted) {
        return this.$t("common.continue");
      } else {
        return this.$t("common.start");
      }
    }
  },
  methods: {
    async startModule() {
      localStorage.setItem("is_geo_module_started", JSON.stringify(true));
      await this.$router.push({name: "geology-map"});
    }
  }
};
</script>

<style scoped lang="scss">

.geo-gif {
  height: 200px;
}

.geology-main {
  margin-top: 80px;
  
  &__title {
    font-size: 3rem;
    font-weight: bold;
  }

  &__description {
    margin-top: 2rem !important;
    font-size: 1.5rem;
  }

  &__tasks {
    &__title {
      color: #009797;
      font-weight: bold;
      font-size: 1.5rem;
      margin-top: 2rem !important;
    }

    &__list {
      margin-top: 1.5rem !important;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  &__run-wrapper {
    &__title {
      margin-top: 3rem !important;
      font-size: 1.1rem;
    }

    &__button {
      font-weight: bold;
      margin-top: 1.2rem;
      color: #18A0FB;
      border: 2px solid #18A0FB;
      border-radius: 6px;
      padding: .5rem 2rem;
      transition: .2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

</style>