<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-row>
      <v-col class="map">
        <Plotly
          :layout="layout"
          :data="mapData"
          :display-mode-bar="false"
          :scroll-zoom="true"
        />
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-snackbar
              v-model="snackbar.show"
              :timeout="3000"
              right
              :color="snackbar.color"
              elevation="24"
              :text="false"
            >
              {{ snackbar.message }}
            </v-snackbar>
          </v-row>
          <div class="mt-4">
            <div>
              <h3 class="mb-2">
                {{ $t("geology.reserves.task") }}&nbsp;<v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      slot="activator"
                      dark
                      color="#009797"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>Для расчета запасов нужно выбрать кубы пористости и нефтенасыщенности, пласт для рассчета и нажать на кнопку "Рассчитать запасы"</span>
                </v-tooltip>
              </h3>
              <v-row class="ma-1 mb-2">
                {{ $t("geology.reserves.vnk") }}  
              </v-row>
            </div>
            <v-select
              v-model="selectedPoro"
              :label="$t('geology.reserves.poro-name')"
              :items="items"
              return-object
              outlined
            />
            <v-select
              v-model="selectedSat"
              :label="$t('geology.reserves.satur-name')"
              :items="items"
              return-object
              outlined
            />
            <v-row>
              <v-col class="surface-selector">
                <v-select
                  v-model="selected_layer"
                  :items="layers"
                  :label="$t('common.layer')"
                  return-object
                  outlined
                  @change="getReservesData"
                />
              </v-col>
            </v-row>
            <button
              class="run-button"
              @click="run"
            >
              {{ $t("geology.reserves.button") }} {{ selected_layer }}
            </button>
          </div>
          <v-col class="mt-2">
            <v-row class="stock-title">
              {{ $t("geology.reserves.reserves-in-situ") }}
            </v-row>
            <v-row class="stock-value">
              {{ reserves | decimalPlaces }} {{ $t("geology.reserves.volume-units") }}
            </v-row>

            <v-row class="stock-title mt-5">
              {{ $t("geology.reserves.reserves-produced") }}
            </v-row>
            <v-row class="stock-value">
              {{ reserves * 0.9085/1000 | decimalPlaces }} {{ $t("geology.reserves.reserves-units") }}
            </v-row>

            <v-row class="stock-title mt-5">
              {{ $t("geology.reserves.reserves-recoverable") }}
            </v-row>
            <v-row class="stock-value">
              {{ (reserves * 0.9085/1000) * 0.294 | decimalPlaces }} {{ $t("geology.reserves.reserves-units") }}
            </v-row>

            <v-row class="stock-title mt-5">
              {{ $t("geology.reserves.reserves-cost") }}
            </v-row>
            <v-row class="stock-value">
              {{ ((reserves * 0.9085/1000) * 0.294) * 0.0389 | decimalPlaces }} {{ $t("geology.reserves.cost-units") }}
            </v-row>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    
    <v-overlay v-model="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </v-container>
</template>

<script>
import reservesService from "@/service/reserves-service";
import surfaceService from "@/service/surface-service";
import {Plotly} from "vue-plotly";
import wellService from "@/service/well-service";

export default {
  name: "ReservesCalcView",
  components: {
    Plotly
  },
  filters: {
    decimalPlaces(val) {
      return Number(val).toLocaleString([], {
        maximumFractionDigits: 2
      });
    }
  },
  data() {
    return {
      layout: {
        title: this.$t("geology.reserves.map-title"),
        showlegend: false,
        autosize: false,
        width: window.innerWidth * 0.6,
        height: window.innerHeight -150,
        hovermode: "closest",
        xaxis: {
          showticklabels:false,
          title: "X, м",
        },
        yaxis: {
          scaleanchor: "x",
          title: "Y, м",
          showticklabels:false,
        },
        dragmode: "pan",
        colorbar: {
          dtick: 1000
        },
      },
      task_attempts: 0,
      layers: [],
      task_id: "",
      selected_layer: "",
      layer_to_push:"",
      reserves: null,
      mapData: [],
      isLoading: false,
      hasStockData: false,
      snackbar: {
        show: false,
        color: null,
        message: "",
      },
      items: [
        "CPOR",
        "GK",
        "DS",
        "AAA"
      ],
      selectedPoro: null,
      selectedSat: null
    };
  },
  async beforeMount() {
    await this.getSurfaceNames();
    await this.getReservesData();
  },
  async mounted() {
    const wellsGisName = await wellService.getGisName().then(({ data }) => {
      return data;
    });
    this.items = [];
    for (let gisNameIndex = 0; gisNameIndex < wellsGisName.length; gisNameIndex++) {
      this.items.push(wellsGisName[gisNameIndex].name);					
    };
  },
  methods: {

    async getSurfaceNames(){
      await surfaceService.getAllSurfaces().then(async ({data}) => {
        this.layers = [];
        for (var i = 0; i < data.length; i++) {
          switch (data[i].zone_name) {
          case "Башкирский":
            this.layers.push(this.$t("common.bashkir"));
            break;
          case "Верейский":
            this.layers.push(this.$t("common.verey"));
            break;
          case "Серпуховский":
            this.layers.push(this.$t("common.serpukhov"));
            break;
          default:
            this.layers.push(data[i].zone_name);}
        }
        this.selected_layer = this.layers[0];});
    },

    getLayerToPush(selected_layer) {
      const layer_to_push = "";
      switch (selected_layer) {
      case this.$t("common.bashkir"):
        this.layer_to_push = "Башкирский";
        break;
      case this.$t("common.verey") :
        this.layer_to_push = "Верейский";
        break;
      case this.$t("common.serpukhov") :
        this.layer_to_push = "Серпуховский"; 
        break;
      default:
        this.layer_to_push = this.selected_layer;
      }
      return this.layer_to_push;
    },
   
    async getReservesData() {

      
      const mapData = [];

      this.layer_to_push = this.getLayerToPush(this.selected_layer);

      const data = await reservesService.getReserves(this.layer_to_push).then(({data}) => {
        return data;
      }).catch(() => {
        this.isError = true;
      });

      if (data == null) {
        return;
      }

      mapData.push({
        ...data.map,
        type: "contour",
        colorscale: "Portland",
        colorbar:{
          title: this.$t("geology.reserves.res-tons"),
          titleside: "left",
          titlefont: {
            size: 14,
            family: "Arial, sans-serif"
          },
        },
        reversescale: false,
        contours: {
          size: 200,
          showlines: true,
          coloring: "heatmap"
        }
      });

      const wells = await wellService.getJustWells().then(({data}) => {
        return data;
      });

      const x = [], y = [], z = [], text = [], customData=[];

      wells.forEach(well => {
        x.push(well.x);
        y.push(well.y);
        text.push(well.name);
        customData.push(well);
      });
      mapData.push({
        x: x,
        y: y,
        z: z,
        text: text,
        bordercolor: "black",
        textfont : {
          color: "white",
          size: 12,
          
        },
        customdata: customData,
        type: "scatter",
        mode: "markers+text",
        textposition: "right center",
        marker: {
          color: "lightgreen",
          size: 10,
          line: {
            color: "white",
            width: 1
          }
        },
        reversescale: true
      });

      this.mapData = mapData;
      this.reserves = data.reserves;
      this.hasStockData = true;
    },
    getStatus(){
      reservesService.getTaskStatus(this.task_id).then(data => {
        const taskStatus = data.data.task_status;
        if (taskStatus === "SUCCESS" || taskStatus === "FAILURE") {
          this.layer_to_push = this.getLayerToPush(this.selected_layer);
          reservesService.getResults(this.task_id, this.layer_to_push)
            .then(data => {
              this.getReservesData();
              this.isLoading=false;
              this.isSuccess = true;
              this.snackbar = {
                show: true,
                message: data.data.message,
                color: "info"
              };
            });
          return false;}
        setTimeout(() => {
          this.getStatus();
        }, 2000);
      })
        .catch((err) => {
          this.isError = true;
        });
    },
    run() {
      if (!this.selectedPoro || !this.selectedSat) {
        this.snackbar = {
          show: true,
          message: this.$t("geology.reserves.select-log"),
          color: "error"
        };
      } else {
        this.isLoading = true;
        this.layer_to_push = this.getLayerToPush(this.selected_layer);
        reservesService.runSecondModule(this.layer_to_push, this.selectedPoro,this.selectedSat)
          .then(async (response) => {
            this.task_id = response.data.task_id;
            this.snackbar = {
              show: true,
              message: response.data.message,
              color: "info"
            };
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.snackbar = {
                show: true,
                message: err.response.data.message,
                color: "teal"
              };
            } else if (err.response.status === 500 || err.response.status === 400){
              this.snackbar = {
                show: true,
                message: err.response.data.message,
                color: "error"
              }; 
            }
          })
          .finally(() => {
            this.getStatus();
          });
      }
    }
  }
};
          
</script>

<style scoped lang="scss">

.main-container {
  margin-top: 80px;
  margin-left: 0;
}

.run-button {
  font-weight: bold;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: #18A0FB;
  border: 2px solid #18A0FB;
  border-radius: 6px;
  padding: .5rem 5rem;
  transition: .2s;

  &:hover {
    transform: scale(1.1);
  }
}

.stock-title {
  font-size: 1.0rem;
  font-weight: 600;
}

.stock-value {
  font-size: 1.0rem;
  font-weight: 800;
  margin-bottom: 1.0rem;
}

.map {
  border: 1 px;
  border-style: dashed;
}
</style>