<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container
    fluid
    class="pt-1"
  >
    <v-row>
      <v-col
        ref="graph-column"
        class="graph-column pa-0"
      >
        <div
          class="plot-header"
          :style="`margin-bottom: ${plotHeaderMarginBottom}px;`"
        >
          <div
            v-if="gisAxisIndexBinding !== undefined && gisAxisIndexBinding !== null"
            class="plot-selects"
          >
            <article
              v-for="axisIndexPlusOne in totalParallelPlotsAmountWarranted"
              :key="'plots-chooser-' + axisIndexPlusOne"
              :class="'plots-chooser plots-chooser-' + axisIndexPlusOne"
            >
              <h4>
                <button
                  :id="`setup-track-btn-${axisIndexPlusOne}`"
                  @click="toggleNowActiveQS('.plots-chooser-' + axisIndexPlusOne + ' h4')"
                >
                  {{ $t("common.configureTrack") }}
                  {{ axisIndexPlusOne }}
                </button>
              </h4>

              <div class="dropdown">
                <h5>{{ $t("common.other-logs") }}:</h5>
                <div
                  v-for="(gisAxisBinding, gisName) in gisAxisIndexBinding"
                  :key="gisName"
                  :style="gisAxisBinding.includes(axisIndexPlusOne - 1) ? 'order: -1; margin-bottom:10px; padding-bottom:10px; border-bottom: 1px solid lightgrey;' : ''"
                >
                  <label>
                    <input
                      v-if="gisAxisBinding !== null && gisAxisBinding.includes(axisIndexPlusOne - 1)"
                      :data-axis-index="axisIndexPlusOne - 1"
                      type="checkbox"
                      checked
                      :name="gisName"
                      @click="changeAxisBinding"
                    >
                    <input
                      v-else
                      type="checkbox"
                      :name="gisName"
                      :data-axis-index="axisIndexPlusOne - 1"
                      @click="changeAxisBinding"
                    >
                    {{ gisName.toString().toUpperCase() }}
                  </label>
                  <div
                    v-if="scalesFunctions !== undefined && gisAxisBinding.includes(axisIndexPlusOne - 1)"
                    class="scales-selects"
                  >
                    <h5>{{ $t("common.scale") }}:</h5>
                    <label>
                      <input
                        v-if="scalesFunctions[axisIndexPlusOne - 1][gisName] === 'log'"
                        id=""
                        checked
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'log')"
                      >
                      <input
                        v-else
                        id=""
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'log')"
                      >
                      {{ $t("common.scale-log") }}
                    </label>
                    <label>
                      <input
                        v-if="scalesFunctions[axisIndexPlusOne - 1][gisName] === 'linear'"
                        id=""
                        checked
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'linear')"
                      >
                      <input
                        v-else
                        id=""
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'linear')"
                      >
                      {{ $t("common.scale-linear") }}
                    </label>
                    <h5>{{ $t("common.min-scale-value") }}</h5>
                    <label>
                      <input
                        :id="'minOfX' + (axisIndexPlusOne - 1).toString() + gisName"
                        type="number"
                        :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1][gisName] && xAxisProps[axisIndexPlusOne - 1][gisName].min) ? xAxisProps[axisIndexPlusOne - 1][gisName].min : ''"
                        @change="changeScaleMin(axisIndexPlusOne - 1, gisName)"
                      >
                    </label>
                    <h5>{{ $t("common.max-scale-value") }}</h5>
                    <label>
                      <input
                        :id="'maxOfX' + (axisIndexPlusOne - 1).toString() + gisName"
                        type="number"
                        :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1][gisName] && xAxisProps[axisIndexPlusOne - 1][gisName].max) ? xAxisProps[axisIndexPlusOne - 1][gisName].max : ''"
                        @change="changeScaleMax(axisIndexPlusOne - 1, gisName)"
                      >
                    </label>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div style="display: flex;width: 100%;">
            <div
              class="scale-container"
              style="z-index: 2;width: 10%;display: flex;justify-content: center;align-items: center;"
            >
              <div
                style="display: flex;justify-content: center;align-items: center;"
                class="dropdown-container"
              >
                <h4
                  class="scale"
                  @click="toggleNowActive"
                >
                  {{ $t("common.depth") }}<br>({{ scaleOfMainPlot }})
                </h4>
                <div
                  class="dropdown"
                  style="transform-origin: top center;"
                >
                  <label
                    v-for="(scale, index) in scalesAvailable"
                    :key="scale + index"
                  >
                    <input
                      v-if="scale == scaleOfMainPlot"
                      :data-scale="scale"
                      type="checkbox"
                      checked
                      @click="changeScale"
                    >
                    <input
                      v-else
                      type="checkbox"
                      :data-scale="scale"
                      @click="changeScale"
                    >
                    {{ scale }}
                  </label>
                </div>
              </div>
            </div>
            <div
              class="plots-choosers"
              :style="`width: ${plotWidth}px; display: flex;`"
            >
              <article
                v-for="axisIndexPlusOne in count"
                :key="'plots-chooser-' + axisIndexPlusOne"
                :class="plots - chooser"
                style="height: 100%;"
              >
                <div
                  v-if="legendInfoForPlots !== undefined"
                  style="display: flex;flex-direction: column-reverse; height: 100%;"
                >
                  <div
                    v-for="(legend, index) in legendInfoForPlots[axisIndexPlusOne - 1]"
                    :key="'plot-legend-' + index"
                    :style="'position: relative; cursor:pointer;display: flex;align-items: stretch;flex-direction: column; justify-content: flex-end;'"
                    :class="'plot-legend-' + axisIndexPlusOne.toString() + index.toString()"
                    @click="toggleNowActiveQS('.plot-legend-' + axisIndexPlusOne.toString() + index.toString() + ' h4')"
                  >
                    <div>
                      <h4 :style="'font-weight: 500; color:' + legend.color">
                        {{ legend.name }} [{{ legend.units }}]
                      </h4>
                      <div
                        style="text-align: center;"
                        class="dropdown"
                      >
                        {{ $t("common.changeColor") }} {{ legend.name }}:
                        <div
                          style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 8px;justify-content: center;"
                        >
                          <div
                            v-for="(color, key) of originalPlotsColors"
                            :key="'inner-plot-legend-' + key"
                            style="width:40px;position: relative; cursor:pointer;display: inline-block;margin:8px; align-items: center;"
                          >
                            <h4
                              :style="'width: 100%; height: 40px; width:40px; margin-right: 10px;display:inline-block; background-color: ' + color"
                              @click="overridePlotColor(legend.name, color)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <svg
                      v-if="clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()]"
                      :id="`replace-color-` + axisIndexPlusOne.toString() + index.toString()"
                      :key="'clonedXaxes' + clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()].length"
                      style="height: 55px;width: 100%;overflow: visible;"
                      v-html="clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()]"
                    />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div id="graph-wrapper" />
      </v-col>

      <v-col
        cols="6"
        class="pa-0"
      >
        <v-container class="resizable_container">
          <v-row class="pt-10">
            <v-col>
              <v-select
                v-model="selectedWell"
                :items="wellWithCoreData"
                :label="$t('common.well')"
                item-text="name"
                item-value="0"
                return-object
                outlined
                @change="getWellData"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="selectedCoreDataX"
                :items="wellCoreData"
                :label="$t('petrophysics.permeability.core-x')"
                item-text="name"
                item-value="0"
                return-object
                outlined
                @change="drawCoreData"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="selectedCoreDataY"
                :items="wellCoreData"
                :label="$t('petrophysics.permeability.core-y')"
                item-text="name"
                item-value="0"
                return-object
                outlined
                @change="drawCoreData"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-container
              fluid
              class="calculation-chooser"
            >
              <div
                data-id="0"
                class="calculation-chooser__item now-active"
                @click="() => ( openSpoiler(0) )"
              >
                <h3 
                  style="margin-top: 0;"
                >
                  {{ $t("petrophysics.permeability.calc-title") }} <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        slot="activator"
                        dark
                        color="#009797"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle
                      </v-icon>
                    </template>
                    <span>Кривая ГИС будет записана с названием PERM, либо с заданным вами названием</span>
                  </v-tooltip>
                </h3>
                
                <div>
                  <label class="calc-view__list-item">
                    <input
                      type="checkbox"
                      name="regrEq"
                      @change="changeEvaluationMode"
                    >
                    <div class="calc-view__list-item__label">
                      <p class="ma-0">{{ $t("petrophysics.permeability.calc-regression") }} {{ getEquation }}</p>
                    </div>
                  </label>
                </div>
                <div>
                  <label><input
                    type="checkbox"
                    name="linearEq"
                    @change="changeEvaluationMode"
                  >{{ $t("petrophysics.permeability.calc-linear") }} </label>
                  <span style="display: flex;align-items: baseline;">
                    <v-text-field
                      v-model="linearEquationField.name"
                      :label="$t('petrophysics.new-log-name')"
                      hide-details="auto"
                    />
                    =
                    <v-text-field
                      v-model="linearEquationField.k"
                      label="k"
                      hide-details="auto"
                    />
                    x
                    <v-select
                      v-model="linearEquationField.gis"
                      :items="wellGisData | excludeDept"
                      :label="$t('common.well-log')"
                      item-text="name"
                      item-value="0"
                      return-object
                      underlined
                      @change="selectEquationGisName"
                    />
                    <!-- v-text-field
                      v-model="linearEquationField.gis_name"
                      label="ГИС"
                      hide-details="auto"
                    / -->
                    +
                    <v-text-field
                      v-model="linearEquationField.b"
                      label="b"
                      hide-details="auto"
                    />
                  </span>
                </div>
                <div>
                  <label><input
                    type="checkbox"
                    name="expoEq"
                    @change="changeEvaluationMode"
                  >{{ $t("petrophysics.permeability.calc-exponent") }} </label>
                  <span style="display: flex;align-items: baseline;">
                    <v-text-field
                      v-model="exponentialEquationField.name"
                      :label="$t('petrophysics.new-log-name')"
                      hide-details="auto"
                    />
                    =
                    <v-text-field
                      v-model="exponentialEquationField.k"
                      label="k"
                      hide-details="auto"
                    />
                    x
                    <v-select
                      v-model="exponentialEquationField.gis"
                      :items="wellGisData | excludeDept"
                      :label="$t('common.well-log')"
                      item-text="name"
                      item-value="0"
                      return-object
                      underlined
                      @change="selectEquationGisName"
                    />
                    ^
                    <v-text-field
                      v-model="exponentialEquationField.a"
                      label="a"
                      hide-details="auto"
                    />
                    +
                    <v-text-field
                      v-model="exponentialEquationField.b"
                      label="b"
                      hide-details="auto"
                    />
                  </span>
                </div>
                <v-row
                  class="pt-5"
                  style="margin: 0px 0 20px;display: flex;justify-content: space-around;"
                >
                  <button
                    type="button"
                    class="calc-button"
                    @click="() => calcGis()"
                  >
                    {{ $t("common.calculate") }}
                  </button>
                  <button
                    type="button"
                    class="calc-all-button"
                  >
                    {{ $t("common.calculate-all") }}
                  </button>
                </v-row>
              </div>
            </v-container>
          </v-row>
          <v-row
            class="plot-container" 
            style="margin: 0px 0 20px;display: flex;justify-content: space-around;"
          >
            <Plotly
              :data="regression.data"
              :layout="regression.layout"
              :display-mode-bar="true"
            />
          </v-row>
          <v-row>
            <v-col>
              <p><strong>{{ $t("common.equation") }}:</strong> {{ getEquation }}</p>
              <p><strong>{{ $t("common.correlation-coeff") }}:</strong> {{ parseFloat(Math.sqrt(regression.coef.r2)).toFixed(4) *100 }}%</p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>
<script>
import wellService from "@/service/well-service";
import calcService from "@/service/calc-service";
import * as d3 from "d3";
import regressionService from "@/service/regression-service";
import {Plotly} from "vue-plotly";

export default {
  name: "CalcPermView",
  components: {
    Plotly
  },
  filters: {
    excludeDept(data) {
      return data.filter(gis => {
        return gis.name !== "dept";
      });
    }
  },
  data() {
    return {
      snackbar: {
        show: false,
        color: null,
        message: "",
      },
      language: "",
      plotWidth: "100%",
      plotHeaderMarginBottom: 0,
      originalPlotsColors: {
        gk: "red",
        ngk: "#000",
        ggkp: "green",
        "satur_water": "#00A58D",
        "satur_oil": "#066206",
        poro: "#0f51e5",
        "c_perm": "#6f88bf",
        "c_poro": "#556078",
        "c_dens": "#30fd0b",
        "c_swirr": "#d777ff",
        perm: "#d777ff",
        ps: "red",
        pz: "#00008b",
        ik: "#066206",
        bk: "#00008b",
        ds: "#066206",
        kp: "#ab6a27",
        kn: "#187667",
        default: "grey"
      },
      clonedXaxes: {},
      plotsColors: null,
      wayOfCalculating: null,
      calculatedGis: {
        name: "none"
      },
      totalParallelPlotsAmountWarranted: 3,
  
      linearEquationField: {
        name: null,
        gis_name: null,
        gis: null,
        k: null,
        b: null,
      },
      exponentialEquationField: {
        name: null,
        gis_name: null,
        gis: null,
        k: null,
        a: null,
        b: null,
      },
      contour: {

        wellData: []
      },
      xAxisProps: {0: {}},
      selectedWell: null,
      activeBZoneItem: null,
      selectedCoreDataX: null,
      selectedCoreDataY: null,
      wellWithCoreData: [],
      wellGisData: [],
      wellCoreData: [],
      circleDrawingGises: [],
      originalCoreDataG: {},
      colorsOfZones: [
        "#69D2E7",
        "#FE4365",
        "#ECD078",
        "#BCBDAC",
      ],
      delta: 0,
      firstPointInitialDepth: 0,
      regression: {
        layout: {
          paper_bgcolor: "#eee",
          title: {
            text:""},
          showlegend: false,
          hovermode: "closest",
          xaxis: {
            title: ""
          },
          yaxis: {
            title: "",
            type: "log",
            autorange: true
          }
        },
        coef: {},
        data: []
      },
      surfaceMapData: [],
      clickOutsideListener: null,
      count: 3,
      settings: {
        data: {
          poro: {
            tracks: []
          }
        }
      },
      legendInfoForPlots: undefined
    };
  },
  computed: {
    getEquation() {
      const bSign = this.regression.coef.b < 0 ? "-" : "+";
      if (!this.selectedCoreDataX || !this.selectedCoreDataY) {
        return "";
      }
      return `${this.selectedCoreDataY.name} = ${this.regression.coef.a} * ${this.selectedCoreDataX.name.trim()} ^ ${Math.abs(this.regression.coef.b)} `;
    }
  },
  mounted() {
    this.language = localStorage.lang !== undefined ? localStorage.lang : this.$root.$i18n.locale;
    this.legendInfoForPlots = [[], [], []];
    // Небольшой костыль, чтобы элементы не наезжали друг на друга
    document.querySelector(".v-navigation-drawer").style.width = "16%";
    document.querySelector(".v-main").style.padding = "70px 0px 0px 16%";
    wellService.getWellWithCores().then(async ({data}) => {
      this.wellWithCoreData = data;
      this.selectedWell = data[0];
      await this.getWellData(this.selectedWell);
    });
    this.clickOutsideListener = (e) => {
      const dropdown = e.target.closest(".dropdown");
      for (let i = 0; i < this.count; i++) {
        const popupSelector = `.plots-chooser-${i + 1} h4`;
        const popup = document.querySelector(popupSelector);
        const target = e.target;
        if (popup) {
          const isNowActive = popup.classList.contains("now-active");
          const isSetupTrackBtn = target.id !== `setup-track-btn-${i + 1}`;
          const isNotPopup = dropdown === null;
          const isHidePopup = isNowActive && isNotPopup && isSetupTrackBtn;
          if (isHidePopup)
            this.toggleNowActiveQS(popupSelector);
        }
        for (let j = 0; j < this.legendInfoForPlots[i].length; j++) {
          const popupSelector = `.plot-legend-${i + 1}${j} h4`;
          const popup = document.querySelector(popupSelector);
          const target = e.target;
          if (popup) {
            const isNowActive = popup.classList.contains("now-active");
            const isReplaceColorBtn = target.closest(`#replace-color-${i + 1}${j}`);
            const isHidePopup = isNowActive && !isReplaceColorBtn;
            if (isHidePopup)
              this.toggleNowActiveQS(popupSelector);
          }
        }
      }
    };
    document.body.onclick = this.clickOutsideListener;
  },
  beforeDestroy() {
    document.body.onclick = null;
  },
  methods: {
    loadCurveSettings() {
      Object.keys(this.gisAxisIndexBinding).map(e => this.gisAxisIndexBinding[e] = []);
      const settings = localStorage.getItem("curve_settings_perm");
      this.settings = JSON.parse(settings);
      this.settings.data.poro.tracks.map((e, i) => {
        Object.keys(e).map((el, j) => {
          let key = Object.keys(e[j])[0];
          if (this.xAxisProps[i][key]) {
            this.xAxisProps[i][Object.keys(e[j])[0]].min = e[el][Object.keys(e[j])[0]].min;
            this.xAxisProps[i][Object.keys(e[j])[0]].max = e[el][Object.keys(e[j])[0]].max;
          }
          this.scalesFunctions[i][Object.keys(e[j])] = e[el][Object.keys(e[j])[0]].scale;
          if (this.gisAxisIndexBinding[Object.keys(e[j])]) {
            this.gisAxisIndexBinding[Object.keys(e[j])].push(i);
          }
          
          if (this.gisAxisIndexBinding[Object.keys(e[j])]) {
            const scale = e[el][Object.keys(e[j])[0]].depth;
            const clickedCheckbox = document.querySelector(`.dropdown input[data-scale="${scale}"]`);
            let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
            this.$store.commit("setPlotScaleInGeology", scaleOfClickedCheckbox);
            document.querySelectorAll("[data-scale]").forEach((element) => {
              element.checked = false;
            });
            clickedCheckbox.checked = true;
            document.querySelector(".scale").classList.remove("now-active");
            this.initPlot("#graph-wrapper");
          }

        });
      });
      this.$forceUpdate();
    },
    saveCurveSettings() {
      const settings = this.settings;
      settings.data.poro = {
        tracks: Array.from({length: this.totalParallelPlotsAmountWarranted}).map((track, i) => ({
          ...(Object.entries(this.gisAxisIndexBinding).filter(e => Object.values(e)[1].includes(i)).map(e => ({
            [Object.values(e)[0]]: {
              scale: this.scalesFunctions[i][Object.values(e)[0]],
              min: this.xAxisProps[i][Object.values(e)[0]].min,
              max: this.xAxisProps[i][Object.values(e)[0]].max,
              depth: this.$store.getters.getPlotScaleInGeology,
            },
          })))
        }))
      };
      localStorage.setItem("curve_settings_perm", JSON.stringify(settings));
    },
    selectEquationGisName(){
      if(this.linearEquationField.gis !== null)
      {
        this.linearEquationField.gis_name = this.linearEquationField.gis.name;
      }
      
      if(this.exponentialEquationField.gis !== null)
      {
        this.exponentialEquationField.gis_name = this.exponentialEquationField.gis.name;
      }
    },
    overridePlotColor(plot, color) {
      this.plotsColors[plot.toLowerCase()] = color;
      this.$forceUpdate();
      this.initPlot("#graph-wrapper");
    },
    openSpoiler(id) {
      let spoiler = document.querySelector(`.calculation-chooser__item[data-id="${id}"]`);
      let spoilerHidden = !spoiler.classList.contains("now-active");
      if (spoilerHidden) {
        document.querySelectorAll(".calculation-chooser__item").forEach((element) => {
          element.classList.remove("now-active");
        });
        spoiler.classList.add("now-active");
      }
    },
    checkIfGisNameExists(name) {
      return this.wellGisData.filter(data => data.name === name).length;
    },
    async calcForAllWells() {
      await this.calcGis(true);
    },
    async calcGis(for_all = false) {
      let response;
      switch(this.wayOfCalculating) {
      case "regrEq":
        response = await calcService.calcPermRegression({
          well_id: this.selectedWell.id,
          gis_name: this.selectedCoreDataX.name,
          core_name: this.selectedCoreDataY.name,
          for_all_wells: for_all
        });
        this.getWellData(this.selectedWell);
        break;
      default:
        this.snackbar = {
          show: true,
          message: this.$t("petrophysics.permeability.not-selected"),
          color: "error"
        };
        break;
      case "linearEq":
        if(!this.checkIfGisNameExists(this.linearEquationField.gis_name)) {
          this.snackbar = {
            show: true,
            message: "Необходимо выбрать кривую ГИС в расчетной формуле",
            color: "error"
          };
          return;
        }
        response = await calcService.calcPermLinearEquation({
          well_id: for_all ? null : this.selectedWell.id,
          log_name: this.linearEquationField.name,
          gis_name: this.linearEquationField.gis_name,
          k: this.linearEquationField.k,
          b: this.linearEquationField.b
        });
        this.getWellData(this.selectedWell);
        break;
      case "expoEq":
        if(!this.checkIfGisNameExists(this.exponentialEquationField.gis_name)) {

          this.snackbar = {
            show: true,
            message: "Необходимо выбрать кривую ГИС в расчетной формуле",
            color: "error"
          };
          return;
        }
        response = await calcService.calcPermExponentialEquation({
          well_id: for_all ? null : this.selectedWell.id,
          log_name: this.exponentialEquationField.name,
          gis_name: this.exponentialEquationField.gis_name,
          k: this.exponentialEquationField.k,
          a: this.exponentialEquationField.a,
          b: this.exponentialEquationField.b
        });
        this.getWellData(this.selectedWell);
        break;
      }
      
      await wellService.getGisData(this.selectedWell.id).then(({data}) => {
        this.wellGisData = data.gis;
        this.delta = this.selectedWell.core_delta;
      });
      await wellService.getWellWithCores();
      //await this.plotGisData();
      this.initPlot("#graph-wrapper");
      await this.calcRegression();
      if (response.status==200) {
        this.snackbar = {
          show: true,
          message: "Расчеты успешно завершены",
          color: "success"
        };
      }
    },
    changeEvaluationMode(e) {

      this.wayOfCalculating = null;
      document.querySelectorAll(".calculation-chooser input").forEach((element) => {
        if (e.target !== element) element.checked = false;
      });
      if (e.target.checked === true) this.wayOfCalculating = e.target.getAttribute("name");
    },
    updateDeltaOfZone(zoneIndex, d3svg) {
      // we are contented with first item because data and original data are moved with save delta each.
      let offsettedItemY = +d3svg.select(`.coreData[data-id="${zoneIndex}"]`).data()[0].y;
      // we are contented with first item because data and original data are moved with save delta each.
      let originalItemY = +d3svg.select(`.originalCoreData[data-id="${zoneIndex}"]`).data()[0].y;
      this.bZoneItems[zoneIndex].deltaVal = (offsettedItemY - originalItemY).toFixed(1);
      // document.querySelector(`.created-zones [data-id="${zoneIndex}"] .delta`).value = delta;
    },
    changeScaleMin(plotIndex, gisName) {
      this.xAxisProps[plotIndex][gisName].min = +document.querySelector("#minOfX" + plotIndex.toString() + gisName).value;
      this.initPlot("#graph-wrapper");
      this.saveCurveSettings();
    },
    changeScaleMax(plotIndex, gisName) {
      this.xAxisProps[plotIndex][gisName].max = +document.querySelector("#maxOfX" + plotIndex.toString() + gisName).value;
      this.initPlot("#graph-wrapper");
      this.saveCurveSettings();
    },
    setScale(plotIndex, gisName, scaleFunc) {
      this.scalesFunctions[plotIndex][gisName] = scaleFunc;
      this.$store.commit("setScalesFunctions", this.scalesFunctions, "petrophysics");
      // ----- reinit autofilling (autoscaling) during further init (Starting line.) -----
      this.xAxisProps = {};
      // ----- reinit autofilling (autoscaling) during further init (Ending line.) -----
      this.initPlot("#graph-wrapper");
      this.saveCurveSettings();
    },
    toggleNowActiveQS(selector) {
      document.querySelector(selector).classList.toggle("now-active");
    },
    removeNowActiveQS(selector) {
      document.querySelector(".plots-chooser-1 h4").classList.remove("now-active");
      document.querySelector(".plots-chooser-2 h4").classList.remove("now-active");
      document.querySelector(".plots-chooser-3 h4").classList.remove("now-active");
    },
    handleOnlyUserChangeOfBZoneItem(itemIndex, inputsObjectKey, inputsSelector) {

      // ----- Performance enchancement of user-fast-clicking behaviour (Starting line.) -----
      if (this.changeOfBZoneTimeoutId) {
        clearTimeout(this.changeOfBZoneTimeoutId);
      }
      // ----- Performance enchancement of user-fast-clicking behaviour (Ending line.) -----
      var thisItem = document.querySelector(`.created-zones li[data-id="${itemIndex}"]`);
      var changedInput = thisItem.querySelector(inputsSelector);

      changedInput.value = Number(changedInput.value).toFixed(1);
      this.bZoneItems[itemIndex][inputsObjectKey] = changedInput.value;

      // ----- check and fix overlap of neighbouring zones (Starting line.) -----
      const nextNeighbour = this.bZoneItems[itemIndex + 1];
      const prevNeighbour = this.bZoneItems[itemIndex - 1];
      if (inputsObjectKey === "upperVal") {
        if (nextNeighbour !== undefined) {
          var newUpperValue = changedInput.value;
          if (newUpperValue > +nextNeighbour.bottomVal) {
            changedInput.value = +nextNeighbour.bottomVal;
            this.bZoneItems[itemIndex][inputsObjectKey] = +nextNeighbour.bottomVal;
          }
        }
      } else if (inputsObjectKey === "bottomVal") {
        if (prevNeighbour !== undefined) {
          var newBottomValue = changedInput.value;
          if (newBottomValue < +prevNeighbour.upperVal) {
            changedInput.value = +prevNeighbour.upperVal;
            this.bZoneItems[itemIndex][inputsObjectKey] = +prevNeighbour.upperVal;
          }
        }
      }
      // ----- check and fix overlap of neighbouring zones (Ending line.) -----

      // ----- fix flipfloping when bottom highr than upper (Starting line.) -----
      let doesUpperAndLowerValuesBothSetted = thisItem.querySelector("input.upper-value").value
        && Number(thisItem.querySelector("input.bottom-value").value);
      if (doesUpperAndLowerValuesBothSetted) {
        if (Number(thisItem.querySelector("input.upper-value").value)
          < Number(thisItem.querySelector("input.bottom-value").value)) {
          e.target.value = Math.min(Number(thisItem.querySelector("input.upper-value").value),
            Number(thisItem.querySelector("input.bottom-value").value));
        }
        this.initPlot("#graph-wrapper");
      }
      // ----- fix flipfloping when bottom highr than upper (Ending line.) -----
    },
    toggleNowActive(e) {
      e.target.classList.toggle("now-active");
    },
    changeScale(e) {
      let clickedCheckbox = e.target;
      let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
      this.$store.commit("setPlotScaleInGeology", scaleOfClickedCheckbox);
      document.querySelectorAll("[data-scale]").forEach((element) => {
        element.checked = false;
      });
      clickedCheckbox.checked = true;
      document.querySelector(".scale").classList.remove("now-active");
      this.initPlot("#graph-wrapper");
      this.saveCurveSettings();
      this.$forceUpdate();
    },
    changeAxisBinding(e) {
      let clickedCheckbox = e.target;
      let nameOfGis = clickedCheckbox.getAttribute("name");
      let axisIndexOfClickedCheckbox = parseInt(clickedCheckbox.getAttribute("data-axis-index"));
      let valueOfCheckboxAfterClick = clickedCheckbox.checked;
      if (valueOfCheckboxAfterClick === false) {
        // if (amountOfAlreadyChosenBindingsForThatAxis < 2) {
        this.gisAxisIndexBinding[nameOfGis].splice(this.gisAxisIndexBinding[nameOfGis].indexOf(axisIndexOfClickedCheckbox), 1);
        // }
      } else {
        this.gisAxisIndexBinding[nameOfGis].push(axisIndexOfClickedCheckbox);
      }
      this.clonedXaxes = {};
      this.$store.commit("setGisAxisIndexBindingInPetroLinking", this.gisAxisIndexBinding);
      this.initPlot("#graph-wrapper");
      this.saveCurveSettings();
    },
    async getWellData(well) {

      await wellService.getGisData(well.id).then(({data}) => {
        this.wellGisData = data.gis;
        this.delta = well.core_delta;
      });
      await wellService.getCores(well.id).then(({data}) => {
        this.wellCoreData = data;
        this.selectedCoreDataY = this.wellCoreData.find(x => x.name === "C_PERM");
        this.selectedCoreDataX = this.wellCoreData.find(x => x.name === "C_PORO");
      });
      this.calcRegression();
      this.gisAxisIndexBinding = null;
      this.$store.commit("setGisAxisIndexBindingInPetroLinking", this.gisAxisIndexBinding);
      this.initPlot("#graph-wrapper");
      this.$forceUpdate();
      const hasSettings = localStorage.getItem("curve_settings_perm");
      if (hasSettings) {
        setTimeout(() => {
          this.loadCurveSettings();
          this.initPlot("#graph-wrapper");
          this.loadCurveSettings();
        }, 0);
      } else {
        this.saveCurveSettings();
      }
    },
    
    drawCoreData() {
      this.calcRegression();
    },
    findXMaxXMinOfGisArr(gis, dept = false) {
      let gisXMax = null;
      let gisXMin = null;
      gis.forEach((gis, i) => {
        if(!dept)
        {
          gis.maxXValue = d3.max(gis.data);
          gis.minXValue = d3.min(gis.data);
        } else {
          let gisDataVals = [];
          gis.data.forEach((gisData, j) => {
            gisDataVals.push(gisData.val);
          });
          gis.maxXValue = d3.max(gisDataVals);
          gis.minXValue = d3.min(gisDataVals);
        }
        if (i === 0) {
          gisXMax = gis.maxXValue;
          gisXMin = gis.minXValue;
        } else {
          if (gisXMax < gis.maxXValue) gisXMax = gis.maxXValue;
          if (gisXMin > gis.minXValue) gisXMin = gis.minXValue;
        }
      });
      return {xMax: gisXMax, xMin: gisXMin};
    },
    /*
      Расчет width для названии линий, чтобы названия не наезжали. 
      Сверху графика.
      4 символа норм вмещается, больше 4 уже наезжает. 
      умножение на 5 это просто подобранная константа
      70% - это начальная ширина
    */
    calculateLegengElementSize(name) {
      let currentSize = 70;
      if(name.length > 4) {
        let needToSubtract = (name.length - 4) * 5;
        currentSize -= needToSubtract;
      }
      return currentSize.toString() + "%";
    },
    initPlot(plotElSelector, show, commonYAxis = null, SVG = null) {
      this.legendInfoForPlots = [[], [], []];
      this.scalesFunctions = this.$store.getters.getScalesFunctions.petrophysics;
      // ----- Scale casing (Starting line.) -----
      let currentScale = this.$store.getters.getPlotScaleInGeology;
      let currentScaleTick;
      this.clonedXaxes = {};

      this.scalesAvailable = ["1:50", "1:100", "1:200", "1:500", "1:1000"];

      switch (currentScale) {
      case "1:50":
        currentScaleTick = 50;
        break;
      case "1:100":
        currentScaleTick = 100;
        break;
      case "1:200":
        currentScaleTick = 200;
        break;
      case "1:500":
        currentScaleTick = 500;
        break;
      case "1:1000":
        currentScaleTick = 1000;
        break;
      }
      this.scaleOfMainPlot = currentScale;
      // ----- Scale casing (Ending line.) -----

      this.gisAxisIndexBinding = this.$store.getters.getGisAxisIndexBindingInPetroLinking;
      let initialBindingNeeded = false;
      if (this.gisAxisIndexBinding === null) {
        this.gisAxisIndexBinding = {};
        initialBindingNeeded = true;
      }

      const margin = {top: 0, right: 0, bottom: 0, left: 15},
        width = 680 - margin.left - margin.right,
        height = 1000 - margin.top - margin.bottom;

      this.plotWidth = width + margin.left * 1.5 + margin.right * 1.5;


      d3.select(plotElSelector).select("svg").remove();
      SVG = d3.select(plotElSelector)
        .append("svg")
        .style("overflow", "visible")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);

      const dept = this.wellGisData.filter(gis => gis.name === "dept")[0].data;
      let zoom = d3.zoom()
        .scaleExtent([.2, 2000]) // This control how much you can unzoom (x0.2) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .on("zoom", updateChart.bind(this));


      let linesDataObj = {};
      let x = {};
      let xAxis = {};
      //let lastUseBoundaryIndex = null;
      let path = {};
      let yPanFromOriginal = 0;
      var gisesOfPlot = {};
      let thisRef = this;
      let userSelectZoneHeight = 0;
      let xAxisGenerator = {};
      if (this.plotsColors === null) this.plotsColors = this.originalPlotsColors; // initialization when no user override of colors happened yet.
      var plotsColors = this.plotsColors;
      const gisWithoutDept = this.wellGisData.filter(gis => {
        return gis.name !== "dept";
      });
      
      let coreDataVals = (this.selectedCoreData ? this.selectedCoreData.data : []).map(point => {
        return {
          x: point.val,
          y: Number(point.dept) + Number(this.delta)
        };
      });

      // ----- Initial binding of plots to axes (Starting line.) -----
      if (initialBindingNeeded) {
        this.originalCoreDataG = {};
        for (let i = 0; i < gisWithoutDept.length; i++) {
          let gisOfThatIteration = gisWithoutDept[i];
          let index = i % this.totalParallelPlotsAmountWarranted;
          this.gisAxisIndexBinding[gisOfThatIteration.name] = [];
          //  this.gisAxisIndexBinding\[[^=]+=
          this.gisAxisIndexBinding[gisOfThatIteration.name].push(index);
        }
        //this.gisAxisIndexBinding[this.selectedCoreData.name] = [];
        this.circleDrawingGises = [];
        for (let i = 0; i < this.wellCoreData.length; i++) { 
          let gisOfThatIteration = this.wellCoreData[i];
          this.gisAxisIndexBinding[gisOfThatIteration.name] = [];
          this.circleDrawingGises.push(gisOfThatIteration.name);
        }
      }

      for (let plotIndex = 0; plotIndex < this.totalParallelPlotsAmountWarranted; plotIndex++) {
        if (this.xAxisProps[plotIndex] === undefined) this.xAxisProps[plotIndex] = {};
        x[plotIndex] = {};
        path[plotIndex] = {};
        this.clonedXaxes[plotIndex] = {};
        if (this.scalesFunctions[plotIndex] === undefined) this.scalesFunctions[plotIndex] = {};

        let plot = SVG
          .append("g")
          .attr("transform",
            "translate(" + (margin.left * plotIndex + width * (plotIndex / this.totalParallelPlotsAmountWarranted)) + ", " + margin.top + ")");
        
        if (plotIndex === 0) {
          // ----- y axis (Starting line.) -----
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Starting line.) -----
          let firstGisDotWithoutNullish = dept.map((element, index) => {
            return {
              x: gisWithoutDept[0].data[index],
              y: element
            };
          }).filter(el => el.x != null);
            
          let firstYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[0].y;
          let lastYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[firstGisDotWithoutNullish.length - 1].y;
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Ending line.) -----
          var y = d3.scaleLinear()
            .domain([+currentScaleTick + +firstYWithNonNullishXOfFirstGis, firstYWithNonNullishXOfFirstGis])
            .range([1000, 0]);
      

          var yAxis = plot.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y)
              .ticks(9)
              .tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5))
            );
          // ----- y axis (Ending line.) -----

          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Starting line.) -----
          let rect = SVG.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")
            .style("cursor", "n-resize")
            .call(zoom);
          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Starting line.) -----
          let globalDefs = plot.append("defs");
          // ----- define plotBoundary clipath (Starting line.) -----
          globalDefs.append("clipPath")
            .attr("id", "plotBoundaryClip")
            .append("rect")
            .attr("width", width / this.totalParallelPlotsAmountWarranted - (margin.left) - (margin.right))
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define plotBoundary clipath (Ending line.) -----

          // ----- define allPlotsButScaleTicksY clipath (Starting line.) -----
          // globalDefs.append("plot:clipPath")
          globalDefs.append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClip")
            .append("rect")
            .attr("width", this.plotWidth)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          globalDefs.append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClipPlusZoneCol")
            .append("rect")
            .attr("width", this.plotWidth + 40)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define allPlotsButScaleTicksY clipath (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Ending line.) -----

        }


        // ----- Retrieve only gises of this plot (Starting line.) -----
        gisesOfPlot[plotIndex] = [];
        for (let i = 0; i < gisWithoutDept.length; i++) {
          let gisOfThatIteration = gisWithoutDept[i];

          let boundedPlotIndexes = this.gisAxisIndexBinding[gisOfThatIteration.name];

          if(!boundedPlotIndexes || !boundedPlotIndexes.includes(plotIndex)) {
            continue;
          }

          gisesOfPlot[plotIndex].push(gisOfThatIteration);
        }

        // ----- Retrieve only gises of this plot (Ending line.) -----
        // ----- include dots for coreDataVals (Starting line.) -----
        if (coreDataVals && coreDataVals.length) {
          /*gisesOfPlot[plotIndex].push({
            name: this.selectedCoreData.name,
            data:
                coreDataVals.map(val => {
                  return val.x;
                })
          });*/
        }
        // ----- include dots for coreDataVals (Ending line.) -----

        for (let i = 0; i < this.wellCoreData.length; i++) { 
          let gisOfThatIteration = this.wellCoreData[i];
          let boundedPlotIndexes = this.gisAxisIndexBinding[gisOfThatIteration.name];

          if(!boundedPlotIndexes || !boundedPlotIndexes.includes(plotIndex)) {
            continue;
          }

          gisesOfPlot[plotIndex].push(gisOfThatIteration);
        }

        for (let gisIndex = 0; gisIndex < gisesOfPlot[plotIndex].length; gisIndex++) {
          let gisOfThatIteration = gisesOfPlot[plotIndex][gisIndex];
          let isCoreGis = this.circleDrawingGises.includes(gisOfThatIteration.name);

          let boundedPlotIndexes = this.gisAxisIndexBinding[gisOfThatIteration.name];
          let thisIterationIsWarrantedToSetCoreData = false;
          // let thisIterationIsWarrantedToSetCoreData = !coreDataSetted && plotIndex === 1;

          if(!boundedPlotIndexes || !boundedPlotIndexes.includes(plotIndex)) {
            continue;
          }


          if (gisOfThatIteration.units !== undefined) {
            this.legendInfoForPlots[plotIndex].push({
              elementSize: this.calculateLegengElementSize(gisOfThatIteration.name.toString()),
              name: gisOfThatIteration.name.toString().toUpperCase(),
              units:  gisOfThatIteration.units[this.language] !== undefined ? gisOfThatIteration.units[this.language].toString() : "",
              color: plotsColors[gisOfThatIteration.name.toLowerCase()] !== undefined ? plotsColors[gisOfThatIteration.name.toLowerCase()] : plotsColors["default"]
            });
          } else {
            this.legendInfoForPlots[plotIndex].push({
              elementSize: this.calculateLegengElementSize(gisOfThatIteration.name.toString()),
              name: gisOfThatIteration.name.toString().toUpperCase(),
              color: plotsColors[gisOfThatIteration.name.toLowerCase()] !== undefined ? plotsColors[gisOfThatIteration.name.toLowerCase()] : plotsColors["default"]
            });
          }
          // ----- Fill in legendInfoForPlots (Ending line.) -----

          if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === undefined) this.scalesFunctions[plotIndex][gisOfThatIteration.name] = "linear";

          linesDataObj[gisOfThatIteration.name] = dept.map((element, index) => {
            return {
              x: gisOfThatIteration.data[index],
              y: element
            };
          }).filter(el => {
            if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "log") {
              return (el.x != null && el.x > 0);
            } else {
              return el.x != null;
            }
          });
          
          let xMaxXMinOfGisOfThatIteration = {};
          if(isCoreGis)
          {
            xMaxXMinOfGisOfThatIteration = this.findXMaxXMinOfGisArr([gisOfThatIteration], true);
          } else {
            xMaxXMinOfGisOfThatIteration = this.findXMaxXMinOfGisArr([gisOfThatIteration]);
          }
          var doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid = gisIndex === 0;
          let tickHeight = -10;
          if (doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid) {
            tickHeight = -height;
          }

          if (this.xAxisProps[plotIndex][gisOfThatIteration.name] === undefined) this.xAxisProps[plotIndex][gisOfThatIteration.name] = {};
          
          if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "log") {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].min == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min = isCoreGis ? 1e-12 : 1;
            }
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].max == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max = isCoreGis ? Math.exp(Math.log(xMaxXMinOfGisOfThatIteration.xMax)) : xMaxXMinOfGisOfThatIteration.xMax;
            }

            // ----- auto-handle undefined x axis range (Ending line.) -----

            // ----- get exactly 13 ticks by getting exactly 12 spans (Starting line.) -----
            var tickSize = (this.xAxisProps[plotIndex][gisOfThatIteration.name].max - this.xAxisProps[plotIndex][gisOfThatIteration.name].min) / 13;
            var tickValuesArr = [];
            for (let i = 0; i <= 13; i++) {
              tickValuesArr.push(this.xAxisProps[plotIndex][gisOfThatIteration.name].min + tickSize * i);
            }
            // ----- get exactly 13 ticks by getting exactly 12 spans (Ending line.) -----

            // scale.log()

            // ----- LOG SCALE MUST START WITH 1!!!! OTHERWISE IT WOULD BE FINIKY (Starting line.) -----
            x[plotIndex][gisOfThatIteration.name] = d3.scaleLog()
              .domain([1, this.xAxisProps[plotIndex][gisOfThatIteration.name].max])
              .range([0, width / this.totalParallelPlotsAmountWarranted - margin.left - margin.right]);

            // ----- LOG SCALE MUST START WITH 1!!!! OTHERWISE IT WOULD BE FINIKY (Ending line.) -----
            xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[plotIndex][gisOfThatIteration.name]).tickValues(tickValuesArr).tickSize(tickHeight).tickFormat((d, i) => i % 2 === 0 ? Math.log(d).toExponential(0) : "");

          } else if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "linear") {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].min == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min = +((xMaxXMinOfGisOfThatIteration.xMin).toFixed(6));
            }
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].max == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max = +((xMaxXMinOfGisOfThatIteration.xMax).toFixed(6));
            }
            // ----- auto-handle undefined x axis range (Ending line.) -----

            // ----- get exactly 13 ticks by getting exactly 12 spans (Starting line.) -----
            var tickSize = (this.xAxisProps[plotIndex][gisOfThatIteration.name].max - this.xAxisProps[plotIndex][gisOfThatIteration.name].min) / 13;
            var tickValuesArr = [];
            for (let i = 0; i <= 13; i++) {
              tickValuesArr.push(this.xAxisProps[plotIndex][gisOfThatIteration.name].min + tickSize * i);
            }
            // ----- get exactly 13 ticks by getting exactly 12 spans (Ending line.) -----

            x[plotIndex][gisOfThatIteration.name] = d3.scaleLinear()
              .domain([this.xAxisProps[plotIndex][gisOfThatIteration.name].min, this.xAxisProps[plotIndex][gisOfThatIteration.name].max])
              .range([0, width / this.totalParallelPlotsAmountWarranted - margin.left - margin.right]);
            // .nice();
            xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[plotIndex][gisOfThatIteration.name]).tickValues(tickValuesArr).tickSize(tickHeight);
            
          }

          let xOffsetWarranted = commonYAxis !== null;
          const isAllowToDrawXAxis = !xAxis[plotIndex];

          if(isAllowToDrawXAxis) {
            if (xOffsetWarranted) {
              xAxis[gisOfThatIteration.name] = plot.append("g")
                .attr("class", "xAxis")
                .call(xAxisGenerator[gisOfThatIteration.name]);
            } else {
              xAxis[gisOfThatIteration.name] = plot.append("g")
                .attr("class", "xAxis2 xAxis" + plotIndex + gisIndex)
                .call(xAxisGenerator[gisOfThatIteration.name]);
            }
            
            if (doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid) {
              xAxis[gisOfThatIteration.name].selectAll("line")
                .attr("stroke", "#e1e1e1");
              let color = this.plotsColors[gisOfThatIteration.name.toLowerCase()];
              if (!color)
                color = this.plotsColors["default"];
              xAxis[gisOfThatIteration.name].selectAll("text")
                .attr("fill", color)
                .attr("style", "font-weight: 600;");
            } else {
              xAxis[gisOfThatIteration.name].selectAll("line")
                .attr("stroke", this.plotsColors[gisOfThatIteration.name.toLowerCase()]);
              let color = this.plotsColors[gisOfThatIteration.name.toLowerCase()];
              if (!color)
                color = this.plotsColors["default"];
              xAxis[gisOfThatIteration.name].selectAll("text")
                .attr("fill", color)
                .attr("style", "font-weight: 600;");
            }
          }

          if (gisIndex + 1 !== 1) { // because column-reverse

            xAxis[gisOfThatIteration.name]
              .attr("style", `display:none;transform: translateY(-${gisIndex * (this.xAxisHeaderOffset + 34)}px)`);
            // ----- clone axes for header (Starting line.) -----

            let clonedXaxis = xAxis[gisOfThatIteration.name]._groups[0][0].cloneNode(true);
            // let clonedXaxis = document.querySelector('.xAxis' + plotIndex + gisIndex).cloneNode(true);
            clonedXaxis.setAttribute("style", "transform: translateY(30px);");
            this.clonedXaxes[plotIndex][gisOfThatIteration.name.toLowerCase()] = clonedXaxis.outerHTML;
            // ----- clone axes for header (Ending line.) -----
          } else { // bottommost exis
            xAxis[gisOfThatIteration.name]
              .attr("style", `transform: translateY(-${gisIndex * (this.xAxisHeaderOffset + 34)}px)`);
          }

          // ----- set mb if bigger than existing (if ohter plots have less or equal x axes than do nothing) (Starting line.) -----
          this.plotHeaderMarginBottom = this.plotHeaderMarginBottom < gisIndex * this.xAxisHeaderOffset + 40 ? gisIndex * this.xAxisHeaderOffset + 40 : this.plotHeaderMarginBottom;
          // ----- set mb if bigger than existing (if ohter plots have less or equal x axes than do nothing) (Ending line.) -----

          // ----- x axis (Ending line.) -----
          if(isCoreGis)
          {
            this.originalCoreDataG[plotIndex] = null;

            show = "show";
            // ----- set coreData (Starting line.) -----
            this.originalCoreDataG[plotIndex] = plot.append("g")
              .attr("class", "core-"+ show +"-data")
              .attr("clip-path", "url(#plotBoundaryClip)");
              
            let deepCoreDataVals = gisOfThatIteration.data.map(point => {
              return {
                x: point.val,
                y: Number(point.dept) + Number(this.delta)
              };
            });

            this.originalCoreDataG[plotIndex]
              .selectAll("circle")
              .data([...deepCoreDataVals])
              .enter()
              .append("circle")
              .attr("cx", (d) => {
                return x[plotIndex][gisOfThatIteration.name](d.x);
              })
              .attr("cy", (d) => {
                return y(d.y);
              })
              .attr("r", 4)
              .style("fill", plotsColors[gisOfThatIteration.name.toLowerCase()] !== undefined ? plotsColors[gisOfThatIteration.name.toLowerCase()] : plotsColors["default"])
              .style("opacity", 0.5)
              .attr("class", "originalCoreData");
          } 
          else
          {
            var line = d3.line()
              .defined(d => !isNaN(d.x))
              .x(d => x[plotIndex][gisOfThatIteration.name](d.x))
              .y(d => y(d.y));


            path[plotIndex][gisOfThatIteration.name] = plot.append("path")
              .datum(linesDataObj[gisOfThatIteration.name])
              .attr("class", "path " + gisOfThatIteration.name + "-line")
              .attr("fill", "none")
              .attr("clip-path", "url(#plotBoundaryClip)")
              .attr("stroke", plotsColors[gisOfThatIteration.name.toLowerCase()] !== undefined ? plotsColors[gisOfThatIteration.name.toLowerCase()] : plotsColors["default"])
              .attr("stroke-width", 1.5)
              .attr("d", line);
          } 

        }
        // ----- set core (керн) data as circle dots (Ending line.) -----

        this.$store.commit("setGisAxisIndexBindingInPetroLinking", this.gisAxisIndexBinding);
        // break;
      }

      let store = this.$store;

      function updateChart(e) {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "wheel") {
          return;
        }

        yPanFromOriginal = d3.event.transform.y;

        if (d3.event.transform.k !== 1) {
          d3.event.transform.k = 1;
        }
        let newY = d3.event.transform.rescaleY(y);

        yAxis.call(
          d3.axisLeft(newY)
            .ticks(9)
            .tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5))
        );

        for (let plotIndex = 0; plotIndex < this.totalParallelPlotsAmountWarranted; plotIndex++) {
            
          // ----- move coreData and originalCoreData (Starting line.) -----
          if(this.originalCoreDataG[plotIndex]) {
            this.originalCoreDataG[plotIndex]
              .selectAll("circle")
              .attr("cy", function (d) {
                return newY(d.y);
              });
          }
          // ----- move coreData and originalCoreData (Ending line.) -----
          for (let i = 0; i < gisesOfPlot[plotIndex].length; i++) {

            let gisOfThatIteration = gisesOfPlot[plotIndex][i];
            if(!path[plotIndex][gisOfThatIteration.name]) {
              continue;
            }
            path[plotIndex][gisOfThatIteration.name].attr("transformY", d3.event.transform.y);

            var line = d3.line()
              .defined(d => !isNaN(d.x))
              .x(d => x[plotIndex][gisOfThatIteration.name](d.x))
              .y(d => newY(d.y));

            path[plotIndex][gisOfThatIteration.name]
              .datum(linesDataObj[gisOfThatIteration.name])
              .attr("class", "path")
              .attr("fill", "none")
              .attr("clip-path", "url(#plotBoundaryClip)")
              .attr("stroke", plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"])
              .attr("stroke-width", 1.5)
              .attr("d", line);
          }
        }
      
        d3.select("body").on("click", function () {
          d3.event.pageY - document.querySelector(".yAxis").getBoundingClientRect().y - yPanFromOriginal;
          y.invert(d3.event.pageY - document.querySelector(".yAxis").getBoundingClientRect().y - yPanFromOriginal);
        });
      
        //yScaler = newY;
        // ----- turn text labels for better visibility (Starting line.) -----
        setTimeout(() => {
          document.querySelectorAll("#graph-wrapper text").forEach((element) => {
            element.setAttribute("style", "rotate(-45deg) translate(2px,-10px);");
          });
        }, 100);
        // ----- turn text labels for better visibility (Ending line.) -----
      }

     

      // ----- fix initial binding ignored by plot header (Starting line.) -----
      if (initialBindingNeeded) {
        this.$forceUpdate();
      }
      // ----- fix initial binding ignored by plot header (Ending line.) -----
    },
    async calcRegression() {
      const result = await regressionService.calc_exponent(
        this.selectedWell.id,
        this.selectedCoreDataX !== null ? this.selectedCoreDataX.name : null,
        this.selectedCoreDataY !== null ? this.selectedCoreDataY.name : null,
      );
      const {scatter_data, coef} = result;
      this.regression.data = [
        {
          x: scatter_data.x,
          y: scatter_data.y,
          mode: "markers",
          type: "scatter",
          name: "Данные"
        }
      ];


      const minX = Math.min(...scatter_data.x.filter(num => num != null));
      const maxX = Math.max(...scatter_data.x.filter(num => num != null));


      this.regression.coef = coef;

      this.regression.data.push(
        {
          x: [minX, maxX],
          y: [coef.a * Math.pow( minX, coef.b), 
            coef.a * Math.pow(maxX, coef.b)],
          type: "scatter",
          line: {color: "red", width: 3, shape: "spline"},
          name: "Линия регрессии"
      
        }
      );



      if (this.selectedCoreDataX.units !== undefined){
        this.regression.layout.xaxis.title = this.selectedCoreDataX.name +",&nbsp;" + this.selectedCoreDataX.units[this.language];
      } else {
        this.regression.layout.xaxis.title = this.selectedCoreDataX.name;
      }
      if (this.selectedCoreDataY.units !== undefined){
        this.regression.layout.yaxis.title = this.selectedCoreDataY.name +",&nbsp;"+this.selectedCoreDataY.units[this.language];
      } else {
        this.regression.layout.yaxis.title = this.selectedCoreDataY.name;
      }
      this.regression.layout.title.text = this.$t("petrophysics.crossplot")+ this.selectedCoreDataX.name+" / "+this.selectedCoreDataY.name;
    },
    async saveDelta() {
      await wellService.saveDelta(this.selectedWell.id, this.delta).then(({data}) => {
        this.selectedWell.core_delta = Number(this.delta);
      });
      await this.calcRegression();
    },
    async plotGisData() {
      const gis = this.wellGisData;
      const depth = gis.filter(gis => gis.name === "dept")[0].data;
      const gisWithoutDept = gis.filter(gis => gis.name !== "dept");
      const data = [];
      const layout = {
        dragmode: "pan",
        hovermode: "closest",
        grid: {
          columns: 2,
          rows: 1,
        },
        margin: {
          r: 20,
          b: 20,
          t: 20,
          pad: 5
        },
        modebar: {
          orientation: "v"
        },
        yaxis: {
          title: "depth",
          tickmode: "auto",
          range: [785, 684],
          domain: [0, 0.9]
        },
        xaxis: {
          fixedrange: true,
          side: "top"
        },
        xaxis2: {
          fixedrange: true,
          side: "top",
        },
      };

      let x1HasPlot = false;
      let x2HasPlot = false;
      let axesIndex = 2;
      let x1PlotsCount = 0;
      let x2PlotsCount = 0;

      for (let i = 0; i < gisWithoutDept.length; i++) {
        const gisName = gisWithoutDept[i].name;
        let overlaying = "x2";
        if (!gisName.includes("_")) {
          overlaying = "x";
        }
        if (!x1HasPlot && overlaying === "x") {
          data.push({
            x: gisWithoutDept[i].data,
            y: depth,
            type: "scatter",
            name: gisWithoutDept[i].name,
            xaxis: "x"
          });
          layout.xaxis["title"] = {
            text: gisWithoutDept[i].name,
            standoff: 100
          };
          x1HasPlot = true;
          this.x1MaxValue = Math.max(...gisWithoutDept[i].data);
          continue;
        }

        if (!x2HasPlot && overlaying === "x2") {
          data.push({
            x: gisWithoutDept[i].data,
            y: depth,
            type: "scatter",
            name: gisWithoutDept[i].name,
            xaxis: "x2"
          });
          layout.xaxis2["title"] = {
            text: gisWithoutDept[i].name,
            standoff: 0
          };
          this.x2MaxValue = Math.max(...gisWithoutDept[i].data);
          x2HasPlot = true;
          continue;
        }

        axesIndex += 1;

        let position = 0;

        if (overlaying === "x") {
          position = 1 - x1PlotsCount * 0.05;
          x1PlotsCount++;
        } else {
          position = 1 - x2PlotsCount * 0.05;
          x2PlotsCount++;
        }

        layout[`xaxis${axesIndex}`] = {
          anchor: "free",
          overlaying: overlaying,
          position: position,
          fixedrange: true,
          side: "left",
          title: {
            text: gisWithoutDept[i].name,
            standoff: 0
          },
          tick0: 0,
          tickformatstops: {
            dtickrange: ["min", "max"],
          },
        };

        data.push({
          x: gisWithoutDept[i].data,
          y: depth,
          type: "scatter",
          name: gisWithoutDept[i].name,
          xaxis: `x${axesIndex}`
        });
      }

      this.layout = layout;
      this.gisData = data;
    },
  }
};




</script>

<style scoped lang="scss">
.delta-save-button {
  background: #18A0FB;
  border-radius: 12px;
  color: white;
  font-size: 1.5rem;
  padding: 1rem 2rem 1rem 2rem;
}

#b-zone button {
  margin-top: 16px;
}

#b-zone {
  padding: 40px;
  border-radius: 25px;
  background: #f0f0f0;
  min-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.created-zones .created-zones__header span,
.created-zones ol li > * {
  padding: 10px;
  display: inline-block;
  border: 1px solid lightgrey;
  width: 24.7%;
}

main button {
  background: #18A0FB;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  padding: 10px 26px;
  transition: all .2s ease-in-out;
  position: relative;
  z-index: 5;
}

main button:hover {
  background: #a5a5a575;
  color: #18A0FB;
}

.calc-all-button{
    border: 1px solid #B9B9B980;
    padding: .5rem 2rem;
    background: #a5a5a575;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    padding: 10px 26px;
    transition: all .2s ease-in-out;
    position: relative;
    z-index: 5;
}

.calc-all-button:hover {
  background: #a5a5a575;
  color: white;
}

.created-zones ol {
  counter-reset: zoneIndex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.created-zones li {
  position: relative;
}

.created-zones input {
  width: 100%;
}

.created-zones {
  max-height: 218px;
  overflow-y: auto;

  min-width: 550px;
  width: 100%;
  background: #f0f0f0;
  border-radius: 10px;
  padding-right: 0;
  // border: 1px solid lightgrey;
  padding: 12px 20px;
}


.created-zones .created-zones__header::before, .created-zones li::before {
  width: 16%;
  display: inline-block;
  text-align: center;
  border: 1px solid lightgrey;
  padding: 10px;
  padding-left: 40px;
}

.created-zones .created-zones__header::before {
  content: 'Группа';
  padding-left: 10px;
}

.created-zones li::before {
  counter-increment: zoneIndex +1;
  content: counter(zoneIndex);
}


.plot-header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  background: #fff;
  z-index: 2;
  position: relative;
  max-width: 730px;
}

.plot-header article {
  flex: 1;
  // padding: 0 30%;
  box-sizing: border-box;
}

.plot-header .scale {
  text-align: center;
  transform: rotate(270deg);
}


.dropdown label,
.scale,
.plot-selects h4 {
  cursor: pointer;
}

.dropdown {
  min-width: 100px;
  position: absolute;
  top: 100%;
  opacity: 0;
  transition: all .4s;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
  z-index: -1;
  pointer-events: none;
  border: 1px solid grey;
  border-radius: 5px;

  max-width: 300px;
  transform: translateX(-50%);
  left: 50%;
}

h4.now-active + .dropdown {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.plot-selects {
  flex: 0 0 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 10%;
}

.plot-selects article {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 50px;
}

#graph-wrapper {
  margin-top: 40px;
  margin-left: calc(10% - 20px);
}

.dropdown-container {
  position: relative;
}

.scales-selects label, .scales-selects input {
  font-size: 13px;
}

.scales-selects {
  display: flex;
  flex-direction: column;
  width: 100%
}

.plot-selects button {
  padding: 5px 15px;
  font-size: 14px;
  margin-left: 10px;
}

.plot-container {
  background-color: #eee;
}

.plot-selects article {
  padding: 10px;
  padding-left: 0;
}

.created-zones [data-id]:not(.now-active) {
  cursor: pointer;
}

.created-zones [data-id].now-active {
  background: lightgrey;
}

// .to-hide {
//   // display: none !important;
// }

.plots-chooser input[type="number"] {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}

.created-zones input[type="number"] {
  outline: 1px solid black;
  border-radius: 5px;
  padding-left: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.created-zones li .color {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  left: 7px;
  top: 48%;
  transform: translateY(-50%);
}

.remove-btn, .add-btn {
  box-sizing: border-box;
  width: 40px !important;
  margin: 2px 5px !important;
  margin-right: 0 !important;
  padding: 10px !important;
  height: 32px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  // font-weight: 900;
  border: none !important;
}

.add-btn {
  width: 90px !important;
  margin-right: 10% !important;
  margin-top: 10px !important;
}

.created-zones__controls {
  display: flex;
  justify-content: flex-end;
}

img.equation {
  margin-left: 30px;
}

.calculation-chooser__item > h3 {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  margin-bottom: 25px;
  margin-top: 20px;
  padding: 5px;
  cursor: pointer;
}

.calculation-chooser__item > h3::after {
  content: '>';
  transition: all .45s;
  transform-origin: 50% 50%;
  transform: rotate(90deg) scaleY(1.5);
  display: inline-block;
}

.calculation-chooser__item.now-active > h3::after {
  transform: rotate(-90deg) scaleY(1.5);
}

.calculation-chooser__item > *:not(h3) {
  opacity: 0;
  max-height: 0;
}

.calculation-chooser__item:not(.now-active) > *:not(h3) {
  margin: 0 !important;
  padding: 0 !important;
}

.calculation-chooser__item.now-active > *:not(h3) {
  opacity: 1;
  max-height: 1000px;
}

.calculation-chooser__item > * {
  transition: all .45s;
}

.calculation-chooser__item label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.calculation-chooser__item.now-active label {
  margin-top: 12px;
}

.calculation-chooser__item label input {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.graph-column {
  margin-left: 15px;
}
.plot-header article:not(:last-of-type) {
  padding-right: 30px;
}
.calc-view__list-item__label {
  gap: 10px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1740px) {
  .calc-button {
    margin-bottom: 15px;
  }
  .resizable_container {
    max-width: 500px;
  }
}

</style>