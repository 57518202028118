<template>
  <v-container
    class="activate-account-container pa-3 justify-center text-center"
    fluid
  >
    <h1>Активация аккаунта</h1>
    <v-row class="justify-center text-center pa-3 text-h5 ">
      {{ usermessage }}
    </v-row>
  </v-container>
</template>
  
<script>
import authService from "@/service/auth-service";
  
export default {
  name: "ActivateAccount",
    
  data() {
    return {
      usermessage: "",
    };
  },
  mounted() {
    authService.checkEmailSending({
      token: this.$route.params.token,
      uid: this.$route.params.uidb64
    }).then((data) => {
      this.usermessage=data.data.message;
    }).then(setTimeout( () => this.$router.push({ path: "/login"}), 7000)); 

  },
};
</script>
  
<style scoped lang="scss">
  
  .activate-account-container {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  
  .v-row {
    font-size: 2.2em;
}
  
</style>