<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-row>
      <v-col class="mt-4">
        <v-select
          v-model="selected_layer"
          :items="layers"
          :label="$t('common.layer')"
          return-object
          outlined
          @change="getTableData()"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="owc_list !=undefined"
      class="tabledata mt-1 mb-2"
    >
      <v-table class="table ml-3">
        <thead>
          <tr>
            <th>{{ $t("petrophysics.owc.table.well-no") }}  </th>
            <th>{{ $t("petrophysics.owc.table.drilling-year") }} </th>
            <th>{{ $t("petrophysics.owc.table.water-top") }} </th>
            <th>{{ $t("petrophysics.owc.table.oil-bot") }} </th>
            <th>{{ $t("petrophysics.owc.table.oil-rate") }} </th>
            <th>{{ $t("petrophysics.owc.table.water-rate") }} </th>
            <th>{{ $t("petrophysics.owc.table.low-perf") }} </th>
            <th>{{ $t("petrophysics.owc.table.top-perf") }} </th>
            <th>{{ $t("common.layer") }} </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="well in AllWellsData"
            :key="well"
            class="text-center"
          >
            <td class="font-weight-bold">
              {{ well.name }}
            </td>
            <td>
              {{ well.drilling_year }}
            </td>
            <td>
              {{ well.top_water !==undefined ? well.top_water + $t("common.m") : $t("common.no-data") }}
            </td>
            <td>
              {{ well.bot_oil !==undefined ? well.bot_oil + $t("common.m") : $t("common.no-data") }}
            </td>
            <td>
              {{ well.misc !==null && well.misc.oil_rate !==undefined ? well.misc.oil_rate +$t("common.ton") : $t("common.no-data") }}
            </td>
            <td>
              {{ well.misc !==null && well.misc.water_rate !==undefined ? well.misc.water_rate + $t("common.ton") : $t("common.no-data") }}
            </td>
            <td>
              {{ well.misc !==null && well.misc.perf_low_oil !==undefined ? well.misc.perf_low_oil+ $t("common.m") : $t("common.no-data") }}
            </td>
            <td>
              {{ well.misc !==null && well.misc.perf_top_water !==undefined ? well.misc.perf_top_water+" м" : $t("common.no-data") }}
            </td>
            <td>{{ selected_layer }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-row>
    
    <h1>
      {{ $t("petrophysics.owc.select-variant") }}
    </h1>
    <v-col class="calculation-chooser">
      <v-row>
        <label class="mb-2">
          <input
            class="label-checkbox"
            type="checkbox"
            name="avg"
            @change="changeEvaluationMode"
          >
          {{ $t("petrophysics.owc.avg") }} 
        </label>
      </v-row>
      <v-row>
        <label class="mb-2">
          <input
            class="label-checkbox"
            type="checkbox"
            name="min"
            @change="changeEvaluationMode"
          >
          {{ $t("petrophysics.owc.min") }}  
        </label>
      </v-row>
      <v-row>
        <label class="mb-2">
          <input
            class="label-checkbox"
            type="checkbox"
            name="max"
            @change="changeEvaluationMode"
          >
          {{ $t("petrophysics.owc.max") }}   
        </label>
      </v-row>
    </v-col>
    <h2 class="mt-3">
      {{ $t("petrophysics.owc.final-owc") }}   {{ finalOwcValue }} {{ $t("petrophysics.owc.m-tvdss") }}  
      <h2 />
    </h2>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <button 
      class="m-0"
      @click="saveFinalOwc()"
    >
      {{ $t("common.save") }}
    </button>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";
import OwcService from "@/service/owc-service";
import surfaceService from "@/service/surface-service";

export default {
  name: "OwcCalcView",
  data(){
    return {
      snackbar: {
        show: false,
        color: null,
        message: "",
      },
      AllWellsData: "",
      layers: [],
      selected_layer:"",
      layer_to_push:"",
      language:"",
      owc_list: [],
      wayOfCalculating:"",
      finalOwcValue:""
    };
  },
  mounted() {
    this.language = localStorage.lang !== undefined ? localStorage.lang : this.$root.$i18n.locale;
    surfaceService.getAllSurfaces().then( ({data}) => {
      for (var i = 0; i < data.length; i++) {
        switch (data[i].zone_name) {
        case "Башкирский":
          this.layers.push(this.$t("common.bashkir"));
          break;
        case "Верейский":
          this.layers.push(this.$t("common.verey"));
          break;
        case "Серпуховский":
          this.layers.push(this.$t("common.serpukhov"));
          break;
        default:
          this.layers.push(data[i].zone_name);}
          
      }
      this.selected_layer = this.layers[0];

      switch (this.selected_layer) {
      case this.$t("common.bashkir"):
        this.layer_to_push = "Башкирский";
        break;
      case this.$t("common.verey") :
        this.layer_to_push = "Верейский";
        break;
      case this.$t("common.serpukhov") :
        this.layer_to_push = "Серпуховский"; 
        break;
      default:
        this.layer_to_push = this.selected_layer;
      }
    }
    ).finally (() => {
      this.getTableData();
    });
  },
  methods :{

    getTableData(){
      switch (this.selected_layer) {
      case this.$t("common.bashkir"):
        this.layer_to_push = "Башкирский";
        break;
      case this.$t("common.verey") :
        this.layer_to_push = "Верейский";
        break;
      case this.$t("common.serpukhov") :
        this.layer_to_push = "Серпуховский"; 
        break;
      default:
        this.layer_to_push = this.selected_layer;
      }
      this.getOwcList(this.layer_to_push);
      wellService.getWellWithCores().then(({data}) => {
        const interactiveWells = data.filter(well => well.has_cores);
        this.AllWellsData = interactiveWells;
        if (this.owc_list.data != undefined){
          for (let i = 0; i < this.AllWellsData.length; i++) {
            for (let j = 0; j < this.owc_list.data.length; j++) {
              if (this.owc_list.data[j].well == this.AllWellsData[i].name) {

                this.AllWellsData[i].top_water= this.owc_list.data[j].top_water;
                this.AllWellsData[i].bot_oil= this.owc_list.data[j].bot_oil;
              }
            }
          }
        }
        this.AllWellsData = this.AllWellsData.filter(well => well.top_water);
      });
      // alert("getTableData");
    },
    getOwcList(layer){
      OwcService.listOWCs(layer).then(({data}) => {
        this.owc_list = data;
        
      });
    },

    saveFinalOwc(){
      if (this.layer_to_push != undefined && this.finalOwcValue != ""){
        OwcService.saveFinalOWC({"zone_name": this.layer_to_push, "value": this.finalOwcValue})
          .then(data => {
            this.snackbar = {
              show: true,
              message: "Значение ВНК залежи сохранено успешно",
              color: "info"
            };
          });;
      } else if (this.owc_list.length ==0) {
        this.snackbar = {
          show: true,
          message: "Отсутствуют скважины с определенным уровнем ВНК",
          color: "error"
        };
      } else {
        this.snackbar = {
          show: true,
          message: "Выберите вариант обоснования",
          color: "error"
        };
      }
    },

    changeEvaluationMode(e) {
      this.wayOfCalculating = null;
      document.querySelectorAll(".calculation-chooser input").forEach((element) => {
        if (e.target !== element) element.checked = false;
      });
      if (e.target.checked === true) {
        this.wayOfCalculating = e.target.getAttribute("name");
        this.finalOwcValue = this.owc_list.owc_methods[this.wayOfCalculating];
      }
   
    },
  }
};
</script>

<style  scoped lang="scss">

.main-container {
  padding-top: 90px;
}

.table {
  width: 100%;
  overflow-x: auto;
}

tr {
  display: flex;
}

td {
  border: 1px solid black;
  width: 100%;
  padding: 10px;
}
td:not(:last-child) {
  border-right: none;
}
tr:not(:last-child) td {
  border-bottom: none;
}
th {
  width: 100%;
  border: 1px solid black;
}

.label-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

main button {
  background: #18a0fb;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  padding: 10px 26px;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
}

main button:hover {
  background: #a5a5a575;
  color: #18a0fb;
}

tbody tr:first-child td {
  border-top: none;
}

thead tr:first-child th:not(:last-child) {
  border-right: none;
}

th, td {
  width: 150px;
}
</style>