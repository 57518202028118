<template>
  <div>
    <PetroMap />
  </div>
</template>

<script>
import PetroMap from "@/components/PetroMap";

export default {
  name: "PetrophysicsMapView",
  components: {PetroMap},
  data() {
    return {
    };
  },

  methods: {

  }
};
</script>

<style scoped>

</style>