<template>
  <v-container
    class="forgot-password-container"
    fluid
    fill-height
    @keyup.enter="submit"
  >
    <v-row class="justify-center">
      <v-col cols="auto">
        <v-form
          ref="authForm"
          v-model="isAuthFromValid"
          class="forgot-password-form"
        >
          <v-container>
            <v-row class=" justify-center pa-3">
              <h2>{{ $t("forgot-password.title") }}</h2>
            </v-row>
            <v-row class="justify-center text-center">
              <p>
                {{ $t("forgot-password.description") }}
              </p>
            </v-row>
            <v-row class="form-field">
              <v-text-field
                v-model="email"
                class="centered-input"
                :label="$t('forgot-password.email-label')"
                clearable
                :rules="emailRules"
              />
            </v-row>
            <v-row class="mt-5 justify-center">
              <v-btn
                width="50%"
                class="green lighten-4"
                @click="submit"
              >
                {{ $t("common.submit") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import authService from "@/service/auth-service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      emailRules: [
        v => !!v || this.$t("common.emailRequired"),
        v => /.+@.+\..+/.test(v) || this.$t("common.emailIncorrect"),
      ],
      requiredRule: [value => !!value || this.$t("common.fieldRequired")],

      isAuthFromValid: false,
      email: "",

      snackbar: {
        show: false,
        color: null,
        message: "",
      }
    };
  },
  methods: {
    submit() {
      this.$refs.authForm.validate();
      if (!this.isAuthFromValid) {
        return;
      }
      this.forgotPassword();
    },
    async forgotPassword() {
      await authService.forgotPassword({
        email: this.email,
      }).then(async (response) => {
        this.snackbar = {
          show: true,
          message: response.data.message,
          color: "success"
        };
        this.$refs.authForm.reset();
      }).catch(err => {
        if (err.response.status === 500) {
          this.snackbar = {
            show: true,
            message: this.$t("common.error"),
            color: "error"
          };
        } else if (err.response.status === 404 || err.response.status === 400){
          this.snackbar = {
            show: true,
            message: this.$t("common.userNotFound"),
            color: "error"
          }; 
        }
      }).then(setTimeout( () => this.$router.push({ path: "/login"}), 5000));
    },
  }
};
</script>

<style scoped lang="scss">



.change-password-container {
  margin-top: 80px;
  padding: 0;
}

.forgot-password-form {
  width: 600px;
}

.form-field {
  width: 600px;
}

.form-navigation {
  color: blue;
  font-size: .7rem;
  user-select: none;

  &:hover {
    cursor: pointer;
    text-shadow: 1px 1px 15px black;
  }
}

</style>