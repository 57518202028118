import axios from "axios";
import {BASE_URL, BASE_PORT, HTTP} from "./base";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const authHttpClient = axios.create({
  baseURL: process.env.VUE_APP_ON_PROD ? `${HTTP}://${BASE_URL}/user` : `${HTTP}://${BASE_URL}:${BASE_PORT}/user`,
  withCredentials: true
});

export default authHttpClient;