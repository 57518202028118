<template>
  <v-container
    class="change-password-container"
    fluid
    @keyup.enter="submit"
  >
    <v-row class="pa-3">
      <v-col>
        <v-form
          ref="changePasswordForm"
          v-model="isChangePasswordFromValid"
          class="change-password-form"
        >
          <v-container>
            <v-row class="pb-3">
              <h2>{{ $t("password.title") }}</h2>
            </v-row>
            <v-row>
              <p>{{ $t("password.description") }}</p>
            </v-row>
            <v-row class="change-password-fields">
              <v-text-field
                v-model="password1"
                type="password"
                :label="$t('password.new-pwd')"
                clearable
                :rules="passwordRules"
              />
            </v-row>
            <v-row class="change-password-fields">
              <v-text-field 
                v-model="password2"
                :label="$t('password.confirm-pwd')"
                type="password"
                clearable
                :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                @blur="validate"
              />
            </v-row>
            <v-row class="mt-5">
              <v-btn
                width="50%"
                class="green lighten-4"
                @click="submit"
              >
                {{ $t("common.save") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>
  
<script>
import authService from "@/service/auth-service";
  
export default {
  name: "ChangePasswordUnauhorized",
  data() {
    return {
      passwordRules: [
        v => !!v || this.$t("password.required"),
        v => (v && v.length >= 5) || this.$t("password.length"),
      ],
      confirmPasswordRules: [v => !!v || this.$t("password.required"),
        v => (v && v.length >= 5) || this.$t("password.length"),],

      requiredRule: [value => !!value || this.$t("password.field-required")],
  
      isChangePasswordFromValid: false,
      password1: "",
      password2: "",
  
      snackbar: {
        show: false,
        color: null,
        message: "",
      }
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password1 === this.password2 || this.$t("password.rule");
    }
  },
  methods: {
    submit() {
      this.$refs.changePasswordForm.validate();
      if (!this.isChangePasswordFromValid) {
        return;
      }
      this.changePassword();
    },
    validate: function() {
      //console.log(this.password1 === this.password2);
    },
    async changePassword() {
      await authService.changePasswordUnauthorized({
        password: this.password2,
        token: this.$route.params.token,
        uid: this.$route.params.uidb64
      }).then(async (data) => {
        this.snackbar = {
          show: true,
          message:  this.$t("password.changed"),
          color: "success"
        };
        this.$refs.changePasswordForm.reset();
      }).catch(err => {
        if (err.response.status === 500) {
          this.snackbar = {
            show: true,
            message: this.$t("common.error"),
            color: "error"
          };
        } else if (err.response.status === 404 || err.response.status === 400){
          this.snackbar = {
            show: true,
            message: err.response.data.message,
            color: "error"
          }; 
        }
      }).then(setTimeout( () => this.$router.push({ path: "/login"}), 3000)); 

    },
  }
};
</script>
  
  <style scoped lang="scss">
  
  
  .change-password-container {
    margin-top: 80px;
    padding: 0;
  }
  
  .change-password-form {
    width: 600px;
  }

  .change-password-fields {
    width: 300px;
  }
  
  .form-navigation {
    color: blue;
    font-size: .7rem;
    user-select: none;
  
    &:hover {
      cursor: pointer;
      text-shadow: 1px 1px 15px black;
    }
  }
  
  </style>