import Vue from "vue";
import Vuex from "vuex";
import userService from "@/service/user-service";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userDrilledWells: [],
    gisAxisIndexBindingInGeology: null,
    gisAxisIndexBindingInOwc: null,
    gisAxisIndexBindingInPetroLinking: null,
    gisAxisIndexBinding: {
      calcView: null
    },
    plotScaleInGeology: "1:100",
    plotScaleInOwc: "1:50",
    scalesFunctions: {geology: {}, linking: {}, petrophysics: {}, owc: {},},
    scalesFunctionLegacy: [],
    wellBindedBZoneItems: {}
  },
  getters: {
    userDetails(state) {
      return state.user;
    },
    fullName(state) {
      return `${state.user.first_name} ${state.user.last_name}`;
    },
    getAxisIndexBindingInGeology(state) {
      return state.gisAxisIndexBindingInGeology;
    },
    getAxisIndexBindingInOwc(state) {
      return state.gisAxisIndexBindingInOwc;
    },
    getGisAxisIndexBinding(state) {
      return state.gisAxisIndexBinding;
    },
    getGisAxisIndexBindingInPetroLinking(state) {
      return state.gisAxisIndexBindingInPetroLinking;
    },
    getPlotScaleInGeology(state) {
      return state.plotScaleInGeology;
    },
    getPlotScaleInOwc(state) {
      return state.plotScaleInOwc;
    },
    getScalesFunctions(state) {
      return state.scalesFunctions;
    },
    getScalesFunctionsLegacy(state) {
      return state.scalesFunctionLegacy;
    },
    getWellBindedBZoneItems(state) {
      return state.wellBindedBZoneItems;
    }
        
  },
  mutations: {
    SET_USER_DETAILS(state, payload) {
      state.user = payload;
    },
    SET_DRILLED_WELLS(state, payload) {
      state.userDrilledWells = payload;
    },
    setAxisIndexBindingInGeology(state, payload) {
      state.gisAxisIndexBindingInGeology = payload;
    },
    setAxisIndexBindingInOwc(state, payload) {
      state.gisAxisIndexBindingInOwc = payload;
    },
    setPlotScaleInGeology(state, payload) {
      state.plotScaleInGeology = payload;
    },
    setPlotScaleInOwc(state, payload) {
      state.plotScaleInOwc = payload;
    },
    setScalesFunctions(state, payload, moduleName) {
      state.scalesFunctions[moduleName] = payload;
    },
    setGisAxisIndexBindingInPetroLinking(state, payload) {
      state.gisAxisIndexBindingInPetroLinking = payload;
    },
    setGisAxisIndexBinding(state, key, payload) {
      state.gisAxisIndexBindingInPetroLinking[key] = payload;
    },
    setWellBindedBZoneItems(state, payload) {

      state.wellBindedBZoneItems[payload.key] = payload.content;
    }
  },
  actions: {
    async getUserDetails({commit}) {
      await userService.getUserDetails().then((data) => {
        // console.log(data.data);
        commit("SET_USER_DETAILS", data.data);
        commit("SET_DRILLED_WELLS", data.data.drilled_wells);
      });
      // console.log('data downloaded');
    }
  },
  modules: {}
});
