import httpClient from "@/http";

class ReservesService {
  getReserves(name) {
    return httpClient.get(`/api/v1/geology/reserves/${name}`);
  }

  runSecondModule(selected_layer, selectedPoro, selectedSat) {
    return httpClient.post(`/api/v1/geology/run/reserves/${selected_layer}/${selectedPoro}/${selectedSat}/`);
  }

  getResults(task_id, selected_layer) {
    return httpClient .get(`/api/v1/geology/run/save-reserves/${task_id}/${selected_layer}/`);
  }

  getTaskStatus(task_id){
    return httpClient.get(`/api/v1/geology/task/${task_id}`);
  }
}

export default new ReservesService();