<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-row>
      <v-col
        ref="graph-column"
        class="graph-column pa-0"
      >
        <div class="to-hide">
          currentPlotScrollY: <span id="currentPlotScrollY">{{ currentPlotScrollY }}</span>
        </div>

        <div
          class="plot-header"
          :style="`margin-bottom: ${plotHeaderMarginBottom}px;`"
        >
          <div
            v-if="gisAxisIndexBinding !== undefined"
            class="plot-selects"
            :style="`width: ${plotWidth}px; display: flex; margin-left: 10%;`"
          >
            <article
              v-for="axisIndexPlusOne in count"
              :key="'plots-chooser-' + axisIndexPlusOne"
              :class="'plots-chooser plots-chooser-' + axisIndexPlusOne"
            >
              <h4>
                <button
                  :id="`setup-track-btn-${axisIndexPlusOne}`"
                  @click="toggleNowActiveQS('.plots-chooser-' + axisIndexPlusOne + ' h4')"
                >
                  {{ $t("common.configureTrack") }}
                  {{ axisIndexPlusOne }}
                </button>
              </h4>

              <div class="dropdown">
                <h5>{{ $t("common.other-logs") }}:</h5>
                <div
                  v-for="(gisAxisBinding, gisName) in gisAxisIndexBinding"
                  :key="gisName"
                  :style="gisAxisBinding.includes(axisIndexPlusOne - 1) ? 'order: -1; margin-bottom:10px; padding-bottom:10px; border-bottom: 1px solid lightgrey;' : ''"
                >
                  <label>
                    <input
                      v-if="gisAxisBinding.includes(axisIndexPlusOne - 1)"
                      :data-axis-index="axisIndexPlusOne - 1"
                      type="checkbox"
                      checked
                      :name="gisName"
                      @click="changeAxisBinding"
                    >
                    <input
                      v-else
                      type="checkbox"
                      :name="gisName"
                      :data-axis-index="axisIndexPlusOne - 1"
                      @click="changeAxisBinding"
                    >
                    {{ gisName.toString().toUpperCase() }}
                  </label>
                  <div
                    v-if="scalesFunctions !== undefined && gisAxisBinding.includes(axisIndexPlusOne - 1)"
                    class="scales-selects"
                  >
                    <h5>{{ $t("common.scale") }}:</h5>
                    <label>
                      <input
                        v-if="scalesFunctions[axisIndexPlusOne - 1][gisName] === 'log'"
                        id=""
                        checked
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'log')"
                      >
                      <input
                        v-else
                        id=""
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'log')"
                      >
                      {{ $t("common.scale-log") }}
                    </label>
                    <label>
                      <input
                        v-if="scalesFunctions[axisIndexPlusOne - 1][gisName] === 'linear'"
                        id=""
                        checked
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'linear')"
                      >
                      <input
                        v-else
                        id=""
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'linear')"
                      >
                      {{ $t("common.scale-linear") }}
                    </label>
                    <h5>{{ $t("common.min-scale-value") }}</h5>
                    <label>
                      <input
                        :id="'minOfX' + (axisIndexPlusOne - 1).toString() + gisName"
                        type="number"
                        :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1][gisName] && xAxisProps[axisIndexPlusOne - 1][gisName].min) ? xAxisProps[axisIndexPlusOne - 1][gisName].min : ''"
                        @change="changeScaleMin(axisIndexPlusOne - 1, gisName)"
                      >
                    </label>
                    <h5>{{ $t("common.max-scale-value") }}</h5>
                    <label>
                      <input
                        :id="'maxOfX' + (axisIndexPlusOne - 1).toString() + gisName"
                        type="number"
                        :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1][gisName] && xAxisProps[axisIndexPlusOne - 1][gisName].max) ? xAxisProps[axisIndexPlusOne - 1][gisName].max : ''"
                        @change="changeScaleMax(axisIndexPlusOne - 1, gisName)"
                      >
                    </label>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div style="display: flex;width: 100%;">
            <div
              class="scale-container"
              style="z-index: 2;width: 10%;display: flex;justify-content: center;align-items: center;"
            >
              <div
                style="display: flex;justify-content: center;align-items: center;"
                class="dropdown-container"
              >
                <h4
                  class="scale"
                  @click="toggleNowActive"
                >
                  {{ $t("common.depth") }}<br>({{ scaleOfMainPlot }})
                </h4>
                <div
                  class="dropdown"
                  style="transform: rotate(90deg) translate(-26px,65px);transform-origin: top center;"
                >
                  <label
                    v-for="(scale, index) in scalesAvailable"
                    :key="scale + index"
                  >
                    <input
                      v-if="scale == scaleOfMainPlot"
                      :data-scale="scale"
                      type="checkbox"
                      checked
                      @click="changeScale"
                    >
                    <input
                      v-else
                      type="checkbox"
                      :data-scale="scale"
                      @click="changeScale"
                    >
                    {{ scale }}
                  </label>
                </div>
              </div>
            </div>
            <div
              class="plots-choosers"
              :style="`width: ${plotWidth}px; display: flex;`"
            >
              <article
                v-for="axisIndexPlusOne in count"
                :key="'plots-chooser-' + axisIndexPlusOne"
                :class="plots - chooser"
                style="height: 100%;"
              >
                <div
                  v-if="legendInfoForPlots !== null"
                  style="display: flex;flex-direction: column-reverse; height: 100%;"
                >
                  <div
                    v-for="(legend, index) in legendInfoForPlots[axisIndexPlusOne - 1]"
                    :key="'plot-legend-' + index"
                    :style="'position: relative; cursor:pointer;display: flex;align-items: stretch;flex-direction: column; justify-content: flex-end;'"
                    :class="'plot-legend-' + axisIndexPlusOne.toString() + index.toString()"
                    @click="toggleNowActiveQS('.plot-legend-' + axisIndexPlusOne.toString() + index.toString() + ' h4')"
                  >
                    <div>
                      <h4 :style="'font-weight: 500; color:' + legend.color">
                        {{ legend.name }} [{{ legend.units }}]
                      </h4>
                      <div
                        style="text-align: center;"
                        class="dropdown"
                      >
                        {{ $t("common.changeColor") }} {{ legend.name }}:
                        <div
                          style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 8px;justify-content: center;"
                        >
                          <div
                            v-for="(color, key) of originalPlotsColors"
                            :key="'inner-plot-legend-' + key"
                            style="width:40px;position: relative; cursor:pointer;display: inline-block;margin:8px; align-items: center;"
                          >
                            <h4
                              :style="'width: 100%; height: 40px; width:40px; margin-right: 10px;display:inline-block; background-color: ' + color"
                              @click="overridePlotColor(legend.name, color)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <svg
                      v-if="clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()]"
                      :id="`replace-color-` + axisIndexPlusOne.toString() + index.toString()"
                      :key="'clonedXaxes' + clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()].length"
                      style="height: 55px;width: 100%;overflow: visible;"
                      v-html="clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()]"
                    />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div id="graph-wrapper" />
      </v-col>
      <v-col>
        <v-row>
          <v-col class="mt-4">
            <v-select
              v-model="selected_layer"
              :items="layers"
              :label="$t('common.layer')"
              return-object
              outlined
              @change="redrawForLayer(selected_layer)"
            />
          </v-col>
        </v-row>
        <v-col>
          <v-row class="owc-title mt-1 mb-2">
            {{ $t("petrophysics.owc.well-data") }}
          </v-row>
          
          <v-col
            v-if="selectedWell.misc !== null && selectedWell.misc !== undefined"
            class="owc-text mt-1 mb-2 pl-0"
          >
            <v-row>
              <strong>{{ $t("petrophysics.owc.drilling-year") }} </strong>&nbsp; {{ selectedWell.drilling_year }}
            </v-row>
            <v-row>
              <strong>Ниж. интер. перф. нефть:</strong>&nbsp; {{ selectedWell.misc !==undefined && selectedWell.misc.perf_low_oil !=='' && selectedWell.misc.perf_low_oil !== undefined ? selectedWell.misc.perf_low_oil +" м" :"нет данных" }}
            </v-row>
            <v-row>
              <strong>Верх. интер. перф. вода:</strong>&nbsp;  {{ selectedWell.misc !==undefined && selectedWell.misc.perf_top_water !=='' && selectedWell.misc.perf_top_water !== undefined ? selectedWell.misc.perf_top_water +" м" :"нет данных" }}
            </v-row>
            <v-row>
              <strong>Дебит нефти:</strong>&nbsp;  {{ selectedWell.misc !==undefined && selectedWell.misc.oil_rate !=='' && selectedWell.misc.oil_rate !== undefined ? selectedWell.misc.oil_rate +" т/сут" :"нет данных" }}
            </v-row>
            <v-row>
              <strong>Дебит воды:</strong>&nbsp;  {{ selectedWell.misc !==undefined && selectedWell.misc.water_rate !=='' && selectedWell.misc.water_rate !== undefined ? selectedWell.misc.water_rate +" м3/сут" :"нет данных" }}
            </v-row>
          </v-col>

          <v-col
            v-if="selectedWell.misc == null"
            class="owc-text mt-1 mb-2 pl-0"
          >
            <v-row>
              <strong>{{ $t("petrophysics.owc.drilling-year") }} </strong>&nbsp; {{ selectedWell.drilling_year }}
            </v-row>
            <v-row>
              <strong>Ниж. интер. перф. нефть:</strong>&nbsp; нет данных
            </v-row>
            <v-row>
              <strong>Верх. интер. перф. вода:</strong>&nbsp; нет данных 
            </v-row>
            <v-row>
              <strong>Дебит нефти:</strong>&nbsp; нет данных
            </v-row>
            <v-row>
              <strong>Дебит воды:</strong>&nbsp; нет данных
            </v-row>
          </v-col>
 
          <v-row class="owc-title mt-1 mb-2">
            {{ $t("petrophysics.owc.log-data") }}
          </v-row>
          <v-row class="tabledata mt-1 mb-2">
            <v-table class="table">
              <thead>
                <tr>
                  <!-- {{ Object.keys(tableData[0]).sort(
                      (e) => e !== 'dept'
                    ) }} -->
                  <th
                    v-for="item in Object.keys(tableData[0])"
                    :key="item"
                    class="text-left"
                  >
                    {{ item.toUpperCase() }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- {{ tableData }} -->
                <tr
                  v-for="row in tableData"
                  :key="row.dept"
                >
                  <td
                    v-for="(column, index) in row"
                    :key="index"
                  >
                    {{ Number(column).toFixed(3) }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-row>
          <v-row class="owc-title mt-1 mb-2">
            {{ $t("petrophysics.owc.bot-oil") }} {{ botOil_input }} м
          </v-row>
          <v-row class="user-owc-input">
            <v-text-field
              v-model="botOil_input"
              type="number"
              :label="$t('petrophysics.owc.insert-value')"
              :rules="[numberRule]"
              @keypress="isNumber($event)"        
            />
          </v-row>
          <v-row class="owc-title mt-1 mb-2">
            {{ $t("petrophysics.owc.top-water") }} {{ topWater_input }} м
          </v-row>
          <v-row class="user-owc-input">
            <v-text-field
              v-model="topWater_input"
              type="number"
              :label="$t('petrophysics.owc.insert-value')"
              :rules="[numberRule]"
              @keypress="isNumber($event)"
            />
          </v-row>
        </v-col>
        <v-snackbar
          v-model="snackbar.show"
          :timeout="3000"
          right
          :color="snackbar.color"
          elevation="24"
          :text="false"
        >
          {{ snackbar.message }}
        </v-snackbar>
        <button 
          class="m-0"
          @click="createOwc(selectedWell)"
        >
          {{ $t("common.save") }}
        </button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";
import surfaceService from "@/service/surface-service";
import OwcService from "@/service/owc-service";
import * as d3 from "d3";


export default {
  name: "OwcView",
  components: {
  },
  filters: {
    excludeDept(data) {
      return data.filter((gis) => {
        return gis.name !== "dept";
      });
    }
  },
  data() {
    return {
      trackSize: null,
      originalPlotsColors: {
        gk: "#000",
        ngk: "blue",
        ps: "red",
        pz: "#00008b",
        ik: "#066206",
        bk: "#00008b",
        ds: "#066206",
        kp: "#ab6a27",
        kn: "#187667",
        ggkp: "red",
        satur_water: "blue",
        satur_oil: "green",
        cpor:"cyan",
        dt: "brown",
        default: "grey"
      },
      snackbar: {
        show: false,
        color: null,
        message: "",
      },
      clonedXaxes: {},
      plotsColors: null,
      currentPlotScrollY: null,
      colorsOfZones: [
        "#69D2E7",
        "#FE4365",
        "#ECD078",
        "#BCBDAC",
      ],

      contour: {
        layout: {
          showlegend: false,
          hovermode: "closest",
          xaxis: {showticklabels:false,
          },
          yaxis: {
            scaleanchor: "x",
            showticklabels:false,
          },
        },

        wellData: []
      },
      xAxisProps: {},
      plotWidth: "100%",
      userZoneBoundaryBarHeight: 10,
      selectedWell: "",
      selectedGisData: null,
      selectedCoreData: null,
      wellGisData: [],
      dept_data: [],
      layers: [],
      botOil_input: "",
      topWater_input: "",
      selected_layer: "",
      tableData: [],
      language: "",
      layer_to_push: "",
      once_iterated: false,
      delta: 0,
      firstPointInitialDepth: 0,
      regression: {
        layout: {
          showlegend: false,
          hovermode: "closest",
          xaxis: {showticklabels:false,
          },
          yaxis: {
            scaleanchor: "x",
            showticklabels:false,
          },
        },
        coef: {},
        data: []
      },
      clickOutsideListener: null,
      count: 3,
      settings: {
        data: {
          owc: {
            tracks: []
          }
        }
      },
      legendInfoForPlots: null,
      yCoord: null,
    };
  },
  watch: {
    topWater_input(val) {
      const water = d3.selectAll(".water");
      if (this.yCoord) {
        const y = this.yCoord(Number.parseFloat(val));
        water.select("line").attr("y1", y).attr("y2", y);
        water.select("text").attr("y", y - 5);
      }
    },
    botOil_input(val) {
      const fuel = d3.selectAll(".fuel");
      if (this.yCoord) {
        const y = this.yCoord(Number.parseFloat(val));
        fuel.select("line").attr("y1", y).attr("y2", y);
        fuel.select("text").attr("y", y - 5);
      }
    },
  },
  mounted() {
    this.language = localStorage.lang !== undefined ? localStorage.lang : this.$root.$i18n.locale;
    surfaceService.getAllSurfaces().then( ({data}) => {
      for (var i = 0; i < data.length; i++) {
        switch (data[i].zone_name) {
        case "Башкирский":
          this.layers.push(this.$t("common.bashkir"));
          break;
        case "Верейский":
          this.layers.push(this.$t("common.verey"));
          break;
        case "Серпуховский":
          this.layers.push(this.$t("common.serpukhov"));
          break;
        default:
          this.layers.push(data[i].zone_name);}
          
      }
      this.selected_layer = this.layers[0];
      this.legendInfoForPlots = [[], [], []];}
      
    ).finally (() => {
      this.getWellData(this.$route.params.id);
      this.getOwc(this.$route.params.id);

    });
   
    this.clickOutsideListener = (e) => {
      const dropdown = e.target.closest(".dropdown");
      for (let i = 0; i < this.count; i++) {
        const popupSelector = `.plots-chooser-${i + 1} h4`;
        const popup = document.querySelector(popupSelector);
        const target = e.target;
        if (popup) {
          const isNowActive = popup.classList.contains("now-active");
          const isSetupTrackBtn = target.id !== `setup-track-btn-${i + 1}`;
          const isNotPopup = dropdown === null;
          const isHidePopup = isNowActive && isNotPopup && isSetupTrackBtn;
          if (isHidePopup)
            this.toggleNowActiveQS(popupSelector);
        }
        for (let j = 0; j < this.legendInfoForPlots[i].length; j++) {
          const popupSelector = `.plot-legend-${i + 1}${j} h4`;
          const popup = document.querySelector(popupSelector);
          const target = e.target;
          if (popup) {
            const isNowActive = popup.classList.contains("now-active");
            const isReplaceColorBtn = target.closest(`#replace-color-${i + 1}${j}`);
            const isHidePopup = isNowActive && !isReplaceColorBtn;
            if (isHidePopup)
              this.toggleNowActiveQS(popupSelector);
          }
        }
      }
    };
    // document.body.onclick = this.clickOutsideListener;
  },
  beforeDestroy() {
    document.body.onclick = null;
  },
  methods: {

    getOwc(sel_well_id){

      const layer_to_push = "";
      switch (this.selected_layer) {
      case this.$t("common.bashkir"):
        this.layer_to_push = "Башкирский";
        break;
      case this.$t("common.verey") :
        this.layer_to_push = "Верейский";
        break;
      case this.$t("common.serpukhov") :
        this.layer_to_push = "Серпуховский"; 
        break;
      default:
        this.layer_to_push = this.selected_layer;
      }
      
      OwcService.getOWC(sel_well_id, this.layer_to_push,
        {
          well: sel_well_id,
          zone_name: this.layer_to_push
        }
      ).then(data => { if (data.data.top_water) {
        
        this.topWater_input = data.data.top_water;
        this.botOil_input = data.data.bot_oil;
      } else {
        this.topWater_input = "";
        this.botOil_input = "";
      }
        
      }).catch();},


    createOwc(well){ 
      OwcService.createOWC(this.$route.params.id, this.layer_to_push,
        {
          top_water: this.topWater_input,
          bot_oil: this.botOil_input,
          well: this.$route.params.id,
          zone_name: this.layer_to_push
        }
      ).then(data => {
        this.snackbar = {
          show: true,
          message: data.data.message,
          color: "info"
        };
      });
    
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },

    numberRule: v => {
      if (!v.trim) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
      return "Значение должно быть в пределах от 0 до 999";
    },

    loadCurveSettings() {
      Object.keys(this.gisAxisIndexBinding).map(e => this.gisAxisIndexBinding[e] = []);
      const settings = localStorage.getItem("curve_settings_owc");
      this.settings = JSON.parse(settings);
      this.settings.data.owc.tracks.map((e, i) => {
        Object.keys(e).map((el, j) => {
          const key = Object.keys(e[j])[0];
          if (this.xAxisProps[i][key]) {
            this.xAxisProps[i][key].min = e[el][Object.keys(e[j])[0]].min;
            this.xAxisProps[i][key].max = e[el][Object.keys(e[j])[0]].max;
          }
          this.scalesFunctions[i][Object.keys(e[j])] = e[el][Object.keys(e[j])[0]].scale;
          if (this.gisAxisIndexBinding[Object.keys(e[j])]) {
            this.gisAxisIndexBinding[Object.keys(e[j])].push(i);
          }

          if (this.gisAxisIndexBinding[Object.keys(e[j])]) {
            const scale = e[el][Object.keys(e[j])[0]].depth;
            const clickedCheckbox = document.querySelector(`.dropdown input[data-scale="${scale}"]`);
            let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
            this.$store.commit("setPlotScaleInOwc", scaleOfClickedCheckbox);
            document.querySelectorAll("[data-scale]").forEach((element) => {
              element.checked = false;
            });
            clickedCheckbox.checked = true;
            document.querySelector(".scale").classList.remove("now-active");
            this.initPlot(3, "#graph-wrapper");
          }
          
        });
      });
      this.$forceUpdate();
    },
    saveCurveSettings() {
      const settings = this.settings;
      settings.data.owc = {
        tracks: Array.from({ length: 3 }).map((track, i) => ({
          ...Object.entries(this.gisAxisIndexBinding)
            .filter((e) => Object.values(e)[1].includes(i))
            .map((e) => ({
              [Object.values(e)[0]]: {
                scale: this.scalesFunctions[i][Object.values(e)[0]],
                min: this.xAxisProps[i][Object.values(e)[0]].min,
                max: this.xAxisProps[i][Object.values(e)[0]].max,
                depth: this.$store.getters.getPlotScaleInOwc,
              },
            })),
        })),
      };
      localStorage.setItem("curve_settings_owc", JSON.stringify(settings));
    },

    overridePlotColor(plot, color) {
      this.plotsColors[plot.toLowerCase()] = color;
      this.$forceUpdate();
      this.initPlot(3, "#graph-wrapper");
    },
    changeScaleMin(plotIndex, gisName) {
      this.xAxisProps[plotIndex][gisName].min = +document.querySelector(
        "#minOfX" + plotIndex.toString() + gisName
      ).value;
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    changeScaleMax(plotIndex, gisName) {
      this.xAxisProps[plotIndex][gisName].max = +document.querySelector(
        "#maxOfX" + plotIndex.toString() + gisName
      ).value;
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    setScale(plotIndex, gisName, scaleFunc) {
      this.scalesFunctions[plotIndex][gisName] = scaleFunc;
      this.$store.commit("setScalesFunctions", this.scalesFunctions, "owc");
      // ----- reinit autofilling (autoscaling) during further init (Starting line.) -----
      this.xAxisProps = {};
      // ----- reinit autofilling (autoscaling) during further init (Ending line.) -----
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    toggleNowActiveQS(selector, dispayNoneAtEnd = false, animationLength = 0) {
      // ----- hide all dropdowns (Starting line.) -----
      document.querySelectorAll("h4.now-active").forEach((element) => {
        if (element !== document.querySelector(selector)) {
          element.classList.remove("now-active");
        }
      });
      // ----- hide all dropdowns (Ending line.) -----
      document.querySelector(selector).classList.toggle("now-active");
    },
    toggleNowActive(e) {
      e.target.classList.toggle("now-active");
    },
    changeScale(e) {
      let clickedCheckbox = e.target;
      let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
      this.$store.commit("setPlotScaleInOwc", scaleOfClickedCheckbox);
      document.querySelectorAll("[data-scale]").forEach((element) => {
        element.checked = false;
      });
      clickedCheckbox.checked = true;
      document.querySelector(".scale").classList.remove("now-active");
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    changeAxisBinding(e) {

      let clickedCheckbox = e.target;
      let nameOfGis = clickedCheckbox.getAttribute("name");
      let axisIndexOfClickedCheckbox = parseInt(
        clickedCheckbox.getAttribute("data-axis-index")
      );
      let valueOfCheckboxAfterClick = clickedCheckbox.checked;
      if (valueOfCheckboxAfterClick === false) {
        // if (amountOfAlreadyChosenBindingsForThatAxis < 2) {
        this.gisAxisIndexBinding[nameOfGis].splice(
          this.gisAxisIndexBinding[nameOfGis].indexOf(
            axisIndexOfClickedCheckbox
          ),
          1
        );
        // }
      } else {
        this.gisAxisIndexBinding[nameOfGis].push(axisIndexOfClickedCheckbox);
      }
      this.clonedXaxes = {};
      this.initPlot(3, "#graph-wrapper");
      this.$forceUpdate(); // necessary fot v-html to properly update
      this.saveCurveSettings();
    },
    async getWellData(well_id) {

      const layer_to_push = "";
      switch (this.selected_layer) {
      case this.$t("common.bashkir"):
        this.layer_to_push = "Башкирский";
        break;
      case this.$t("common.verey") :
        this.layer_to_push = "Верейский";
        break;
      case this.$t("common.serpukhov") :
        this.layer_to_push = "Серпуховский"; 
        break;
      default:
        this.layer_to_push = this.selected_layer;
      }
      
      await wellService.getOwcGisData(well_id, this.layer_to_push).then(({data}) => {

        this.selectedWell = data.well;
        this.wellGisData = data.gis;
        this.selectedGisData = this.wellGisData[0];
  
        this.prepareTableData(data);

        const hasSettings = localStorage.getItem("curve_settings_owc");
        if (hasSettings) {
          setTimeout(() => {
            this.loadCurveSettings();
            this.initPlot(3, "#graph-wrapper");
            this.loadCurveSettings();
          }, 0);
        } else {
          //this.saveCurveSettings();
        }
      });
      this.initPlot(3, "#graph-wrapper");
    },

    prepareTableData(data) {
      this.tableData = [];
      const tableData = [];
      const dataLength = data.gis[0].data.length;
      const sortWellGisData = this.wellGisData.sort((e) => e.name !== "dept");
      for (let i = 0; i < dataLength; i++) {
        let tableRow = {};
        for (let gis of sortWellGisData) {
          tableRow[`${gis.name}`] = gis.data[i];
        }
        tableData.push(tableRow);
      }
      // tableData.sort((row) => row);
      this.tableData = tableData;
    },

    async redrawForLayer(selected_layer) {
  
      await wellService.getOwcGisData(this.$route.params.id, selected_layer).then(({data}) => {
        this.wellGisData = data.gis;
        this.selectedGisData = this.wellGisData[0];
        this.prepareTableData(data);
        this.getOwc(this.$route.params.id);

      });
      this.initPlot(3, "#graph-wrapper");
    },

    initPlot(
      totalParallelPlotsAmountWarranted,
      plotElSelector,
      commonYAxis = null,
      SVG = null
    ) {
      var componentContext = this;
      
      this.clonedXaxes = {};

      this.legendInfoForPlots = [[], [], []];

      this.scalesFunctions = this.$store.getters.getScalesFunctions.owc;
      // ----- Scale casing (Starting line.) -----
      var currentScale = this.$store.getters.getPlotScaleInOwc;
      var currentScaleTick;

      this.scalesAvailable = ["1:10", "1:25", "1:50", "1:100"];

      switch (currentScale) {
      case "1:10":
        currentScaleTick = 10;
        break;
      case "1:25":
        currentScaleTick = 25;
        break;
      case "1:50":
        currentScaleTick = 50;
        break;
      case "1:100":
        currentScaleTick = 100;
        break;
      }
      this.scaleOfMainPlot = currentScale;
      // ----- Scale casing (Ending line.) -----

      //  ----- gis store bindings (Starting line.) -----
      this.gisAxisIndexBinding =
        this.$store.getters.getAxisIndexBindingInOwc;
      if (this.gisAxisIndexBinding === null) {
        this.gisAxisIndexBinding = {};
        var initialBindingNeeded = true;
      } else {
        var initialBindingNeeded = false;
      }
      //  ----- gis store bindings (Ending line.) -----

      // const margin = {top: 20, right: 30, bottom: 30, left: 60},
      this.plotHeaderMarginBottom = 0;
      const margin = { top: 0, right: 0, bottom: 0, left: 20 },
        width = 680,
        height = 600;
      this.plotWidth = width + margin.left * 1.5 + margin.right * 1.5;

      d3.select(plotElSelector).select("svg").remove();
      var SVG = d3
        .select(plotElSelector)
        .append("svg")
        .style("overflow", "visible")
        .attr("width", width)
        .attr("height", height);

      const dept = this.wellGisData.filter((gis) => gis.name === "dept")[0]
        .data;

      const gisWithoutDept = this.wellGisData.filter((gis) => {
        return gis.name !== "dept";
      });

      function findXMaxXMinOfGisArr(gisWithoutDept) {
        var gisXMax = null;
        var gisXMin = null;
        gisWithoutDept.forEach((gis, i) => {
          gis.maxXValue = d3.max(gis.data);
          gis.minXValue = d3.min(gis.data);
          if (i === 0) {
            gisXMax = gis.maxXValue;
            gisXMin = gis.minXValue;
          } else {
            if (gisXMax < gis.maxXValue) gisXMax = gis.maxXValue;
            if (gisXMin > gis.minXValue) gisXMin = gis.minXValue;
          }
        });
        return { xMax: gisXMax, xMin: gisXMin };
      }

      var gisXMin = d3.min(this.selectedGisData.data);

      var zoom = d3
        .zoom()
        .scaleExtent([0.2, 2000]) // This control how much you can unzoom (x0.2) and zoom (x20)
        .extent([
          [0, 0],
          [width, height],
        ])
        .on("zoom", updateChart);

      var linesDataObj = {};
      var x = {};
      var xAxis = {};
      var path = {};
      var yPanFromOriginal = 0;
      this.xAxisHeaderOffset = 0;
      this.gisesOfPlot = {};
      var thisRef = this;
      this.firstBoundaryPositionY = null;
      this.secondBoundaryPositionY = null;
      var xAxisGenerator = {};
      if (this.plotsColors === null)
        this.plotsColors = this.originalPlotsColors; // initialization when no user override of colors happened yet.
      var plotsColors = this.plotsColors;

      // ----- Perform initial binging (Starting line.) -----
      if (initialBindingNeeded) {
        for (let i = 0; i < gisWithoutDept.length; i++) {
          let gisOfThatIteration = gisWithoutDept[i];
          this.gisAxisIndexBinding[gisOfThatIteration.name] = [];
          //  this.gisAxisIndexBinding\[[^=]+=
          this.gisAxisIndexBinding[gisOfThatIteration.name].push(
            i % totalParallelPlotsAmountWarranted
          );
        }
      }
      // ----- Perform initial binging (Ending line.) -----

      for (
        let plotIndex = 0;
        plotIndex < totalParallelPlotsAmountWarranted;
        plotIndex++
      ) {
        if (this.xAxisProps[plotIndex] === undefined)
          this.xAxisProps[plotIndex] = {};
        x[plotIndex] = {};
        path[plotIndex] = {};
        this.clonedXaxes[plotIndex] = {};
        if (this.scalesFunctions[plotIndex] === undefined)
          this.scalesFunctions[plotIndex] = {};

        var plot = SVG.append("g").attr(
          "transform",
          "translate(" +
            (margin.left * plotIndex +
              width * (plotIndex / totalParallelPlotsAmountWarranted)) +
            ", " +
            margin.top +
            ")"
        );

        if (plotIndex === 0) {
          // ----- y axis (Starting line.) -----
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Starting line.) -----
          var firstGisDotWithoutNullish = dept
            .map((element, index) => {
              return {
                x: gisWithoutDept[0].data[index],
                y: element,
              };
            })
            .filter((el) => el.x != null);

          var firstYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[0].y;
          var lastYWithNonNullishXOfFirstGis =
            firstGisDotWithoutNullish[firstGisDotWithoutNullish.length - 1].y;
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Ending line.) -----
          if (!this.currentPlotScrollY) {
            var y = d3
              .scaleLinear()
              .domain([
                +currentScaleTick + +firstYWithNonNullishXOfFirstGis,
                +firstYWithNonNullishXOfFirstGis,
              ])
              .range([600, 0]); // Where 600 is just plot heihght in px.
          } else {
            var currentPlotScrollYRef = this.currentPlotScrollY;
            var y = d3
              .scaleLinear()
              .domain([
                +currentScaleTick + +currentPlotScrollYRef,
                +currentPlotScrollYRef,
              ])
              .range([600, 0]); // Where 600 is just plot heihght in px.
          }

          var yAxis = plot
            .append("g")
            .attr("class", "yAxis")
            .call(
              d3
                .axisLeft(y)
                .ticks(9)
                .tickSize(-width - margin.left * 1.5 - margin.right * 1.5)
            );
          // ----- y axis (Ending line.) -----

          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Starting line.) -----
          var rect = SVG.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("cursor", "n-resize")
            .style("fill", "none")
            .style("pointer-events", "all")
            .call(zoom);
          // .on("wheel.zoom", null) // disables default zoom wheel behavior
          // .on("wheel", pan);

          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Starting line.) -----
          var globalDefs = plot.append("defs");
          // ----- define plotBoundary clipath (Starting line.) -----
          globalDefs
            .append("clipPath")
            .attr("id", "plotBoundaryClip")
            .append("rect")
            .attr(
              "width",
              width / totalParallelPlotsAmountWarranted -
                margin.left -
                margin.right
            )
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define plotBoundary clipath (Ending line.) -----

          // ----- define allPlotsButScaleTicksY clipath (Starting line.) -----

          globalDefs
            .append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClip")
            .append("rect")
            .attr("width", this.plotWidth)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          globalDefs
            .append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClipPlusZoneCol")
            .append("rect")
            .attr("width", this.plotWidth + 40)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define allPlotsButScaleTicksY clipath (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Ending line.) -----
        }

        // ----- Retrieve only gises of this plot (Starting line.) -----
        this.gisesOfPlot[plotIndex] = [];
        for (const gisName in this.gisAxisIndexBinding) {
          const bindingsArr = this.gisAxisIndexBinding[gisName];
          if (bindingsArr.includes(plotIndex)) {
            gisWithoutDept.forEach((gisWithData) => {
              if (gisWithData.name == gisName) {
                this.gisesOfPlot[plotIndex].push(gisWithData);
              }
            });
          }
        }
        // ----- Retrieve only gises of this plot (Ending line.) -----

        let xMaxXMinOfGisesOfPlot = findXMaxXMinOfGisArr(
          this.gisesOfPlot[plotIndex]
        );

        this.gisesOfPlot[plotIndex].forEach((gisOfThatIteration, gisIndex) => {
          // ----- Fill in legendInfoForPlots (Starting line.) -----
          if (gisOfThatIteration.units !== undefined) {
            this.legendInfoForPlots[plotIndex].push({
              name: gisOfThatIteration.name.toString().toUpperCase(),
              units:  gisOfThatIteration.units[this.language] !== undefined ? gisOfThatIteration.units[this.language].toString() : "",
              color:
              plotsColors[gisOfThatIteration.name] !== undefined
                ? plotsColors[gisOfThatIteration.name]
                : plotsColors["default"],
            });
          } else { this.legendInfoForPlots[plotIndex].push({
            name: gisOfThatIteration.name.toString().toUpperCase(),
            color:
              plotsColors[gisOfThatIteration.name] !== undefined
                ? plotsColors[gisOfThatIteration.name]
                : plotsColors["default"],
          });}
          
          // ----- Fill in legendInfoForPlots (Ending line.) -----

          this.selectedGisData = gisOfThatIteration; // TODO: delete carefully.

          linesDataObj[gisOfThatIteration.name] = dept
            .map((element, index) => {
              return {
                x: gisOfThatIteration.data[index],
                y: element,
              };
            })
            .filter((el) => {
              if (
                this.scalesFunctions[plotIndex][gisOfThatIteration.name] ===
                "log"
              ) {
                return el.x != null && el.x > 0;
              } else {
                return el.x != null;
              }
            });

          // ----- x axis (Starting line.) -----
          if (this.xAxisProps[plotIndex][gisOfThatIteration.name] === undefined)
            this.xAxisProps[plotIndex][gisOfThatIteration.name] = {};
          if (
            this.scalesFunctions[plotIndex][gisOfThatIteration.name] ===
            undefined
          )
            this.scalesFunctions[plotIndex][gisOfThatIteration.name] = "linear";

          let xMaxXMinOfGisOfThatIteration = findXMaxXMinOfGisArr([
            gisOfThatIteration,
          ]);

          var doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid =
            gisIndex === 0;
          if (doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid) {
            var tickHeight = -height;
          } else {
            var tickHeight = -10;
          }
          //    ----- scalesFunctions (Starting line.) -----
          if (
            this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "log"
          ) {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min ==
              undefined
            ) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min = 1;
            }
            if (
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max ==
              undefined
            ) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max =
                xMaxXMinOfGisOfThatIteration.xMax;

              // this.xAxisProps[plotIndex][gisOfThatIteration.name].max = Math.exp(Math.log(xMaxXMinOfGisOfThatIteration.xMax) + 5);
            }

            // ----- auto-handle undefined x axis range (Ending line.) -----

            // ----- get exactly 13 ticks by getting exactly 12 spans (Starting line.) -----
            var tickSize =
              (this.xAxisProps[plotIndex][gisOfThatIteration.name].max -
                this.xAxisProps[plotIndex][gisOfThatIteration.name].min) /
              13;
            var tickValuesArr = [];
            for (let i = 0; i <= 13; i++) {
              tickValuesArr.push(
                this.xAxisProps[plotIndex][gisOfThatIteration.name].min +
                  tickSize * i
              );
            }
            // ----- get exactly 13 ticks by getting exactly 12 spans (Ending line.) -----

            // scale.log()

            // ----- LOG SCALE MUST START WITH 1!!!! OTHERWISE IT WOULD BE FINIKY (Starting line.) -----
            x[plotIndex][gisOfThatIteration.name] = d3
              .scaleLog()
              .domain([
                1,
                this.xAxisProps[plotIndex][gisOfThatIteration.name].max,
              ])
              .range([
                0,
                width / totalParallelPlotsAmountWarranted -
                  margin.left -
                  margin.right,
              ]);

            // ----- LOG SCALE MUST START WITH 1!!!! OTHERWISE IT WOULD BE FINIKY (Ending line.) -----
            xAxisGenerator[gisOfThatIteration.name] = d3
              .axisTop(x[plotIndex][gisOfThatIteration.name])
              .tickValues(tickValuesArr)
              .tickSize(tickHeight)
              .tickFormat((d, i) =>
                i % 2 === 0 ? Math.log(d).toExponential(0) : ""
              );
          } else if (
            this.scalesFunctions[plotIndex][gisOfThatIteration.name] ===
            "linear"
          ) {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min ==
              undefined
            ) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min =
                +xMaxXMinOfGisOfThatIteration.xMin.toFixed(6);
            }
            if (
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max ==
              undefined
            ) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max =
                +xMaxXMinOfGisOfThatIteration.xMax.toFixed(6);
            }
            // ----- auto-handle undefined x axis range (Ending line.) -----

            // ----- get exactly 13 ticks by getting exactly 12 spans (Starting line.) -----
            var tickSize =
              (this.xAxisProps[plotIndex][gisOfThatIteration.name].max -
                this.xAxisProps[plotIndex][gisOfThatIteration.name].min) /
              13;
            var tickValuesArr = [];
            for (let i = 0; i <= 13; i++) {
              tickValuesArr.push(
                this.xAxisProps[plotIndex][gisOfThatIteration.name].min +
                  tickSize * i
              );
            }
            // ----- get exactly 13 ticks by getting exactly 12 spans (Ending line.) -----

            x[plotIndex][gisOfThatIteration.name] = d3
              .scaleLinear()
              .domain([
                this.xAxisProps[plotIndex][gisOfThatIteration.name].min,
                this.xAxisProps[plotIndex][gisOfThatIteration.name].max,
              ])
              .range([
                0,
                width / totalParallelPlotsAmountWarranted -
                  margin.left -
                  margin.right,
              ]);
            // .nice();
            xAxisGenerator[gisOfThatIteration.name] = d3
              .axisTop(x[plotIndex][gisOfThatIteration.name])
              .tickValues(tickValuesArr)
              .tickSize(tickHeight);
          }
          //    ----- scalesFunctions (Ending line.) -----

          let xOffsetWarranted = commonYAxis !== null;
          if (xOffsetWarranted) {
            xAxis[gisOfThatIteration.name] = plot
              .append("g")
              .attr("class", "xAxis")
              // .attr("transform", "translate(" + ((plotIndex !== 0 ? margin.left : '') + width * (plotIndex / totalParallelPlotsAmountWarranted)) + ", 0)");
              .call(xAxisGenerator[gisOfThatIteration.name]);
          } else {
            xAxis[gisOfThatIteration.name] = plot
              .append("g")
              .attr("class", "xAxis2 xAxis" + plotIndex + gisIndex)
              // .attr('class', 'xAxis2 xAxis' + gisIndex + plotIndex)
              // .attr("transform", "translate(" + (plotIndex !== 0 ? margin.left : '') + ", 0)")
              .call(xAxisGenerator[gisOfThatIteration.name]);
          }

          // ----- set colors for axis grid and ticks labels (Starting line.) -----
          if (doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid) {
            xAxis[gisOfThatIteration.name]
              .selectAll("line")
              .attr("stroke", "#e1e1e1");
            let color = this.plotsColors[gisOfThatIteration.name.toLowerCase()];
            if (!color) color = this.plotsColors["default"];
            xAxis[gisOfThatIteration.name]
              .selectAll("text")
              .attr("fill", color)
              .attr("style", "font-weight: 600;");
          } else {
            xAxis[gisOfThatIteration.name]
              .selectAll("line")
              .attr("stroke", this.plotsColors[gisOfThatIteration.name]);
            xAxis[gisOfThatIteration.name]
              .selectAll("text")
              .attr("fill", this.plotsColors[gisOfThatIteration.name])
              .attr("style", "font-weight: 600;");
          }
          // ----- set colors for axis grid and ticks labels (Ending line.) -----

          // ----- make starecase of plots (Starting line.) -----

          if (gisIndex + 1 !== 1) {
            // because column-reverse

            xAxis[gisOfThatIteration.name].attr(
              "style",
              `display:none;transform: translateY(-${
                gisIndex * (this.xAxisHeaderOffset + 34)
              }px)`
            );
            // ----- clone axes for header (Starting line.) -----

            let clonedXaxis =
              xAxis[gisOfThatIteration.name]._groups[0][0].cloneNode(true);
            // let clonedXaxis = document.querySelector('.xAxis' + plotIndex + gisIndex).cloneNode(true);
            clonedXaxis.setAttribute("style", "transform: translateY(30px);");
            this.clonedXaxes[plotIndex][gisOfThatIteration.name] =
              clonedXaxis.outerHTML;
            // ----- clone axes for header (Ending line.) -----
          } else {
            // bottommost exis
            xAxis[gisOfThatIteration.name].attr(
              "style",
              `transform: translateY(-${
                gisIndex * (this.xAxisHeaderOffset + 34)
              }px)`
            );
          }
          // ----- make starecase of plots (Ending line.) -----

          // ----- set mb if bigger than existing (if ohter plots have less or equal x axes than do nothing) (Starting line.) -----
          this.plotHeaderMarginBottom =
            this.plotHeaderMarginBottom < gisIndex * this.xAxisHeaderOffset + 40
              ? gisIndex * this.xAxisHeaderOffset + 40
              : this.plotHeaderMarginBottom;
          // ----- set mb if bigger than existing (if ohter plots have less or equal x axes than do nothing) (Ending line.) -----

          // ----- x axis (Ending line.) -----

          var line = d3
            .line()
            .defined((d) => !isNaN(d.x))
            .x((d) => x[plotIndex][gisOfThatIteration.name](d.x))
            .y((d) => y(d.y));

          path[plotIndex][gisOfThatIteration.name] = plot
            .append("path")
            .datum(linesDataObj[gisOfThatIteration.name])
            .attr("class", "path " + gisOfThatIteration.name + "-line")
            .attr("fill", "none")
            .attr("clip-path", "url(#plotBoundaryClip)")
            .attr(
              "stroke",
              plotsColors[gisOfThatIteration.name] !== undefined
                ? plotsColors[gisOfThatIteration.name]
                : plotsColors["default"]
            )
            .attr("stroke-width", 1.5)
            .attr("d", line);

          var componentContext = this;

          componentContext.yCoord = y;

          if (!componentContext.trackSize) componentContext.trackSize = path[plotIndex][gisOfThatIteration.name]
            .node()
            .getBBox().width;
          const w = componentContext.trackSize;
          // water roof
          const yWater = y(Number.parseFloat(this.topWater_input));
          const water = plot
            .append("g")
            .attr("clip-path", "url(#plotBoundaryClip)")
            .attr("class", "water");
          water.append("text")
            .attr("x", 5)
            .attr("y", yWater - 5)
            .text(this.$t("petrophysics.owc.top-water-label"))
            .attr("stroke", "blue");
          water.append("line")
            .attr("x1", 0)
            .attr("y1", yWater)
            .attr("x2", w)
            .attr("y2", yWater)
            .attr("stroke", "blue");
          // fuel sole
          const yFuel = y(Number.parseFloat(this.botOil_input));
          const fuel = plot
            .append("g")
            .attr("clip-path", "url(#plotBoundaryClip)")
            .attr("class", "fuel");
          fuel.append("text")
            .attr("x", 5)
            .attr("y", yFuel - 5)
            .text(this.$t("petrophysics.owc.bot-oil-label"))
            .attr("stroke", "brown");
          fuel.append("line")
            .attr("x1", 0)
            .attr("y1", yFuel)
            .attr("x2", w)
            .attr("y2", yFuel)
            .attr("stroke", "brown");

        });
        var yScaler = y;
        this.$store.commit(
          "setAxisIndexBindingInOwc",
          this.gisAxisIndexBinding
        );

        // break;
      }

      // function pan() {
      //   zoom.translateBy(SVG.transition().duration(100), d3.event.transform.x, d3.event.transform.y);
      // }
      // zoom.scaleBy(SVG, 1 / 1.3);
      var store = this.$store;
      this.updatingChart = false;

      function updateChart(e) {
        // if (thisRef.updatingChart) return;
        thisRef.updatingChart = true;
        if (d3.event.sourceEvent.type === "wheel") {
          if (d3.event.sourceEvent.deltaY > 0) {
            d3.event.transform.y += 150;
          } else {
            d3.event.transform.y -= 150;
          }
        } else {
          yPanFromOriginal = d3.event.transform.y;
        }
        if (d3.event.transform.k !== 1) {
          d3.event.transform.k = 1;
        }

        // return;
        // SVG.attr('transform', d3.event.transform);
        // return;
        var newY = d3.event.transform.rescaleY(y);

        componentContext.yCoord = newY;

        thisRef.currentPlotScrollY = newY.invert(0);
        yAxis.call(
          d3
            .axisLeft(newY)
            .ticks(9)
            .tickSize(-width - margin.left * 1.5 - margin.right * 1.5)
        );

        for (
          let plotIndex = 0;
          plotIndex < totalParallelPlotsAmountWarranted;
          plotIndex++
        ) {
          for (let i = 0; i < thisRef.gisesOfPlot[plotIndex].length; i++) {
            let gisOfThatIteration = thisRef.gisesOfPlot[plotIndex][i];

            path[plotIndex][gisOfThatIteration.name].attr(
              "transformY",
              d3.event.transform.y
            );

            var line = d3
              .line()
              .defined((d) => !isNaN(d.x))
              .x((d) => x[plotIndex][gisOfThatIteration.name](d.x))
              .y((d) => newY(d.y));

            path[plotIndex][gisOfThatIteration.name]
              .datum(linesDataObj[gisOfThatIteration.name])
              .attr("class", "path")
              .attr("fill", "none")
              .attr("clip-path", "url(#plotBoundaryClip)")
              .attr(
                "stroke",
                plotsColors[gisOfThatIteration.name] !== undefined
                  ? plotsColors[gisOfThatIteration.name]
                  : plotsColors["default"]
              )
              .attr("stroke-width", 1.5)
              .attr("d", line);

            const water = d3.selectAll(".water");
            water.select("line")
              .attr("y1", function() {
                const y = Number.parseFloat(componentContext.topWater_input);
                return newY(y);
              })
              .attr("y2", function() {
                const y = Number.parseFloat(componentContext.topWater_input);
                return newY(y);
              });
            water.select("text")
              .attr("y", function() {
                const y = Number.parseFloat(componentContext.topWater_input);
                return newY(y) - 5;
              });
            const fuel = d3.selectAll(".fuel");
            fuel.select("line")
              .attr("y1", function() {
                const y = Number.parseFloat(componentContext.botOil_input);
                return newY(y);
              })
              .attr("y2", function() {
                const y = Number.parseFloat(componentContext.botOil_input);
                return newY(y);
              });
            fuel.select("text")
              .attr("y", function() {
                const y = Number.parseFloat(componentContext.botOil_input);
                return newY(y) - 5;
              });
          }
        }

        yScaler = newY;

        thisRef.updatingChart = false;
        return;
      }

      document
        .querySelector("#graph-wrapper")
        .addEventListener("click", function (e) {
          // ----- hide all dropdowns (Starting line.) -----
          document.querySelectorAll("h4.now-active").forEach((element) => {
            element.classList.remove("now-active");
          });
          // ----- hide all dropdowns (Ending line.) -----
        });

      removeAllActvesIfClickExaclyAtElWithQS(".graph-column");
      removeAllActvesIfClickExaclyAtElWithQS(".plot-header");

      function removeAllActvesIfClickExaclyAtElWithQS(qs) {
        document.querySelector(qs).addEventListener("click", function (e) {
          if (e.target === document.querySelector(qs)) {
            // ----- hide all dropdowns (Starting line.) -----
            document.querySelectorAll("h4.now-active").forEach((element) => {
              element.classList.remove("now-active");
            });
            // ----- hide all dropdowns (Ending line.) -----
          }
        });
      }

      this.$forceUpdate(); // necessary fot v-html to properly update
      return;
    },
  },
};
</script>

<style scoped lang="scss">

.main-container {
  padding-top: 90px;
}

.delta-save-button {
  background: #18a0fb;
  border-radius: 12px;
  color: white;
  font-size: 1.5rem;
  padding: 1rem 2rem 1rem 2rem;
}

#b-zone button {
  margin: 10px 0;
  margin-right: 10px;
}

#b-zone {
  height: auto;
  padding: 30px;
  max-width: 524px;
  border-left: 2px solid grey;
  font-size: 14px;
  padding-right: 15px; // because scrollbar
}

.created-zones .created-zones__header span,
.created-zones ol li > * {
  padding: 10px;
  display: inline-block;
  border: 1px solid lightgrey;
  width: 24.7%;
}

main button {
  background: #18a0fb;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  padding: 10px 26px;
  transition: all 0.2s ease-in-out;
}

main button:hover {
  background: #a5a5a575;
  color: #18a0fb;
}

.created-zones ol {
  counter-reset: zoneIndex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.created-zones li {
  position: relative;
}

.created-zones input {
  width: 100%;
}

.created-zones {
  width: 100%;
  background: #fff;
}

.created-zones .created-zones__header::before {
  content: "0";
  color: transparent;
  user-select: none;
}

.created-zones .created-zones__header::before,
.created-zones li::before {
  width: 10%;
  display: inline-block;
  text-align: center;
  border: 1px solid lightgrey;
  padding: 10px;
}

.created-zones li::before {
  counter-increment: zoneIndex + 1;
  content: counter(zoneIndex);
}

.plot-header {
  display: flex;
  flex-wrap: wrap;
  // padding-left: 15px;
  margin-top: 20px;
  // background: #fff;
  z-index: 2;
  //  z-index:-1;
  position: relative;
}

.plot-header article {
  flex: 1;
  box-sizing: border-box;
}

.plot-header article:not(:last-of-type) {
  padding-right: 30px;
}

.scale-container {
  text-align: center;
  transform: rotate(270deg);
}

.dropdown label,
.scale,
.plot-selects h4 {
  cursor: pointer;
}

.dropdown {
  min-width: 100px;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: all 0.4s;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 15px;
  padding-right: 5px; // Bacuse scrollbar is 10px thus 15-10=5
  box-sizing: border-box;
  z-index: -1;
  pointer-events: none;
  border: 1px solid grey;
  border-radius: 5px;
  max-height: 83vh;
  overflow-y: auto;
}

/* width */
.dropdown::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
.dropdown::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);
  cursor: grab;
}

/* Handle on hover */
.dropdown::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 105);
}

h4.now-active + .dropdown {
  opacity: 1;
  z-index: 99;
  pointer-events: all;
}

.plot-selects {
  display: flex;
  justify-content: space-around;
}

.plot-selects article {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 50px;
}

#graph-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 10%;
}

.dropdown-container {
  position: relative;
}

.scales-selects label,
.scales-selects input {
  font-size: 13px;
}

.scales-selects {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.plot-selects button {
  padding: 5px 15px;
  font-size: 14px;
  margin-left: 10px;
}

.plot-selects article {
  padding: 10px;
  padding-left: 0;
}

.to-hide {
  display: none !important;
}

.plots-chooser input[type="number"] {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}

.created-zones input[type="number"] {
  outline: 1px solid black;
  border-radius: 5px;
  padding-left: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.created-zones .created-zones__header::before,
.created-zones li::before {
  width: 16%;
  display: inline-block;
  text-align: center;
  border: 1px solid lightgrey;
  padding: 10px;
  padding-left: 40px;
}

.created-zones .created-zones__header::before {
  content: "Группа";
  padding-left: 10px;
}

.created-zones li::before {
  counter-increment: zoneIndex + 1;
  content: counter(zoneIndex);
}

.created-zones input[type="number"] {
  outline: 1px solid black;
  border-radius: 5px;
  padding-left: 5px;
}

.created-zones li .color {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  left: 7px;
  top: 48%;
  transform: translateY(-50%);
}

.remove-btn,
.add-btn {
  box-sizing: border-box;
  width: 40px !important;
  margin: 2px 5px !important;
  margin-right: 0 !important;
  padding: 10px !important;
  height: 32px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  // font-weight: 900;
  border: none !important;
}

thead {
  display: block;
  width: 100%;
}

tbody {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: block;
}

.table {
  width: 100%;
}

tr {
  display: flex;
}

td {
  border: 1px solid black;
  width: 100%;
  padding: 10px;
}
td:not(:last-child) {
  border-right: none;
}
tr:not(:last-child) td {
  border-bottom: none;
}
th {
  width: 100%;
}

.add-btn {
  width: 90px !important;
  margin-right: 10% !important;
  margin-top: 10px !important;
}

.add-btn__disabled {
  background: #a5a5a575;
}
.add-btn__disabled:hover {
  color: #fff;
}

.created-zones__controls {
  display: flex;
  justify-content: flex-end;
}

select.naming {
  cursor: pointer;
}

.plots-choosers {
  z-index: 2;
}

.owc-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.owc-text {
  font-size: 1rem;
  font-weight: 400;
}
</style>
