import authHttpClient from "@/http/auth";

class AuthService {
  login(credentials) {
    return authHttpClient.post("login/", credentials);
  }

  register(credentials) {
    return authHttpClient.post("register/", credentials);
  }

  authorized() {
    localStorage.setItem("authorized", JSON.stringify(true));
  }

  isAuthorized() {
    return JSON.parse(localStorage.getItem("authorized")) || false;
  }

  logout() {
    localStorage.setItem("authorized", JSON.stringify(false));
    return authHttpClient.get("logout/");
  }

  forgotPassword(email){
    return authHttpClient.get("forgot-password/", { params: email });
  }

  changePassword(credentials){
    return authHttpClient.put("change-password/", credentials);
  }

  changePasswordUnauthorized(credentials){
    return authHttpClient.post(`password-reset/${credentials.uid}/${credentials.token}`, credentials);
  }

  checkEmailSending(credentials) {
    return authHttpClient.get(`activate/${credentials.uid}/${credentials.token}`);
  }

  deleteResults(){
    return authHttpClient.get("delete-results/");
  }

    
}

export default new AuthService();