import httpClient from "@/http";


class RegressionService {
  async calc(wellId, gisName, coreName) {
    const result = httpClient.post("/api/v1/petrophysics/linear-regression/", {
      well_id: wellId,
      gis_name: gisName,
      core_name: coreName
    }).then(({data}) => {
      return data;
    });
    return result;
  }
  async calc_exponent(wellId, gisName, coreName) {
    const result = httpClient.post("/api/v1/petrophysics/exponent-regression/", {
      well_id: wellId,
      gis_name: gisName,
      core_name: coreName
    }).then(({data}) => {
      return data;
    });
    return result;
  }
}

export default new RegressionService();