import httpClient from "@/http";

class OwcService {
  getFinalOWC() {
    return httpClient.get("/api/v1/petrophysics/final-owc/");
  }

  saveFinalOWC(owc_data) {
    return httpClient.post("/api/v1/petrophysics/final-owc/", this.clearParams(owc_data));
  }

  listOWCs(zone_name) {
    return httpClient.get(`/api/v1/petrophysics/list-owcs/${zone_name}/`);
  }

  createOWC(well, zone_name, owc_data) {
    return httpClient.post(`/api/v1/petrophysics/owc/${well}/${zone_name}/`, this.clearParams(owc_data));
  }

  getOWC(well, zone_name, owc_data) {
    return httpClient.get(`/api/v1/petrophysics/owc/${well}/${zone_name}/`, this.clearParams(owc_data));
  }

  clearParams(data) {
    for(const [key, value] of Object.entries(data)) {
      if(value === null) {
        delete data[key];
      }
    }
    return data;
  }
}

export default new OwcService();