<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container
    class="main-container"
    fluid
  >
    <v-row>
      <v-col
        ref="graph-column"
        class="graph-column pa-0"
      >
        <div class="to-hide">
          currentPlotScrollY: <span id="currentPlotScrollY">{{ currentPlotScrollY }}</span>
        </div>
        <div class="to-hide">
          userSelectZoneHeight: <span id="userSelectZoneHeight" />
        </div>
        <div class="to-hide">
          activeBZoneItem: <span class="firstBoundaryPositionY">{{ activeBZoneItem }}</span>
        </div>
        <div class="to-hide">
          firstBoundaryPositionY: <span class="firstBoundaryPositionY">{{
            firstBoundaryPositionY
          }}</span>
        </div>
        <div class="to-hide">
          secondBoundaryPositionY: <span class="secondBoundaryPositionY">{{
            secondBoundaryPositionY
          }}</span>
        </div>

        <div
          class="plot-header"
          :style="`margin-bottom: ${plotHeaderMarginBottom}px;`"
        >
          <div
            v-if="gisAxisIndexBinding !== undefined"
            class="plot-selects"
            :style="`width: ${plotWidth}px; display: flex; margin-left: 10%;`"
          >
            <article
              v-for="axisIndexPlusOne in count"
              :key="'plots-chooser-' + axisIndexPlusOne"
              :class="'plots-chooser plots-chooser-' + axisIndexPlusOne"
            >
              <h4>
                <button
                  :id="`setup-track-btn-${axisIndexPlusOne}`"
                  @click="toggleNowActiveQS('.plots-chooser-' + axisIndexPlusOne + ' h4')"
                >
                  {{ $t("common.configureTrack") }}
                  {{ axisIndexPlusOne }}
                </button>
              </h4>

              <div class="dropdown">
                <h5>{{ $t("common.other-logs") }}:</h5>
                <div
                  v-for="(gisAxisBinding, gisName) in gisAxisIndexBinding"
                  :key="gisName"
                  :style="gisAxisBinding.includes(axisIndexPlusOne - 1) ? 'order: -1; margin-bottom:10px; padding-bottom:10px; border-bottom: 1px solid lightgrey;' : ''"
                >
                  <label>
                    <input
                      v-if="gisAxisBinding.includes(axisIndexPlusOne - 1)"
                      :data-axis-index="axisIndexPlusOne - 1"
                      type="checkbox"
                      checked
                      :name="gisName"
                      @click="changeAxisBinding"
                    >
                    <input
                      v-else
                      type="checkbox"
                      :name="gisName"
                      :data-axis-index="axisIndexPlusOne - 1"
                      @click="changeAxisBinding"
                    >
                    {{ gisName.toString().toUpperCase() }}
                  </label>
                  <div
                    v-if="scalesFunctions !== undefined && gisAxisBinding.includes(axisIndexPlusOne - 1)"
                    class="scales-selects"
                  >
                    <h5>{{ $t("common.scale") }}:</h5>
                    <label>
                      <input
                        v-if="scalesFunctions[axisIndexPlusOne - 1][gisName] === 'log'"
                        id=""
                        checked
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'log')"
                      >
                      <input
                        v-else
                        id=""
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'log')"
                      >
                      {{ $t("common.scale-log") }}
                    </label>
                    <label>
                      <input
                        v-if="scalesFunctions[axisIndexPlusOne - 1][gisName] === 'linear'"
                        id=""
                        checked
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'linear')"
                      >
                      <input
                        v-else
                        id=""
                        type="radio"
                        :name="'scale-radio-' + axisIndexPlusOne.toString() + gisName"
                        @change="setScale(axisIndexPlusOne - 1, gisName, 'linear')"
                      >
                      {{ $t("common.scale-linear") }}
                    </label>
                    <h5>{{ $t("common.min-scale-value") }}</h5>
                    <label>
                      <input
                        :id="'minOfX' + (axisIndexPlusOne - 1).toString() + gisName"
                        type="number"
                        :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1][gisName] && xAxisProps[axisIndexPlusOne - 1][gisName].min) ? xAxisProps[axisIndexPlusOne - 1][gisName].min : ''"
                        @change="changeScaleMin(axisIndexPlusOne - 1, gisName)"
                      >
                    </label>
                    <h5>{{ $t("common.max-scale-value") }}</h5>
                    <label>
                      <input
                        :id="'maxOfX' + (axisIndexPlusOne - 1).toString() + gisName"
                        type="number"
                        :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1][gisName] && xAxisProps[axisIndexPlusOne - 1][gisName].max) ? xAxisProps[axisIndexPlusOne - 1][gisName].max : ''"
                        @change="changeScaleMax(axisIndexPlusOne - 1, gisName)"
                      >
                    </label>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div style="display: flex;width: 100%;">
            <div
              class="scale-container"
              style="z-index: 2;width: 10%;display: flex;justify-content: center;align-items: center;"
            >
              <div
                style="display: flex;justify-content: center;align-items: center;"
                class="dropdown-container"
              >
                <h4
                  class="scale"
                  @click="toggleNowActive"
                >
                  {{ $t("common.depth") }}<br>({{ scaleOfMainPlot }})
                </h4>
                <div
                  class="dropdown"
                  style="transform: rotate(90deg) translate(-26px,65px);transform-origin: top center;"
                >
                  <label
                    v-for="(scale, index) in scalesAvailable"
                    :key="scale + index"
                  >
                    <input
                      v-if="scale == scaleOfMainPlot"
                      :data-scale="scale"
                      type="checkbox"
                      checked
                      @click="changeScale"
                    >
                    <input
                      v-else
                      type="checkbox"
                      :data-scale="scale"
                      @click="changeScale"
                    >
                    {{ scale }}
                  </label>
                </div>
              </div>
            </div>
            <div
              class="plots-choosers"
              :style="`width: ${plotWidth}px; display: flex;`"
            >
              <article
                v-for="axisIndexPlusOne in count"
                :key="'plots-chooser-' + axisIndexPlusOne"
                :class="plots - chooser"
                style="height: 100%;"
              >
                <div
                  v-if="legendInfoForPlots !== null"
                  style="display: flex;flex-direction: column-reverse; height: 100%;"
                >
                  <div
                    v-for="(legend, index) in legendInfoForPlots[axisIndexPlusOne - 1]"
                    :key="'plot-legend-' + index"
                    :style="'position: relative; cursor:pointer;display: flex;align-items: stretch;flex-direction: column; justify-content: flex-end;'"
                    :class="'plot-legend-' + axisIndexPlusOne.toString() + index.toString()"
                    @click="toggleNowActiveQS('.plot-legend-' + axisIndexPlusOne.toString() + index.toString() + ' h4')"
                  >
                    <div>
                      <h4 :style="'font-weight: 500; color:' + legend.color">
                        {{ legend.name }} [{{ legend.units }}]
                      </h4>
                      <div
                        style="text-align: center;"
                        class="dropdown"
                      >
                        {{ $t("common.changeColor") }} {{ legend.name }}:
                        <div
                          style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 8px;justify-content: center;"
                        >
                          <div
                            v-for="(color, key) of originalPlotsColors"
                            :key="'inner-plot-legend-' + key"
                            style="width:40px;position: relative; cursor:pointer;display: inline-block;margin:8px; align-items: center;"
                          >
                            <h4
                              :style="'width: 100%; height: 40px; width:40px; margin-right: 10px;display:inline-block; background-color: ' + color"
                              @click="overridePlotColor(legend.name, color)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <svg
                      v-if="clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()]"
                      :id="`replace-color-` + axisIndexPlusOne.toString() + index.toString()"
                      :key="'clonedXaxes' + clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()].length"
                      style="height: 55px;width: 100%;overflow: visible;"
                      v-html="clonedXaxes[axisIndexPlusOne - 1][legend.name.toLowerCase()]"
                    />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div id="graph-wrapper" />
      </v-col>
      <v-col class="mt-4">
        <v-select
          v-model="selectedWell"
          :items="interactiveWells"
          :label="$t('common.well')"
          item-text="name"
          item-value="0"
          return-object
          outlined
          @change="navigateToGis(selectedWell.id)"
        />
      </v-col>
      <div id="b-zone">
        <div>
          <h3 class="mb-2">
            {{ $t("geology.welltops-list") }}&nbsp;<v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  slot="activator"
                  dark
                  color="#009797"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>В данную таблицу можно занести одну или несколько отбивок, название горизонта можно выбрать из выпадающего списка</span>
            </v-tooltip>
          </h3>
        </div>
        <div class="created-zones">
          <div class="created-zones__header">
            <span class="naming">{{ $t("geology.formation") }}</span>
            <span class="upper-value">{{ $t("geology.top-m") }}</span>
            <span class="bottom-value">{{ $t("geology.bot-m") }}</span>
          </div>
          <ol>
            <li
              v-for="(bZoneItem, index) in bZoneItems"
              :key="'b-zone-item-' + index"
              :data-id="index"
            >
              <span
                class="color"
                :style="'background:' + colorsOfZones[index % (colorsOfZones.length)]"
              />
              <select
                id=""
                v-model="names[index]"
                class="naming"
                name=""
              >
                <option value="Башкирский">
                  {{ $t("common.bashkir") }}
                </option>
                <option value="Верейский">
                  {{ $t("common.verey") }}
                </option>
                <option value="Серпуховский">
                  {{ $t("common.serpukhov") }}
                </option>
              </select>
              <label>
                <input
                  type="number"
                  lang="en-US"
                  max="12000"
                  class="bottom-value"
                  :value="bZoneItem.bottomVal"
                  step="0.1"
                  @change="handleOnlyUserChangeOfBZoneItem(index, 'bottomVal', '.bottom-value')"
                >
              </label>
              <label>
                <input
                  type="number"
                  lang="en-US"
                  max="12000"
                  class="upper-value"
                  :value="bZoneItem.upperVal"
                  step="0.1"
                  @change="handleOnlyUserChangeOfBZoneItem(index, 'upperVal', '.upper-value')"
                >
              </label>
              <!-- <label>
         <input @change="handleOnlyUserChangeOfBZoneItem(index, 'deltaVal', '.delta')" type="number" lang="en-US" max="12000" class="delta" :value="bZoneItem.deltaVal" step="0.1">
       </label> -->
              <button
                class="remove-btn"
                @click="removeBZoneItem(index)"
              >
                -
              </button>
            </li>
          </ol>
          <div class="created-zones__controls">
            <button
              class="add-btn"
              :class="{'add-btn__disabled': bZoneItems.length >= count}"
              :disabled="bZoneItems.length >= count"
              @click="addNewBZoneItem"
            >
              +
            </button>
          </div>
        </div>
        <button
          type="button"
          @click="saveBZoneItemState"
        >
          {{ $t("common.save") }}
        </button>
        <Plotly
          ref="plot2"
          :data="surfaceMapData"
          :layout="contour.layout"
          :display-mode-bar="false"
          :scroll-zoom="true"
          style="height: 550px;"
        />
      </div>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";
import surfaceService from "@/service/surface-service";
import * as d3 from "d3";
import {Plotly} from "vue-plotly";


export default {
  name: "GeologyGisViewer",
  components: {
    Plotly
  },
  filters: {
    excludeDept(data) {
      return data.filter(gis => {
        return gis.name !== "dept";
      });
    }
  },
  data() {
    return {
      originalPlotsColors: {
        gk: "#000",
        ngk: "blue",
        ps: "red",
        pz: "#00008b",
        ik: "#066206",
        bk: "#00008b",
        ds: "#066206",
        kp: "#ab6a27",
        kn: "#187667",
        ggkp: "red",
        satur_water: "blue",
        satur_oil: "green",
        cpor:"cyan",
        dt: "brown",
        default: "grey"
      },
      clonedXaxes: {},
      plotsColors: null,
      currentPlotScrollY: null,
      colorsOfZones: [
        "#69D2E7",
        "#FE4365",
        "#ECD078",
        "#BCBDAC",
      ],
      bZoneItems: [
        {
          bottomVal: "",
          upperVal: "",
          deltaVal: "",
        },

      ],
      contour: {
        layout: {
          showlegend: false,
          hovermode: "closest",
          xaxis: {showticklabels:false,
          },
          yaxis: {
            scaleanchor: "x",
            showticklabels:false,
          },
        },

        wellData: []
      },
      snackbar: {
        show: false,
        color: null,
        message: "",
      },
      xAxisProps: {},
      plotWidth: "100%",
      translatedLayerNames: [],
      userZoneBoundaryBarHeight: 10,
      selectedWell: null,
      activeBZoneItem: null,
      selectedGisData: null,
      selectedCoreData: null,
      AllWellsData: [],
      wellGisData: [],
      language:"",
      wellCoreData: [],
      interactiveWells: [],
      surface_is_rendered: false,
      once_iterated: false,
      delta: 0,
      firstPointInitialDepth: 0,
      regression: {
        layout: {
          showlegend: false,
          hovermode: "closest",
          xaxis: {showticklabels:false,
          },
          yaxis: {
            scaleanchor: "x",
            showticklabels:false,
          },
        },
        coef: {},
        data: []
      },
      surfaceMapData: [],
      clickOutsideListener: null,
      count: 3,
      names: [],
      settings: {
        data: {
          geology: {
            tracks: []
          }
        }
      },
      legendInfoForPlots: null
    };
  },

  mounted() {
    this.language = localStorage.lang !== undefined ? localStorage.lang : this.$root.$i18n.locale;
    this.legendInfoForPlots = [[], [], []];
    wellService.getAllWells().then(async ({data}) => {

      this.AllWellsData = data;

      this.selectedWell = data.reduce((load, currentItem) => {
        if (this.$route.params.id == currentItem.id) {
          load = currentItem;
        }
        return load;
      });
      this.names = [
      ];

      await this.getWellData(this.selectedWell);

      this.bZoneItems = [];
      this.translatedLayerNames = [];
      for (let i = 0; i < this.selectedWell.zones.length; i++) {

        switch (this.selectedWell.zones[i].zone_name) {
        case "Башкирский":
          this.translatedLayerNames.push(this.$t("common.bashkir"));
          break;
        case "Верейский":
          this.translatedLayerNames.push(this.$t("common.verey"));
          break;
        case "Серпуховский":
          this.translatedLayerNames.push(this.$t("common.serpukhov"));
          break;
        default:
          this.translatedLayerNames.push(data[i].zone_name);}

        let welltop = {
          bottomVal: this.selectedWell.zones[i].top,
          upperVal: this.selectedWell.zones[i].bottom,
          id: this.selectedWell.zones[i].id
        };
        this.bZoneItems.push(welltop);
        this.names.push(this.selectedWell.zones[i].zone_name);
      };
      if (this.names[0] == null) { 
     
        this.names = [this.selectedWell.zones[0].zone_name ?? "Башкирский"];

        this.bZoneItems = [
          {
            bottomVal: "",
            upperVal: ""
          },
        ];

      };     
      const hasSettings = localStorage.getItem("curve_settings_geology");
      if (hasSettings) {
        this.loadCurveSettings();
        this.initPlot(3, "#graph-wrapper");
        this.loadCurveSettings();
      } else {
        this.saveCurveSettings();
      }
    });
    this.clickOutsideListener = (e) => {
      const dropdown = e.target.closest(".dropdown");
      for (let i = 0; i < this.count; i++) {
        const popupSelector = `.plots-chooser-${i + 1} h4`;
        const popup = document.querySelector(popupSelector);
        const target = e.target;
        if (popup) {
          const isNowActive = popup.classList.contains("now-active");
          const isSetupTrackBtn = target.id !== `setup-track-btn-${i + 1}`;
          const isNotPopup = dropdown === null;
          const isHidePopup = isNowActive && isNotPopup && isSetupTrackBtn;
          if (isHidePopup)
            this.toggleNowActiveQS(popupSelector);
        }
        for (let j = 0; j < this.legendInfoForPlots[i].length; j++) {
          const popupSelector = `.plot-legend-${i + 1}${j} h4`;
          const popup = document.querySelector(popupSelector);
          const target = e.target;
          if (popup) {
            const isNowActive = popup.classList.contains("now-active");
            const isReplaceColorBtn = target.closest(`#replace-color-${i + 1}${j}`);
            const isHidePopup = isNowActive && !isReplaceColorBtn;
            if (isHidePopup)
              this.toggleNowActiveQS(popupSelector);
          }
        }
      }
    };
    document.body.onclick = this.clickOutsideListener;
  },
  beforeDestroy() {
    document.body.onclick = null;
  },
  methods: {
    loadCurveSettings() {
      Object.keys(this.gisAxisIndexBinding).map(e => this.gisAxisIndexBinding[e] = []);
      const settings = localStorage.getItem("curve_settings_geology");
      this.settings = JSON.parse(settings);
      this.settings.data.geology.tracks.map((e, i) => {
        Object.keys(e).map((el, j) => {
          const key = Object.keys(e[j])[0];
          if (this.xAxisProps[i][key]) {
            this.xAxisProps[i][key].min = e[el][Object.keys(e[j])[0]].min;
            this.xAxisProps[i][key].max = e[el][Object.keys(e[j])[0]].max;
          }
          this.scalesFunctions[i][Object.keys(e[j])] = e[el][Object.keys(e[j])[0]].scale;
          if (this.gisAxisIndexBinding[Object.keys(e[j])]) {
            this.gisAxisIndexBinding[Object.keys(e[j])].push(i);
          }

          if (this.gisAxisIndexBinding[Object.keys(e[j])]) {
            const scale = e[el][Object.keys(e[j])[0]].depth;
            const clickedCheckbox = document.querySelector(`.dropdown input[data-scale="${scale}"]`);
            let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
            this.$store.commit("setPlotScaleInGeology", scaleOfClickedCheckbox);
            document.querySelectorAll("[data-scale]").forEach((element) => {
              element.checked = false;
            });
            clickedCheckbox.checked = true;
            document.querySelector(".scale").classList.remove("now-active");
            this.initPlot(3, "#graph-wrapper");
          }
          
        });
      });
      this.$forceUpdate();
    },
    saveCurveSettings() {
      const settings = this.settings;
      settings.data.geology = {
        tracks: Array.from({length: 3}).map((track, i) => ({
          ...(Object.entries(this.gisAxisIndexBinding).filter(e => Object.values(e)[1].includes(i)).map(e => ({
            [Object.values(e)[0]]: {
              scale: this.scalesFunctions[i][Object.values(e)[0]],
              min: this.xAxisProps[i][Object.values(e)[0]].min,
              max: this.xAxisProps[i][Object.values(e)[0]].max,
              depth: this.$store.getters.getPlotScaleInGeology,
            },
          })))
        }))
      };
      localStorage.setItem("curve_settings_geology", JSON.stringify(settings));
    },
    navigateToGis(well) {
      if (this.$route.params.id === well) {
        return;
      }
      this.$router.push({
        name: "geology-gis",
        params: {
          id: well
        }
      });
      window.location.reload();
    },

    overridePlotColor(plot, color) {
      this.plotsColors[plot.toLowerCase()] = color;
      this.$forceUpdate();
      this.initPlot(3, "#graph-wrapper");
    },
    addNewBZoneItem() {
      if (this.names.length < this.count) {
        this.bZoneItems.push(
          {
            bottomVal: "",
            upperVal: "",
            deltaVal: "",
          }
        );
        this.names.push("Башкирский");
        this.initPlot(3, "#graph-wrapper");
      }
    },
    removeBZoneItem(itemIndex) {
      wellService.deleteWelltop(this.bZoneItems[itemIndex].id);
      this.bZoneItems = [...this.bZoneItems.filter((item, index) => index != itemIndex)];
      this.names = [...this.names.filter((item, index) => index != itemIndex)];
      
      this.$forceUpdate();
      this.initPlot(3, "#graph-wrapper");
    },
    changeScaleMin(plotIndex, gisName) {
      this.xAxisProps[plotIndex][gisName].min = +document.querySelector("#minOfX" + plotIndex.toString() + gisName).value;
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    changeScaleMax(plotIndex, gisName) {
      this.xAxisProps[plotIndex][gisName].max = +document.querySelector("#maxOfX" + plotIndex.toString() + gisName).value;
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    setScale(plotIndex, gisName, scaleFunc) {
      this.scalesFunctions[plotIndex][gisName] = scaleFunc;
      this.$store.commit("setScalesFunctions", this.scalesFunctions, "geology");
      // ----- reinit autofilling (autoscaling) during further init (Starting line.) -----
      this.xAxisProps = {};
      // ----- reinit autofilling (autoscaling) during further init (Ending line.) -----
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    toggleNowActiveQS(selector, dispayNoneAtEnd = false, animationLength = 0) {
      // ----- hide all dropdowns (Starting line.) -----
      document.querySelectorAll("h4.now-active").forEach((element) => {
        if (element !== document.querySelector(selector)) {
          element.classList.remove("now-active");
        }
      });
      // ----- hide all dropdowns (Ending line.) -----
      document.querySelector(selector).classList.toggle("now-active");

    },
    handleOnlyUserChangeOfBZoneItem(itemIndex, inputsObjectKey, inputsSelector) {

      // ----- Performance enchancement of user-fast-clicking behaviour (Starting line.) -----
      if (this.changeOfBZoneTimeoutId) {
        clearTimeout(this.changeOfBZoneTimeoutId);
      }
      // ----- Performance enchancement of user-fast-clicking behaviour (Ending line.) -----
      var thisItem = document.querySelector(`.created-zones li[data-id="${itemIndex}"]`);
      var changedInput = thisItem.querySelector(inputsSelector);

      changedInput.value = Number(changedInput.value).toFixed(1);
      this.bZoneItems[itemIndex][inputsObjectKey] = changedInput.value;

      // ----- fix flipfloping when bottom highr than upper (Starting line.) -----
      let doesUpperAndLowerValuesBothSetted = thisItem.querySelector("input.upper-value").value && Number(thisItem.querySelector("input.bottom-value").value);
      if (doesUpperAndLowerValuesBothSetted) {
        if (Number(thisItem.querySelector("input.upper-value").value) < Number(thisItem.querySelector("input.bottom-value").value)) {
          e.target.value = Math.min(Number(thisItem.querySelector("input.upper-value").value), Number(thisItem.querySelector("input.bottom-value").value));
        }
        this.initPlot(3, "#graph-wrapper");
      }
      // ----- fix flipfloping when bottom highr than upper (Ending line.) -----
    },
    setActiveBZoneItem(index) {
      document.querySelectorAll(".created-zones [data-id]").forEach((element) => {
        element.classList.remove("now-active");
      });
      document.querySelector(`.created-zones [data-id="${index}"]`).classList.add("now-active");
      this.activeBZoneItem = document.querySelector(`.created-zones [data-id="${index}"]`);
      this.initPlot(3, "#graph-wrapper");
    },
    saveBZoneItemState() {
      this.once_iterated = false;
      this.bZoneItems.map(async (bZoneItem, i) => {
        await wellService.setWelltop({
          well_id: this.selectedWell.id,
          name: this.names[i],
          top: +this.bZoneItems[i].bottomVal,
          bottom: +this.bZoneItems[i].upperVal,
        }).then((data) => {
          this.snackbar = {
            show: true,
            message: this.$t("geology.welltop-saved"),
            color: "success"
          };
        });
        if (this.once_iterated == false) {
          this.once_iterated = true;
          await wellService.getAllWells().then(async ({data}) => {

            this.AllWellsData = data;

            this.selectedWell = data.reduce((load, currentItem) => {
              if (this.$route.params.id == currentItem.id) {
                load = currentItem;
              }
              return load;
            });

            this.bZoneItems = [];
            this.names = [];
            for (let i = 0; i < this.selectedWell.zones.length; i++) {
              let welltop = {
                bottomVal: this.selectedWell.zones[i].top,
                upperVal: this.selectedWell.zones[i].bottom,
                id: this.selectedWell.zones[i].id
              };
              this.bZoneItems.push(welltop);
              this.names.push(this.selectedWell.zones[i].zone_name);
          
            };
          
          });
        
        }
      });
    
      this.initPlot(3, "#graph-wrapper");
    },
    toggleNowActive(e) {
      e.target.classList.toggle("now-active");

    },
    changeScale(e) {
      let clickedCheckbox = e.target;
      let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
      this.$store.commit("setPlotScaleInGeology", scaleOfClickedCheckbox);
      document.querySelectorAll("[data-scale]").forEach((element) => {
        element.checked = false;
      });
      clickedCheckbox.checked = true;
      document.querySelector(".scale").classList.remove("now-active");
      this.initPlot(3, "#graph-wrapper");
      this.saveCurveSettings();
    },
    changeAxisBinding(e) {

      let clickedCheckbox = e.target;
      let nameOfGis = clickedCheckbox.getAttribute("name");
      let axisIndexOfClickedCheckbox = parseInt(clickedCheckbox.getAttribute("data-axis-index"));
      let valueOfCheckboxAfterClick = clickedCheckbox.checked;
      if (valueOfCheckboxAfterClick === false) {
        // if (amountOfAlreadyChosenBindingsForThatAxis < 2) {
        this.gisAxisIndexBinding[nameOfGis].splice(this.gisAxisIndexBinding[nameOfGis].indexOf(axisIndexOfClickedCheckbox), 1);
        // }
      } else {
        this.gisAxisIndexBinding[nameOfGis].push(axisIndexOfClickedCheckbox);
      }
      // this.drawGisData();
      this.clonedXaxes = {};
      this.initPlot(3, "#graph-wrapper");
      this.$forceUpdate(); // necessary fot v-html to properly update
      this.saveCurveSettings();
    },
    async getWellData(well) {

      await wellService.getGisData(this.$route.params.id).then(({data}) => {
        this.wellGisData = data.gis;
        this.selectedGisData = this.wellGisData[1];
      });
      this.bZoneItems = [];
      for (let i = 0; i < this.selectedWell.zones.length; i++) {
        let welltop = {
          bottomVal: this.selectedWell.zones[i].top,
          upperVal: this.selectedWell.zones[i].bottom,
          id: this.selectedWell.zones[i].id
        };
        this.bZoneItems.push(welltop);
      }

      
      this.wellCoreData = [{name: "user-boundary", data: [{dept: 700, val: -10}]}];

      this.initPlot(3, "#graph-wrapper");
    },
    drawGisData() {
      this.initPlot(3, "#graph-wrapper");
    },
    drawCoreData() {
      this.initPlot(3, "#graph-wrapper");
    },
    async getSurfaceData() {
      let x = [];
      let y = [];
      let z = [];
      let text = [];
      let customData = [];
      let color = [];
      let data = [];
      this.interactiveWells = this.AllWellsData.filter(well => well.is_interactive);
      const notInteractiveWells = this.AllWellsData.filter(well => !well.is_interactive);

      this.contour.interactiveWells = this.interactiveWells;

      data = await surfaceService.getSurface("Башкирский").then(({data}) => {
        return data.top;
      });

      this.contour.wellData = [];

      this.contour.wellData.push({
        ...data,
        type: "contour",
        colorscale: "Earth",
        reversescale: true,
        contours: {
          coloring: "heatmap"
        },
        colorbar:{
          title: this.$t("common.depth"),
          titleside: "left",
          titlefont: {
            size: 14,
            family: "Arial, sans-serif"
          },
        },
      });

      notInteractiveWells.forEach(well => {
        x.push(well.x);
        y.push(well.y);
        z.push(well.zones[0].top);
        text.push(well.name);
        customData.push(well);
      });
      this.contour.wellData.push({
        x: x,
        y: y,
        z: z,
        text: text,
        name:"Скв.",
        customdata: customData,
        type: "scatter",
        mode: "markers+text",
        textposition: "right center",
        marker: {
          color: "lightgreen",
          size: 20,
          line: {
            color: "black",
            width: 1
          }
        },
      });

      x = [];
      y = [];
      z = [];
      text = [];
      customData = [];
      this.interactiveWells.forEach(well => {
        x.push(well.x);
        y.push(well.y);
        z.push(well.zones[0].top);
        text.push(well.name);
        if (well.zones[0].top == null || well.zones[0].bottom == null) {
          color.push("red");
        } else {
          color.push("green");
        }
        customData.push(well);
      });

      this.contour.wellData.push({
        x: x,
        y: y,
        z: z,
        customdata: customData,
        text: text,
        type: "scatter",
        name:"Скв.",
        mode: "markers+text",
        textposition: "right center",
        marker: {
          color: color,
          size: 20,
          line: {
            color: "black",
            width: 1
          }
        },
      });

      this.surfaceMapData = [...this.contour.wellData];

    },
    initPlot(totalParallelPlotsAmountWarranted, plotElSelector, commonYAxis = null, SVG = null) {

      this.clonedXaxes = {};
      
      if (this.surface_is_rendered == false){
        this.getSurfaceData().then();
      }
      
      this.surface_is_rendered = true;


      this.legendInfoForPlots = [[], [], []];

      this.scalesFunctions = this.$store.getters.getScalesFunctions.geology;
      // ----- Scale casing (Starting line.) -----
      var currentScale = this.$store.getters.getPlotScaleInGeology;
      var currentScaleTick;

      this.scalesAvailable = ["1:50", "1:100", "1:200", "1:500", "1:1000"];

      switch (currentScale) {
      case "1:50":
        currentScaleTick = 50;
        break;
      case "1:100":
        currentScaleTick = 100;
        break;
      case "1:200":
        currentScaleTick = 200;
        break;
      case "1:500":
        currentScaleTick = 500;
        break;
      case "1:1000":
        currentScaleTick = 1000;
        break;
      }
      this.scaleOfMainPlot = currentScale;
      // ----- Scale casing (Ending line.) -----

      //  ----- gis store bindings (Starting line.) -----
      this.gisAxisIndexBinding = this.$store.getters.getAxisIndexBindingInGeology;
      if (this.gisAxisIndexBinding === null) {
        this.gisAxisIndexBinding = {};
        var initialBindingNeeded = true;
      } else {
        var initialBindingNeeded = false;
      }
      //  ----- gis store bindings (Ending line.) -----


      // const margin = {top: 20, right: 30, bottom: 30, left: 60},
      this.plotHeaderMarginBottom = 0;
      const margin = {top: 0, right: 0, bottom: 0, left: 20},
        width = 680,
        height = 600;
      this.plotWidth = width + margin.left * 1.5 + margin.right * 1.5;

      d3.select(plotElSelector).select("svg").remove();
      var SVG = d3.select(plotElSelector)
        .append("svg")
        .style("overflow", "visible")
        .attr("width", width)
        .attr("height", height);

      const dept = this.wellGisData.filter(gis => gis.name === "dept")[0].data;

      const gisWithoutDept = this.wellGisData.filter(gis => {
        return gis.name !== "dept";
      });

      var deptYMax = d3.max(dept);
      var deptYMin = d3.min(dept);

      function findXMaxXMinOfGisArr(gisWithoutDept) {
        var gisXMax = null;
        var gisXMin = null;
        gisWithoutDept.forEach((gis, i) => {
          gis.maxXValue = d3.max(gis.data);
          gis.minXValue = d3.min(gis.data);
          if (i === 0) {
            gisXMax = gis.maxXValue;
            gisXMin = gis.minXValue;
          } else {
            if (gisXMax < gis.maxXValue) gisXMax = gis.maxXValue;
            if (gisXMin > gis.minXValue) gisXMin = gis.minXValue;
          }
        });
        return {xMax: gisXMax, xMin: gisXMin};
      }

      var gisXMin = d3.min(this.selectedGisData.data);

      var zoom = d3.zoom()
        .scaleExtent([.2, 2000]) // This control how much you can unzoom (x0.2) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .on("zoom", updateChart);


      var linesDataObj = {};
      var x = {};
      var xAxis = {};
      var lastUseBoundaryIndex = null;
      var path = {};
      var yPanFromOriginal = 0;
      var scatter = {};
      var translucentColorFill = {};
      var translucentColorFillText = {};
      var zoneBoundaryTop = {};
      var zoneBoundaryBottom = {};
      this.xAxisHeaderOffset = 0;
      var scatter2 = {};
      this.gisesOfPlot = {};
      var thisRef = this;
      this.firstBoundaryPositionY = null;
      this.secondBoundaryPositionY = null;
      var userSelectZoneHeight = 0;
      var scatterSetted = false;
      var xAxisGenerator = {};
      if (this.plotsColors === null) this.plotsColors = this.originalPlotsColors; // initialization when no user override of colors happened yet.
      var plotsColors = this.plotsColors;


      // ----- Perform initial binging (Starting line.) -----
      if (initialBindingNeeded) {
        for (let i = 0; i < gisWithoutDept.length; i++) {
          let gisOfThatIteration = gisWithoutDept[i];
          this.gisAxisIndexBinding[gisOfThatIteration.name] = [];
          //  this.gisAxisIndexBinding\[[^=]+=
          this.gisAxisIndexBinding[gisOfThatIteration.name].push(i % totalParallelPlotsAmountWarranted);
        }
      }
      // ----- Perform initial binging (Ending line.) -----

      for (let plotIndex = 0; plotIndex < totalParallelPlotsAmountWarranted; plotIndex++) {
        if (this.xAxisProps[plotIndex] === undefined) this.xAxisProps[plotIndex] = {};
        x[plotIndex] = {};
        path[plotIndex] = {};
        this.clonedXaxes[plotIndex] = {};
        if (this.scalesFunctions[plotIndex] === undefined) this.scalesFunctions[plotIndex] = {};

        var plot = SVG
          .append("g")
          .attr("transform",
            "translate(" + (margin.left * plotIndex + width * (plotIndex / totalParallelPlotsAmountWarranted)) + ", " + margin.top + ")");

        if (plotIndex === 0) {
          // ----- y axis (Starting line.) -----
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Starting line.) -----
          var firstGisDotWithoutNullish = dept.map((element, index) => {
            return {
              x: gisWithoutDept[0].data[index],
              y: element
            };
          }).filter(el => el.x != null);

          var firstYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[0].y;
          var lastYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[firstGisDotWithoutNullish.length - 1].y;
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Ending line.) -----
          if (!this.currentPlotScrollY) {
            var y = d3.scaleLinear()
              .domain([+currentScaleTick + +firstYWithNonNullishXOfFirstGis, +firstYWithNonNullishXOfFirstGis])
              .range([600, 0]); // Where 600 is just plot heihght in px.
          } else {
            var currentPlotScrollYRef = this.currentPlotScrollY;
            var y = d3.scaleLinear()
              .domain([+currentScaleTick + +currentPlotScrollYRef, +currentPlotScrollYRef])
              .range([600, 0]); // Where 600 is just plot heihght in px.
          }
          var yAxis = plot.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y).ticks(9).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5)));
          // ----- y axis (Ending line.) -----

          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Starting line.) -----
          var rect = SVG.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("cursor", "n-resize")
            .style("fill", "none")
            .style("pointer-events", "all")
            .call(zoom);
          // .on("wheel.zoom", null) // disables default zoom wheel behavior
          // .on("wheel", pan);

          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Starting line.) -----
          var globalDefs = plot.append("defs");
          // ----- define plotBoundary clipath (Starting line.) -----
          globalDefs.append("clipPath")
            .attr("id", "plotBoundaryClip")
            .append("rect")
            .attr("width", width / totalParallelPlotsAmountWarranted - (margin.left) - (margin.right))
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define plotBoundary clipath (Ending line.) -----

          // ----- define allPlotsButScaleTicksY clipath (Starting line.) -----
          // globalDefs.append("plot:clipPath")
          globalDefs.append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClip")
            .append("rect")
            .attr("width", this.plotWidth)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          globalDefs.append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClipPlusZoneCol")
            .append("rect")
            .attr("width", this.plotWidth + 40)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define allPlotsButScaleTicksY clipath (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Ending line.) -----
        }

        // ----- Retrieve only gises of this plot (Starting line.) -----
        this.gisesOfPlot[plotIndex] = [];
        for (const gisName in this.gisAxisIndexBinding) {
          const bindingsArr = this.gisAxisIndexBinding[gisName];
          if (bindingsArr.includes(plotIndex)) {
            gisWithoutDept.forEach((gisWithData) => {
              if (gisWithData.name == gisName) {
                this.gisesOfPlot[plotIndex].push(gisWithData);
              }
            });
          }
        }
        // ----- Retrieve only gises of this plot (Ending line.) -----

        let xMaxXMinOfGisesOfPlot = findXMaxXMinOfGisArr(this.gisesOfPlot[plotIndex]);

        this.gisesOfPlot[plotIndex].forEach((gisOfThatIteration, gisIndex) => {
          // ----- Fill in legendInfoForPlots (Starting line.) -----
          this.legendInfoForPlots[plotIndex].push({
            name: gisOfThatIteration.name.toString().toUpperCase(),
            units: gisOfThatIteration.units !== undefined ? gisOfThatIteration.units[this.language].toString() : "",
            color: plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"]
          });
          // ----- Fill in legendInfoForPlots (Ending line.) -----

          this.selectedGisData = gisOfThatIteration; // TODO: delete carefully.

          linesDataObj[gisOfThatIteration.name] = dept.map((element, index) => {
            return {
              x: gisOfThatIteration.data[index],
              y: element
            };
          }).filter(el => {
            if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "log") {
              return (el.x != null && el.x > 0);
            } else {
              return el.x != null;
            }
          });

          // ----- x axis (Starting line.) -----
          if (this.xAxisProps[plotIndex][gisOfThatIteration.name] === undefined) this.xAxisProps[plotIndex][gisOfThatIteration.name] = {};
          if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === undefined) this.scalesFunctions[plotIndex][gisOfThatIteration.name] = "linear";

          let xMaxXMinOfGisOfThatIteration = findXMaxXMinOfGisArr([gisOfThatIteration]);

          var doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid = gisIndex === 0;
          if (doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid) {
            var tickHeight = -height;
          } else {
            var tickHeight = -10;

          }
          //    ----- scalesFunctions (Starting line.) -----
          if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "log") {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].min == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min = 1;
            }
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].max == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max = xMaxXMinOfGisOfThatIteration.xMax;


              // this.xAxisProps[plotIndex][gisOfThatIteration.name].max = Math.exp(Math.log(xMaxXMinOfGisOfThatIteration.xMax) + 5);
            }

            // ----- auto-handle undefined x axis range (Ending line.) -----

            // ----- get exactly 13 ticks by getting exactly 12 spans (Starting line.) -----
            var tickSize = (this.xAxisProps[plotIndex][gisOfThatIteration.name].max - this.xAxisProps[plotIndex][gisOfThatIteration.name].min) / 13;
            var tickValuesArr = [];
            for (let i = 0; i <= 13; i++) {
              tickValuesArr.push(this.xAxisProps[plotIndex][gisOfThatIteration.name].min + tickSize * i);
            }
            // ----- get exactly 13 ticks by getting exactly 12 spans (Ending line.) -----

            // scale.log()

            // ----- LOG SCALE MUST START WITH 1!!!! OTHERWISE IT WOULD BE FINIKY (Starting line.) -----
            x[plotIndex][gisOfThatIteration.name] = d3.scaleLog()
              .domain([1, this.xAxisProps[plotIndex][gisOfThatIteration.name].max])
              .range([0, width / totalParallelPlotsAmountWarranted - margin.left - margin.right]);

            // ----- LOG SCALE MUST START WITH 1!!!! OTHERWISE IT WOULD BE FINIKY (Ending line.) -----
            xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[plotIndex][gisOfThatIteration.name]).tickValues(tickValuesArr).tickSize(tickHeight).tickFormat((d, i) => i % 2 === 0 ? Math.log(d).toExponential(0) : "");

          } else if (this.scalesFunctions[plotIndex][gisOfThatIteration.name] === "linear") {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].min == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].min = +((xMaxXMinOfGisOfThatIteration.xMin).toFixed(6));
            }
            if (this.xAxisProps[plotIndex][gisOfThatIteration.name].max == undefined) {
              this.xAxisProps[plotIndex][gisOfThatIteration.name].max = +((xMaxXMinOfGisOfThatIteration.xMax).toFixed(6));
            }
            // ----- auto-handle undefined x axis range (Ending line.) -----

            // ----- get exactly 13 ticks by getting exactly 12 spans (Starting line.) -----
            var tickSize = (this.xAxisProps[plotIndex][gisOfThatIteration.name].max - this.xAxisProps[plotIndex][gisOfThatIteration.name].min) / 13;
            var tickValuesArr = [];
            for (let i = 0; i <= 13; i++) {
              tickValuesArr.push(this.xAxisProps[plotIndex][gisOfThatIteration.name].min + tickSize * i);
            }
            // ----- get exactly 13 ticks by getting exactly 12 spans (Ending line.) -----

            x[plotIndex][gisOfThatIteration.name] = d3.scaleLinear()
              .domain([this.xAxisProps[plotIndex][gisOfThatIteration.name].min, this.xAxisProps[plotIndex][gisOfThatIteration.name].max])
              .range([0, width / totalParallelPlotsAmountWarranted - margin.left - margin.right]);
            // .nice();
            xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[plotIndex][gisOfThatIteration.name]).tickValues(tickValuesArr).tickSize(tickHeight);
          }
          //    ----- scalesFunctions (Ending line.) -----


          let xOffsetWarranted = commonYAxis !== null;
          if (xOffsetWarranted) {
            xAxis[gisOfThatIteration.name] = plot.append("g")
              .attr("class", "xAxis")
              // .attr("transform", "translate(" + ((plotIndex !== 0 ? margin.left : '') + width * (plotIndex / totalParallelPlotsAmountWarranted)) + ", 0)");
              .call(xAxisGenerator[gisOfThatIteration.name]);
          } else {
            xAxis[gisOfThatIteration.name] = plot.append("g")
              .attr("class", "xAxis2 xAxis" + plotIndex + gisIndex)
              .call(xAxisGenerator[gisOfThatIteration.name]);

          }

          // ----- set colors for axis grid and ticks labels (Starting line.) -----
          if (doesItIsFirstXAxisForThatPlotAndThusPendingSettingTicksAsGrid) {
            xAxis[gisOfThatIteration.name].selectAll("line")
              .attr("stroke", "#e1e1e1");
            let color = this.plotsColors[gisOfThatIteration.name.toLowerCase()];
            if (!color)
              color = this.plotsColors["default"];
            xAxis[gisOfThatIteration.name].selectAll("text")
              .attr("fill", color)
              .attr("style", "font-weight: 600;");
          } else {
            xAxis[gisOfThatIteration.name].selectAll("line")
              .attr("stroke", this.plotsColors[gisOfThatIteration.name]);
            xAxis[gisOfThatIteration.name].selectAll("text")
              .attr("fill", this.plotsColors[gisOfThatIteration.name])
              .attr("style", "font-weight: 600;");
          }
          // ----- set colors for axis grid and ticks labels (Ending line.) -----

          // ----- make starecase of plots (Starting line.) -----

          if (gisIndex + 1 !== 1) { // because column-reverse

            xAxis[gisOfThatIteration.name]
              .attr("style", `display:none;transform: translateY(-${gisIndex * (this.xAxisHeaderOffset + 34)}px)`);
            // ----- clone axes for header (Starting line.) -----

            let clonedXaxis = xAxis[gisOfThatIteration.name]._groups[0][0].cloneNode(true);
            // let clonedXaxis = document.querySelector('.xAxis' + plotIndex + gisIndex).cloneNode(true);
            clonedXaxis.setAttribute("style", "transform: translateY(30px);");
            this.clonedXaxes[plotIndex][gisOfThatIteration.name] = clonedXaxis.outerHTML;
            // ----- clone axes for header (Ending line.) -----
          } else { // bottommost exis
            xAxis[gisOfThatIteration.name]
              .attr("style", `transform: translateY(-${gisIndex * (this.xAxisHeaderOffset + 34)}px)`);
          }
          // ----- make starecase of plots (Ending line.) -----

          // ----- set mb if bigger than existing (if ohter plots have less or equal x axes than do nothing) (Starting line.) -----
          this.plotHeaderMarginBottom = this.plotHeaderMarginBottom < gisIndex * this.xAxisHeaderOffset + 40 ? gisIndex * this.xAxisHeaderOffset + 40 : this.plotHeaderMarginBottom;
          // ----- set mb if bigger than existing (if ohter plots have less or equal x axes than do nothing) (Ending line.) -----

          let isThatTimeWeSettingLastPlotOfWarrantedAmount = plotIndex + 1 == totalParallelPlotsAmountWarranted;
          if (isThatTimeWeSettingLastPlotOfWarrantedAmount && !scatterSetted) {
            this.firstPointInitialDepth = this.delta;
            this.bZoneItems.forEach((bZoneItem, zoneIndex) => {
              let element = document.querySelector(`.created-zones li[data-id="${zoneIndex}"]`);
              var boundaryUpper = [{x: 0, y: Number(bZoneItem.upperVal)}];
              var boundaryLower = [{x: 0, y: Number(bZoneItem.bottomVal)}];
              var translucentElHeight = y(boundaryUpper[0].y) - y(boundaryLower[0].y);
              var translucentElTopY = y(boundaryUpper[0].y);

              translucentColorFill[zoneIndex] = SVG.append("g")
                .attr("clip-path", "url(#allPlotsButScaleTicksYClipPlusZoneCol)");

              // ----- yeah it is approx with text dimesions equal 28 px height (Starting line.) -----
              let textX = this.plotWidth + 20;
              let textY = translucentElTopY + translucentElHeight / 2 + 28;
              // ----- yeah it is approx with text dimesions equal 28 px height (Ending line.) -----
              translucentColorFillText[zoneIndex] = translucentColorFill[zoneIndex]
                .append("g")
                .attr("class", "translucentColorFill__text")
                .style("transform", "rotate(-90deg)")
                .style("font-weight", "700")
                .style("font-size", "15px")
                .style("transform-origin", `${textX}px ${textY}px`);

              translucentColorFillText[zoneIndex]
                .append("text")
                .attr("x", textX)
                .attr("y", textY)
                .text(this.translatedLayerNames[zoneIndex]);

              translucentColorFill[zoneIndex]
                .selectAll("circle")
                .data(boundaryLower)
                .enter()
                .append("rect")
                .attr("x", 0)
                .attr("y", function (d) {
                  // ----- for info: these lines are a Fix for "bug" when zonename centering is not changing on drag and zonename flys away on changing input on bzone. (Starting line.) -----
                  let element = thisRef.bZoneItems[zoneIndex];
                  // ----- translucentColorFillText (Starting line.) -----
                  var translucentElHeight = y(element.upperVal) - y(element.bottomVal);
                  var translucentElTopY = y(d.y);
                  // ----- yeah it is approx with text dimesions equal 28 px height (Starting line.) -----
                  let textX = thisRef.plotWidth + 20;
                  let textY = translucentElTopY + translucentElHeight / 2 + 28;
                  // ----- yeah it is approx with text dimesions equal 28 px height (Ending line.) -----
                  translucentColorFillText[zoneIndex]
                    .style("transform-origin", `${textX}px ${textY}px`);
                  translucentColorFillText[zoneIndex].select("text")
                    .attr("y", textY);
                  // ----- for info: these lines are a Fix for "bug" when zonename centering is not changing on drag and zonename flys away on changing input on bzone. (Ending line.) -----

                  return y(d.y);
                })
                .attr("data-id", zoneIndex)
                .attr("class", "translucentColorFill translucentColorFill" + zoneIndex)
                .attr("height", function (d) {
                  var boundaryUpper = {x: 0, y: Number(bZoneItem.upperVal)};
                  var boundaryLower = {x: 0, y: Number(bZoneItem.bottomVal)};
                  return y(boundaryUpper.y) - y(boundaryLower.y);
                })
                .attr("width", this.plotWidth + 40)
                .style("fill", this.colorsOfZones[zoneIndex % (this.colorsOfZones.length)])
                .style("pointer-events", "none")
                .style("opacity", 0.55);


              translucentColorFill[zoneIndex].selectAll(".user-zone-" + zoneIndex).call(d3.drag()
                .on("drag", userZoneDrag1)
              );

              zoneBoundaryTop[zoneIndex] = SVG.append("g")
                .attr("clip-path", "url(#allPlotsButScaleTicksYClipPlusZoneCol)");

              zoneBoundaryTop[zoneIndex]
                .selectAll("circle")
                .data(boundaryUpper)
                .enter()
                .append("rect")
                .attr("x", 0)
                .attr("y", function (d) {
                  return y(d.y);
                })
                .attr("class", "user-zone-boundary-1")
                .attr("data-id", zoneIndex)
                .attr("height", this.userZoneBoundaryBarHeight)
                .attr("width", this.plotWidth + 40)
                .style("fill", this.colorsOfZones[zoneIndex % (this.colorsOfZones.length)])
                .style("cursor", "grab")
                .style("opacity", 0.875);

              zoneBoundaryTop[zoneIndex].selectAll(".user-zone-boundary-1").call(d3.drag()
                .on("drag", userZoneDrag1)
              );

              zoneBoundaryBottom[zoneIndex] = SVG.append("g")
                .attr("clip-path", "url(#allPlotsButScaleTicksYClipPlusZoneCol)");

              zoneBoundaryBottom[zoneIndex]
                .selectAll("circle")
                .data(boundaryLower)
                .enter()
                .append("rect")
                .attr("x", 0)
                .attr("y", function (d) {
                  return y(d.y);
           
                })
                .attr("class", "user-zone-boundary-2")
                .attr("height", this.userZoneBoundaryBarHeight)
                .attr("width", this.plotWidth + 40)
                .attr("data-id", zoneIndex)
                // .style("fill", "blue")
                .style("fill", this.colorsOfZones[zoneIndex % (this.colorsOfZones.length)])
                .style("cursor", "grab")
                .style("opacity", 0.875);

              zoneBoundaryBottom[zoneIndex].selectAll(".user-zone-boundary-2").call(d3.drag()
                .on("drag", userZoneDrag2)
              );
            });

            scatterSetted = true;

          }
          
          var line = d3.line()
            .defined(d => !isNaN(d.x))
            .x(d => x[plotIndex][gisOfThatIteration.name](d.x))
            .y(d => y(d.y));


          path[plotIndex][gisOfThatIteration.name] = plot.append("path")
            .datum(linesDataObj[gisOfThatIteration.name])
            .attr("class", "path " + gisOfThatIteration.name + "-line")
            .attr("fill", "none")
            .attr("clip-path", "url(#plotBoundaryClip)")
            .attr("stroke", plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"])
            .attr("stroke-width", 1.5)
            .attr("d", line);

          var componentContext = this;

        });
        var yScaler = y;
        this.$store.commit("setAxisIndexBindingInGeology", this.gisAxisIndexBinding);

      }

      var store = this.$store;
      this.updatingChart = false;

      function updateChart(e) {

        thisRef.updatingChart = true;
        if (d3.event.sourceEvent.type === "wheel") {
          if (d3.event.sourceEvent.deltaY > 0) {
            d3.event.transform.y += 150;
          } else {
            d3.event.transform.y -= 150;
          }
        } else {
          yPanFromOriginal = d3.event.transform.y;
        }
        if (d3.event.transform.k !== 1) {
          d3.event.transform.k = 1;
        }

        var newY = d3.event.transform.rescaleY(y);


        thisRef.currentPlotScrollY = newY.invert(0);
        yAxis.call(
          d3.axisLeft(newY)
            .ticks(9)
            .tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5))
        );

        for (let plotIndex = 0; plotIndex < totalParallelPlotsAmountWarranted; plotIndex++) {
          for (let i = 0; i < thisRef.gisesOfPlot[plotIndex].length; i++) {

            let gisOfThatIteration = thisRef.gisesOfPlot[plotIndex][i];

            path[plotIndex][gisOfThatIteration.name].attr("transformY", d3.event.transform.y);

            var line = d3.line()
              .defined(d => !isNaN(d.x))
              .x(d => x[plotIndex][gisOfThatIteration.name](d.x))
              .y(d => newY(d.y));

            path[plotIndex][gisOfThatIteration.name]
              .datum(linesDataObj[gisOfThatIteration.name])
              .attr("class", "path")
              .attr("fill", "none")
              .attr("clip-path", "url(#plotBoundaryClip)")
              .attr("stroke", plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"])
              .attr("stroke-width", 1.5)
              .attr("d", line);
          }
        }
        thisRef.bZoneItems.forEach((element, zoneIndex) => {
          let bothValsSettedInThisBZZoneItem = element.upperVal && element.bottomVal;
          if (bothValsSettedInThisBZZoneItem) {
            translucentColorFill[zoneIndex]
              .selectAll(".translucentColorFill")
              .attr("y", function (d) {
                // ----- translucentColorFillText (Starting line.) -----
                var translucentElHeight = newY(element.upperVal) - newY(element.bottomVal);
                var translucentElTopY = newY(d.y);
                // ----- yeah it is approx with text dimesions equal 28 px height (Starting line.) -----
                let textX = thisRef.plotWidth + 20;
                let textY = translucentElTopY + translucentElHeight / 2 + 28;
                // ----- yeah it is approx with text dimesions equal 28 px height (Ending line.) -----
                translucentColorFillText[zoneIndex]
                  .style("transform-origin", `${textX}px ${textY}px`);
                translucentColorFillText[zoneIndex].select("text")
                  .attr("y", textY);
                // ----- translucentColorFillText (Ending line.) -----

                return newY(d.y);
                // return newY(d.y - (lastUseBoundaryIndex === 2 ? userSelectZoneHeight : 0));
              });

            zoneBoundaryTop[zoneIndex]
              .selectAll(".user-zone-boundary-1")
              .attr("y", function (d) {
                return newY(d.y);
              });
            zoneBoundaryBottom[zoneIndex]
              .selectAll(".user-zone-boundary-2")
              .attr("y", function (d) {
                return newY(d.y);
              });
          }
        });

        yScaler = newY;

        thisRef.updatingChart = false;
        return;
      }

      function userZoneDrag2(d) {
        (userZoneDragGeneric.bind(this))(2, d);
        lastUseBoundaryIndex = 2;
      }

      function userZoneDrag1(d) {
        (userZoneDragGeneric.bind(this))(1, d);
        lastUseBoundaryIndex = 1;
      }

      function userZoneDragGeneric(indexOfZoneBoundary, d) {
        var idOfZone = this.getAttribute("data-id");
        const before = Number(yScaler.invert(d3.select(this).attr("y")));
        const after = Number(yScaler.invert(d3.mouse(this)[1]));
        const delta = after - before;

        // ----- Part of relativity calculation (Starting line.) -----
        if (indexOfZoneBoundary === 2) {
          userSelectZoneHeight += delta;
        }
        if (indexOfZoneBoundary === 1) {
          userSelectZoneHeight += delta;
        }
        // ----- Part of relativity calculation (Ending line.) -----

        SVG.select(".user-zone-boundary-" + indexOfZoneBoundary + "[data-id=\"" + idOfZone + "\"]").attr("y", function (d) {
          const before = Number(yScaler.invert(d3.select(this).attr("y") - 0.5 * thisRef.userZoneBoundaryBarHeight));

          if (indexOfZoneBoundary === 2) {

            var isBottomUselectBoundaryOnTopOfBottomOrEqual = (Number(before + delta)) < Number(thisRef.bZoneItems[idOfZone].upperVal) - 0.1;
          }
          if (indexOfZoneBoundary === 1) {
            var isBottomUselectBoundaryOnTopOfBottomOrEqual = Number(thisRef.bZoneItems[idOfZone].bottomVal) < (Number(before + delta)) - 0.1;
          }
          
          if (isBottomUselectBoundaryOnTopOfBottomOrEqual) {

            d.y = Number(before + delta);
            if (indexOfZoneBoundary === 2) {
              document.querySelector(".secondBoundaryPositionY").innerText = d.y;
              thisRef.bZoneItems[idOfZone].bottomVal = Number(d.y).toFixed(1);
            }
            if (indexOfZoneBoundary === 1) {
              document.querySelector(".firstBoundaryPositionY").innerText = d.y;
              thisRef.bZoneItems[idOfZone].upperVal = Number(d.y).toFixed(1);
            }
            // ----- handle translucentColorFill (Starting line.) -----
            SVG.select(`.translucentColorFill[data-id="${idOfZone}"]`)
              .attr("height", function (d) {
                var boundaryUpper = {x: -10, y: Number(thisRef.bZoneItems[idOfZone].upperVal)};
                var boundaryLower = {x: -10, y: Number(thisRef.bZoneItems[idOfZone].bottomVal)};
                return y(boundaryUpper.y) - y(boundaryLower.y);
              })
              .attr("y", function (d) {
                let element = thisRef.bZoneItems[idOfZone];
                // ----- translucentColorFillText (Starting line.) -----
                var translucentElHeight = yScaler(element.upperVal) - yScaler(element.bottomVal);
                var translucentElTopY = yScaler(d.y);
                // ----- yeah it is approx with text dimesions equal 28 px height (Starting line.) -----
                let textX = thisRef.plotWidth + 20;
                let textY = translucentElTopY + translucentElHeight / 2 + 28;
                // ----- yeah it is approx with text dimesions equal 28 px height (Ending line.) -----
                translucentColorFillText[idOfZone]
                  .style("transform-origin", `${textX}px ${textY}px`);
                translucentColorFillText[idOfZone].select("text")
                  .attr("y", textY);
                // ----- translucentColorFillText (Ending line.) -----
                return yScaler(d.y) - 0.5 * thisRef.userZoneBoundaryBarHeight;
              });
            // ----- handle translucentColorFill (Ending line.) -----

            return yScaler(d.y) - 0.5 * thisRef.userZoneBoundaryBarHeight;
          } else {
            return yScaler(d.y) - 0.5 * thisRef.userZoneBoundaryBarHeight;
          }

        });

      }

      document.querySelector("#graph-wrapper").addEventListener("click", function (e) {
        // ----- hide all dropdowns (Starting line.) -----
        document.querySelectorAll("h4.now-active").forEach((element) => {
          element.classList.remove("now-active");
        });
        // ----- hide all dropdowns (Ending line.) -----
      });

      removeAllActvesIfClickExaclyAtElWithQS(".graph-column");
      removeAllActvesIfClickExaclyAtElWithQS(".plot-header");

      function removeAllActvesIfClickExaclyAtElWithQS(qs) {
        document.querySelector(qs).addEventListener("click", function (e) {
          if (e.target === document.querySelector(qs)) {
            // ----- hide all dropdowns (Starting line.) -----
            document.querySelectorAll("h4.now-active").forEach((element) => {
              element.classList.remove("now-active");
            });
            // ----- hide all dropdowns (Ending line.) -----
          }
        });
      }

      this.$forceUpdate(); // necessary fot v-html to properly update
      return;

    },
  }
};
</script>

<style scoped lang="scss">

.main-container {
  margin-top: 80px;
  margin-left: 0;
}

.delta-save-button {
  background: #18A0FB;
  border-radius: 12px;
  color: white;
  font-size: 1.5rem;
  padding: 1rem 2rem 1rem 2rem;
}

#b-zone button {
  margin: 10px 0;
  margin-right: 10px;

}

#b-zone {

  height: auto;
  padding: 30px;
  max-width: 524px;
  border-left: 2px solid grey;
  font-size: 14px;
  padding-right: 15px; // because scrollbar
}

.created-zones .created-zones__header span,
.created-zones ol li > * {
  padding: 10px;
  display: inline-block;
  border: 1px solid lightgrey;
  width: 24.7%;
}

main button {
  background: #18A0FB;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  padding: 10px 26px;
  transition: all .2s ease-in-out;
}

main button:hover {
  background: #a5a5a575;
  color: #18A0FB;
}

.created-zones ol {
  counter-reset: zoneIndex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.created-zones li {
  position: relative;
}

.created-zones input {
  width: 100%;
}

.created-zones {
  width: 100%;
  background: #fff;
}

.created-zones .created-zones__header::before {
  content: '0';
  color: transparent;
  user-select: none;
}

.created-zones .created-zones__header::before,
.created-zones li::before {
  width: 10%;
  display: inline-block;
  text-align: center;
  border: 1px solid lightgrey;
  padding: 10px;
}

.created-zones li::before {
  counter-increment: zoneIndex +1;
  content: counter(zoneIndex);
}


.plot-header {
  display: flex;
  flex-wrap: wrap;
  // padding-left: 15px;
  margin-top: 20px;
  // background: #fff;
  z-index: 2;
  //  z-index:-1;
  position: relative;
}

.plot-header article {
  flex: 1;
  box-sizing: border-box;
}

.plot-header article:not(:last-of-type) {
  padding-right: 30px;
}

.scale-container {
  text-align: center;
  transform: rotate(270deg);
}


.dropdown label,
.scale,
.plot-selects h4 {
  cursor: pointer;
}

.dropdown {
  min-width: 100px;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: all .4s;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 15px;
  padding-right: 5px; // Bacuse scrollbar is 10px thus 15-10=5
  box-sizing: border-box;
  z-index: -1;
  pointer-events: none;
  border: 1px solid grey;
  border-radius: 5px;
  max-height: 83vh;
  overflow-y: auto;
}

/* width */
.dropdown::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
.dropdown::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);
  cursor: grab;
}

/* Handle on hover */
.dropdown::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 105);
}

h4.now-active + .dropdown {
  opacity: 1;
  z-index: 99;
  pointer-events: all;
}

.plot-selects {
  display: flex;
  justify-content: space-around;
}

.plot-selects article {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 50px;
}

#graph-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 10%;
}

.dropdown-container {
  position: relative;
}

.scales-selects label,
.scales-selects input {
  font-size: 13px;
}

.scales-selects {
  display: flex;
  flex-direction: column;
  width: 100%
}

.plot-selects button {
  padding: 5px 15px;
  font-size: 14px;
  margin-left: 10px;
}

.plot-selects article {
  padding: 10px;
  padding-left: 0;
}

.to-hide {
  display: none !important;
}

.plots-chooser input[type="number"] {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}

.created-zones input[type="number"] {
  outline: 1px solid black;
  border-radius: 5px;
  padding-left: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.created-zones .created-zones__header::before,
.created-zones li::before {
  width: 16%;
  display: inline-block;
  text-align: center;
  border: 1px solid lightgrey;
  padding: 10px;
  padding-left: 40px;
}

.created-zones .created-zones__header::before {
  content: 'Группа';
  padding-left: 10px;
}

.created-zones li::before {
  counter-increment: zoneIndex +1;
  content: counter(zoneIndex);
}


.created-zones input[type="number"] {
  outline: 1px solid black;
  border-radius: 5px;
  padding-left: 5px;
}

.created-zones li .color {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  left: 7px;
  top: 48%;
  transform: translateY(-50%);
}

.remove-btn,
.add-btn {
  box-sizing: border-box;
  width: 40px !important;
  margin: 2px 5px !important;
  margin-right: 0 !important;
  padding: 10px !important;
  height: 32px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  // font-weight: 900;
  border: none !important;
}

.add-btn {
  width: 90px !important;
  margin-right: 10% !important;
  margin-top: 10px !important;
}

.add-btn__disabled {
  background: #a5a5a575;
}
.add-btn__disabled:hover {
  color: #fff;
}

.created-zones__controls {
  display: flex;
  justify-content: flex-end;
}

select.naming {
  cursor: pointer;
}

.plots-choosers {
  z-index: 2;
}
</style>