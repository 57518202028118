import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "@/plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import Notifications from "vue-notification";
import * as THREE from "three";
import VueApexCharts from "vue-apexcharts";
import VueMq from "vue-mq";

Vue.config.productionTip = false;
// ----- Suppress all Vue logs and warnings. (Starting line.) -----
Vue.config.silent = true;
// ----- Suppress all Vue logs and warnings. (Ending line.) -----

Vue.use(Notifications);

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  }
});

Vue.prototype.scene = new THREE.Scene();
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Vue.use(VueApexCharts);

Vue.component("Apexchart", VueApexCharts);


