import axios from "axios";
import auth from "@/http/auth";
import router from "@/router";
import { BASE_PORT, BASE_URL, HTTP } from "./base";

const config = {
  baseURL: process.env.VUE_APP_ON_PROD ? `${HTTP}://${BASE_URL}/` : `${HTTP}://${BASE_URL}:${BASE_PORT}/`,
  withCredentials: true
};

const httpClient = axios.create(config);

httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const { config, response } = err;

    if (response.status !== 401) {
      return Promise.reject(err);
    }

    await auth.post("refresh/").catch(async err => {
      localStorage.setItem("authorized", JSON.stringify(false));
      await router.push({ name: "login" });
      return Promise.reject(err);
    });
    return httpClient(config);
  }
);

export default httpClient;