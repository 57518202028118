<template>
  <div>
    <v-container
      fluid
    >
      <mq-layout mq="mobile">
        <mobile-menu>
          <v-row class="main-section-mobile justify-center align-center"> 
            <v-row class="justify-center align-center">
              <v-col>
                <img
                  src="@/assets/main-page-logo/logo1.png"
                  alt=""
                  class="logo-image-mobile"
                >
              </v-col>
              <v-col>
                <a href="https://geopy.pro/">
                  <img
                    src="@/assets/main-page-logo/logo3.png"
                    alt=""
                    class="logo-image-mobile"
                    style="height: 25vw"
                  >
                </a>
              </v-col>
            </v-row>
            <v-row class="slogan-text-mobile">
              {{ $t("main.slogan") }}
            </v-row>
            <v-row class="justify-center pa-5">
              <img
                src="@/assets/main-page-logo/main.png"
                alt=""
                class="map-image"
              >
            </v-row>
          </v-row>
          <v-row
            class="button-wrapper-mobile"
          >
            <button
              class="start-button-mobile"
              @click="start"
            >
              {{ $t("main.hello") }}
            </button>
          </v-row>
          <v-row class="under-development-mobile justify-center  pb-10">
            <v-col
              cols="auto"
            >
              {{ $t("main.release") }} {{ release }}
            </v-col>
          </v-row>
        </mobile-menu>
      </mq-layout>
      <mq-layout mq="tablet+">
        <desktop-menu>
          <v-row class="main-section justify-center align-center">
            <v-col cols="12">
              <v-container fluid>
                <v-row class="justify-center align-center">
                  <v-col cols="3">
                    <img
                      src="@/assets/main-page-logo/logo1.png"
                      alt=""
                      class="logo-image"
                    >
                  </v-col>
                  <v-col cols="3">
                    <a href="https://geopy.pro/">
                      <img
                        src="@/assets/main-page-logo/logo3.png"
                        alt=""
                        class="logo-image"
                        style="height: 10vw"
                      >
                    </a>
                  </v-col>
                </v-row>
                <v-row class="justify-center slogan-text">
                  <v-col
                    cols="auto"
                    class="pt-10"
                  >
                    {{ $t("main.slogan") }}
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="5">
                    <img
                      src="@/assets/main-page-logo/main.png"
                      alt=""
                      class="map-image"
                    >
                  </v-col>
                  <v-col
                    class="button-wrapper"
                  >
                    <button
                      class="start-button"
                      @click="start"
                    >
                      {{ $t("main.hello") }}
                    </button>
                  </v-col>
                </v-row>
                <v-row class="justify-center under-development pb-10">
                  <v-col
                    cols="auto"
                  >
                    {{ $t("main.release") }} {{ release }}
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </desktop-menu>
      </mq-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "StartPageView",
  data() {
    return {
      release: process.env.VUE_APP_RELEASE
    };
  },

  methods: {
    start() {
      this.$router.push({name: "episodes"});
    }
  }
};
</script>

<style scoped lang="scss">

.main-section {
  padding-top: 80px;
  height: calc(100vh - 120px);

  &-mobile {
    padding-top: 150px;
  }
}

.start-page-image-wrapper {
  position: relative;
}

.start-page-image {
  max-width: 50vw;
  max-height: 60vh;
}

.slogan-text {
  font-weight: bold;
  color: #4d4b4b;
  font-size: 4vw;

  &-mobile {
    font-size: 9vw;
    text-align: center;
    justify-content: center;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;

  &-mobile {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }
}

.start-button {
  font-size: 5vw;
  font-weight: bold;
  color: #025ba4;
  transition: .4s;
  text-transform: uppercase;

  &:hover {
    color: #053a64;
    transform: scale(1.1);
  }
}

.start-button-mobile {
  font-size: 15vw;
  font-weight: bold;
  color: #025ba4;
  transition: .4s;
  text-transform: uppercase;


}


.logo-image {
  width: auto;
  display: block;
  height: 6vw;
  margin: auto;

  &-mobile {
    height: 15vw;
    margin: 10px;
  }
}

.map-image {
  width: 100%;
}


.logo-description {
  font-weight: 600;
}

.under-development {
  font-size: 1vw;
  font-weight: bold;
  color: #4d4b4b;
  text-transform: uppercase;

  &-mobile {
    font-size: 4vw;
    font-weight: bold;
    color: #4d4b4b;
    text-transform: uppercase;
  }
}

</style>