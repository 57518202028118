import httpClient from "@/http";

class UserService {
  getUserDetails() {
    return httpClient.get(
      httpClient.defaults.baseURL.replace("/rest", "") + "user/details/"
    );
  }
  getUserProfile() {
    return httpClient.get(
      httpClient.defaults.baseURL.replace("/rest", "") + "user/profile/"
    );
  }
}

export default new UserService();