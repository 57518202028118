<template>
  <v-container
    class="main-container"
  >
    <v-table>
      <h1>{{ $t("rating.userRating") }}</h1> <br>
      <thead>
        <tr>
          <th class="text-primary pa-1">
            {{ $t("rating.place") }}
          </th>
          <th class="text-primary pa-1">
            {{ $t("rating.user") }}
          </th>
          <th class="text-primary pa-1">
            {{ $t("rating.geology") }}
          </th>
          <th class="text-primary pa-1">
            {{ $t("rating.petrophysics") }}
          </th>
          <th class="text-primary pa-1">
            {{ $t("rating.total") }}
          </th>
          <th class="text-primary pa-1">
            {{ $t("rating.date") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in ratings"
          :key="item.user"
        >    
          <td class="pa-1 text-center">
            {{ index + 1 }}
          </td>
          <td class="pa-1 text-center">
            {{ item.user.first_name }} {{ item.user.last_name }}
          </td>
          <td class="pa-1 text-center">
            {{ item.score_geology.toFixed(3) }}
          </td>
          <td class="pa-1 text-center">
            {{ item.score_petrophysics.toFixed(3) }}
          </td>
          <td class="pa-1 text-center">
            {{ item.overall_score.toFixed(3) }}
          </td>
          <td class="pa-1 text-center">
            {{ formatDate(item.modified) }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-container>
</template>

<script>
import RatingService from "@/service/rating-service";
import moment from "moment";

export default {
  name: "RatingsView",
  data(){
    return {
      ratings: [],
    };
  },
  mounted() {
    RatingService.getRatings().then(({data}) => {
      this.ratings = data.sort((a, b) => b.overall_score -a.overall_score );
    });
  },
  methods: {
        
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      };
    }
  },
};

</script>

<style scoped lang="scss">

.main-container {
  margin-top: 80px;
  margin-left: 0;
}

table, th, td {
    border:1px solid black;
    }

</style>