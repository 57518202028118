import httpClient from "@/http";

class SurfaceService {
  getSurface(surface_name) {
    return httpClient.get(`/api/v1/geology/surface/${surface_name}/`);
  }

  getAllSurfaces(){
    return httpClient.get("/api/v1/geology/list-surface-names/");
  }

  runSurfaceModule(selected_layer) {
    return httpClient.post(`/api/v1/geology/run/surface/${selected_layer}/`);
  }

  getResults(task_id, selected_layer) {
    return httpClient.get(`/api/v1/geology/run/save-surface/${task_id}/${selected_layer}/`);
  }

  getTaskStatus(task_id){
    return httpClient.get(`/api/v1/geology/task/${task_id}`);
  }
}

export default new SurfaceService();