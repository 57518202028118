<template>
  <v-container class="">
    <v-col>
      <v-row><h1>{{ $t("user-profile.userInfo") }}</h1> </v-row>
      <v-row class="pt-7">
        Email:&nbsp; <strong> {{ user_data.user_email }} </strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.total_wells") }}&nbsp;  <strong>{{ user_data.total_wells }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.cored_wells") }} &nbsp; <strong>{{ user_data.cored_wells }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.well_logs") }} &nbsp; <strong>{{ user_data.well_logs_created }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.well_tops") }} &nbsp; <strong>{{ user_data.well_tops_created }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.surfaces") }} &nbsp; <strong>{{ user_data.surfaces }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.core_delta_groups") }} &nbsp; <strong>{{ user_data.core_delta_groups }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.reserves_calculated") }} &nbsp; <strong>{{ user_data.reserves_calculated }}</strong>
      </v-row>
      <v-row class="pt-2">
        {{ $t("user-profile.owcs") }} &nbsp; <strong>{{ user_data.owcs }}</strong>
      </v-row>
      <v-row class="pt-2">
        <button
          class="module__btn"
          @click="delete_results"
        >
          {{ $t("user-profile.deleteResults") }}
        </button>
      </v-row>
      <v-row class="pt-7">
        <h2>{{ $t("user-profile.achievements") }}</h2>
      </v-row>
      <v-row class="pt-7">
        <h2>{{ $t("user-profile.accountManagement") }}</h2>
      </v-row>
      <v-row class="pt-2">
        <router-link to="/change-password">
          {{ $t("user-profile.changePassword") }}
        </router-link> 
      </v-row>
      <v-row class="pt-2">
        <button
          class="module__btn"
          @click="user_logout"
        >
          {{ $t("user-profile.logout") }}
        </button>
      </v-row>
    </v-col>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="4000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import UserService from "@/service/user-service";
import authService from "@/service/auth-service";
import moment from "moment";

export default {
  name: "UserProfileView",
  data(){
    return {
      user_data: [],
      snackbar: {
        show: false,
        color: null,
        message: "",
      }
    };
  },
  mounted() {
    UserService.getUserProfile().then(({data}) => {
      this.user_data = data;
    });
  },
  
  methods: {
        
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      };
    },

    user_logout() {
      authService.logout().then(() => {
        this.$router.push({name: "login"});
      });
    },

    delete_results() {
      authService.deleteResults().then(async (response) => {
        this.snackbar = {
          show: true,
          message: this.$t("user-profile.historyDeleted"),
          color: "success"
        };
      }).catch(err => {
        this.snackbar = {
          show: true,
          message: this.$t("common.error"),
          color: "error"
        };
      });;
    }
  },
        
};

</script>

<style scoped lang="scss">

.container{
  margin-top: 80px;
}

table, th, td {
    border:1px solid black;
    }
.module {
  &__title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__description {
    font-size: 1.2rem;
    margin-bottom: .2rem;
    min-height: 100px;
    text-align: justify;
  }

  &__btn {
    color: #18A0FB;
    border: 1px solid #18A0FB;
    border-radius: 6px;
    padding: .5rem 2rem;
    transition: .2s;


    &:hover {
      transform: scale(1.1);
    }

    &-disabled {
      color: #B9B9B980;
      border: 1px solid #B9B9B980;
      border-radius: 6px;
      padding: .5rem 2rem;
    }
  }
}
</style>