import httpClient from "@/http";

class WellService {
  getGisData(wellId) {
    return httpClient.get(`/api/v1/geology/well/${wellId}/gis/`);
  }

  getOwcGisData(wellId, zone_name) {
    return httpClient.get(`/api/v1/geology/well/${wellId}/owc_gis/${zone_name}`);
  }

  getGisAll(gisName) {
    return httpClient.get(`/api/v1/geology/well/all_wells/${gisName}/`);
  }
  getAllWells() {
    return httpClient.get("/api/v1/geology/well/");
  }
  getGisName() {
    return httpClient.get("/api/v1/geology/list-well-logs");
  }
    
  getJustWells() {
    return httpClient.get("/api/v1/geology/list-wells/");
  }
    
  setWelltop(data) {
    return httpClient.post("/api/v1/geology/well/welltop/", data);
  }

  deleteWelltop(id) {
    return httpClient.delete(`/api/v1/geology/well/welltop/${id}`);
  }

  getInteractiveWells() {
    return httpClient.get("/api/v1/geology/well/interactive/");
  }

  getActiveWells() {
    return httpClient.get("/api/v1/geology/well/active/");
  }

  getWellWithCores() {
    return httpClient.get("/api/v1/geology/well/cored/");
  }

  getCores(wellId) {
    return httpClient.get(`/api/v1/geology/well/${wellId}/cores/`);
  }

  calcGisData(data) {
    return httpClient.post("/api/v1/geology/calc-gis/", data);
  }
    
  getGisAllLayer(gisName, layerName) {
    return httpClient.get(`/api/v1/geology/well/all_wells_layer/${gisName}/${layerName}/`);
  }
}

export default new WellService();