import httpClient from "@/http";

class CalcService {

  /**
	 * По уравнению регресии
	 * well_id - id скважины у на гисах и керне которой посчитать коэффициенты регрессии (required)
	 * gis_name - имя кривой, по которой будет считаться регрессия
	 * core_name - имя керна, по которой будет считаться регрессия
	 * for_all_wells - если true, то кривая посчитается для всех скважен, 
	 *  иначе только для скважины с id = well_id. Если этот параметр не передавать, то будет считать для одной скважины.
	 * 
	 * @param {well_id: Number, gis_name: String, core_name: String, for_all_wells: bolean} data 
	 * @returns Promise
	 */
  calcCporRegression(data) {
    return httpClient.post("/api/v1/petrophysics/calc-poro/regression", this.clearParams(data));
  }
	
  /**
	 * По нейтронному каротажу
	 * well_id -  id скважины для которой нужно расчитать пористость. Если не передать данный параметр, то расчет будет произведен для всех скважин.
	 * 
	 * @param {well_id: Number} data 
	 * @returns Promise
	 */
  calcCporNgk(data) {
    return httpClient.post("/api/v1/petrophysics/calc-poro/ngk", this.clearParams(data));
  }
	
  /**
	 * По акустическому каротажу
	 * well_id -  id скважины для которой нужно расчитать пористость. Если не передать данный параметр, то расчет будет произведен для всех скважин.
	 * 
	 * @param {well_id: Number} data 
	 * @returns Promise
	 */
  calcCporDt(data) {
    return httpClient.post("/api/v1/petrophysics/calc-poro/dt", this.clearParams(data));
  }
	
  /**
	 * По заданному линейному уравнению
	 * well_id -  id скважины для которой нужно расчитать пористость. Если не передать данный параметр, то расчет будет произведен для всех скважин.
	 * gis_name - имя гиса, по которому будет считаться пористость
	 * k - коэффициент k
	 * b - коэффициент b
	 * 
	 * @param {well_id: Number} data 
	 * @returns Promise
	 */
  calcCporLinearEquation(data) {
    return httpClient.post("/api/v1/petrophysics/calc-poro/linear-equation", this.clearParams(data));
  }
	
  /**
	 * По заданному экспоненциальному уравнению
	 * log_name
	 * well_id -  id скважины для которой нужно расчитать пористость. Если не передать данный параметр, то расчет будет произведен для всех скважин.
	 * gis - имя гиса, по которому будет считаться пористость
	 * k - коэффициент k
	 * a - коэффициент a
	 * b - коэффициент b
	 * 
	 * @param {well_id: Number} data 
	 * @returns Promise
	 */
  calcCporExponentialEquation(data) {
    return httpClient.post("/api/v1/petrophysics/calc-poro/exponential-equation", this.clearParams(data));
  }
	
  /**
	 * По заданному линейному уравнению
	 * well_id -  id скважины для которой нужно расчитать пористость. Если не передать данный параметр, то расчет будет произведен для всех скважин.
	 * a - коэффициент a, по дефолту будет 1
	 * n - коэффициент n, по дефолту будет 2
	 * m - коэффициент m, по дефолту будет 2
	 * 
	 * @param {well_id: Number} data 
	 * @returns Promise
	 */
  calcSaturation(data) {
    return httpClient.post("/api/v1/petrophysics/calc-satur", this.clearParams(data));
  }

  calcPermRegression(data) {
    return httpClient.post("/api/v1/petrophysics/calc-perm/regression", this.clearParams(data));
  }

  calcPermLinearEquation(data) {
    return httpClient.post("/api/v1/petrophysics/calc-perm/linear-equation", this.clearParams(data));
  }

  calcPermExponentialEquation(data) {
    return httpClient.post("/api/v1/petrophysics/calc-perm/exponential-equation", this.clearParams(data));
  }


  clearParams(data) {
    for(const [key, value] of Object.entries(data)) {
      if(value === null) {
        delete data[key];
      }
    }
    return data;
  }
}

export default new CalcService();