<template>
  <v-container
    fluid
    style=" margin-top: 80px;"
  >
    <div>
      <v-dialog
        v-model="showDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("petrophysics.matching-pop-up.title-1") }} <br> 
            {{ $t("petrophysics.matching-pop-up.title-2") }}
          </v-card-title>

          <v-card-text>
            {{ $t("petrophysics.matching-pop-up.text-1") }}<span class="font-weight-bold">{{ $t("petrophysics.matching-pop-up.text-2") }}</span> {{ $t("petrophysics.matching-pop-up.text-3") }}
          </v-card-text>

          <v-card-actions>
            <v-checkbox
              v-model="dontShowAgain"
            />
            <span>{{ $t("common.dont-show") }}</span>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="closeDialog"
            >
              {{ $t("common.ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row>
      <v-col
        ref="graph-column"
        class="graph-column pa-0"
      >
        <div class="to-hide">
          userSelectZoneHeight: <span id="userSelectZoneHeight" />
        </div>
        <div class="to-hide">
          activeBZoneItem: <span class="firstBoundaryPositionY">{{ activeBZoneItem }}</span>
        </div>
        <div class="to-hide">
          firstBoundaryPositionY: <span
            class="firstBoundaryPositionY"
          >{{ firstBoundaryPositionY }}</span>
        </div>
        <div class="to-hide">
          secondBoundaryPositionY: <span
            class="secondBoundaryPositionY"
          >{{ secondBoundaryPositionY }}</span>
        </div>

        <div class="plot-header test2">
          <div
            v-if="gisAxisIndexBinding !== undefined"
            class="plot-selects"
          >
            <article
              v-for="axisIndexPlusOne in 1"
              :key="'plots-chooser-' + axisIndexPlusOne"
              :class="'plots-chooser' + ' plots-chooser-' + axisIndexPlusOne"
            >
              <h4>
                <button
                  id="setup-track-btn"
                  @click="toggleNowActiveQS('.plots-chooser-' + axisIndexPlusOne + ' h4')"
                >
                  {{ $t("common.configureTrack") }}
                </button>
              </h4>

              <div class="dropdown">
                <h5>{{ $t("common.scale") }}:</h5>
                <label>
                  <input
                    v-if="scalesFunctions[axisIndexPlusOne - 1] === 'log'"
                    id=""
                    checked
                    type="radio"
                    :name="'scale-radio-' + axisIndexPlusOne"
                    @change="setScale(axisIndexPlusOne - 1, 'log'); notifyScaleChanges()"
                  >
                  <input
                    v-else
                    id=""
                    type="radio"
                    :name="'scale-radio-' + axisIndexPlusOne"
                    @change="setScale(axisIndexPlusOne - 1, 'log'); notifyScaleChanges()"
                  >
                  {{ $t("common.scale-log") }}
                </label>
                <label>
                  <input
                    v-if="scalesFunctions[axisIndexPlusOne - 1] === 'linear'"
                    id=""
                    checked
                    type="radio"
                    :name="'scale-radio-' + axisIndexPlusOne"
                    @change="setScale(axisIndexPlusOne - 1, 'linear'); notifyScaleChanges()"
                  >
                  <input
                    v-else
                    id=""
                    type="radio"
                    :name="'scale-radio-' + axisIndexPlusOne"
                    @change="setScale(axisIndexPlusOne - 1, 'linear'); notifyScaleChanges()"
                  >
                  {{ $t("common.scale-linear") }}
                </label>
                <!-- ----- scatter ad different visualization phenomenon (Starting line.) ----- -->
                <h5
                  style="margin-top: 15px;"
                  class=""
                >
                  {{ $t("common.core") }}:
                </h5>
                <label
                  v-for="(gisAxisBindingIndex, gisName) in gisAxisIndexBinding"
                  :key="gisName"
                  class="to-hide"
                >
                  <input
                    v-if="gisAxisBindingIndex !== null && gisAxisBindingIndex + 1 === axisIndexPlusOne"
                    :data-axis-index="axisIndexPlusOne - 1"
                    type="checkbox"
                    checked
                    :name="gisName"
                    @click="changeAxisBinding"
                  >
                  <input
                    v-else
                    type="checkbox"
                    :name="gisName"
                    :data-axis-index="axisIndexPlusOne - 1"
                    @click="changeAxisBinding"
                  >
                  {{ gisName.toString().toUpperCase() }}
                </label>
                <div
                  v-if="scalesFunctions !== undefined"
                  class="scales-selects"
                >
                  <h5>{{ $t("common.min-scale-value") }}</h5>
                  <label>
                    <input
                      :id="'minOfX' + ('scatter')"
                      type="number"
                      lang="en-US"
                      :value="(xAxisProps['scatter'] && xAxisProps['scatter'].min != undefined) ? xAxisProps['scatter'].min : ''"
                      @change="changeScaleMin('scatter')"
                    >
                  </label>
                  <h5>{{ $t("common.max-scale-value") }}</h5>
                  <label>
                    <input
                      :id="'maxOfX' + ('scatter')"
                      type="number"
                      lang="en-US"
                      :value="(xAxisProps['scatter'] && xAxisProps['scatter'].max != undefined) ? xAxisProps['scatter'].max : ''"
                      @change="changeScaleMax('scatter')"
                    >
                  </label>
                </div>
                <!-- ----- scatter ad different visualization phenomenon (Ending line.) ----- -->

                <h5
                  style="margin-top: 15px;"
                  class=""
                >
                  {{ $t("common.well-log") }}:
                </h5>
                <label
                  v-for="(gisAxisBindingIndex, gisName) in gisAxisIndexBinding"
                  :key="gisName"
                  class="to-hide"
                >
                  <input
                    v-if="gisAxisBindingIndex !== null && gisAxisBindingIndex + 1 === axisIndexPlusOne"
                    :data-axis-index="axisIndexPlusOne - 1"
                    type="checkbox"
                    checked
                    :name="gisName"
                    @click="changeAxisBinding"
                  >
                  <input
                    v-else
                    type="checkbox"
                    :name="gisName"
                    :data-axis-index="axisIndexPlusOne - 1"
                    @click="changeAxisBinding"
                  >
                  {{ gisName.toString().toUpperCase() }}
                </label>
                <div
                  v-if="scalesFunctions !== undefined"
                  class="scales-selects"
                >
                  <h5>{{ $t("common.min-scale-value") }}</h5>
                  <label>
                    <input
                      :id="'minOfX' + (axisIndexPlusOne - 1)"
                      type="number"
                      lang="en-US"
                      :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1].min != undefined) ? xAxisProps[axisIndexPlusOne - 1].min : ''"
                      @change="changeScaleMin(axisIndexPlusOne - 1)"
                    >
                  </label>
                  <h5>{{ $t("common.max-scale-value") }}</h5>
                  <label>
                    <input
                      :id="'maxOfX' + (axisIndexPlusOne - 1)"
                      type="number"
                      lang="en-US"
                      :value="(xAxisProps[axisIndexPlusOne - 1] && xAxisProps[axisIndexPlusOne - 1].max != undefined) ? xAxisProps[axisIndexPlusOne - 1].max : ''"
                      @change="changeScaleMax(axisIndexPlusOne - 1)"
                    >
                  </label>
                </div>
              </div>
            </article>
          </div>

          <div
            style="width: 10%;display: flex;justify-content: center;align-items: center;"
            class="dropdown-container"
          >
            <h4
              class="scale"
              @click="toggleNowActive"
            >
              {{ $t("common.depth") }}<br>({{ scaleOfMainPlot }})
            </h4>
            <div class="dropdown">
              <label
                v-for="(scale, index) in scalesAvailable"
                :key="scale + index"
              >
                <input
                  v-if="scale == scaleOfMainPlot"
                  :data-scale="scale"
                  type="checkbox"
                  checked
                  @click="changeScale"
                >
                <input
                  v-else
                  type="checkbox"
                  :data-scale="scale"
                  @click="changeScale"
                >
                {{ scale }}
              </label>
            </div>
          </div>

          <article
            v-for="axisIndexPlusOne in 1"
            :key="'plots-chooser-' + axisIndexPlusOne"
            :class="plots-chooser"
          >
            <div v-if="legendInfoForPlots !== undefined">
              <!-- Ось X{{axisIndexPlusOne}} -->
              <div
                v-for="(legend, index) in legendInfoForPlots[axisIndexPlusOne - 1]"
                :key="'plot-legend-' + index"
                :style="(legend.name !== 'SCATTER' ? 'margin-top:60px;' : '') + 'display: flex;align-items: center;'"
                :class="plots-chooser"
              >
                <span
                  :style="'width: 80%; height: 2px; margin-right: 10px;display:inline-block; background-color: ' + (legend.name !== 'SCATTER' ? legend.color : 'transparent')"
                />

                <span style="width:200px">{{ legend.name === 'SCATTER' ? selectedCoreData.name+",&nbsp;"+selectedCoreData.units[language] : legend.name+", " +legend.units }}</span>
              </div>
            </div>
          </article>
        </div>
        <div id="graph-wrapper" />
      </v-col>

      <v-col
        cols="6"
        class="pa-0"
      >
        <v-container class="right_side">
          <v-row>
            <v-col>
              <v-select
                v-model="selectedWell"
                :items="wellWithCoreData"
                :label="$t('common.well')"
                item-text="name"
                item-value="0"
                return-object
                outlined
                @change="getWellData"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="selectedGisData"
                :items="wellGisData | excludeDept"
                :label="$t('common.well-log')"
                item-text="name"
                item-value="0"
                return-object
                outlined
                @change="drawGisData($event), loadCurveSettings()"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="selectedCoreData"
                :items="wellCoreData"
                :label="$t('common.core')"
                item-text="name"
                item-value="0"
                return-object
                outlined
                @change="drawCoreData()"
              />
            </v-col>
          </v-row>
          <v-row class="wells-info__title justify-center">
            <v-col>
              {{ $t("petrophysics.matching-task") }} <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    slot="activator"
                    dark
                    color="#009797"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>Для корректировки увязки нужно выделить группу точек  с помощью таблицы и задать сдвиг по глубине</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div id="b-zone">
                <div class="created-zones">
                  <div
                    class="created-zones__header"
                    :data-content="$t('petrophysics.group')"
                  >
                    <span class="upper-value">{{ $t("petrophysics.top-m") }}</span>
                    <span class="bottom-value">{{ $t("petrophysics.bot-m") }}</span>
                    <span class="delta">{{ $t("petrophysics.shift-m") }}</span>
                  </div>
                  <ol>
                    <li
                      v-for="(bZoneItem, index) in bZoneItems"
                      :key="'b-zone-item-' + index"
                      :data-id="index"
                    >
                      <span
                        class="color"
                        :style="'background:' + colorsOfZones[getColorIndex(index)]"
                      />
                      <label>
                        <input
                          type="number"
                          lang="en-US"
                          max="12000"
                          class="bottom-value"
                          :value="bZoneItem.bottomVal"
                          step="0.1"
                          @change="handleOnlyUserChangeOfBZoneItem(index, 'bottomVal', '.bottom-value')"
                        >
                      </label>
                      <label>
                        <input
                          type="number"
                          lang="en-US"
                          max="12000"
                          class="upper-value"
                          :value="bZoneItem.upperVal"
                          step="0.1"
                          @change="handleOnlyUserChangeOfBZoneItem(index, 'upperVal', '.upper-value')"
                        >
                      </label>
                      <label>
                        <input
                          type="number"
                          lang="en-US"
                          max="12000"
                          class="delta"
                          :value="bZoneItem.deltaVal"
                          step="0.1"
                          @change="handleOnlyUserChangeOfBZoneItem(index, 'deltaVal', '.delta', $event)"
                        >
                      </label>
                      <button
                        style="display: none !important; width: 0px !important;"
                        class="remove-btn"
                        @click="removeBZoneItem(index)"
                      >
                        -
                      </button>
                    </li>
                  </ol>
                  <div class="created-zones__controls">
                    <button
                      class="revert-all"
                      @click="revertAll"
                    >
                      {{ $t("petrophysics.to-initial-state") }}
                    </button>
                    <button
                      class="add-btn"
                      @click="addNewBZoneItem"
                    >
                      +
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  @click="saveBZoneItemState"
                >
                  {{ $t("common.save") }}
                </button>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <Plotly
              :data="regression.data"
              :layout="regression.layout"
              :display-mode-bar="true"
            />
          </v-row>
          <v-row>
            <v-col>
              <router-link to="/petrophysics/core">
                {{ $t("common.show-data") }}
              </router-link>
              <p><strong>{{ $t("common.equation") }}: </strong>{{ getEquation }}</p>
              <p><strong>{{ $t("common.correlation-coeff") }}: </strong> {{ parseFloat(Math.sqrt(regression.coef.r2)).toFixed(4) *100 }}%</p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";
import * as d3 from "d3";
import regressionService from "@/service/regression-service";
import linkingApi from "@/service/linking-api";
import {Plotly} from "vue-plotly";

var plotsUpdaterFuncs = {};
var lastYTransform = {};

export default {
  name: "LinkingView",
  components: {
    Plotly
  },
  filters: {
    excludeDept(data) {
      return data.filter(gis => {
        return gis.name !== "dept";
      });
    }
  },
  data() {
    return {
      showDialog: true,
      dontShowAgain: false,
      currentPlotScrollY: null,
      bZoneItems: [],
      regresion_calculated: false,
      language: "",
      contour: {
        wellData: []
      },
      xAxisProps: {0: {}},
      selectedWell: null,
      showAlertOnceDuringTImeTimeoutId: null,
      activeBZoneItem: null,
      selectedGisData: null,
      selectedCoreData: null,
      snackbar: {
        show: false,
        color: null,
        message: "",
      },
      wellWithCoreData: [],
      wellGisData: [],
      wellCoreData: [],
      colorsOfZones: [
        "#69D2E7",
        "#FE4365",
        "#ECD078",
        "#BCBDAC",
      ],
      delta: 0,
      hasUnsavedChanges: false,
      firstPointInitialDepth: 0,
      regression: {
        layout: {
          showlegend: false,
          hovermode: "closest",
          xaxis: {
            title: ""
          },
          yaxis: {
            title: ""
          }
        },
        coef: {},
        data: []
      },

      clickOutsideListener: null,
      settings: {
        data: {
          linking: {
            tracks: [
              {}
            ]
          }
        }
      }
    };
  },
  computed: {
    getEquation() {
      const bSign = this.regression.coef.b < 0 ? "-" : "+";
      if (!this.selectedCoreData || !this.selectedGisData) {
        return "";
      }
      return `${this.selectedCoreData.name} = ${this.regression.coef.a} * ${this.selectedGisData.name.trim()} ${bSign}${Math.abs(this.regression.coef.b)}`;
    }
  },
  watch: {
    bZoneItems: {
      handler(val) {
        this.$store.commit("setWellBindedBZoneItems", {key: this.selectedWell.id, content: val});
      },
      deep: true
    },
    selectedWell: {
      handler(val) {
        if (this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones != undefined) {
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 500);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 1000);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 2000);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 3000);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 4000);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 5000);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 6000);
          setTimeout(() => {
            this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
          }, 7000);
        }
      },
      deep: true
    },
  },
  beforeMount() {
    this.showDialog = JSON.parse(localStorage.getItem("dontShowLinkingDialogOnOpen")) !== true;
  },
  mounted() {
    this.language = localStorage.lang !== undefined ? localStorage.lang : this.$root.$i18n.locale;
    
    wellService.getWellWithCores().then(({data}) => {
      this.wellWithCoreData = data;
      this.selectedWell = data[0];

      this.getWellData(this.selectedWell);
    });
    this.clickOutsideListener = (e) => {
      const dropdown = e.target.closest(".dropdown");
      const popupSelector = ".plots-chooser-1 h4";
      const popup = document.querySelector(popupSelector);
      if (popup) {
        const isNowActive = popup.classList.contains("now-active");
        const target = e.target;
        const isSetupTrackBtn = target.id !== "setup-track-btn";
        const isNotPopup = dropdown === null;
        const isHidePopup = isNowActive && isNotPopup && isSetupTrackBtn;
        if (isHidePopup)
          this.toggleNowActiveQS(popupSelector);
      }
    };
    document.body.onclick = this.clickOutsideListener;
  },
  beforeDestroy() {
    document.body.onclick = null;
  },
  methods: {
    loadCurveSettings() {
      const settings = localStorage.getItem("curve_settings_linking");
      this.settings = JSON.parse(settings);
      this.settings.data.linking.tracks.map((e, i) => {
        this.xAxisProps[0].min = e[this.selectedGisData.name]["0"].min;
        this.xAxisProps[0].max = e[this.selectedGisData.name]["0"].max;
        this.scalesFunctions[0] = e[this.selectedGisData.name]["0"].scale;
        this.xAxisProps["scatter"].min = e[this.selectedGisData.name]["scatter"].min;
        this.xAxisProps["scatter"].max = e[this.selectedGisData.name]["scatter"].max;
        const clickedCheckbox = document.querySelector(`.dropdown input[data-scale="${e[this.selectedGisData.name]["0"].depth}"]`);
        let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
        this.$store.commit("setPlotScaleInGeology", scaleOfClickedCheckbox);
        document.querySelectorAll("[data-scale]").forEach((element) => {
          element.checked = false;
        });
        clickedCheckbox.checked = true;
        document.querySelector(".scale").classList.remove("now-active");
        this.initPlot(1, "#graph-wrapper");
      });
      this.$forceUpdate();
    },
    saveCurveSettings() {
      const settings = this.settings;
      settings.data.linking.tracks[0][this.selectedGisData.name] = {
        "0": {
          scale: this.scalesFunctions[0],
          min: this.xAxisProps[0].min,
          max: this.xAxisProps[0].max,
          depth: this.$store.getters.getPlotScaleInGeology,
        },
        "scatter": {
          scale: this.scalesFunctions[0],
          min: this.xAxisProps["scatter"].min,
          max: this.xAxisProps["scatter"].max,
          depth: this.$store.getters.getPlotScaleInGeology,
        },
      };
      localStorage.setItem("curve_settings_linking", JSON.stringify(settings));
    },
    getColorIndex(index) {
      const computedColorIndex = (index + 1) % 4 === 0 ? 3 : index - (Math.trunc(this.bZoneItems.length / 4) * 4);
      return index < 4 ? index : computedColorIndex;
    },
    closeDialog() {
      if (this.dontShowAgain) {
        localStorage.setItem("dontShowLinkingDialogOnOpen", JSON.stringify(true));
      }
      this.showDialog = false;
    },

    showAlertOnceDuringTIme(msg, time) {
      if (this.showAlertOnceDuringTImeTimeoutId !== null) clearTimeout(this.showAlertOnceDuringTImeTimeoutId);
      this.showAlertOnceDuringTImeTimeoutId = setTimeout(() => {
        alert(msg);
      }, time);
    },
    updateDeltaOfZone(zoneIndex, d3svg) {
      let offsettedItemY = +d3svg.select(`.coreData[data-id="${zoneIndex}"][d3-to-remove-that="false"]`).data()[0].y; // we are contented with first item because data and original data are moved with save delta each.
      let originalItemY = +d3svg.select(`.originalCoreData[data-id="${zoneIndex}"][d3-to-remove-that="false"]`).data()[0].y; // we are contented with first item because data and original data are moved with save delta each.
      this.bZoneItems[zoneIndex].deltaVal = (offsettedItemY - originalItemY).toFixed(1);
    },
    checkAllowanceOfDelta(idOfZone, deltaInPx, showAlertOnFail = true) {

      // ----- clear timemout af alert to show it only if user end moving dot on the forbidden state (Starting line.) -----
      if (this.showAlertOnceDuringTImeTimeoutId !== null) clearTimeout(this.showAlertOnceDuringTImeTimeoutId);
      // ----- clear timemout af alert to show it only if user end moving dot on the forbidden state (Ending line.) -----

      deltaInPx = Math.ceil(deltaInPx);
      var querySelectorOfCirclesBelongingToThisCirclesZone = `.coreData[data-id="${idOfZone}"]`;
      var zoneInWhichDraggedCircle = document.querySelector(".user-zone-" + idOfZone);

      var wantReturn = false;
      var wantReturnReason = null;
      document.querySelectorAll(querySelectorOfCirclesBelongingToThisCirclesZone).forEach((circle) => {

        var topCircleBoundaryY = +circle.getAttribute("cy") - +circle.getAttribute("r");
        var bottomCircleBoundaryY = +circle.getAttribute("cy") + +circle.getAttribute("r");

        var circleCenterY = +circle.getAttribute("cy");

        var zoneBottomY = +zoneInWhichDraggedCircle.getAttribute("y") + +zoneInWhichDraggedCircle.getAttribute("height");
        var zoneTopY = +zoneInWhichDraggedCircle.getAttribute("y");
        let isCircleNowInZone = (circleCenterY > zoneTopY && bottomCircleBoundaryY < zoneBottomY) || (topCircleBoundaryY > zoneTopY && circleCenterY < zoneBottomY);
        // let isCircleNowInZone = topCircleBoundaryY > +zoneInWhichDraggedCircle.getAttribute('y') && bottomCircleBoundaryY < zoneBottomY;

        if (isCircleNowInZone) {
          var proposedFutureDistanceBetweenCircleBottomBoundaryAndBottomBoundaryOfBoundedZoneInPx = (circleCenterY + deltaInPx) - zoneTopY;//+zoneInWhichDraggedCircle.getAttribute('y');
          var proposedFutureDistanceBetweenCircleUpperBoundaryAndUpperBoundaryOfBoundedZoneInPx = zoneBottomY - ((circleCenterY + +circle.getAttribute("r")) + deltaInPx);

          if (proposedFutureDistanceBetweenCircleBottomBoundaryAndBottomBoundaryOfBoundedZoneInPx <= 1 ||
            proposedFutureDistanceBetweenCircleUpperBoundaryAndUpperBoundaryOfBoundedZoneInPx <= 1) {
            wantReturn = true;
            wantReturnReason = "какая-то из точек собирается покинуть группу при предложенном сдвиге, что запрещено";
          }
        } else { // if circle that is now outside zone want to enter zone...
          //circle.remove();
        }
      });

      return !wantReturn;
    },
    addNewBZoneItem() {
      this.bZoneItems.push(
        {
          bottomVal: "",
          upperVal: "",
          deltaVal: "",
        }
      );
    },
    async revertAll() {
      
      await linkingApi.removeGroup(this.selectedWell.id).then((response) => {
        this.snackbar = {
          show: true,
          message: this.$t("petrophysics.groups-deleted"),
          color: "success"
        };
      }, err => alert(err));
         
      this.bZoneItems = [{
        bottomVal: "",
        upperVal: "",
        deltaVal: "",
      }];
      this.initPlot(1, "#graph-wrapper");
               
      this.calcRegression();      
      
    },
    removeBZoneItem(itemIndex) {
      this.bZoneItems = [...this.bZoneItems.filter((item, index) => index != itemIndex)];

      this.initPlot(1, "#graph-wrapper");
    },
    changeScaleMin(plotIndex) {
      this.xAxisProps[plotIndex].min = document.querySelector("#minOfX" + plotIndex).value;
      this.initPlot(1, "#graph-wrapper");
      this.saveCurveSettings();
    },
    changeScaleMax(plotIndex) {
      this.xAxisProps[plotIndex].max = document.querySelector("#maxOfX" + plotIndex).value;
      this.initPlot(1, "#graph-wrapper");
      this.saveCurveSettings();
    },
    setScale(plotIndex, scaleFunc) {
      this.scalesFunctions[plotIndex] = scaleFunc;
      this.$store.commit("setScalesFunctionsInGeology", this.scalesFunctions);
      // ----- reinit autofilling (autoscaling) during further init (Starting line.) -----
      this.xAxisProps = {};
      // ----- reinit autofilling (autoscaling) during further init (Ending line.) -----
      this.initPlot(1, "#graph-wrapper");
    },
    notifyScaleChanges() {
      this.saveCurveSettings();
    },
    toggleNowActiveQS(selector) {
      document.querySelector(selector).classList.toggle("now-active");
    },
    handleOnlyUserChangeOfBZoneItem(itemIndex, inputsObjectKey, inputsSelector, event) {

      // ----- Performance enchancement of user-fast-clicking behaviour (Starting line.) -----
      if (this.changeOfBZoneTimeoutId) {
        clearTimeout(this.changeOfBZoneTimeoutId);
      }
      // ----- Performance enchancement of user-fast-clicking behaviour (Ending line.) -----
      var thisItem = document.querySelector(`.created-zones li[data-id="${itemIndex}"]`);
      var changedInput = thisItem.querySelector(inputsSelector);

      changedInput.value = Number(changedInput.value).toFixed(1);

      // ----- check if zone would still contain dot asif dot has delta of 0 (time reverse integrity check)  ===  "если при изменении границы группы набор точек (входящих в эту группу) меняется" (Starting line.) -----
      var idOfZone = itemIndex;
      var querySelectorOfCirclesBelongingToThisCirclesZone = `.coreData[data-id="${idOfZone}"]`;
      var zoneEl = document.querySelector(`.user-zone-${idOfZone}`);
      // var circlesBelongingToThisCirclesZone = d3.select(querySelectorOfCirclesBelongingToThisCirclesZone)._groups[0];

      var circlesBelongingToThisCirclesZone = document.querySelectorAll(querySelectorOfCirclesBelongingToThisCirclesZone);
      var correctionValue = 0;

      for (let i = 0; i < circlesBelongingToThisCirclesZone.length; i++) {
        const circle = circlesBelongingToThisCirclesZone[i];
        var topCircleBoundaryY = +circle.getAttribute("cy") - +circle.getAttribute("r");
        var bottomCircleBoundaryY = +circle.getAttribute("cy") + +circle.getAttribute("r");

        var topCircleBoundaryYDelta0 = this.yScaler(this.yScaler.invert(+circle.getAttribute("cy") - +circle.getAttribute("r")) - +this.bZoneItems[itemIndex].deltaVal);
        var bottomCircleBoundaryYDelta0 = this.yScaler(this.yScaler.invert(+circle.getAttribute("cy") + +circle.getAttribute("r")) - +this.bZoneItems[itemIndex].deltaVal);

        var zoneBottomY = +zoneEl.getAttribute("y") + +zoneEl.getAttribute("height");
        var zoneTopY = +zoneEl.getAttribute("y");
        let isCircleNowInZone = topCircleBoundaryY > +zoneEl.getAttribute("y") && bottomCircleBoundaryY < zoneBottomY;

        if (isCircleNowInZone) {
          if (inputsObjectKey === "upperVal") {
            var proposedZoneUpperVal = this.yScaler(+changedInput.value);

            // from bottomCircleBoundaryYDelta0 = proposedZoneUpperVal
            if (bottomCircleBoundaryYDelta0 > proposedZoneUpperVal) {

              let correctionValueOfThatIteration = this.yScaler.invert(bottomCircleBoundaryYDelta0) - this.yScaler.invert(proposedZoneUpperVal);

              if (correctionValueOfThatIteration > correctionValue) correctionValue = correctionValueOfThatIteration;
            }
          } else if (inputsObjectKey === "bottomVal") {
            var proposedZoneBottomVal = this.yScaler(+changedInput.value);

            if (topCircleBoundaryYDelta0 < proposedZoneBottomVal) {
              let correctionValueOfThatIteration = this.yScaler.invert(topCircleBoundaryYDelta0) - this.yScaler.invert(proposedZoneBottomVal);
              if (correctionValueOfThatIteration < correctionValue) correctionValue = correctionValueOfThatIteration;
            }
          }
        }
        if (inputsObjectKey === "deltaVal") {

          //var deltaInPx = Math.abs(this.yScaler.invert(Math.abs(+changedInput.value)) - this.yScaler.invert(0));
          // var deltaInPx = Math.abs(this.yScaler.invert(Math.abs(+changedInput.value)));

          if (event) {
            // changedInput.value = event.target.value;
            this.bZoneItems[itemIndex].deltaVal = event.target.value;
          }
          return;
          /*if (!this.checkAllowanceOfDelta(itemIndex, deltaInPx * (+changedInput.value / Math.abs(+changedInput.value)))) {
                    }*/
        }
      }
      // ----- check if zone would still contain dot asif dot has delta of 0 (time reverse integrity check)  ===  "если при изменении границы группы набор точек (входящих в эту группу) меняется" (Ending line.) -----

      // ----- check overlap and forbid (Starting line.) -----
      if (inputsObjectKey === "upperVal") {
        var newUpperValue = +changedInput.value;
        for (let i = 0; i < this.bZoneItems.length; i++) {
          if (itemIndex === i) continue; // not checking self old value.
          const bZoneItem = this.bZoneItems[i];
          if (newUpperValue < +bZoneItem.upperVal && newUpperValue > +bZoneItem.bottomVal) {
            this.snackbar = {
              show: true,
              message: this.$t("petrophysics.groups-intersect"),
              color: "error"
            };
            changedInput.value = this.bZoneItems[itemIndex][inputsObjectKey];
            return;
            // alert('overlap of upper val');
          }
        }
      } else if (inputsObjectKey === "bottomVal") {
        var newBottomValue = +changedInput.value;
        for (let i = 0; i < this.bZoneItems.length; i++) {
          if (itemIndex === i) continue; // not checking self old value.
          const bZoneItem = this.bZoneItems[i];
          if (newBottomValue < +bZoneItem.upperVal && newBottomValue > +bZoneItem.bottomVal) {
            this.snackbar = {
              show: true,
              message: this.$t("petrophysics.groups-intersect"),
              color: "error"
            };
            changedInput.value = this.bZoneItems[itemIndex][inputsObjectKey];
            return;
            // alert('overlap of bottom val');
          }
        }
      }
      // ----- check overlap and forbid (Ending line.) -----

      // if (correctionValue !== 0) {
      //   alert('При таком изменении границы группы набор точек (входящих в эту группу) меняется, это рушит причинно следственные связи и поэтому запрещено.');
      // }
      this.bZoneItems[itemIndex][inputsObjectKey] = (+changedInput.value + correctionValue).toFixed(1);

      // ----- fix flipfloping when bottom highr than upper (Starting line.) -----
      let doesUpperAndLowerValuesBothSetted = thisItem.querySelector("input.upper-value").value && Number(thisItem.querySelector("input.bottom-value").value);
      if (doesUpperAndLowerValuesBothSetted) {
        if (Number(thisItem.querySelector("input.upper-value").value) < Number(thisItem.querySelector("input.bottom-value").value)) {
          e.target.value = Math.min(Number(thisItem.querySelector("input.upper-value").value), Number(thisItem.querySelector("input.bottom-value").value));
        }
        this.initPlot(1, "#graph-wrapper");
      }
      // ----- fix flipfloping when bottom highr than upper (Ending line.) -----

      return;

    },
    setActiveBZoneItem(index) {
      document.querySelectorAll(".created-zones [data-id]").forEach((element) => {
        element.classList.remove("now-active");
      });
      document.querySelector(`.created-zones [data-id="${index}"]`).classList.add("now-active");
      this.activeBZoneItem = document.querySelector(`.created-zones [data-id="${index}"]`);
      this.initPlot(1, "#graph-wrapper");
    },
    saveBZoneItemState() {
      var thisRef = this;

      function serviceLoop(i) {
        let element = thisRef.bZoneItems[i];
        let user_element = thisRef.bZoneItems[i];
        linkingApi.saveLinkings(null, {
          "top": user_element.upperVal,
          "bot": user_element.bottomVal,
          "value": user_element.deltaVal,
          "id": user_element.id ?? "undefined",
          "well": thisRef.selectedWell.id
        }
        ).then((response) => {
          linkingApi.getLinkings(thisRef.selectedWell.id).then((response) => {
            thisRef.bZoneItems = [];
            var sorted_zones = response.data.sort((a, b) => a.id - b.id);            
            sorted_zones.forEach((element) => {
              thisRef.bZoneItems.push({
                id: element.id,
                bottomVal: element.bot,
                upperVal: element.top,
                deltaVal: element.value
              }); 
              if (user_element.id == "undefined") {thisRef.bZoneItems.push({
                bottomVal: "",
                upperVal: "",
                deltaVal: "",
              });}
            });
                        
          });


          if (i + 1 < thisRef.bZoneItems.length) {
            serviceLoop(++i);
          } else {
            //alert('Сохранено успешно!');
            thisRef.snackbar = {
              show: true,
              message: thisRef.$t("petrophysics.groups-saved"),
              color: "success"
            };
            thisRef.initPlot(1, "#graph-wrapper");
            thisRef.calcRegression();

          }
        }).catch(err => {
          if (err.response.status === 500) {
            thisRef.snackbar = {
              show: true,
              message: thisRef.$t("common.error"),
              color: "error"
            };
          } else if (err.response.status === 404 || err.response.status === 400){
            thisRef.snackbar = {
              show: true,
              message: thisRef.$t("petrophysics.value-required"),
              color: "error"
            }; 
          }
          thisRef.bZoneItems[thisRef.bZoneItems.length -1] = {
            bottomVal: "",
            upperVal: "",
            deltaVal: "",
          };
          thisRef.initPlot(1, "#graph-wrapper");
        });;
      }
      
      serviceLoop(0);
   
    },
    toggleNowActive(e) {
      e.target.classList.toggle("now-active");
    },
    changeScale(e) {

      let clickedCheckbox = e.target;
      let scaleOfClickedCheckbox = clickedCheckbox.getAttribute("data-scale");
      this.$store.commit("setPlotScaleInGeology", scaleOfClickedCheckbox);
      document.querySelectorAll("[data-scale]").forEach((element) => {
        element.checked = false;
      });
      clickedCheckbox.checked = true;
      document.querySelector(".scale").classList.remove("now-active");
      this.initPlot(1, "#graph-wrapper");

      this.saveCurveSettings();
    },
    changeAxisBinding(e) {

      let clickedCheckbox = e.target;
      let nameOfGis = clickedCheckbox.getAttribute("name");
      let axisIndexOfClickedCheckbox = parseInt(clickedCheckbox.getAttribute("data-axis-index"));
      let valueOfCheckboxAfterClick = clickedCheckbox.checked;
      if (valueOfCheckboxAfterClick === false) {
        this.gisAxisIndexBinding[nameOfGis] = null;
      } else {
        this.gisAxisIndexBinding[nameOfGis] = axisIndexOfClickedCheckbox;
      }
      this.drawGisData();
    },
    async getWellData(well) {
      this.regresion_calculated = false;

      await wellService.getGisData(well.id).then(({data}) => {
        this.wellGisData = data.gis;
        this.selectedGisData = this.wellGisData[1];
        this.delta = well.core_delta;
      });
      await wellService.getCores(well.id).then(({data}) => {
        this.wellCoreData = data;
        this.selectedCoreData = this.wellCoreData[1]; // PORO
      });

      var storageSelectedWellBZoneItems = this.$store.getters.getWellBindedBZoneItems[well.id];
            
      this.bZoneItems=[];
            
      await linkingApi.getLinkings(this.selectedWell.id).then(async (response) => {

        if (response.data.length == 0) {
          this.bZoneItems = [
            {
              bottomVal: "",
              upperVal: "",
              deltaVal: "",
            }
          ];
        } else {
          response.data.forEach((element) => {
            this.bZoneItems.push({
              id: element.id,
              bottomVal: element.bot,
              upperVal: element.top,
              deltaVal: element.value
            });
          });
        };
        //alert(err);
      });

      document.querySelector("#graph-wrapper").innerHTML = "";
      this.initPlot(1, "#graph-wrapper");
      this.$forceUpdate();
      await this.setScale(0, "linear");
      const hasSettings = localStorage.getItem("curve_settings_linking");
      if (hasSettings) {
        this.loadCurveSettings();
        this.initPlot(1, "#graph-wrapper");
        this.loadCurveSettings();
      } else {
        this.saveCurveSettings();
      }
    },
    drawGisData(boundVModelObj) {
      this.regresion_calculated = false;
      // ----- handle changing of select (Starting line.) -----
      // ----- reinit autofilling (autoscaling) during further init (Starting line.) -----
      this.xAxisProps = {};
      // ----- reinit autofilling (autoscaling) during further init (Ending line.) -----
      for (const key in this.gisAxisIndexBinding) {
        if (Object.hasOwnProperty.call(this.gisAxisIndexBinding, key)) {
          this.gisAxisIndexBinding[key] = null;
        }
      }
      this.gisAxisIndexBinding[boundVModelObj.name] = 0;
      this.$store.commit("setGisAxisIndexBindingInPetroLinking", this.gisAxisIndexBinding);
      this.initPlot(1, "#graph-wrapper");
      // ----- handle changing of select (Ending line.) -----
    },
    drawCoreData() {

      
      this.regresion_calculated = false;
      this.initPlot(1, "#graph-wrapper");
    },
    async calcRegression() {
      const result = await regressionService.calc(
        this.selectedWell.id,
        this.selectedGisData.name,
        this.selectedCoreData.name,
      );
      const {scatter_data, coef} = result;
      this.regression.data = [
        {
          x: scatter_data.x,
          y: scatter_data.y,
          mode: "markers",
          type: "scatter",
          name: "Данные"
        }
      ];

      const minX = Math.min(...scatter_data.x.filter(num => num != null));
      const maxX = Math.max(...scatter_data.x.filter(num => num != null));

      this.regression.coef = coef;

      this.regression.data.push(
        {
          x: [minX, maxX],
          y: [coef.a * minX + coef.b, coef.a * maxX + coef.b],
          type: "scatter",
          name: "Линия регрессии"
        }
      );

      if (this.selectedGisData.units !== undefined){
        this.regression.layout.xaxis.title = this.selectedGisData.name +",&nbsp;" + this.selectedGisData.units[this.language];
      } else {
        this.regression.layout.xaxis.title = this.selectedGisData.name;
      }
      if (this.selectedCoreData.units !== undefined){
        this.regression.layout.yaxis.title = this.selectedCoreData.name +",&nbsp;"+this.selectedCoreData.units[this.language];
      } else {
        this.regression.layout.yaxis.title = this.selectedCoreData.name;
      }
    },
    initPlot(totalParallelPlotsAmountWarranted, plotElSelector, commonYAxis = null, SVG = null) {
      var xTicsGridSetted = false;
      function isCircleOwnedByZone(circle, zone) {
        var circleY = circle.getAttribute("cy");
        let zoneYBeginning = zone.getAttribute("y");
        let zoneHeight = zone.getAttribute("height");
        if (+circleY >= +zoneYBeginning && +circleY <= (+zoneYBeginning + +zoneHeight)) {
          return true;
        }
        return false;
      }

      function circleOwnedByWhichZone(circle) {
        let allZones = document.querySelectorAll(".user-zone");
        for (let i = 0; i < allZones.length; i++) {
          if (isCircleOwnedByZone(circle, allZones[i])) {
            return allZones[i];
          }
        }
        return null;
      }

      var thisReference = this;

      function setDisplayNoneToAllCoreDataOutsideTheirBoundedZones() {
        document.querySelectorAll(".coreData, .originalCoreData").forEach((circle) => { // hide even all original core data
          var zoneInWhichDraggedCircle = circleOwnedByWhichZone(circle);
          if (zoneInWhichDraggedCircle !== null) {
            var zoneIdInWhichDraggedCircle = zoneInWhichDraggedCircle.getAttribute("data-id");
            var circleIdOfBoundedZone = circle.getAttribute("data-id");

            if (zoneIdInWhichDraggedCircle == circleIdOfBoundedZone) {
              circle.setAttribute("d3-to-remove-that", "false");

              circle.style.display = null;

              if (document.querySelector(`.created-zones [data-id="${zoneInWhichDraggedCircle.getAttribute("data-id")}"]`) !== null &&
                !circle.classList.contains("originalCoreData")) {

                var deltaOfCircleGroup = thisReference.bZoneItems[zoneInWhichDraggedCircle.getAttribute("data-id")].deltaVal;
                var bottomValOfCircleGroup = thisReference.bZoneItems[zoneInWhichDraggedCircle.getAttribute("data-id")].bottomVal;
                var upperValOfCircleGroup = thisReference.bZoneItems[zoneInWhichDraggedCircle.getAttribute("data-id")].upperVal;


                var yPosOfCircleReal = thisReference.yScaler.invert(circle.getAttribute("cy"));

                if (yPosOfCircleReal - deltaOfCircleGroup < bottomValOfCircleGroup || yPosOfCircleReal - deltaOfCircleGroup > upperValOfCircleGroup) {
                  // ----- debug visualization (Starting line.) -----
                  circle.setAttribute("r", "2");
                  // ----- debug visualization (Ending line.) -----
                  // circle.setAttribute("d3-to-remove-that", 'true');
                  // d3.select('[d3-to-remove-that="true"]').remove();
                  circle.remove();
                }
              }
              // var isCircleAllowedToDisplayInZone = thisReference.checkAllowanceOfDelta(zoneInWhichDraggedCircle.getAttribute('data-id'), deltaInPx, false);
              // if (isCircleAllowedToDisplayInZone)  circle.style.display = null;
              // else {
              //   // circle.remove();
              // }


            } else {
              // circle.setAttribute("d3-to-remove-that", 'true');
              // d3.select('[d3-to-remove-that="true"]').remove();

              // circle.remove();
              circle.style.display = "none";
            }
          } else {
            // circle.setAttribute("d3-to-remove-that", 'true');
            // d3.select('[d3-to-remove-that="true"]').remove();

            // circle.remove();
            circle.style.display = "none";
          }
        });
        document.querySelectorAll(".originalCoreData0").forEach((element) => { // restore only 0ith core data to prevent coredata boundling on topof each other
          element.style.display = null;
        });
      }

      this.setDisplayNoneToAllCoreDataOutsideTheirBoundedZones = setDisplayNoneToAllCoreDataOutsideTheirBoundedZones;

      function serColorBlackToCoreData0IfOutsideAnyZone() {
        document.querySelectorAll(".originalCoreData0").forEach((circle) => { // hide even all original core data
          var zoneInWhichDraggedCircle = circleOwnedByWhichZone(circle);
          if (zoneInWhichDraggedCircle === null) {
            circle.style.fill = "#000";
            circle.style.opacity = "0.9";
          }
        });
      }
            

      this.legendInfoForPlots = [[], [], []];
      this.scalesFunctions = this.$store.getters.getScalesFunctionsLegacy;
      // ----- Scale casing (Starting line.) -----
      var currentScale = this.$store.getters.getPlotScaleInGeology;
      var currentScaleTick;

      this.scalesAvailable = ["1:50", "1:100", "1:200", "1:500", "1:1000"];

      switch (currentScale) {
      case "1:50":
        currentScaleTick = 50;
        break;
      case "1:100":
        currentScaleTick = 100;
        break;
      case "1:200":
        currentScaleTick = 200;
        break;
      case "1:500":
        currentScaleTick = 500;
        break;
      case "1:1000":
        currentScaleTick = 1000;
        break;
      }
      this.scaleOfMainPlot = currentScale;
      // ----- Scale casing (Ending line.) -----

      this.gisAxisIndexBinding = this.$store.getters.getGisAxisIndexBindingInPetroLinking;
      if (this.gisAxisIndexBinding === null) {
        this.gisAxisIndexBinding = {};
        var initialBindingNeeded = true;
      } else {
        var initialBindingNeeded = false;
      }

      if (this.regresion_calculated ==false) {
        this.calcRegression();
        this.regresion_calculated = true;
      }
      // const margin = {top: 20, right: 30, bottom: 30, left: 60},
      const margin = {top: 0, right: 0, bottom: 0, left: 20},
        width = 680 - margin.left - margin.right,
        height = 600 - margin.top - margin.bottom;


      d3.select(plotElSelector).select("svg").remove();
      var SVG = d3.select(plotElSelector)
        .append("svg")
        .style("overflow", "visible")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);

      const dept = this.wellGisData.filter(gis => gis.name === "dept")[0].data;

      var gisWithoutDept = this.wellGisData.filter(gis => {
        return gis.name !== "dept";
      });

      var coreDeptValues = this.selectedCoreData.data.map(element => element.dept);
      var coreXValues = this.selectedCoreData.data.map(element => element.val);

      var deptYMax = d3.max(dept);
      var deptYMin = d3.min(dept);
      var coreYMax = d3.max(coreDeptValues);
      var coreYMin = d3.min(coreDeptValues);
      var yMax = d3.max([deptYMax, coreYMax]);
      var yMin = d3.min([deptYMin, coreYMin]);

      var coreXMax = d3.max(coreXValues);
      var coreXMin = d3.min(coreXValues);

      function findXMaxXMinOfGisArr(gisWithoutDept) {
        var gisXMax = null;
        var gisXMin = null;
        gisWithoutDept.forEach((gis, i) => {
          gis.maxXValue = d3.max(gis.data);
          gis.minXValue = d3.min(gis.data);
          if (i === 0) {
            gisXMax = gis.maxXValue;
            gisXMin = gis.minXValue;
          } else {
            if (gisXMax < gis.maxXValue) gisXMax = gis.maxXValue;
            if (gisXMin > gis.minXValue) gisXMin = gis.minXValue;
          }
        });
        return {xMax: gisXMax, xMin: gisXMin};
      }

      var gisXMin = d3.min(this.selectedGisData.data);

      var zoom = d3.zoom()
        .scaleExtent([.2, 2000]) // This control how much you can unzoom (x0.2) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .on("zoom", updateChart);


      var linesDataObj = {};
      var x = {};
      var xAxis = {};
      var lastUseBoundaryIndex = null;
      var path = {};
      var yPanFromOriginal = 0;
      var scatter = {};
      var scatter2 = null;
      let gisesOfPlot = {};
      var thisRef = this;
      this.firstBoundaryPositionY = null;
      this.secondBoundaryPositionY = null;
      var userSelectZoneHeight = 0;
      var scatterSetted = false;
      var coreDataSetted = false;
      var xAxisGenerator = {};
      var plotsColors = {
        gk: "red",
        ngk: "#000",
        ps: "red",
        pz: "#00008b",
        ik: "#066206",
        scatter: "transparent", // that is because here it would otherwise create grey copy of gis (complex unexpected behaviour fix)
        bk: "#00008b",
        ds: "#066206",
        default: "grey"
      };
      var coreDataVals = this.selectedCoreData.data.map(point => {
        return {
          x: point.val,
          y: Number(point.dept) + Number(this.delta)
        };
      });

      for (let plotIndex = 0; plotIndex < totalParallelPlotsAmountWarranted; plotIndex++) {
        if (this.xAxisProps[plotIndex] === undefined) this.xAxisProps[plotIndex] = {};
        if (this.xAxisProps["scatter"] === undefined) this.xAxisProps["scatter"] = {};

        var plot = SVG
          .append("g")
          .attr("transform",
            "translate(" + (margin.left + width * (plotIndex / totalParallelPlotsAmountWarranted)) + ", " + margin.top + ")");
        // .style("fill","transparent")
        // .style("pointer-events", "all")
        // .on("click", function() {
        // });

        if (plotIndex === 0) {

          // ----- y axis (Starting line.) -----
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Starting line.) -----
          var firstGisDotWithoutNullish = dept.map((element, index) => {
            return {
              x: gisWithoutDept[0].data[index],
              y: element
            };
          }).filter(el => el.x != null);

          var firstYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[0].y;
          var lastYWithNonNullishXOfFirstGis = firstGisDotWithoutNullish[firstGisDotWithoutNullish.length - 1].y;
          // ----- find firstYWithNonNullishX and lastYWithNonNullishXOfFirstGis for y (Ending line.) -----
          if (!this.currentPlotScrollY) {
            var y = d3.scaleLinear()
              .domain([+currentScaleTick + +firstYWithNonNullishXOfFirstGis, +firstYWithNonNullishXOfFirstGis])
              .range([600, 0]); // Where 600 is just plot heihght in px.
          } else {
            var currentPlotScrollYRef = this.currentPlotScrollY;
            var y = d3.scaleLinear()
              .domain([+currentScaleTick + +currentPlotScrollYRef, +currentPlotScrollYRef])
              .range([600, 0]); // Where 600 is just plot heihght in px.
          }
          var yAxis = plot.append("g")
            .attr("class", "yAxis yAxisLinking")
            .call(d3.axisLeft(y).ticks(9).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5)));
          // ----- y axis (Ending line.) -----

          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Starting line.) -----
          var rect = SVG.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")
            .style("cursor", "n-resize")
            .call(zoom);
          // ----- create base rect that defines scope of zoom and contains some variables of d3 runtime (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Starting line.) -----
          var globalDefs = plot.append("defs");
          // ----- define plotBoundary clipath (Starting line.) -----
          globalDefs.append("clipPath")
            .attr("id", "plotBoundaryClip")
            .append("rect")
            .attr("width", width / totalParallelPlotsAmountWarranted)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define plotBoundary clipath (Ending line.) -----

          // ----- define plotBoundary clipath (Starting line.) -----
          globalDefs.append("clipPath")
            .attr("id", "plotBoundaryClipLegacy")
            .append("rect")
            .attr("width", (width / totalParallelPlotsAmountWarranted) + margin.right)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
          // ----- define plotBoundary clipath (Ending line.) -----

          // ----- define allPlotsButScaleTicksY clipath (Starting line.) -----
          // globalDefs.append("plot:clipPath")
          globalDefs.append("clipPath")
            .attr("id", "allPlotsButScaleTicksYClip")
            .append("rect")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height)
            .attr("x", 20)
            .attr("y", 0);
          // ----- define allPlotsButScaleTicksY clipath (Ending line.) -----

          // ----- define cliparea outside which paths would be hidden (clipped) (Ending line.) -----

        }
        // ----- initial binding (Starting line.) -----

        if (initialBindingNeeded) {
          let maxAmountToBind = 1;
          let amountBinded = 0;
          for (let i = 0; i < gisWithoutDept.length; i++) {
            let gisOfThatIteration = gisWithoutDept[i];

            if (amountBinded >= maxAmountToBind) {
              this.gisAxisIndexBinding[gisOfThatIteration.name] = null;
            } else {
              this.gisAxisIndexBinding[gisOfThatIteration.name] = i % totalParallelPlotsAmountWarranted;
            }
            amountBinded++;
          }
        }

        // ----- initial binding (Ending line.) -----

        // ----- Retrieve only gises of this plot (Starting line.) -----
        gisesOfPlot[plotIndex] = [];
        for (let i = 0; i < gisWithoutDept.length; i++) {
          let gisOfThatIteration = gisWithoutDept[i];

          let boundedPlotIndex = this.gisAxisIndexBinding[gisOfThatIteration.name];

          let currentIterationGisUnboundedOrNotBelongsToThisPlot = boundedPlotIndex === null || boundedPlotIndex !== plotIndex;
          if (currentIterationGisUnboundedOrNotBelongsToThisPlot) {
            continue;
          }

          gisesOfPlot[plotIndex].push(gisOfThatIteration);
        }

        // ----- Retrieve only gises of this plot (Ending line.) -----

        // ----- include dots for coreDataVals (Starting line.) -----
        if (coreDataVals) {
          gisesOfPlot[plotIndex].push({
            data: coreDataVals.map(val => {
              return val.x;
            })
          });
        }
        // ----- include dots for coreDataVals (Ending line.) -----

        let xMaxXMinOfGisesOfPlot = findXMaxXMinOfGisArr(gisesOfPlot[plotIndex]);

        // ----- scatter as different visualization phenomenon (Starting line.) -----
        gisWithoutDept = [{
          name: "scatter",
          units: gisWithoutDept[0].units,
          data: gisWithoutDept[0].data,
          maxXValue: gisWithoutDept[0].maxXValue,
          minXValue: gisWithoutDept[0].minXValue
        }, ...gisWithoutDept];
        this.gisAxisIndexBinding.scatter = 0;
        
        // ----- scatter as different visualization phenomenon (Ending line.) -----


        for (let i = 0; i < gisWithoutDept.length; i++) {
          let gisOfThatIteration = gisWithoutDept[i];
          let boundedPlotIndex = this.gisAxisIndexBinding[gisOfThatIteration.name];

          let currentIterationGisUnboundedOrNotBelongsToThisPlot = boundedPlotIndex === null || boundedPlotIndex !== plotIndex;
          if (currentIterationGisUnboundedOrNotBelongsToThisPlot) {
            continue;
          }
  
          if (gisOfThatIteration.units !== undefined) { 
            this.legendInfoForPlots[boundedPlotIndex].push({
              name: gisOfThatIteration.name.toString().toUpperCase(),
              units: gisOfThatIteration.units[this.language] !== undefined ? gisOfThatIteration.units[this.language].toString() : "",
              color: plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"]
            });
          } else {
            this.legendInfoForPlots[boundedPlotIndex].push({
              name: gisOfThatIteration.name.toString().toUpperCase(),
              color: plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"]
            });
          }
          // ----- Fill in legendInfoForPlots (Starting line.) -----
          
          // ----- Fill in legendInfoForPlots (Ending line.) -----

          this.selectedGisData = gisOfThatIteration; // TODO: delete carefully.

          linesDataObj[gisOfThatIteration.name] = dept.map((element, index) => {
            return {
              x: gisOfThatIteration.data[index],
              y: element
            };
          }).filter(el => {
            if (this.scalesFunctions[plotIndex] === "log") {
              return (el.x != null && el.x > 0);
            } else {
              return el.x != null;
            }
          });

          // ----- x axis (Starting line.) -----
          if (this.scalesFunctions[plotIndex] === "log") {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (this.xAxisProps[plotIndex].min == undefined) {
              this.xAxisProps[plotIndex].min = 1e-12;
            }
            if (this.xAxisProps[plotIndex].max == undefined) {
              this.xAxisProps[plotIndex].max = Math.exp(Math.log(xMaxXMinOfGisesOfPlot.xMax) + 5);
            }
            // ----- auto-handle undefined x axis range (Ending line.) -----

            x[gisOfThatIteration.name] = d3.scaleLog()
              .domain([this.xAxisProps[plotIndex].min, this.xAxisProps[plotIndex].max])
              .range([1e-12, width / totalParallelPlotsAmountWarranted]);
            if (!xTicsGridSetted) {
              xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(12).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5));
              xTicsGridSetted = true;
            } else {
              xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(12).tickSize(-13);
            }
          } else {
            // ----- auto-handle undefined x  axis range (Starting line.) -----
            if (this.xAxisProps[plotIndex].min == undefined) {
              //  this.xAxisProps[plotIndex].min = +((xMaxXMinOfGisesOfPlot.xMin - 0.1).toFixed(6));
              this.xAxisProps[plotIndex].min = +((xMaxXMinOfGisesOfPlot.xMin).toFixed(6));
            }
            if (this.xAxisProps[plotIndex].max == undefined) {
              // this.xAxisProps[plotIndex].max = +((xMaxXMinOfGisesOfPlot.xMax + 0.1).toFixed(6));
              this.xAxisProps[plotIndex].max = +((xMaxXMinOfGisesOfPlot.xMax).toFixed(6));
            }
            // ----- auto-handle undefined x axis range (Ending line.) -----

            x[gisOfThatIteration.name] = d3.scaleLinear()
              .domain([this.xAxisProps[plotIndex].min, this.xAxisProps[plotIndex].max])
              .range([0, width / totalParallelPlotsAmountWarranted]);
            if (!xTicsGridSetted) {
              xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(15).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5));
              xTicsGridSetted = true;
            } else {
              xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(15).tickSize(-13);
            }
          }


          let xOffsetWarranted = commonYAxis !== null;
          if (xOffsetWarranted) {
            xAxis[gisOfThatIteration.name] = plot.append("g")
              .call(xAxisGenerator[gisOfThatIteration.name])
              .attr("class", "xAxis2 xAxisLinking")
              .attr("transform", "translate(" + width * (plotIndex / totalParallelPlotsAmountWarranted) + ", 0)");
          } else {
            xAxis[gisOfThatIteration.name] = plot.append("g")
              .attr("class", "xAxis2 xAxisLinking")
              .call(xAxisGenerator[gisOfThatIteration.name]);

          }

          // ----- x axis (Ending line.) -----

          if (!scatterSetted) {

            this.firstPointInitialDepth = this.delta;
            this.bZoneItems.forEach((bZoneItem, zoneIndex) => {


              let element = document.querySelector(`.created-zones li[data-id="${zoneIndex}"]`);
              var boundaryUpper = [{x: -10, y: Number(bZoneItem.upperVal)}];
              var boundaryLower = [{x: -10, y: Number(bZoneItem.bottomVal)}];

              scatter[zoneIndex] = SVG.append("g")
                .attr("clip-path", "url(#allPlotsButScaleTicksYClip)");

              scatter[zoneIndex]
                .selectAll("circle")
                .data(boundaryLower)
                .enter()
                .append("rect")
                .attr("x", 20)
                .attr("y", function (d) {
                  return y(d.y);
                })
                .attr("data-id", zoneIndex)
                .attr("class", "user-zone user-zone-" + zoneIndex)
                .attr("height", function (d) {
                  var boundaryUpper = {x: -10, y: Number(bZoneItem.upperVal)};
                  var boundaryLower = {x: -10, y: Number(bZoneItem.bottomVal)};
                  return y(boundaryUpper.y) - y(boundaryLower.y);
                })
                .attr("width", width + 20)
                // .style("fill", 'transparent')
                .style("fill", this.colorsOfZones[zoneIndex])
                .style("pointer-events", "none")
                .style("opacity", 0.5);

              scatter[zoneIndex].selectAll(".user-zone-" + zoneIndex).call(d3.drag()
                .on("drag", userZoneDrag1)
              );
              // ----- setting backroungs for group selections (Ending line.) -----
            });

            scatterSetted = true;

          }

          if (!coreDataSetted) {
            // ----- scatter as different visualization phenomenon (Starting line.) -----
            // ----- x axis (Starting line.) -----
            if (this.scalesFunctions.scatter === "log") {
              // ----- auto-handle undefined x  axis range (Starting line.) -----
              if (this.xAxisProps.scatter.min == undefined) {
                this.xAxisProps.scatter.min = 1e-12;
              }
              if (this.xAxisProps.scatter.max == undefined) {
                this.xAxisProps.scatter.max = Math.exp(Math.log(xMaxXMinOfGisesOfPlot.xMax) + 5);
              }
              // ----- auto-handle undefined x axis range (Ending line.) -----

              x[gisOfThatIteration.name] = d3.scaleLog()
                .domain([this.xAxisProps.scatter.min, this.xAxisProps.scatter.max])
                .range([1e-12, width / totalParallelPlotsAmountWarranted]);
              if (!xTicsGridSetted) {
                xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(12).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5));
                xTicsGridSetted = true;
              } else {
                xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(12).tickSize(-13);
              }
            } else {
              // ----- auto-handle undefined x  axis range (Starting line.) -----
              if (this.xAxisProps.scatter.min == undefined) {
                //  this.xAxisProps.scatter.min = +((xMaxXMinOfGisesOfPlot.xMin - 0.1).toFixed(6));
                this.xAxisProps.scatter.min = +((xMaxXMinOfGisesOfPlot.xMin).toFixed(6));
              }
              if (this.xAxisProps.scatter.max == undefined) {
                // this.xAxisProps.scatter.max = +((xMaxXMinOfGisesOfPlot.xMax + 0.1).toFixed(6));
                this.xAxisProps.scatter.max = +((xMaxXMinOfGisesOfPlot.xMax).toFixed(6));
              }
              // ----- auto-handle undefined x axis range (Ending line.) -----

              x[gisOfThatIteration.name] = d3.scaleLinear()
                .domain([this.xAxisProps.scatter.min, this.xAxisProps.scatter.max])
                .range([0, width / totalParallelPlotsAmountWarranted]);
              if (!xTicsGridSetted) {
                xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(15).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5));
                xTicsGridSetted = true;
              } else {
                xAxisGenerator[gisOfThatIteration.name] = d3.axisTop(x[gisOfThatIteration.name]).ticks(15).tickSize(-13);
              }
            }


            let xOffsetWarranted = commonYAxis !== null;
            if (xOffsetWarranted) {
              xAxis[gisOfThatIteration.name] = plot.append("g")
                .call(xAxisGenerator[gisOfThatIteration.name])
                .attr("class", "xAxis2 xAxisLinking xAxisScatter");
              // xAxis[gisOfThatIteration.name].select('.domain').attr('stroke', 'transparent');
            } else {
              xAxis[gisOfThatIteration.name] = plot.append("g")
                .attr("class", "xAxis2 xAxisLinking xAxisScatter")
                .call(xAxisGenerator[gisOfThatIteration.name])
                .attr("transform", "translate(0, -75)");
              // xAxis[gisOfThatIteration.name].select('.domain').attr('stroke', 'transparent');
            }


            // ----- x axis (Ending line.) -----
            // ----- scatter as different visualization phenomenon (Ending line.) -----


            // ----- set coreData (Starting line.) -----
            var coreData = {};
            var originalCoreData = {};
            coreDataSetted = true;

            this.bZoneItems.forEach((bZoneItem, zoneIndex) => {
              originalCoreData[zoneIndex] = SVG.append("g")
                .attr("clip-path", "url(#allPlotsButScaleTicksYClip)");

              let deepCopyOfCoreDataVals1 = JSON.parse(JSON.stringify(coreDataVals));
              let deepCopyOfCoreDataVals2 = JSON.parse(JSON.stringify(coreDataVals));

              originalCoreData[zoneIndex]
                .selectAll("circle")
                .data([...deepCopyOfCoreDataVals1])
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                  return x[gisOfThatIteration.name](d.x) + 20;
                })
                .attr("cy", function (d) {
                  return y(d.y);
                })
                .attr("r", 4)
                .style("fill", "grey")
                .style("opacity", 0.5)
                .attr("data-id", zoneIndex)
                .attr("class", "originalCoreData originalCoreData" + zoneIndex);

              coreData[zoneIndex] = SVG.append("g")
                .attr("clip-path", "url(#allPlotsButScaleTicksYClip)");

              // ----- account for delta straight in data (Starting line.) -----
              deepCopyOfCoreDataVals2.forEach((element) => {
                element.y = +element.y + +bZoneItem.deltaVal;
              });
              // ----- account for delta straight in data (Ending line.) -----


              coreData[zoneIndex]
                .selectAll("circle")
                .data([...deepCopyOfCoreDataVals2])
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                  return x[gisOfThatIteration.name](d.x) + 20;
                })
                .attr("cy", function (d) {
                  return y(d.y);
                })
                .attr("r", 4)
                // .style("fill", "#000")
                .style("fill", this.colorsOfZones[zoneIndex])
                .style("opacity", 1)
                .style("cursor", "grab")
                .attr("data-id", zoneIndex)
                .attr("class", "coreData coreData" + zoneIndex);

              coreData[zoneIndex].selectAll(".coreData").call(d3.drag()
                .on("drag", coreDataValsDrag)
              );
            });


            // ----- set coreData (Ending line.) -----
          }

          var line = d3.line()
            .defined(d => !isNaN(d.x))
            .x(d => x[gisOfThatIteration.name](d.x))
            .y(d => y(d.y));


          path[gisOfThatIteration.name] = plot.append("path")
            .datum(linesDataObj[gisOfThatIteration.name])
            .attr("class", "path " + gisOfThatIteration.name + "-line")
            .attr("fill", "none")
            .attr("clip-path", "url(#plotBoundaryClip)")
            .attr("stroke", plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"])
            .attr("stroke-width", 1.5)
            .attr("d", line);

          var componentContext = this;

          var yScaler = y;
          this.yScaler = y;

        }
        this.$store.commit("setGisAxisIndexBindingInPetroLinking", this.gisAxisIndexBinding);
        // break;
      }

      // zoom.scaleBy(SVG, 1 / 1.3);
      var store = this.$store;

      function updateChart(e) {
        if (!this.updatingChart) {

          this.updatingChart = true;
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "wheel") {
            if (d3.event.sourceEvent.deltaY > 0) {
              d3.event.transform.y += 150;
            } else {
              d3.event.transform.y -= 150;
            }
          } else {
            yPanFromOriginal = d3.event.transform.y;
          }
          if (d3.event.transform.k !== 1) {
            d3.event.transform.k = 1;
          }

          yPanFromOriginal = d3.event.transform.y;

          var newY = d3.event.transform.rescaleY(y);

          thisRef.currentPlotScrollY = newY.invert(0);
          yAxis.call(d3.axisLeft(newY).tickSize(-width - (margin.left * 1.5) - (margin.right * 1.5)));


          // ----- move coreData and originalCoreData (Starting line.) -----

          thisRef.bZoneItems.forEach((element, bZoneItemIndex) => {
            if (element.upperVal && element.bottomVal) {
              coreData[bZoneItemIndex]
                .selectAll("circle")
                .attr("cy", function (d) {
                  return newY(d.y);
                });
            }
          });
          SVG
            .selectAll(".originalCoreData")
            .attr("cy", function (d) {
              return newY(d.y);
            });
          // ----- move coreData and originalCoreData (Ending line.) -----

          // if (thisRef.activeBZoneItem != null) {
          thisRef.bZoneItems.forEach((element, zoneIndex) => {
            let bothValsSettedInThisBZZoneItem = element.upperVal && element.bottomVal;
            if (bothValsSettedInThisBZZoneItem) {
              scatter[zoneIndex]
                .selectAll(".user-zone")
                .attr("y", function (d) {

                  return newY(d.y);
                  // return newY(d.y - (lastUseBoundaryIndex === 2 ? userSelectZoneHeight : 0));
                });
            }
          });

          // for (let plotIndex = 0; plotIndex < totalParallelPlotsAmountWarranted; plotIndex++) {
          for (let i = 0; i < gisWithoutDept.length; i++) {
            let gisOfThatIteration = gisWithoutDept[i];
            let boundedPlotIndex = store.getters.getGisAxisIndexBindingInPetroLinking[gisOfThatIteration.name];
            let gisHasBinding = boundedPlotIndex !== null;
            if (gisHasBinding) {


              path[gisOfThatIteration.name].attr("transformY", d3.event.transform.y);

              line = d3.line()
                .defined(d => !isNaN(d.x))
                .x(d => x[gisOfThatIteration.name](d.x))
                .y(d => newY(d.y));

              path[gisOfThatIteration.name]
                .datum(linesDataObj[gisOfThatIteration.name])
                .attr("class", "path")
                .attr("fill", "none")
                .attr("clip-path", "url(#plotBoundaryClip)")
                .attr("stroke", plotsColors[gisOfThatIteration.name] !== undefined ? plotsColors[gisOfThatIteration.name] : plotsColors["default"])
                .attr("stroke-width", 1.5)
                .attr("d", line);
            }
          }
          // d3.select('body').on('click', function () {
          //     var yAxisClickedPlaceInPxFromTop = d3.event.pageY - document.querySelector('.yAxis').getBoundingClientRect().y - yPanFromOriginal;
          //     var yAxisClickedValue = y.invert(d3.event.pageY - document.querySelector('.yAxis').getBoundingClientRect().y - yPanFromOriginal);
          // });
          yScaler = newY;
          thisRef.yScaler = newY;


          this.updatingChart = false;
          SVG.selectAll(".tick line").attr("clip-path", "url(#plotBoundaryClipLegacy)");
          SVG.selectAll(".domain").attr("clip-path", "url(#plotBoundaryClipLegacy)");
        }
      }

      // plotsUpdaterFuncs[plotIndex] = updateChart;


      function coreDataValsDrag(d) {
        var draggedCircle = this;

        // ----- move circles that are in this cirle's zone (Starting line.) -----

        // // ----- old handler for boundary conditions (Starting line.) -----
        var zoneInWhichDraggedCircle = circleOwnedByWhichZone(this);
        var before = Number(yScaler.invert(d3.select(this).attr("cy")));
        var after = Number(yScaler.invert(d3.mouse(this)[1]));
        var delta = after - before;

        var beforeInPx = Number(d3.select(this).attr("cy"));
        var afterInPx = Number(d3.mouse(this)[1]);
        var deltaInPx = afterInPx - beforeInPx;

        var circleIdOfBoundedZone = draggedCircle.getAttribute("data-id");


        if (zoneInWhichDraggedCircle !== null) {
          if (!thisRef.checkAllowanceOfDelta(circleIdOfBoundedZone, deltaInPx)) {
            return;
          }
        }

        // ----- old handler for boundary conditions (Ending line.) -----

        SVG.selectAll(`.coreData[data-id="${circleIdOfBoundedZone}"]`).attr("cy", function (d) {
          const before = Number(yScaler.invert(d3.select(this).attr("cy")));
          d.y = Number(before + delta).toFixed(1);
          return yScaler(d.y);
        });
        // setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
        // serColorBlackToCoreData0IfOutsideAnyZone();

        // ----- move circles that are in this cirle's zone (Ending line.) -----
        thisRef.updateDeltaOfZone(circleIdOfBoundedZone, SVG);
      }

      function userZoneDrag2(d) {
        (userZoneDragGeneric.bind(this))(2, d);
        lastUseBoundaryIndex = 2;
      }

      function userZoneDrag1(d) {
        (userZoneDragGeneric.bind(this))(1, d);
        lastUseBoundaryIndex = 1;
      }

      function userZoneDragGeneric(indexOfZoneBoundary, d) {
        const before = Number(yScaler.invert(d3.select(this).attr("y")));
        const after = Number(yScaler.invert(d3.mouse(this)[1]));
        const delta = after - before;

        // ----- Part of relativity calculation (Starting line.) -----
        if (indexOfZoneBoundary === 2) {
          userSelectZoneHeight += delta;
        }
        if (indexOfZoneBoundary === 1) {
          userSelectZoneHeight += delta;
        }
        // ----- Part of relativity calculation (Ending line.) -----
        document.querySelector("#userSelectZoneHeight").innerText = userSelectZoneHeight;

        SVG.selectAll(".user-zone-boundary-" + indexOfZoneBoundary).attr("y", function (d) {
          const before = Number(yScaler.invert(d3.select(this).attr("y")));

          if (indexOfZoneBoundary === 2) {

            var isBottomUselectBoundaryOnTopOfBottomOrEqual = (Number(before + delta)) < Number(thisRef.activeBZoneItem.querySelector("input.upper-value").value) - 0.1;
          }
          if (indexOfZoneBoundary === 1) {
            var isBottomUselectBoundaryOnTopOfBottomOrEqual = Number(thisRef.activeBZoneItem.querySelector("input.bottom-value").value) < (Number(before + delta)) - 0.1;
          }

          if (isBottomUselectBoundaryOnTopOfBottomOrEqual) {
            d.y = Number(before + delta);
            if (indexOfZoneBoundary === 2) {
              document.querySelector(".secondBoundaryPositionY").innerText = d.y;
              thisRef.activeBZoneItem.querySelector("input.bottom-value").value = Number(d.y).toFixed(1);
            }
            if (indexOfZoneBoundary === 1) {
              document.querySelector(".firstBoundaryPositionY").innerText = d.y;
              thisRef.activeBZoneItem.querySelector("input.upper-value").value = Number(d.y).toFixed(1);
            }
            return yScaler(d.y);
          } else {
            return yScaler(d.y);
          }

        });
      }


      // ----- hide initialized CoreData dots that are outside their respective (binded) zone (Starting line.) -----
      setDisplayNoneToAllCoreDataOutsideTheirBoundedZones();
      // ----- hide initialized CoreData dots that are outside their respective (binded) zone (Ending line.) -----
      serColorBlackToCoreData0IfOutsideAnyZone();

      // ----- fix initial binding ignored by plot header (Starting line.) -----
      if (initialBindingNeeded) {
        this.$forceUpdate();
      }
      // ----- fix initial binding ignored by plot header (Ending line.) -----

      SVG.selectAll(".tick line").attr("clip-path", "url(#plotBoundaryClipLegacy)");
      SVG.selectAll(".domain").attr("clip-path", "url(#plotBoundaryClipLegacy)");

    }
  }
};
</script>

<style scoped lang="scss">
.delta-save-button {
    background: #18A0FB;
    border-radius: 12px;
    color: white;
    font-size: 1.5rem;
    padding: 1rem 2rem 1rem 2rem;
}

#b-zone button {
    margin-top: 16px !important;
}

#b-zone {
    padding: 40px;
    border-radius: 25px;
    background: #f0f0f0;
    min-width: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.created-zones .created-zones__header span,
.created-zones ol li > * {
    padding: 10px;
    display: inline-block;
    border: 1px solid lightgrey;
    // width: 24.7%;
    width: 28%;
}

main button {
    background: #18A0FB;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    padding: 10px 26px;
    transition: all .2s ease-in-out;
}

main button:hover {
    background: #a5a5a575;
    color: #18A0FB;
}

.created-zones ol {
    counter-reset: zoneIndex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.created-zones li {
    position: relative;
}

.created-zones input {
    width: 100%;
}

.created-zones {
    max-height: 218px;
    overflow-y: auto;

    min-width: 550px;
    width: 100%;
    background: #f0f0f0;
    border-radius: 10px;
    padding-right: 0;
    // border: 1px solid lightgrey;
    padding: 12px 20px;
}


.created-zones .created-zones__header::before, .created-zones li::before {
    width: 16%;
    display: inline-block;
    text-align: center;
    border: 1px solid lightgrey;
    padding: 10px;
    padding-left: 40px;
}

.created-zones .created-zones__header::before {
    content: attr(data-content);
    padding-left: 10px;
}

.created-zones li::before {
    counter-increment: zoneIndex +1;
    content: counter(zoneIndex);
}


.plot-header {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    margin-top: 40px;
    z-index: 2;
    position: relative;
}

.plot-header article {
    flex: 1;
    padding: 0 30%;
    box-sizing: border-box;
}

.plot-header .scale {
    text-align: center;
    transform: rotate(270deg);
}

.scale {
    padding-bottom: 24px;
}

.dropdown label,
.scale,
.plot-selects h4 {
    cursor: pointer;
}

.dropdown {
    min-width: 100px;
    position: absolute;
    top: 100%;
    opacity: 0;
    transition: all .4s;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 10px 15px;
    box-sizing: border-box;
    z-index: -1;
    pointer-events: none;
    border: 1px solid grey;
    border-radius: 5px;

    max-width: 300px;
    transform: translateX(-50%);
    left: 50%;
}

h4.now-active + .dropdown {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
}

.plot-selects {
    flex: 0 0 100%;
    display: flex;
    justify-content: space-around;
    padding-left: 10%;
}

.plot-selects article {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 50px;
}

#graph-wrapper {
    margin-top: 30px;
    margin-left: calc(10% - 20px);
}

.dropdown-container {
    position: relative;
}

.scales-selects label, .scales-selects input {
    font-size: 13px;
}

.scales-selects {
    display: flex;
    flex-direction: column;
    width: 100%
}

.plot-selects button {
    padding: 5px 15px;
    font-size: 14px;
    margin-left: 10px;
}

.plot-selects article {
    padding: 10px;
    padding-left: 0;
}

.created-zones [data-id]:not(.now-active) {
    cursor: pointer;
}

.created-zones [data-id].now-active {
    background: lightgrey;
}

.to-hide {
    display: none !important;
}

.plots-chooser input[type="number"] {
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
}

.created-zones input[type="number"] {
    outline: 1px solid black;
    border-radius: 5px;
    padding-left: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
}

.created-zones li .color {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: none;
    left: 7px;
    top: 48%;
    transform: translateY(-50%);
}

.remove-btn, .add-btn, .revert-all {
    box-sizing: border-box;
    width: 40px !important;
    margin: 2px 5px !important;
    margin-right: 0 !important;
    padding: 10px !important;
    // height: 32px;
    height: 55px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    // font-weight: 900;
    border: none !important;
}

.revert-all {
    margin-left: 0 !important;
    width: 295px !important;
}

.add-btn {
    width: 90px !important;
    // margin-right: 10% !important;
    margin-top: 10px !important;
}

.created-zones__controls {
    display: flex;
    justify-content: space-between;
}

*::-webkit-scrollbar {
    width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: rgb(167, 167, 167);
    cursor: grab;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: rgb(105, 105, 105);
}

.tick line {
    stroke: lightgrey !important;
}

.wells-info__title {
  font-size: 1.4rem;
  font-weight: 700;
  user-select: none;
  padding: 0rem;
}

.right_side{
    margin-left: 2%;
}

</style>