import Vue from "vue";
import VueRouter from "vue-router";
import GISViewer from "@/views/geology/GeologyGISViewer";
import Login from "@/views/Login";
import authService from "@/service/auth-service";
import GeologyMainPageView from "@/views/geology/GeologyMainPageView";
import MapView from "@/views/geology/GeologyMapView";
import InterpolationCalcView from "@/views/geology/calcs/InterpolationCalcView";
import ReservesCalcView from "@/views/geology/calcs/ReservesCalcView";
import StartPageView from "@/views/StartPageView";
import EpisodesPageView from "@/views/EpisodesPageView";
import store from "@/store";
import PetrophysicsMainPageView from "@/views/petrophysics/PetrophysicsMainPageView";
import CoreView from "@/views/petrophysics/CoreView";
import LinkingView from "@/views/petrophysics/LinkingView";
import CalcPoroView from "@/views/petrophysics/CalcPoroView";
import CalcPermView from "@/views/petrophysics/CalcPermView";
import CalcSaturView from "@/views/petrophysics/CalcSaturView";
import AboutView from "@/views/AboutView";
import CreatorsView from "@/views/CreatorsView";
import RatingsView from "@/views/RatingsView";
import GlossaryView from "@/views/GlossaryView";
import UserProfileView from "@/views/UserProfileView";
import ForgotPassword from "@/views/ForgotPassword";
import ChangePassword from "@/views/ChangePassword";
import ChangePasswordUnauhorized from "@/views/ChangePasswordUnauhorized";
import ActivateAccount from "@/views/ActivateAccount";
import HistoryMainPageView from "@/views/history/HistoryMainPageView";
import HistoryDashbordView from "@/views/history/HistoryDashbordView";
import OwcView from "@/views/petrophysics/OwcView";
import OwcCalcView from "@/views/petrophysics/OwcCalcView";
import PetrophysicsMap from "@/views/petrophysics/PetrophysicsMap";
import i18n from "../i18n";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "start",
    component: StartPageView,
    meta: {
      authRequired: false,
      layout: "no-navigation",
      title: i18n.t("page-titles.home")
    }
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
    meta: {
      authRequired: false,
      layout: "no-navigation",
      title: i18n.t("page-titles.about")
    }
  },
  {
    path: "/creators",
    name: "creators",
    component: CreatorsView,
    meta: {
      authRequired: false,
      layout: "no-navigation",
      title: i18n.t("page-titles.creators")
    }
  },
  {
    path: "/ratings",
    name: "ratings",
    component: RatingsView,
    meta: {
      authRequired: true,
      layout: "base-layout",
    }
  },
  {
    path: "/faq",
    name: "faq",
    component: GlossaryView,
    meta: {
      authRequired: true,
      layout: "base-layout",
    }
  },
  {
    path: "/user-profile",
    name: "user-profile",
    component: UserProfileView,
    meta: {
      authRequired: true,
      layout: "base-layout",
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      authRequired: false,
      layout: "no-navigation",
    }
  },
  {
    path: "/password-reset/:uidb64/:token",
    name: "password-reset",
    component: ChangePasswordUnauhorized,
    meta: {
      authRequired: false,
      layout: "no-navigation",
    }
  },
  {
    path: "/activate/:uidb64/:token",
    name: "activate",
    component: ActivateAccount,
    meta: {
      authRequired: false,
      layout: "no-navigation",
    }
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: {
      authRequired: true,
      layout: "base-layout",
    }
  },
  {
    path: "/episodes",
    name: "episodes",
    component: EpisodesPageView,
    meta: {
      authRequired: true,
      layout: "no-navigation",
    }
  },
  {
    path: "/geology",
    name: "geology",
    component: GeologyMainPageView,
    meta: {
      authRequired: true,
      layout: "geology-layout"
    }
  },
  {
    path: "/geology/map",
    name: "geology-map",
    component: MapView,
    meta: {
      authRequired: true,
      layout: "geology-layout"
    }
  },
  {
    path: "/geology/gis/:id",
    name: "geology-gis",
    component: GISViewer,
    meta: {
      authRequired: true,
      layout: "geology-layout"
    },
    props: (route) => {
      const id= Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/geology/calc/interpolation",
    name: "geology-interpolation",
    component: InterpolationCalcView,
    meta: {
      authRequired: true,
      layout: "geology-layout"
    }
  },
  {
    path: "/geology/calc/reserves",
    name: "geology-reserves",
    component: ReservesCalcView,
    meta: {
      authRequired: true,
      layout: "geology-layout"
    }
  },
  {
    path: "/history",
    name: "history",
    component: HistoryMainPageView,
    meta: {
      authRequired: true,
      layout: "history-layout"
    }
  },
  {
    path: "/history/dashbord",
    name: "history-dashbord",
    component: HistoryDashbordView,
    meta: {
      authRequired: true,
      layout: "history-layout"
    }
  },
  {
    path: "/petrophysics",
    name: "petrophysics",
    component: PetrophysicsMainPageView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/core",
    name: "petrophysics-core",
    component: CoreView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/depth-matching",
    name: "petrophysics-depth-matching",
    component: LinkingView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/calc-poro",
    name: "petrophysics-calc-poro",
    component: CalcPoroView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },
  {
    path: "/petrophysics/calc-perm",
    name: "petrophysics-calc-perm",
    component: CalcPermView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/calc-saturation",
    name: "petrophysics-calc-saturation",
    component: CalcSaturView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/map",
    name: "petrophysics-map",
    component: PetrophysicsMap,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/owc/:id",
    name: "petrophysics-owc",
    component: OwcView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    },
    props: (route) => {
      const id= Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },

  {
    path: "/petrophysics/owc-calc",
    name: "petrophysics-owc-calc",
    component: OwcCalcView,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    }
  },

  {
    path: "/petrophysics/gis/:id",
    name: "petrophysics-gis-view",
    component: GISViewer,
    meta: {
      authRequired: true,
      layout: "petrophysics-layout"
    },
    props: (route) => {
      const id= Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },


  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "auth"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach(async (to, from, next) => {
  let metaTitle = to.meta.title;
  if (metaTitle) {
    document.title = i18n.t("page-titles.main-name")+" - "+ to.meta.title;
  } else {
    document.title = i18n.t("page-titles.main-name");
  }
  

  const isAuthorized = authService.isAuthorized();

  if (to.name === "login" && isAuthorized) {
    next({name: "episodes"});
    return;
  }

  if (!to.meta.authRequired) {
    next();
    return;
  }

  if (to.meta.authRequired && !isAuthorized) {
    next({name: "login"});
    return;
  }

  if (to.name !== "login" && store.state.user == null) {
    await store.dispatch("getUserDetails").then(() => {
      next();
    });
  }

  next();
});

export default router;
