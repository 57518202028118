import httpClient from "@/http";

class GridPropertiesService {

  getList(data = {}, surface_name) {
    return httpClient.get(`/api/v1/geology/grid/properties/${surface_name}/`, this.clearParams(data));
  }

  get(id) {
    return httpClient.get(`/api/v1/geology/grid/property/${id}`);
  }
  getName(name) {
    return httpClient.get(`/api/v1/geology/grid/property/${name}`);
  }
  clearParams(data) {
    for(const [key, value] of Object.entries(data)) {
      if(value === null) {
        delete data[key];
      }
    }
    return data;
  }
}

export default new GridPropertiesService();