<template>
  <v-container
    style=" margin-top: 80px;"
    fluid
  >
    <v-combobox
      v-model="selectedWell"
      :items="wellWithCoreData"
      :label="$t('common.well')"
      item-text="name"
      item-value="0"
      outlined
      @change="getWellCoreData"
    />
    <table>
      <tr>
        <th
          v-for="columnName in tableColumns"
          :key="columnName"
        >
          {{ columnName.replace('_CORE', '') }}
        </th>
      </tr>
      <tr
        v-for="row in tableData"
        :key="row.depth"
      >
        <td
          v-for="(column, index) in row"
          :key="index"
        >
          {{ Number(column).toFixed(2) }}
        </td>
      </tr>
    </table>
  </v-container>
</template>

<script>
import wellService from "@/service/well-service";

export default {
  name: "CoreView",
  data() {
    return {
      tableColumns: [],
      tableData: [],
      depthDelta: 0,
      wellWithCoreData: [],
      selectedWell: null,
    };
  },
  mounted() {
    wellService.getWellWithCores().then(({data}) => {
      this.wellWithCoreData = data;
      this.selectedWell = data[0];
      this.getWellCoreData(this.selectedWell);
    });
  },
  methods: {
    getWellCoreData(well) {
      if (!well) {
        return;
      }
      wellService.getCores(well.id).then(({data}) => {
        if (data.length === 0) {
          this.tableColumns = [];
          return;
        }
        this.depthDelta = well.core_delta;
        this.tableColumns = ["depth_corr", "depth"];
        for (let core of data) {
          this.tableColumns.push(core.name);
        }
        const tableData = [];
        const dataLength = data[0].data.length;
        const dataDept = data[0].data.map(el => el.dept);
        for (let i = 0; i < dataLength; i++) {
          let tableRow = {
            delta: dataDept[i] + this.depthDelta,
            depth: dataDept[i]
          };
          for (let core of data) {
            tableRow[`${core.name}`] = core.data[i].val;
          }
          tableData.push(tableRow);
        }
        this.tableData = tableData;
      });
    }
  }
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

td, th {
  border: 1px solid #bbb4b4;
  margin: 0
}
</style>