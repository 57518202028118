<template>
  <div>
    <Map />
    <v-dialog
      v-model="showDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("geology.map-pop-up.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("geology.map-pop-up.text-1") }}<span class="font-weight-bold">{{ $t("geology.map-pop-up.text-2") }}</span> {{ $t("geology.map-pop-up.text-3") }}
        </v-card-text>

        <v-card-actions>
          <v-checkbox
            v-model="dontShowAgain"
          />
          <span>{{ $t("common.dont-show") }}</span>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="closeDialog"
          >
            {{ $t("common.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Map from "@/components/Map";

export default {
  name: "MapView",
  components: {Map},
  data() {
    return {
      showDialog: true,
      dontShowAgain: false
    };
  },
  beforeMount() {
    this.showDialog = JSON.parse(localStorage.getItem("dontShowDialogOnOpen")) !== true;
  },
  methods: {
    closeDialog() {
      if (this.dontShowAgain) {
        localStorage.setItem("dontShowDialogOnOpen", JSON.stringify(true));
      }
      this.showDialog = false;
    }
  }
};
</script>

<style scoped>

</style>