<template>
  <v-container
    class="auth-container"
    fluid
    fill-height
    @keyup.enter="submit"
  >
    <v-row class="justify-center pa-0">
      <v-col cols="auto">
        <v-form
          ref="authForm"
          v-model="isAuthFromValid"
          class="auth-form"
        >
          <v-container>
            <v-row>
              <v-text-field
                v-if="!isLogin"
                v-model="firstName"
                :label="$t('auth-form.firstName')"
                clearable
                :rules="requiredRule"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-if="!isLogin"
                v-model="lastName"
                :label="$t('auth-form.lastName')"
                clearable
                :rules="requiredRule"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="email"
                :label="$t('auth-form.email')"
                clearable
                :rules="emailRules"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="password"
                type="password"
                :rules="passwordRules"
                :label="$t('auth-form.password')"
                clearable
              />
            </v-row>
            <v-row class="mt-5">
              <v-btn
                width="100%"
                class="blue lighten-4"
                @click="submit"
              >
                {{ buttonText }}
              </v-btn>
            </v-row>
            <v-row class="justify-center pa-2">
              <span
                class="form-navigation text-center text-uppercase"
                @click="isLogin = !isLogin"
              >{{ navigationText }}</span>
            </v-row>
          </v-container>
        </v-form>
        <v-row
          v-if="isLogin"
          class="justify-center pa-2"
        >
          <router-link
            class="form-navigation text-center justify-center" 
            :to="{name:'forgot-password'}"
            :exact-path="true"
          >
            {{ $t("auth-form.forgot") }}
          </router-link>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      right
      :color="snackbar.color"
      elevation="24"
      :text="false"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import authService from "@/service/auth-service";
import store from "@/store";
export default {
  name: "Login",
  data() {
    return {
      emailRules: [
        v => !!v || this.$t("common.emailRequired"),
        v => /.+@.+\..+/.test(v) || this.$t("common.emailIncorrect"),
      ],
      passwordRules: [
        v => !!v || this.$t("auth-form.pwdRequired"),
        v => (v && v.length >= 5) || this.$t("auth-form.pwdLength"),
      ],
      requiredRule: [value => !!value || this.$t("common.fieldRequired")],

      isAuthFromValid: false,
      isLogin: true,
      email: "",
      password: "",
      firstName: "",
      lastName: "",

      snackbar: {
        show: false,
        color: null,
        message: "",
      }
    };
  },
  computed: {
    buttonText() {
      if (this.isLogin) {
        return this.$t("auth-form.sign-in");
      }
      return this.$t("auth-form.sign-up");;
    },
    navigationText() {
      if (this.isLogin) {
        return this.$t("auth-form.register");
      }
      return this.$t("auth-form.sign-in");
    }
  },
  methods: {
    submit() {
      this.$refs.authForm.validate();
      if (!this.isAuthFromValid) {
        return;
      }
      if (this.isLogin) {
        this.login();
      } else {
        this.register();
      }
    },
    async register() {
      await authService.register({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password
      }).then(async (data) => {
        this.snackbar = {
          show: true,
          message: this.$t("auth-form.emailSent"),
          color: "success"
        };
        this.isLogin = true;
        this.$refs.authForm.reset();
      }).catch(err => {
        this.snackbar = {
          show: true,
          message: this.$t("auth-form.userExists"),
          color: "error"
        };
      });
    },
    async login() {
      await authService.login({
        email: this.email,
        password: this.password
      }).then(async () => {
        await store.dispatch("getUserDetails");
        authService.authorized();
        await this.$router.push({name: "episodes"});
      }).catch(err => {
        if (err.response.status === 401) {
          this.snackbar = {
            show: true,
            message: this.$t("common.wrongEmailOrPwd"),
            color: "error"
          };
        } else if (err.response.status === 403){
          this.snackbar = {
            show: true,
            message: this.$t("common.accountNotActivated"),
            color: "error"
          }; 
        }
      } );
    }
  }
};
</script>

<style scoped lang="scss">

.auth-container {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.auth-form {
  width: 300px;
}

.form-navigation {
  color: blue;
  font-size: .7rem;
  user-select: none;

  &:hover {
    cursor: pointer;
    text-shadow: 1px 1px 15px black;
  }
}

</style>