<!-- eslint-disable vue/no-lone-template -->
<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-row>
      <v-col>
        <div
          id="three-scene"
          :style="'min-width: ' + scene3DFieldWidth + 'px; min-height: ' + scene3DFieldHeight + 'px;'"
        >
          <div 
            v-if="threeLoading == true"
            class="progress-container"
          >
            <v-progress-circular
              :width="8"
              :size="170"
              color="grey"
              indeterminate
            />
          </div>
          <div id="color-map" />
          <div id="three-scene-axes-window" />
        </div>
      </v-col>
      <v-col style="padding-right: 23px; padding-left: 23px;">
        <v-row>
          <div>
            <h3 class="mt-2">
              {{ $t("geology.3d.title") }} &nbsp;<v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    slot="activator"
                    dark
                    color="#009797"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>Для построения объемной модели нужно выполнить интерполяцию свойств, а затем выбрать свойство для отображения</span>
              </v-tooltip>
            </h3>
          </div>
        </v-row>
        <v-row>
          <v-col class="surface-selector">
            <v-select
              v-model="selected_layer"
              :items="layers"
              :label="$t('common.layer')"
              return-object
              outlined
              @change="getGridData"
            />
          </v-col>
        </v-row>
        <v-row style="display: flex;flex-direction: column;">
          <button 
            class="run-button" 
            @click="run"
          >
            {{ $t("geology.3d.button") }} 
          </button>
          <v-row style="margin: 12px 5px;">
            <input
              id="actnum"
              v-model="toggle"
              type="checkbox"
              :disabled="!interpolated || propertiyToDisplay === null"
              @change="selectedLayerChange"
            >
            <label
              for="actnum"
              class="actnum__label"
            >ACTNUM</label>
          </v-row>
          <v-column>
            <V-row>
              <template>
                <input
                  id="profileX"
                  v-model="toggleProfileX"
                  type="checkbox"
                  :disabled="!interpolated || propertiyToDisplay === null"
                  @change="profileXchange"
                >
                <label
                  for="profileX"
                  class="profile__label"
                >{{ $t("geology.3d.axis-x") }} </label>
                <input
                  v-model="sliderValue"
                  type="range"
                  style="width: 250px;"
                  step="1"
                  min="0"
                  :max="sliderDegreeGrid1"
                  class="slider"
                  :disabled="!toggleProfileX"
                  @change="profileXchange"
                >
                <input
                  v-model="sliderValue"
                  class="slider__numb"
                  type="number"
                  min="0"
                  max="73"
                  @change="profileXchange"
                >
              </template>
            </V-row>
            <V-row>
              <template>
                <input
                  id="profileY"
                  v-model="toggleProfileY"
                  type="checkbox"
                  :disabled="!interpolated || propertiyToDisplay === null"
                  @change="profileXchange"
                >
                <label
                  for="profileY"
                  class="profile__label"
                >{{ $t("geology.3d.axis-y") }} </label>
                <input
                  v-model="sliderValueY"
                  type="range"
                  style="width: 250px;"
                  step="1"
                  min="0"
                  :max="sliderDegreeGrid2"
                  class="slider"
                  :disabled="!toggleProfileY"
                  @change="profileXchange"
                >
                <input
                  v-model="sliderValueY"
                  class="slider__numb"
                  type="number"
                  min="0"
                  max="126"
                  @change="profileXchange"
                >
              </template>
            </V-row>
            <v-row class="gis-checkbox">
              <template>
                <input
                  id="displayHistogramms"
                  v-model="toggleDisplayBarChart"
                  style="width: 15px;"
                  type="checkbox"
                  @change="displayBarChartChange"
                >
                <label
                  for="displayHistogramms"
                  class="profile__label"
                  style="width: 250px;"
                >&nbsp;{{ $t("geology.3d.show-hist") }}  </label>
              </template>
            </V-row>
          </v-column>
          <v-select
            v-model="propertiyToDisplay"
            :disabled="!interpolated"
            :label="$t('geology.3d.property')"
            :items="propertiesToShow"
            @change="propertiyToDisplayChange"
          />
          <v-select
            v-model="zScale"
            :disabled="!interpolated || propertiyToDisplay === null"
            :label="$t('geology.3d.vertical-scale')"
            :items="zScaleOptions"
            @change="changeZScale"
          />
          <v-select
            v-model="selectedLayerIndex"
            clearable
            :disabled="!interpolated || propertiyToDisplay === null"
            :label="$t('geology.3d.layer')"
            :items="layersIndexes"
            @change="selectedLayerChange"
          />
          <v-select
            v-model="gisToDisplay"
            :disabled="!interpolated || propertiyToDisplay === null"
            :label="$t('common.well-log')"
            :items="gisToShow"
            @change="gisToDisplayChange"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row />
    <v-snackbar 
      v-model="isSuccess" 
      :timeout="4500" 
      right 
      color="green" 
      class="text--black" 
      elevation="24"
    >
      {{ $t("geology.3d.success") }}
    </v-snackbar>
    <v-snackbar 
      v-model="isError" 
      :timeout="4500" 
      right 
      color="red accent-2" 
      elevation="24"
    >
      {{ $t("common.error") }}
    </v-snackbar>
    <v-overlay v-model="isLoading">
      <v-progress-circular 
        indeterminate 
        size="64" 
      />
    </v-overlay>
  </v-container>
</template>
<script>

import { ref } from "vue";
import surfaceService from "@/service/surface-service";
import gridService from "@/service/grid-service";
import wellService from "@/service/well-service";
import gridPropertiesService from "@/service/grid-properties-service";
import * as THREE from "three";
import { NURBSSurface } from "three/addons/curves/NURBSSurface.js";
import { ParametricGeometry } from "three/addons/geometries/ParametricGeometry.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { ConvexGeometry } from "three/addons/geometries/ConvexGeometry.js";
import {
  CSS2DRenderer,
  CSS2DObject
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import {
  CSS3DRenderer, 
  CSS3DObject
} from "three/examples/jsm/renderers/CSS3DRenderer.js";
import { Lut } from "three/addons/math/Lut.js";
export default {
  name: "InterpolationCalcView",
  // Для сброса мусора
  beforeRouteLeave(to, from, next) {
    location.href = to.fullPath;
    //next();
  },
  data() {
    return {
      layers: [],
      selected_layer: "",
      layer_to_push: "",
      isBot: false,
      threeLoading: false,
      isError: false,
      selectedLayerIndex: "all",
      zScaleOptions: [1, 5, 7, 10, 15],
      gisToDisplay: null,
      propertiyToDisplay: null,
      scene3DFieldWidth: 1200,
      scene3DFieldHeight: 850,
      zScale: 1,
      degreeGrid1: 0,
      degreeGrid2: 0,
      sliderDegreeGrid1: 0,
      sliderDegreeGrid2: 0,
      currentCameraProps: {
        zoom: 5,
      },
      isLoading: false,
      isSuccess: false,
      toggle: false,
      toggleProfileX: false,
      toggleProfileY: false,
      sliderValue: ref(0),
      sliderValueY: ref(0),
      sceneIndex: 0,
      propertiesToShow: [],
      gisToShow: [],
      propertiesToShowId: [],
      mapData: [],
      actnum: [],
      secondWell:[],
      iteration: 0,
      disposeAll: false,
      geometry: new THREE.BufferGeometry(),
      uiScene: new THREE.Scene(),
      uiScene2: new THREE.Scene(),
      gridLinePoints: [],
      data:[{
        x: [1,2,3,4],
        y: [10,15,13,17],
        type:"scatter"
      }],
      layout:{
        title: "My graph"
      },
      mesh: null,
      toggleDisplayBarChart: true,
      wellSpecificGis: []
    };
  },
  watch: {
    async isBot() {
      await this.getMapData(this.layer_to_push);
      await this.getGridData(this.layer_to_push);
			 
    }
  },
  async beforeMount() {
    	
  },
  async created() {
    this.selected_layer = await this.getSurfaceNames();
    this.layer_to_push = await this.getLayerToPush(this.selected_layer);
    // ----- right and left buttons simultaneous click trascking (Starting line.) -----
    var leftButtonDown = false;
    var rightButtonDown = false;
	
    document.addEventListener("mousedown", function (e) {
      if (e.which == 1) {
        leftButtonDown = true;
      } else if (e.which == 3) {
        rightButtonDown = true;
      }
    });
	
    document.addEventListener("mouseup", function (e) {
      if (e.which == 1) {
        leftButtonDown = false;
      } else if (e.which == 3) {
        rightButtonDown = false;
      }
    });
    var thisRef = this;
    document.addEventListener("mousedown", function () {
      if (leftButtonDown && rightButtonDown) {
        thisRef.returnToDefaultCameraZoom();
      }
    });
    // ----- right and left buttons simultaneous click trascking (Ending line.) -----
    const response = await gridPropertiesService.getList({}, this.layer_to_push);
    response.data.forEach((item) => {
      this.propertiesToShow.push(item.name);
      this.propertiesToShowId.push(item.id);
    });
    await this.getMapData(this.layer_to_push);
    await this.getGridData(this.layer_to_push);
		
    //this.create3DField(true); // true is by tech task given to me : "По умолчанию на белом фоне отображается только направление координатных осей в правом углу области построения 3D модели."
  
  },
  methods: {
    async getSurfaceNames(){
      await surfaceService.getAllSurfaces().then(async ({data}) => {
        this.layers = [];
        for (var i = 0; i < data.length; i++) {
          switch (data[i].zone_name) {
          case "Башкирский":
            this.layers.push(this.$t("common.bashkir"));
            break;
          case "Верейский":
            this.layers.push(this.$t("common.verey"));
            break;
          case "Серпуховский":
            this.layers.push(this.$t("common.serpukhov"));
            break;
          default:
            this.layers.push(data[i].zone_name);}
        }
        this.selected_layer = this.layers[0];});

      return this.layers[0];
    },

    getLayerToPush(selected_layer) {
      const layer_to_push = "";
      switch (selected_layer) {
      case this.$t("common.bashkir"):
        this.layer_to_push = "Башкирский";
        break;
      case this.$t("common.verey") :
        this.layer_to_push = "Верейский";
        break;
      case this.$t("common.serpukhov") :
        this.layer_to_push = "Серпуховский"; 
        break;
      default:
        this.layer_to_push = this.selected_layer;
      }
      return this.layer_to_push;
    },
   
    median(numbers) {
      const sorted = Array.from(numbers).sort((a, b) => a - b);
      const middle = Math.floor(sorted.length / 2);

      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
      }

      return sorted[middle];
    },
    async gisToDisplayChange() {
      const testEl = document.querySelectorAll("#tester");
      for (let i = 0; i < testEl.length; i++) {
        const wellplot = this.scene.getObjectByName(`wellplot${i}`);
        this.scene.remove(wellplot);
        const wellmesh = this.scene.getObjectByName(`wellmesh${i}`);
        this.scene.remove(wellmesh);
        const welllabel = this.scene.getObjectByName(`wellLabel${i}`);
        this.scene.remove(welllabel);
        const colorMaxText = this.uiScene2.getObjectByName("colorMaxText");
        this.uiScene2.remove(colorMaxText);
        const colorMidText = this.uiScene2.getObjectByName("colorMidText");
        this.uiScene2.remove(colorMidText);
        const colorMinText = this.uiScene2.getObjectByName("colorMinText");
        this.uiScene2.remove(colorMinText);
      }
      await this.create3DField();

      let gridLinePointsNew = [];
      var gridData = this.gridData[0];
      for (let gridIndex = 0; gridIndex < gridData.grid.length; gridIndex++) {
        let	gridX = (gridData.grid[gridIndex][0] - gridData.grid[0][0]);
        let	gridY = (gridData.grid[gridIndex][1] - gridData.grid[0][1]);
        let gridZ = ((gridData.grid[gridIndex][2] - gridData.grid[0][2]) * this.zScale) * -1;
        gridLinePointsNew.push(gridX, gridY, gridZ);
      }
      this.geometry.setAttribute( "position", new THREE.Float32BufferAttribute(gridLinePointsNew, 3 ) );
      this.geometry.getAttribute( "position").needsUpdate = true;
    },
    async getGridData(layer) {
      const gridData=[];
      const grid = await gridService.getGrid(layer).then(({ data }) => {
        return data;
      });
      gridData.push({
        ...grid,
        type: "grid",
        colorscale: "Earth",
      });
      this.gridData = gridData;
    },
    async getMapData(layer) {
      const mapData = [];
      const surface = await surfaceService.getSurface(layer).then(({ data }) => {
        return data;
      });
      const max = Math.max(...[].concat(...surface.bot.z));
      const min = Math.min(...[].concat(...surface.top.z));
	  
      surface.top.z = surface.top.z.map(arr => {
        return arr.map(el => {
          return -el;
        });
      });
	  
      surface.bot.z = surface.bot.z.map(arr => {
        return arr.map(el => {
          return -el;
        });
      });
	
      if (!this.isBot) {
        mapData.push({
          ...surface.top,
          type: "surface",
          colorscale: "Earth",
        });
      } else {
        mapData.push({
          ...surface.bot,
          type: "surface",
          colorscale: "Earth",
        });
      }
      this.threeLoading = true;

      const wells = await wellService.getAllWells().then(({ data }) => {
        return data;
      });
      this.allWells = wells;
      let arrayOfGis = [];
      const wellsGisName = await wellService.getGisName().then(({ data }) => {
        return data;
      });
      for (let gisNameIndex = 0; gisNameIndex < wellsGisName.length; gisNameIndex++) {
        arrayOfGis.push(wellsGisName[gisNameIndex].name);					
      }
      this.gisToShow = arrayOfGis;	
      this.gisToDisplay =this.gisToShow[0];
      this.threeLoading = false;

      this.mapData = mapData;
    },
  
    async create3DField(hideMainScene = false) {
  
      let canvasBeenCreatedAndremovingWarranted = document.querySelector("#three-scene canvas") !== null;
      if (canvasBeenCreatedAndremovingWarranted) {
        document.querySelectorAll("#three-scene :not(#three-scene-axes-window, #color-map)").forEach(e=>e.remove());
      }
  
      this.threeLoading = true;
  
      var width = this.scene3DFieldWidth,
        height = this.scene3DFieldHeight;
  
      this.iteration += 1;
      var scene2 = new THREE.Scene(); // For small cordinate sustem at corner
  
      var renderer2 = new THREE.WebGLRenderer();
      renderer2.setSize(110, 110);
      renderer2.setClearColor( 0xffffff, 0);
      renderer2.domElement.style.zIndex = 5;
      document.querySelector("#three-scene-axes-window").innerHTML = "";
      document.querySelector("#three-scene-axes-window").appendChild(renderer2.domElement);
      var renderer3 = new THREE.WebGLRenderer();
      renderer3.setSize(100, 310);
      renderer3.setClearColor( 0xffffff, 0);
      renderer3.domElement.style.zIndex = 4;
      renderer3.domElement.style.position = "absolute";
      renderer3.domElement.style.left = "calc(31px / 2)";
      renderer3.domElement.style.top = "0px";

      var renderer4 = new THREE.WebGLRenderer();
      renderer4.setSize(100, 310);
      renderer4.setClearColor( 0xffffff, 0);
      renderer4.domElement.style.zIndex = 4;
      renderer4.domElement.style.position = "absolute";
      renderer4.domElement.style.left = `calc(${this.scene3DFieldWidth}px - (122px + 31px / 2))`;
      renderer4.domElement.style.top = "0px";

      document.querySelector("#color-map").innerHTML = "";
      document.querySelector("#color-map").appendChild(renderer3.domElement);
      document.querySelector("#color-map").appendChild(renderer4.domElement);
			  
      var threeDwellsRenderer = new CSS3DRenderer();
      threeDwellsRenderer.setSize(width, height);
      threeDwellsRenderer.domElement.style.position = "absolute";
      threeDwellsRenderer.domElement.style.top = "0px";
      threeDwellsRenderer.domElement.style.zIndex = 3;
      document.querySelector("#three-scene").appendChild(threeDwellsRenderer.domElement);

      this.layer_to_push = this.getLayerToPush(this.selected_layer);	
      this.wellSpecificGis = await wellService.getGisAllLayer(this.gisToDisplay, this.layer_to_push ).then(({ data }) => {
        return data;
      });
      const wellMeshSizeZ = 500 * this.zScale / this.zScaleOptions[0];
      for (let i = 0; i < this.wellSpecificGis.length; i++) { 
        const well = this.wellSpecificGis[i];
        let whereToPlaceX = well.x - this.mapData[0].x[0];
        let whereToPlaceY = well.y - this.mapData[0].y[0];
        var plot = document.createElement("div");
        plot.setAttribute("id", "tester");
        plot.setAttribute("class", `tester${i}`);

        wellPlot = new CSS3DObject(plot);
        wellPlot.name = "wellplot" + i;
        wellPlot.position.copy(new THREE.Vector3(whereToPlaceX + 80, whereToPlaceY, wellMeshSizeZ / 1.8));
        wellPlot.position.z = wellMeshSizeZ / 1.8;
        wellPlot.rotateX( Math.PI / 2 );
        this.scene.add(wellPlot);
      }

      var axesHelper2 = new THREE.AxesHelper(16);
      scene2.add(axesHelper2);
  
      var camera = new THREE.PerspectiveCamera(45, width / height, 1, 90000);
      // ----- initial positioning (Starting line.) -----
      camera.position.x = 250;
      camera.position.y = 250;
      camera.position.z = 250;
      camera.position.setLength( 4000 );
      var orthoCamera = new THREE.OrthographicCamera( - 1, 1, 1, - 1, 1, 2 );
      orthoCamera.position.set( 0.5, 0, 1 );
      // ----- initial positioning (Ending line.) -----
      camera.up.set(0,0,1); // Set z axis to poinit downwards.
  
      this.camera = camera;
      this.orthoCamera = orthoCamera;
  
      var camera2 = new THREE.PerspectiveCamera(45, width / height, 1, 5000);
      camera2.up.set(0,0,1); // Set z axis to poinit downwards.
      scene2.add(camera2);
      const pointLight = new THREE.PointLight( 0xffffff, 3, 0, 0 );
      if(!hideMainScene) 
      {
        var layerDepth = 30; // should be calculated based on data given
        var polygonHeight = 50; // should be calculated based on data given
        var polygonWidth = 50; // should be calculated based on data given
  
        const wellMeshSizeZ = 500;
        const wellMeshSizeX = 5;
        const wellMeshSizeY = 5;
        var wellMesh;
        var wellLabelText;
        var wellPlot;

        let maxColorGisValue = 0;
        let index = -1;
        const wellGis = this.wellSpecificGis.map(e => e.gis.findIndex(e => e.name === this.gisToDisplay)).filter(e => e >= 0);
        if (wellGis.length) index = wellGis[0];
        try {
          maxColorGisValue = Math.max(...this.wellSpecificGis.filter(e => e.gis[index].name === this.gisToDisplay).map(e => e.gis[index].data).flat()).toFixed(2);
        } catch (e) {}
        let minColorGisValue = 0;
        try {
          minColorGisValue = Math.min(...this.wellSpecificGis.filter(e => e.gis[index].name === this.gisToDisplay).map(e => e.gis[index].data).flat()).toFixed(2);
        } catch (e) {}
        let midColorGisValue = 0;
        try {
          midColorGisValue = this.median(this.wellSpecificGis.filter(e => e.gis[index].name === this.gisToDisplay).map(e => this.median(e.gis[index].data))).toFixed(2);
        } catch (e) {}
				  
        var wellcanvas;
        var wellGisPlaneGeometry;
        const wellGroup = new THREE.Group();
        var labelRenderer = new CSS2DRenderer();
	
        labelRenderer.setSize(width, height);
        labelRenderer.domElement.style.position = "absolute";
        labelRenderer.domElement.style.top = "0px";
        document.querySelector("#three-scene").appendChild(labelRenderer.domElement);

        var controls = new OrbitControls(camera, threeDwellsRenderer.domElement);
						
        controls.maxDistance = 10000000;
        controls.minDistance = 100;

        controls.maxPolarAngle = Math.PI;
        controls.minPolarAngle = 0;
        controls.autoRotate = false;
        controls.autoRotateSpeed = 0;
        controls.rotateSpeed = 0.5;
        controls.enableDamping = false;
        controls.dampingFactor = 0.1;
        controls.enableZoom = true;
        controls.enablePan = true;

        this.cameraControls = controls;
        this.maxInitialCameraCoordinate = Math.max(controls.object.position.x, controls.object.position.y, controls.object.position.z);
				  
        var cube,
          cubeGeometry,
          cubeMaterial;
  
        var group = new THREE.Group();
        group.position.y = 50;
        this.layersIndexes = ["all"];
  
        const nsControlPoints = [];
        let linePoints = [];
        let zLinePoints = [];
        const lineGroups = new THREE.Group();
  
        let degree1 = 0;
        let degree2 = 0;
        // ----- create cubes from this.mapData (Starting line.) -----
        for (let i = 0; i < this.mapData.length; i++) {
  
          var mapData = this.mapData[i];
          var gridData = this.gridData[i];
  
          outer:
          this.degreeGrid1 = (gridData.params.nx + 1);
          this.degreeGrid2 = (gridData.params.ny + 1);
          this.sliderDegreeGrid1 = gridData.params.nx-1; 
          this.sliderDegreeGrid2 = gridData.params.ny-1; 
          var layerIndex = 0;
          for (let gridIndex = 0; gridIndex < gridData.grid.length; gridIndex++) {
            let	gridX = (gridData.grid[gridIndex][0] - gridData.grid[0][0]);
            let	gridY = (gridData.grid[gridIndex][1] - gridData.grid[0][1]);
  
            let gridZ = ((gridData.grid[gridIndex][2] - gridData.grid[0][2]) * this.zScale) * -1;
            if(gridX == 0 && gridY == 0 ){
              layerIndex++;
              this.layersIndexes.push(layerIndex);
            }
            this.gridLinePoints.push(gridX, gridY, gridZ);
          }
          this.layersIndexes.pop();
  
  
          for (let xIndex = 0; xIndex < mapData.z[0].length; xIndex++) {
						  
            let points = [];
            for (let yIndex = 0; yIndex < mapData.z.length; yIndex++) {
              // ----- colorOfCube mockup (Starting line.) -----
              var colorOfCube = "red";
              if (i != 0) {
                if (i == this.mapData.length - 2) // prelast layer
                  colorOfCube = Math.random() > 0.6 ? "yellow" : "lime";
                else 
                  colorOfCube = Math.random() > 0.9 ? "yellow" : "lime";
              }
              // ----- colorOfCube mockup (Ending line.) -----
              let mockUpDecrement = layerDepth * i;
              let x = mapData.x[xIndex] - mapData.x[0];
						  
              let y = mapData.y[yIndex] - mapData.y[0];
              let z = ((mapData.z[yIndex][xIndex] - mapData.z[0][0]) * this.zScale) - mockUpDecrement;
              let extz = z - 50;
              let w = z < 0 ? z * (-1) : z;

              linePoints.push(x, y, z);
              zLinePoints.push(x, y, extz);
              var mapPoints =[];
              mapPoints.push(new THREE.Vector3(x, y, z));
              if(xIndex === 0)
              {
                degree2++;
              }
  
              let point = new THREE.Vector4(x, y, z, 1 );
              points.push(point);
  
              cubeGeometry = new THREE.BoxGeometry(polygonHeight, polygonWidth, layerDepth);
              cubeMaterial = new THREE.MeshBasicMaterial({ color: colorOfCube });
              cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
  
              cube.position.x = mapData.x[xIndex] - mapData.x[0];
              cube.position.y = mapData.y[yIndex] - mapData.y[0];
  
              cube.position.z = z;

							  
              let geo = new THREE.EdgesGeometry( cubeGeometry );
              let mat = new THREE.LineBasicMaterial( { color: 0xffffff } );
              var wireframe = new THREE.LineSegments( geo, mat );

  
            }
  
            nsControlPoints.push(linePoints);
            degree1++;
          }
  
          let mesh;
          mesh = new THREE.Mesh( undefined, new THREE.MeshPhongMaterial( {
            color: 0xffffff,
            flatShading: true,
            vertexColors: true,
            side: THREE.DoubleSide
          } ) );
          this.mesh = mesh;
          this.scene.add( mesh );
					  
          let extrudedLinePoints = [...linePoints, ...zLinePoints];
          let degreeGrid1 = this.degreeGrid1;
          let degreeGrid2 = this.degreeGrid2;
					 
          let degreeLayer = (degreeGrid1*degreeGrid2);
          this.actnum = (await gridPropertiesService.get(this.propertiesToShowId[2])).data.data;
  
          var indexForLinePoints = [];
          var indexForLinePointsMulti = [];
          let p1 = 0, p2 = 1, p3 = (degreeGrid1 + 1), p4 = (degreeGrid1 + 1), p5 = degreeGrid1, p6 = 0;
					  
          for ( var l = 0; l < (degreeGrid2 - 1); l++) {
            for ( var j = 0; j < (degreeGrid1 - 1); j++) {
              indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
                p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
                (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
            }
          }
          if (this.selectedLayerIndex && (this.selectedLayerIndex != "all")) {
            for( var n = 0; n < indexForLinePoints.length; n++) {
              indexForLinePointsMulti.push((indexForLinePoints[n]) + (degreeLayer*(this.selectedLayerIndex - 1)));
            }
          } else {
            for (var k = 0; k <= (this.layersIndexes.length - 2); k++){
              for( var n = 0; n < indexForLinePoints.length; n++) {
                indexForLinePointsMulti.push((indexForLinePoints[n]) + (degreeLayer*k));
              }
            }
            if (this.toggle) {
              for (var iws = this.actnum.length-1; iws >= 0; iws--) {
                if(this.actnum[iws] == 0)	{
                  let ids = iws*36;
                  indexForLinePointsMulti.splice(ids,36);
                }	
              }
            }
          }
		
          this.geometry.setIndex(indexForLinePointsMulti);
          this.geometry.setAttribute( "position", new THREE.Float32BufferAttribute(this.gridLinePoints, 3 ) );
          let gps;
          if( this.propertiyToDisplay == this.propertiesToShow[0]) {
            gps = (await gridPropertiesService.get(this.propertiesToShowId[0])).data.data;
          } else if( this.propertiyToDisplay == this.propertiesToShow[1]) {
            gps = (await gridPropertiesService.get(this.propertiesToShowId[1])).data.data;
          } else if( this.propertiyToDisplay == this.propertiesToShow[2]) {
            gps = (await gridPropertiesService.get(this.propertiesToShowId[2])).data.data;
          } 
          else {
            gps = (await gridPropertiesService.get(this.propertiesToShowId[3])).data.data;
          }
          // let gps = (await gridPropertiesService.getName(this.propertiyToDisplay)).data.data;
          let gpsIncrement = 0;
          let gpsConstanta = gps.length;
          for( let gpsIndex = 0; gpsIndex <= gpsConstanta; gpsIndex++){
            if( gpsIndex != 0 && gpsIndex % (degreeGrid1-1) === 0){
              gps.splice((gpsIndex + gpsIncrement), 0, (gps[(gpsIndex + gpsIncrement)-1]) );
              gpsIncrement++;
            }
          }
          let gpsIncrementNew = 0;
          let gpsConstantaNew = gps.length;
          let degreeLayerUnwhole = degreeGrid1*(degreeGrid2-1);
          for( let gpsIndex = 0; gpsIndex <= gpsConstantaNew; gpsIndex++){
            if( gpsIndex != 0 && gpsIndex % degreeLayerUnwhole === 0){
              let newRow = gps.slice(((gpsIndex + (degreeGrid1*gpsIncrementNew)) - degreeGrid1), (gpsIndex + (degreeGrid1*gpsIncrementNew)));
              gps.splice((gpsIndex + (gpsIncrementNew*degreeGrid1)), 0, ...newRow );
              gpsIncrementNew++;
            }
          }
          let lastProfile = gps.slice(((degreeGrid1*degreeGrid2*gpsIncrementNew)-(degreeGrid1*degreeGrid2)), (degreeGrid1*degreeGrid2*gpsIncrementNew));
          gps.splice((degreeGrid1*degreeGrid2*gpsIncrementNew), 0, ...lastProfile );

          this.geometry.setAttribute( "pressure", new THREE.Float32BufferAttribute(gps, 3 ));
          this.geometry.setAttribute( "color", new THREE.Float32BufferAttribute( new Float32Array( this.geometry.attributes.position.count * 3 ), 3 ) );
  
          let lut, sprite;
          lut = new Lut( "rainbow", 512 );
          //var actnum = (await gridPropertiesService.get(this.propertiesToShowId[2])).data.data;
          let actnumSize = this.actnum.length;
          let actnumData = new Uint8Array(actnumSize);
          for (let p = 0; p < actnumSize; p++) {
            actnumData[p] = actnumData[p] * 255; // pass anything from 0 to 255
          }
          var texture = new THREE.DataTexture(actnumData, 75, 24, THREE.LuminanceFormat, THREE.UnsignedByteType);

          function getMax(arr) {
            let len = arr.length;
            let max = -Infinity;
            while (len--) {
              max = arr[len] > max ? arr[len] : max;
            }
            return Number(max).toFixed(2);
          }
          function getMin(arr) {
            let len = arr.length;
            let min = Infinity;
            while (len--) {
              min = arr[len] < min ? arr[len] : min;
            }
            if (!min) min = 0;
            return min;
          }

          // Отображение надписей скважин
          for (let i = 0; i < this.wellSpecificGis.length; i++) {
            const well = this.wellSpecificGis[i];
            let wellGisData = well.gis.length > 0 ? well.gis[0].data : null;
            if (well.gis.length > 1)
              wellGisData = well.gis[1].data;
            let scalar = 0;
            if (Math.max(...wellGisData.filter(e => e).map(e => Number(e) || 0).filter(e => e)))
              scalar = Math.max(...wellGisData.filter(e => e).map(e => Number(e) || 0));
            const color = "red";
            wellMesh = new THREE.Mesh(new THREE.BoxGeometry(wellMeshSizeX, wellMeshSizeY, wellMeshSizeZ), new THREE.MeshBasicMaterial({ color }));
            let whereToPlaceX = well.x - this.mapData[0].x[0];
            let whereToPlaceY = well.y - this.mapData[0].y[0];
            wellMesh.position.x = whereToPlaceX;
            wellMesh.position.y = whereToPlaceY;
            // wellMesh.position.z = wellMeshSizeZ / 2; // because we normalized our data to be at z = 0 (for layers) AND because positioning is setted by center of BoxGeometry
            wellMesh.position.z = wellMeshSizeZ * this.zScale / this.zScaleOptions[0] / 2; // because we normalized our data to be at z = 0 (for layers) AND because positioning is setted by center of BoxGeometry
            wellMesh.scale.z = this.zScale / this.zScaleOptions[0];
            wellMesh.name = "wellmesh" + i;
						
            var div = document.createElement("div");
            this.secondWell = wellGisData;
  
            div.className = "well__label";
            div.textContent = this.$t("common.well-short") + well.name;

            wellLabelText = new CSS2DObject(div);
			

            wellLabelText.name = "wellLabel" + i;
            wellLabelText.position.copy(new THREE.Vector3(whereToPlaceX, whereToPlaceY, wellMeshSizeZ * this.zScale / this.zScaleOptions[0] + 15));
            this.scene.add(wellLabelText);
            this.scene.add(wellMesh);
          }
			
          lut.setMax(getMax(gps));
          lut.setMin(getMin(gps));
          let gpsMid = (getMax(gps) - getMin(gps))/2;


          const colors = this.geometry.attributes.color;
          const color = new THREE.Color;
          mesh.geometry = this.geometry;
          
          for ( let i = 0, n = colors.array.length; i < n; ++i ) {
            let checkGps = gps[i] == null? 0 : gps[i];
            color.copy( lut.getColor(checkGps).convertSRGBToLinear());
            colors.setXYZ( i, color.r, color.g, color.b );
          }
          colors.needsUpdate = true;
          sprite = new THREE.Sprite( new THREE.SpriteMaterial( {
            map: new THREE.CanvasTexture( lut.createCanvas() )
          }));
          sprite.material.map.colorSpace = THREE.SRGBColorSpace;
          sprite.scale.x = 0.325;
          sprite.scale.y = 1.8;
          this.uiScene.add( sprite );
          var colorMaxRenderer = new CSS2DRenderer();
          colorMaxRenderer.domElement.style.position = "absolute";
          colorMaxRenderer.domElement.style.zIndex = "7";
          colorMaxRenderer.domElement.style.top = "-1px";
          colorMaxRenderer.domElement.style.left = "31px";
          colorMaxRenderer.domElement.style.width = "122px";
          colorMaxRenderer.domElement.style.height = "296px";

          document.querySelector("#color-map").appendChild(colorMaxRenderer.domElement);
          var divs = document.createElement("div");
          divs.className = "max-color";
          divs.textContent = getMax(gps);
          divs.style.right = "0px";
          divs.style.fontSize = "11px";
          divs.style.borderBottom = "1px solid black";
          divs.style.width = "100%";
          divs.style.textAlign = "end";

          let colorMaxText = new CSS2DObject(divs);
          this.uiScene.add(colorMaxText);

          var divQuarter = document.createElement("div");
          divQuarter.className = "quarter-color";
          divQuarter.textContent = " ";
          divQuarter.style.right = "55px";
          divQuarter.style.bottom = "73px";
          divQuarter.style.fontSize = "11px";
          divQuarter.style.borderBottom = "1px solid black";
          divQuarter.style.width = "50%";
          divQuarter.style.textAlign = "end";
          let colorQuarterText = new CSS2DObject(divQuarter);
          this.uiScene.add(colorQuarterText);

          var divMid = document.createElement("div");
          divMid.className = "mid-color";
          divMid.textContent = gpsMid;
          divMid.style.right = "0px";
          divMid.style.fontSize = "11px";
          divMid.style.borderBottom = "1px solid black";
          divMid.style.width = "100%";
          divMid.style.bottom = "145px";
          divMid.style.textAlign = "end";


          let colorMidText = new CSS2DObject(divMid);
          this.uiScene.add(colorMidText);

          var divThirdQuarter = document.createElement("div");
          divThirdQuarter.className = "third-quarter-color";
          divThirdQuarter.textContent = " ";
          divThirdQuarter.style.right = "55px";
          divThirdQuarter.style.bottom = "218px";
          divThirdQuarter.style.fontSize = "11px";
          divThirdQuarter.style.borderBottom = "1px solid black";
          divThirdQuarter.style.width = "50%";
          divThirdQuarter.style.textAlign = "end";
          let colorThirdQuarterText = new CSS2DObject(divThirdQuarter);
          this.uiScene.add(colorThirdQuarterText);

          var divMin = document.createElement("div");
          divMin.className = "min-color";
          divMin.textContent = getMin(gps);
          divMin.style.right = "0px";
          divMin.style.fontSize = "11px";
          divMin.style.borderBottom = "1px solid black";
          divMin.style.width = "100%";
          divMin.style.bottom = "0px";
          divMin.style.textAlign = "end";

          let colorMinText = new CSS2DObject(divMin);
          this.uiScene.add(colorMinText);
          const map = sprite.material.map;
          lut.updateCanvas( map.image );
          map.needsUpdate = true;
          // ----- create cubes from this.mapData (Ending line.) -----


          // lut = new Lut( "rainbow", 512 );
          // //var actnum = (await gridPropertiesService.get(this.propertiesToShowId[2])).data.data;
          // actnumSize = this.actnum.length;
          // actnumData = new Uint8Array(actnumSize);
          // for (let p = 0; p < actnumSize; p++) {
          //   actnumData[p] = actnumData[p] * 255; // pass anything from 0 to 255
          // }
          // var texture = new THREE.DataTexture(actnumData, 75, 24, THREE.LuminanceFormat, THREE.UnsignedByteType);

          // lut.setMax(getMax(gps));
          // lut.setMin(getMin(gps));
          // gpsMid = (getMax(gps) - getMin(gps))/2;
          // const colors2 = this.geometry.attributes.color;
          // const color2 = new THREE.Color;
          // mesh.geometry = this.geometry;
          // for ( let i = 0, n = colors2.array.length; i < n; ++i ) {
          //   let checkGps = gps[i] == null? 0 : gps[i];
          //   color2.copy( lut.getColor(checkGps).convertSRGBToLinear());
          //   colors2.setXYZ( i, color.r, color.g, color.b );
          // }
          // colors2.needsUpdate = true;
          // sprite = new THREE.Sprite( new THREE.SpriteMaterial( {
          //   map: new THREE.CanvasTexture( lut.createCanvas() )
          // }));
          // sprite.material.map.colorSpace = THREE.SRGBColorSpace;
          // sprite.scale.x = 0.325;
          // sprite.scale.y = 1.8;
          // this.uiScene2.add( sprite );
          // var colorMaxRenderer2 = new CSS2DRenderer();
          // colorMaxRenderer2.domElement.style.zIndex = "7";
          // colorMaxRenderer2.domElement.style.position = "absolute";
          // colorMaxRenderer2.domElement.style.top = "-1px";
          // colorMaxRenderer2.domElement.style.left = `calc(${this.scene3DFieldWidth}px - 122px)`;
          // colorMaxRenderer2.domElement.style.width = "122px";
          // colorMaxRenderer2.domElement.style.height = "296px";

          // document.querySelector("#color-map").appendChild(colorMaxRenderer2.domElement);
          // var divs = document.createElement("div");
          // divs.className = "max-color";
          // divs.textContent = maxColorGisValue;
          // divs.style.right = "0px";
          // divs.style.fontSize = "11px";
          // divs.style.borderBottom = "1px solid black";
          // divs.style.width = "100%";
          // divs.style.textAlign = "end";

          // colorMaxText = new CSS2DObject(divs);
          // colorMaxText.name = "colorMaxText";
          // this.uiScene2.add(colorMaxText);

          // var divQuarter = document.createElement("div");
          // divQuarter.className = "quarter-color";
          // divQuarter.textContent = " ";
          // divQuarter.style.right = "55px";
          // divQuarter.style.bottom = "73px";
          // divQuarter.style.fontSize = "11px";
          // divQuarter.style.borderBottom = "1px solid black";
          // divQuarter.style.width = "50%";
          // divQuarter.style.textAlign = "end";
          // colorQuarterText = new CSS2DObject(divQuarter);
          // this.uiScene2.add(colorQuarterText);

          // var divMid = document.createElement("div");
          // divMid.className = "mid-color";
          // divMid.textContent = midColorGisValue;
          // divMid.style.right = "0px";
          // divMid.style.fontSize = "11px";
          // divMid.style.borderBottom = "1px solid black";
          // divMid.style.width = "100%";
          // divMid.style.bottom = "145px";
          // divMid.style.textAlign = "end";


          // colorMidText = new CSS2DObject(divMid);
          // colorMidText.name = "colorMidText";
          // this.uiScene2.add(colorMidText);

          // var divThirdQuarter = document.createElement("div");
          // divThirdQuarter.className = "third-quarter-color";
          // divThirdQuarter.textContent = " ";
          // divThirdQuarter.style.right = "55px";
          // divThirdQuarter.style.bottom = "218px";
          // divThirdQuarter.style.fontSize = "11px";
          // divThirdQuarter.style.borderBottom = "1px solid black";
          // divThirdQuarter.style.width = "50%";
          // divThirdQuarter.style.textAlign = "end";
          // colorThirdQuarterText = new CSS2DObject(divThirdQuarter);
          // this.uiScene2.add(colorThirdQuarterText);

          // var divMin = document.createElement("div");
          // divMin.className = "min-color";
      
          // divMin.textContent = minColorGisValue;
          // divMin.style.right = "0px";
          // divMin.style.fontSize = "11px";
          // divMin.style.borderBottom = "1px solid black";
          // divMin.style.width = "100%";
          // divMin.style.bottom = "0px";
          // divMin.style.textAlign = "end";

          // colorMinText = new CSS2DObject(divMin);
          // colorMinText.name = "colorMinText";
          // this.uiScene2.add(colorMinText);
          // const map2 = sprite.material.map;
          // lut.updateCanvas( map2.image );
          // map2.needsUpdate = true;
          // // ----- create cubes from this.mapData (Ending line.) -----

        }
        this.sceneIndex = 1;
  
        const knots1 = [0];
        const knots2 = [0];
        for(let i = 0; i < degree1 - 1; i++)
        {
          knots1.push(0);
        }
  
        for(let i = 0; i < degree1 - 1; i++)
        {
          knots1.push(1);
        }
  
        for(let i = 0; i < degree2 - 1; i++)
        {
          knots2.push(0);
        }
  
        for(let i = 0; i < degree2 - 1; i++)
        {
          knots2.push(1);
        }
        knots1.push(1);
        knots2.push(1);
        const nurbsSurface = new NURBSSurface( degree1 - 1, degree2 - 1, knots1, knots2, nsControlPoints );
        function getSurfacePoint( u, v, target ) {
          return nurbsSurface.getPoint( u, v, target );
        }
  
        const material = new THREE.MeshLambertMaterial( { 
          color: "rgb(30,130,130)",
          opacity: 1,
          side: THREE.DoubleSide, 
        } );
        const shape = new ParametricGeometry( getSurfacePoint, 20, 20 );
  
        const object = new THREE.Mesh( shape, material );
  
  
        var boxGeom = new THREE.PlaneGeometry(2000, 3000, degree1 -1, degree2 -1);
				  
        const boxPosition = boxGeom.attributes.position.array;
        var numbers = boxPosition.map(function (i, idx) { 
          return (idx + 1) % 3 == 0 ?
            zLinePoints[idx]
            : i;}); 
        boxGeom.setAttribute( "position", new THREE.Float32BufferAttribute( numbers, 3 ) );
        var deformed = new THREE.Mesh(boxGeom, new THREE.MeshBasicMaterial({
          color: "red",
          wireframe: true
        }));
  

        this.scene.add( group );
  
        // Create a renderer and add it to the DOM.
        var renderer = new THREE.WebGLRenderer( { powerPreference: "high-performance", } );
        renderer.setSize(width, height);
        renderer.setClearColor( 0xffffff, 0);
  
        renderer.domElement.style.zIndex = 3;
        renderer.domElement.style.pointerEvents = "none";
        renderer.domElement.style.position = "relative";
        document.querySelector("#three-scene").appendChild(renderer.domElement);
        var light = new THREE.PointLight(0xffffff);
        light.position.set(100, 100, 200);
        camera.add(light);
  
        this.scene.add(camera);
        this.scene.add(orthoCamera);
  
        // ----- center camera on last added cube (Ending line.) -----
      }
      var thisIteration = this.iteration;
      var requestId;
      // ----- Render the scene (Starting line.) -----
      animate.bind(this)();

      function animate() {
				  
        requestId = requestAnimationFrame(animate.bind(this));
  
        if (!hideMainScene) {
          renderer3.render( this.uiScene, orthoCamera );
          renderer4.render( this.uiScene2, orthoCamera );
          colorMaxRenderer.render( this.uiScene, orthoCamera );
          //colorMaxRenderer2.render( this.uiScene2, orthoCamera );
          renderer.render(this.scene, camera);
          labelRenderer.render(this.scene, camera);
          threeDwellsRenderer.render(this.scene, camera);

          if (camera.position.z > 15 * this.zScale) {
            renderer.domElement.style.zIndex = 2;
            renderer.domElement.style.pointerEvents = "all";
          } else {
            renderer.domElement.style.zIndex = 3;
            renderer.domElement.style.pointerEvents = "none";
          }
          controls.update();
        }
  
        // ----- for small coordinates legend (Starting line.) -----
        camera2.position.copy( camera.position ); // Copy position for small coordinates legend (i.e. camera2)
        // camera2.position.sub( controls.target );
        camera2.position.setLength( 50 ); // Fix distance to small coordinates legend, so wheel zoom would not affect it.
        camera2.lookAt( scene2.position ); // Fix panning for small coordinates legend at 0, 0;
        // ----- for small coordinates legend (Ending line.) -----
  
        renderer2.render(scene2, camera2);
  
        if(thisIteration != this.iteration || this.disposeAll)
        {
          cancelAnimationFrame(requestId);
          renderer2.dispose();
  
          if (!hideMainScene) { 
            renderer.dispose();
          }
        }
  
      }
      var timeoutId = setTimeout(() => { this.threeLoading = false; }, 2000); // Timeout for smooth animation at the ane.

      var testEl = document.querySelectorAll("#tester");
      if(testEl) {
        var layout = {
          showlegend: false,
          paper_bgcolor:"rgba(0,0,0,0)",
          plot_bgcolor:"rgba(0,0,0,0)",
          "xaxis": {
            "showgrid": false,
            "zeroline": false, 
            "visible": false  
          },
          "yaxis": {
            "showgrid": false,
            "zeroline": false, 
            "visible": false  
          },
        };
		
        for (let i = 0; i < this.wellSpecificGis.length; i++) {
          const well = this.wellSpecificGis[i];
          let wellGisData = [];
          const index = well.gis.findIndex(e => e.name === this.gisToDisplay);
          if (index >= 0) {
            wellGisData = well.gis[index].data;
          }
          let scalar = 0;
          if (Math.max(...wellGisData.filter(e => e).map(e => Number(e) || 0).filter(e => e)))
            scalar = Math.max(...wellGisData.filter(e => e).map(e => Number(e) || 0));
          let color = new THREE.Color("blue").convertLinearToSRGB().lerpHSL(
            new THREE.Color("red").convertLinearToSRGB(),
            scalar
          );
          var layout = {

            margin: {t: 0, b: 0},
            showlegend: false,
            paper_bgcolor:"rgba(0,0,0,0)",
            plot_bgcolor:"rgba(0,0,0,0)",
            "xaxis": {
              "showgrid": false,
              "zeroline": false, 
              "visible": false  
            },
            "yaxis": {
              "showgrid": false,
              "zeroline": false, 
              "visible": false  
            },
          };
          const max = Math.max(...wellGisData.filter(e => e).map(e => parseFloat(e) || 0));
          if (max) {
            if (testEl[i])
              Plotly.newPlot(testEl[i], [
                {
                  y: wellGisData,
                  type: "histogram",
                  marker: {
                    color: wellGisData.filter(e => e).map((e, i) => {
                      return `#${new THREE.Color("blue").convertLinearToSRGB().lerpHSL(
                        new THREE.Color("red").convertLinearToSRGB(),
                        Math.max(...wellGisData.filter(e => e)) - e * Math.max(...wellGisData.filter(e => e))
                      ).getHexString()}`;
                    }).reverse(),
                    showscale: false
                  },
                  legend: {
                    itemclick: false,
                    itemdoubleclick: false
                  },
                  clickmode: "none"
                }], layout, {displayModeBar: false, staticPlot: true} );
          }
        }
        var testEl = document.querySelectorAll("#tester");
        for (let i = 0; i < this.wellSpecificGis.length; i++) {
          const wellplot = this.scene.getObjectByName(`wellplot${i}`);
          const el = document.querySelector(`.tester${i}`);
          if (el) {
            const width = el.clientWidth;
            const height = el.clientHeight;
            const wellmesh = this.scene.getObjectByName(`wellmesh${i}`);
            wellplot.position.x = (wellmesh.position.x) + (width / 2) - 80;
            wellplot.position.y = (wellmesh.position.y);
            // wellplot.position.z = (wellmesh.position.z + (500 * this.zScale / 2)) - (height / 2);
            const well = this.wellSpecificGis[i];
            const index = well.gis.findIndex(e => e.name !== this.gisToDisplay);
            if (index >= 0) wellplot.position.z = (well.gis[index].data[0] - well.gis[index].data[well.gis[index].data.length - 1]) + (height / 2);
          }
        }
      };

      // set camera position from mesh size
      this.geometry.computeBoundingBox();
      this.camera.position.set(this.geometry.boundingBox.getSize(new THREE.Vector3(0, 0, 0)).x, this.geometry.boundingBox.getSize(new THREE.Vector3(0, 0, 0)).y, this.scene3DFieldHeight);

    },
    async selectedLayerChange() {			
      let degreeGrid1 = this.degreeGrid1;
      let degreeGrid2 = this.degreeGrid2;
      let degreeLayer = (degreeGrid1*degreeGrid2);
  
      var indexForLinePoints = [];
      var indexForLinePointsMulti = [];
      var indexForActnumSelected = [];
      let p1 = 0, p2 = 1, p3 = (degreeGrid1 + 1), p4 = (degreeGrid1 + 1), p5 = degreeGrid1, p6 = 0;
      this.threeLoading = true;
      if(this.toggleProfileX && !this.toggleProfileY){
        let j = parseInt(this.sliderValue);
        for ( var l = 0; l < (degreeGrid2-1); l++) {
          indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
            p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
            (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
        }
      } else if(!this.toggleProfileX && this.toggleProfileY) {
        let l = parseInt(this.sliderValueY);
        for ( var j = 0; j < (degreeGrid1-1); j++) {
          indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
            p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
            (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
        }
      } else if(this.toggleProfileX && this.toggleProfileY) {
        let j = parseInt(this.sliderValue);
        for ( var l = 0; l < (degreeGrid2-1); l++) {
          if(l == this.sliderValueY){
            for ( var jd = 0; jd < (degreeGrid1-1); jd++) {
              indexForLinePoints.push(p1+(jd + degreeGrid1*l), p2+(jd + degreeGrid1*l), p3+(jd + degreeGrid1*l), p4+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l), p6+(jd + degreeGrid1*l), (degreeLayer + p1)+(jd + degreeGrid1*l), (degreeLayer + p2)+(jd + degreeGrid1*l), (degreeLayer + p3)+(jd + degreeGrid1*l), (degreeLayer + p4)+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l), (degreeLayer + p6)+(jd + degreeGrid1*l),
                p1+(jd + degreeGrid1*l), p2+(jd + degreeGrid1*l), (degreeLayer + p2)+(jd + degreeGrid1*l),p1+(jd + degreeGrid1*l), (degreeLayer + p1)+(jd + degreeGrid1*l), (degreeLayer + p2)+(jd + degreeGrid1*l), p4+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l),p4+(jd + degreeGrid1*l), (degreeLayer + p4)+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l),
                (degreeLayer + p1)+(jd + degreeGrid1*l),p1+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l),(degreeLayer + p1)+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l),(degreeLayer + p2)+(jd + degreeGrid1*l), p2+(jd + degreeGrid1*l), p3+(jd + degreeGrid1*l),(degreeLayer + p2)+(jd + degreeGrid1*l),(degreeLayer + p3)+(jd + degreeGrid1*l), p3+(jd + degreeGrid1*l));						
            }
          }
          indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
            p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
            (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
        }
      }
      else 
      {
        for ( var l = 0; l < (degreeGrid2-1); l++) {
          for ( var j = 0; j < (degreeGrid1-1); j++) {
            indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
              p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
              (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
          }
        }
      }
      if (this.selectedLayerIndex && (this.selectedLayerIndex != "all")) {
        for( var n = 0; n < indexForLinePoints.length; n++) {
          indexForLinePointsMulti.push((indexForLinePoints[n]) + (degreeLayer*(this.selectedLayerIndex - 1)));
        }	
        if (this.toggle) {
          for(var actindex = (((degreeGrid1-1)*(degreeGrid2-1))*(this.selectedLayerIndex - 1)); actindex < (((degreeGrid1-1)*(degreeGrid2-1))*(this.selectedLayerIndex)); actindex++) {
            indexForActnumSelected.push(this.actnum[actindex]);
          }
          if(this.toggleProfileX && !this.toggleProfileY) {
            let newActnum = [];
            for (var newActnumIndex = 0; newActnumIndex < indexForActnumSelected.length; newActnumIndex++) {
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(indexForActnumSelected[newActnumIndex]);
              }
            }
  
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if (!this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < indexForActnumSelected.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...indexForActnumSelected.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if (this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < indexForActnumSelected.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...indexForActnumSelected.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(indexForActnumSelected[newActnumIndex]);
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          }
          else {
            for (var is = indexForActnumSelected.length-1; is >= 0; is--) {
              if(indexForActnumSelected[is] == 0)	{
                let ids = is*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            } 
          }
        }
      } else {
        for (var k = 0; k <= (this.layersIndexes.length - 2); k++){
          for( var n = 0; n < indexForLinePoints.length; n++) {
            indexForLinePointsMulti.push((indexForLinePoints[n]) + (degreeLayer*k));
          }
        }
        if (this.toggle) {
          if(this.toggleProfileX && !this.toggleProfileY) {
            let newActnum = [];
            for (var newActnumIndex = 0; newActnumIndex < this.actnum.length; newActnumIndex++) {
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(this.actnum[newActnumIndex]);
              }
            }
  
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if(!this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < this.actnum.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...this.actnum.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if (this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < this.actnum.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...this.actnum.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(this.actnum[newActnumIndex]);
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          }
          else {
            for (var iws = this.actnum.length-1; iws >= 0; iws--) {
              if(this.actnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          }
        }
      }
  
      this.geometry.setIndex(indexForLinePointsMulti);
      this.geometry.index.needsUpdate = true;
      var timeoutId = setTimeout(() => { this.threeLoading = false; }, 2000); // Timeout for smooth animation at the ane.

    },
    changeZScale(e) {
      let gridLinePointsNew = [];
      var gridData = this.gridData[0];
      for (let gridIndex = 0; gridIndex < gridData.grid.length; gridIndex++) {
        let	gridX = (gridData.grid[gridIndex][0] - gridData.grid[0][0]);
        let	gridY = (gridData.grid[gridIndex][1] - gridData.grid[0][1]);

        let gridZ = ((gridData.grid[gridIndex][2] - gridData.grid[0][2]) * this.zScale) * -1;
        gridLinePointsNew.push(gridX, gridY, gridZ);
      }
      const wellMeshSizeZ = 500 * this.zScale / this.zScaleOptions[0];
      for (let i = 0; i < this.allWells.length; i++) {
						
        const layout = {

          legend: {
            itemdoubleclick: false,
            itemclick: false
          },
          clickmode: "none",
          margin: {t: 0, l: 0, r: 0, b: 0}
        };
        const wellPlot = this.scene.getObjectByName("wellplot" + i);
						
        let wellScaled = this.scene.getObjectByName("wellmesh" + i);
        let wellLabelScaled = this.scene.getObjectByName("wellLabel" + i);
        wellScaled.scale.set(1, 1, this.zScale);
        wellScaled.position.z = wellMeshSizeZ/2;

        wellLabelScaled.position.z = wellMeshSizeZ + 15;
						
        wellPlot.position.z = wellMeshSizeZ / (1.8 * this.zScale);
        Plotly.relayout(document.querySelector(`.tester${i}`), layout);

					
        const wellplot = this.scene.getObjectByName(`wellplot${i}`);

        let scalar = this.zScale;
        if (this.zScale > 1) scalar = this.zScale / 10;
        wellplot.scale.set(scalar, scalar, scalar);
        const width = document.querySelector(`.tester${0}`).clientWidth * this.zScale / 10;
        const height = document.querySelector(`.tester${0}`).clientHeight * this.zScale / 10;
        const wellmesh = this.scene.getObjectByName(`wellmesh${i}`);
        wellplot.position.x = (wellmesh.position.x) + (width / 2);
        wellplot.position.y = (wellmesh.position.y);
        wellplot.position.z = (wellmesh.position.z + (500 * this.zScale / 2)) - (height / 2);
        const well = this.wellSpecificGis[i];
        const index = well.gis.findIndex(e => e.name !== this.gisToDisplay);
        if (index >= 0) wellplot.position.z = (well.gis[index].data[well.gis[index].data.length - 1] - well.gis[index].data[0]) + (height / 2);
      }

      this.geometry.setAttribute( "position", new THREE.Float32BufferAttribute(gridLinePointsNew, 3 ) );
      this.geometry.getAttribute( "position").needsUpdate = true;
      this.threeLoading = false;
    },
    async propertiyToDisplayChange() {
      if( this.sceneIndex == 0) {
        this.create3DField();
      } else {
        let gps;
        let degreeGrid1 = this.degreeGrid1;
        let degreeGrid2 = this.degreeGrid2;

        if( this.propertiyToDisplay == this.propertiesToShow[0]) {
          gps = (await gridPropertiesService.get(this.propertiesToShowId[0])).data.data;
          
        } else if( this.propertiyToDisplay == this.propertiesToShow[1]) {
          gps = (await gridPropertiesService.get(this.propertiesToShowId[1])).data.data;
        } else if( this.propertiyToDisplay == this.propertiesToShow[2]) {
          gps = (await gridPropertiesService.get(this.propertiesToShowId[2])).data.data;
        } 
        else {
          gps = (await gridPropertiesService.get(this.propertiesToShowId[3])).data.data;
        }
        let gpsIncrement = 0;
        let gpsConstanta = gps.length;
  
        for( let gpsIndex = 0; gpsIndex <= gpsConstanta; gpsIndex++){
          if( gpsIndex != 0 && gpsIndex % (degreeGrid1-1) === 0){
            gps.splice((gpsIndex + gpsIncrement), 0, (gps[(gpsIndex + gpsIncrement)-1]) );
            gpsIncrement++;
          }
        }
        let gpsIncrementNew = 0;
        let gpsConstantaNew = gps.length;
		
        let degreeLayerUnwhole = degreeGrid1*(degreeGrid2-1);
        for( let gpsIndex = 0; gpsIndex <= gpsConstantaNew; gpsIndex++){
          if( gpsIndex != 0 && gpsIndex % degreeLayerUnwhole === 0){
            let newRow = gps.slice(((gpsIndex + (degreeGrid1*gpsIncrementNew)) - degreeGrid1), (gpsIndex + (degreeGrid1*gpsIncrementNew)));
            gps.splice((gpsIndex + (gpsIncrementNew*degreeGrid1)), 0, ...newRow );
            gpsIncrementNew++;
          }
        }
        let lastProfile = gps.slice(((degreeGrid1*degreeGrid2*gpsIncrementNew)-(degreeGrid1*degreeGrid2)), (degreeGrid1*degreeGrid2*gpsIncrementNew));
        gps.splice((degreeGrid1*degreeGrid2*gpsIncrementNew), 0, ...lastProfile );
        this.geometry.setAttribute( "color", new THREE.Float32BufferAttribute( new Float32Array( this.geometry.attributes.position.count * 3 ), 3 ) );
  
        let lut, sprite;
        lut = new Lut( "rainbow", 512 );
        function getMax(arr) {
          let len = arr.length;
          let max = -Infinity;
          while (len--) {
            max = arr[len] > max ? arr[len] : max;
          }
          return Number(max).toFixed(2);
        }
        function getMin(arr) {
          let len = arr.length;
          let min = Infinity;
          while (len--) {
            min = arr[len] < min ? arr[len] : min;
          }
          if (!min) min = 0;
          return min;
        }
  
        lut.setMax(getMax(gps));
        lut.setMin(getMin(gps));
        let gpsMid = (getMax(gps) - getMin(gps))/2;
  
  
        const colors = this.geometry.attributes.color;
        const color = new THREE.Color;
  
        for ( let i = 0, n = colors.array.length; i < n; ++i ) {
          let checkGps = gps[i] == null? 0 : gps[i];
          color.copy( lut.getColor(checkGps).convertSRGBToLinear());
          colors.setXYZ( i, color.r, color.g, color.b );
  
        }
        colors.needsUpdate = true;
        sprite = new THREE.Sprite( new THREE.SpriteMaterial( {
          map: new THREE.CanvasTexture( lut.createCanvas() )
        }));
        sprite.material.map.colorSpace = THREE.SRGBColorSpace;
        sprite.scale.x = 0.325;
        sprite.scale.y = 1.8;
        this.uiScene.add( sprite );
  
        let divs = document.querySelector(".max-color");
        divs.textContent = getMax(gps);
        divs.style.right = "0px";
        divs.style.fontSize = "11px";
        divs.style.borderBottom = "1px solid black";
        divs.style.width = "100%";
        divs.style.textAlign = "end";
  
        let colorMaxText = new CSS2DObject(divs);
        this.uiScene.add(colorMaxText);
  
        let divQuarter = document.querySelector(".quarter-color");
        divQuarter.textContent = " ";
        divQuarter.style.right = "55px";
        divQuarter.style.bottom = "73px";
        divQuarter.style.fontSize = "11px";
        divQuarter.style.borderBottom = "1px solid black";
        divQuarter.style.width = "50%";
        divQuarter.style.textAlign = "end";
        let colorQuarterText = new CSS2DObject(divQuarter);
        this.uiScene.add(colorQuarterText);
  
        let divMid = document.querySelector(".mid-color");
        divMid.textContent = gpsMid;
        divMid.style.right = "0px";
        divMid.style.fontSize = "11px";
        divMid.style.borderBottom = "1px solid black";
        divMid.style.width = "100%";
        divMid.style.bottom = "145px";
        divMid.style.textAlign = "end";
  
  
        let colorMidText = new CSS2DObject(divMid);
        this.uiScene.add(colorMidText);
  
  
        let divThirdQuarter = document.querySelector(".third-quarter-color");
        divThirdQuarter.textContent = " ";
        divThirdQuarter.style.right = "55px";
        divThirdQuarter.style.bottom = "218px";
        divThirdQuarter.style.fontSize = "11px";
        divThirdQuarter.style.borderBottom = "1px solid black";
        divThirdQuarter.style.width = "50%";
        divThirdQuarter.style.textAlign = "end";
        let colorThirdQuarterText = new CSS2DObject(divThirdQuarter);
        this.uiScene.add(colorThirdQuarterText);
  
        let divMin = document.querySelector(".min-color");
  
        divMin.textContent = getMin(gps);
        divMin.style.right = "0px";
        divMin.style.fontSize = "11px";
        divMin.style.borderBottom = "1px solid black";
        divMin.style.width = "100%";
        divMin.style.bottom = "0px";
        divMin.style.textAlign = "end";
  
  
        colorMidText = new CSS2DObject(divMin);
        this.uiScene.add(colorMidText);
        const map2 = sprite.material.map;
        lut.updateCanvas( map2.image );
        map2.needsUpdate = true;
  
      }
    },
    async profileXchange() {
      let degreeGrid1 = this.degreeGrid1;
      let degreeGrid2 = this.degreeGrid2;
      let degreeLayer = (degreeGrid1*degreeGrid2);
  
      var indexForLinePoints = [];
      var indexForLinePointsMulti = [];
      var indexForActnumSelected = [];
      let p1 = 0, p2 = 1, p3 = (degreeGrid1 + 1), p4 = (degreeGrid1 + 1), p5 = degreeGrid1, p6 = 0;
  
      if(this.toggleProfileX && !this.toggleProfileY){
        let j = parseInt(this.sliderValue);
        for ( var l = 0; l < (degreeGrid2-1); l++) {
          indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
            p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
            (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
        }
      } else if(!this.toggleProfileX && this.toggleProfileY) {
        let l = parseInt(this.sliderValueY);
        for ( var j = 0; j < (degreeGrid1-1); j++) {
          indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
            p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
            (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
        }
      } else if(this.toggleProfileX && this.toggleProfileY) {
        let j = parseInt(this.sliderValue);
        for ( var l = 0; l < (degreeGrid2-1); l++) {
          if(l == this.sliderValueY){
            for ( var jd = 0; jd < (degreeGrid1-1); jd++) {
              indexForLinePoints.push(p1+(jd + degreeGrid1*l), p2+(jd + degreeGrid1*l), p3+(jd + degreeGrid1*l), p4+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l), p6+(jd + degreeGrid1*l), (degreeLayer + p1)+(jd + degreeGrid1*l), (degreeLayer + p2)+(jd + degreeGrid1*l), (degreeLayer + p3)+(jd + degreeGrid1*l), (degreeLayer + p4)+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l), (degreeLayer + p6)+(jd + degreeGrid1*l),
                p1+(jd + degreeGrid1*l), p2+(jd + degreeGrid1*l), (degreeLayer + p2)+(jd + degreeGrid1*l),p1+(jd + degreeGrid1*l), (degreeLayer + p1)+(jd + degreeGrid1*l), (degreeLayer + p2)+(jd + degreeGrid1*l), p4+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l),p4+(jd + degreeGrid1*l), (degreeLayer + p4)+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l),
                (degreeLayer + p1)+(jd + degreeGrid1*l),p1+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l),(degreeLayer + p1)+(jd + degreeGrid1*l), (degreeLayer + p5)+(jd + degreeGrid1*l), p5+(jd + degreeGrid1*l),(degreeLayer + p2)+(jd + degreeGrid1*l), p2+(jd + degreeGrid1*l), p3+(jd + degreeGrid1*l),(degreeLayer + p2)+(jd + degreeGrid1*l),(degreeLayer + p3)+(jd + degreeGrid1*l), p3+(jd + degreeGrid1*l));						
            }
          }
          indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
            p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
            (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
        }
      }
      else 
      {
        for ( var l = 0; l < (degreeGrid2-1); l++) {
          for ( var j = 0; j < (degreeGrid1-1); j++) {
            indexForLinePoints.push(p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), p6+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), (degreeLayer + p3)+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), (degreeLayer + p6)+(j + degreeGrid1*l),
              p1+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), (degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p2)+(j + degreeGrid1*l), p4+(j + degreeGrid1*l), p5+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),p4+(j + degreeGrid1*l), (degreeLayer + p4)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l),
              (degreeLayer + p1)+(j + degreeGrid1*l),p1+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p1)+(j + degreeGrid1*l), (degreeLayer + p5)+(j + degreeGrid1*l), p5+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l), p2+(j + degreeGrid1*l), p3+(j + degreeGrid1*l),(degreeLayer + p2)+(j + degreeGrid1*l),(degreeLayer + p3)+(j + degreeGrid1*l), p3+(j + degreeGrid1*l));						
          }
        }
      }
      if (this.selectedLayerIndex && (this.selectedLayerIndex != "all")) {
        for( var n = 0; n < indexForLinePoints.length; n++) {
          indexForLinePointsMulti.push((indexForLinePoints[n]) + (degreeLayer*(this.selectedLayerIndex - 1)));
        }	
        if (this.toggle) {
          for(var actindex = (((degreeGrid1-1)*(degreeGrid2-1))*(this.selectedLayerIndex - 1)); actindex < (((degreeGrid1-1)*(degreeGrid2-1))*(this.selectedLayerIndex)); actindex++) {
            indexForActnumSelected.push(this.actnum[actindex]);
          }
          if(this.toggleProfileX && !this.toggleProfileY) {
            let newActnum = [];
            for (var newActnumIndex = 0; newActnumIndex < indexForActnumSelected.length; newActnumIndex++) {
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(indexForActnumSelected[newActnumIndex]);
              }
            }
  
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if (!this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < indexForActnumSelected.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...indexForActnumSelected.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if (this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < indexForActnumSelected.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...indexForActnumSelected.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(indexForActnumSelected[newActnumIndex]);
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          }
          else {
            for (var is = indexForActnumSelected.length-1; is >= 0; is--) {
              if(indexForActnumSelected[is] == 0)	{
                let ids = is*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            } 
          }
        }
      } else {
        for (var k = 0; k <= (this.layersIndexes.length - 2); k++){
          for( var n = 0; n < indexForLinePoints.length; n++) {
            indexForLinePointsMulti.push((indexForLinePoints[n]) + (degreeLayer*k));
          }
        }
        if (this.toggle) {
          if(this.toggleProfileX && !this.toggleProfileY) {
            let newActnum = [];
            for (var newActnumIndex = 0; newActnumIndex < this.actnum.length; newActnumIndex++) {
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(this.actnum[newActnumIndex]);
              }
            }
  
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if(!this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < this.actnum.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...this.actnum.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          } else if (this.toggleProfileX && this.toggleProfileY) {
            let newActnum = [];
            var newActnumLayerIndex = 0;
            for (var newActnumIndex = 0; newActnumIndex < this.actnum.length; newActnumIndex++) {
              if((newActnumIndex == (this.sliderValueY*(degreeGrid1-1)) + (newActnumLayerIndex*((degreeGrid1-1)*(degreeGrid2-1))))) {
                newActnum.push(...this.actnum.slice(newActnumIndex, (newActnumIndex + (degreeGrid1-1))));
                newActnumLayerIndex++;
              }
              if((newActnumIndex - this.sliderValue) % (degreeGrid1-1) == 0 ) {
                newActnum.push(this.actnum[newActnumIndex]);
              }
            }
            for (var iws = newActnum.length-1; iws >= 0; iws--) {
              if(newActnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          }
          else {
            for (var iws = this.actnum.length-1; iws >= 0; iws--) {
              if(this.actnum[iws] == 0)	{
                let ids = iws*36;
                indexForLinePointsMulti.splice(ids,36);
              }	
            }
          }
        }
      }
      this.geometry.setIndex(indexForLinePointsMulti);
      this.geometry.index.needsUpdate = true;
    },
	
    returnToDefaultCameraZoom() {
	
      let maxCurrentCameraCoordinate = Math.max(this.cameraControls.object.position.x, this.cameraControls.object.position.y, this.cameraControls.object.position.z);
      let cameraCoordinatesScalerForBecomingDefault = maxCurrentCameraCoordinate / this.maxInitialCameraCoordinate;
	
      this.cameraControls.object.position.x = this.cameraControls.object.position.x / cameraCoordinatesScalerForBecomingDefault;
      this.cameraControls.object.position.y = this.cameraControls.object.position.y / cameraCoordinatesScalerForBecomingDefault;
      this.cameraControls.object.position.z = this.cameraControls.object.position.z / cameraCoordinatesScalerForBecomingDefault;
      this.camera.updateProjectionMatrix();
    },
	
    run() {
      this.isSuccess = true;
      this.create3DField(true); // this is by tech task given to me : "По умолчанию на белом фоне отображается только направление координатных осей в правом углу области построения 3D модели."
      this.isLoading = false;
      this.interpolated = true;

    },
    displayBarChartChange() {
      const testEl = document.querySelectorAll("#tester");
      if (this.toggleDisplayBarChart) {
        testEl.forEach(e => e.style.visibility = "visible");
      } else {
        testEl.forEach(e => e.style.visibility = "hidden");
      }
    }
  }
};
	
</script>
	<style scoped lang="scss">

.main-container {
  margin-top: 80px;
  margin-left: 0;
}

	.run-button {
	   font-weight: bold;
	   margin: 20px 5px;
	   color: #18A0FB;
	   border: 2px solid #18A0FB;
	   border-radius: 6px;
	   padding: .5rem 2rem;
	   transition: .2s;
	   &:hover {
		  transform: scale(1.1);
	   }
	}
	#three-scene .progress-container {
	   width: 100%;
	   height: 100%;
	   display: flex;
	   align-items: center;
	   justify-content: center;
	   position: absolute;
	   background: #ffffff;
	   z-index: 900;
	}
	#three-scene {
	   //  z-index: 2;
	   position: relative;
	   
	}
	#three-scene > canvas {
	   z-index: 2;
	   position: relative;
	}
	#three-scene-axes-window {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: absolute;
		background-color: #fff;
		border: 1px solid #575757;
		border-radius: 5px;
	   //  left: -180px;
		z-index: 5;
		bottom: 0;
		right: 0;
	}
	.max-color {
	  right: 0;
	  font-size: 14px;
	  bottom: -4px;
	}
	#actnum {
	  margin-right: 10px;
	  cursor: pointer;
	}
	.actnum__label {
	  cursor: pointer;
	}
	#actnum[disabled="disabled"] ~ .actnum__label{
	  color: rgba(0,0,0,.38);
	}
	#profileX, #profileY {
	  margin-left: 17px;
	  margin-right: 17px;
	}
	.profile__label {
	  margin-right: 15px;
	}
	.slider__numb {
	  width: 50px;
	}
	.modebar-container {
		display: none;
	}
	.tester > .plotly > .svg-container > .main-svg:first-child {
		background: rgba(255, 255, 255, 0)!important;
	}
	.gis-checkbox{
		margin: 12px 5px;
	}
	</style>