import httpClient from "@/http";

class HistoryService {
  getHistory(parameter) {
    return httpClient.get(`/api/v1/history/all-wells-history/${parameter}`);
  }

  getParamsNames() {
    return httpClient.get("/api/v1/history/list-history-params/");
  }

}

export default new HistoryService();